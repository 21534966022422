import { Button, Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap-v5";
import { Check2, Mailbox2, X } from "react-bootstrap-icons";
import { InterviewSession, PaginatedRecord, ReviewUser, VJFPUser } from "app/API";
import { getInitials, getStyleByIndex, MappingBranch, MappingClearance, MappingEducation, MappingGeography, MappingType } from "app/helpers/MiscUtils";
import { useCallback, useEffect, useState } from "react";

import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BasePageProps } from "app/types";
import { BoothService } from "app/services/BoothService";
import { ChatRecord } from "app/modules/shared/chat/chat-types";
import { CompanyDocumentService } from "app/services/CompanyDocumentsService";
import { InterviewSessionService } from "app/services/InterviewSessionService";
import { ResumeService } from "app/services/resumeService";
import { ReviewUserService } from "app/services/ReviewUserService";
import { VJFPUserService } from "app/services/VJFPUserService";
import classNames from "classnames";
import { parse } from "zipson/lib";
import { useAuth } from "app/context/auth-context";
import { useAppContext } from "app/context/app-context";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from "moment";
import { getFileUrlFromS3 } from "app/helpers/awsUtils";
import { CandidateProfileForHistory } from "./CandiateProfileForHistory";
import saveAs from "file-saver";
import RecruiterBoothMatrix from "app/modules/shared/RecruiterBoothMatrix";
import { HMSMessage } from "@100mslive/react-sdk";
interface InterviewSessionsItem extends InterviewSession {
    candidate?: VJFPUser;
    recruiter?: VJFPUser;
    reviewUser?: ReviewUser;
}

interface ICandidateResumeItem {
    jobSeeker: VJFPUser
    fileName: string
    resumeUrl?: string
    jobTitle?: string | null
    notes?: string | null
}



const RecruiterHistory = (props: BasePageProps) => {

    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const reviewUserService = new ReviewUserService();
    const vjfpUserService = new VJFPUserService();
    const resumeService = new ResumeService();
    const boothService = new BoothService();
    const companyDocumentService = new CompanyDocumentService();
    const interviewSessionService = new InterviewSessionService();

    const [interviewSessions, setInterviewSessions] = useState<InterviewSessionsItem[]>([]);
    const [resumes, setResumes] = useState<(ICandidateResumeItem)[]>([])
    const [chatCandidate, setChatCandidate] = useState<VJFPUser | undefined>(undefined);
    const [activeInterviewSession, setActiveInterviewSession] = useState<InterviewSession | undefined>(undefined)
    const [chatRecords, setChatRecords] = useState<HMSMessage[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCandidate, setSelectedCandidate] = useState<VJFPUser | undefined>(undefined);
    const [lastExecutedKey, setLastExecutedKey] = useState<any>(undefined);
    const [boothId, setBoothId] = useState<string | undefined>(undefined);

    const getInterviewSession = useCallback(
        async () => {

            if (boothId && jobFair?.id && user?.id) {
                setLoading(true);

                const paginatedInterviewSessions = await interviewSessionService.getInterviewsByBoothId(jobFair.id, boothId, lastExecutedKey) as PaginatedRecord<InterviewSessionsItem[]>;
                const _interviewSessions = [...interviewSessions, ...paginatedInterviewSessions.items];
                setLastExecutedKey(paginatedInterviewSessions.lastEvaluatedKey)
                if (_interviewSessions) {

                    for (let item of _interviewSessions) {
                        if (item?.candidateId)
                            item.candidate = await vjfpUserService.getById(item.candidateId);
                        if (item?.recruiterId)
                            item.recruiter = await vjfpUserService.getById(item.recruiterId);
                        const _review = await reviewUserService.getUserReviewBySessionIdAndRatedBy(jobFair.id, item.id, user.id);
                        if (_review && _review != null)
                            item.reviewUser = _review;
                    }
                    setInterviewSessions(_interviewSessions);
                    if (_interviewSessions.length > 0) {
                        onActiveInterviewSessionIdChanged(_interviewSessions[0].jobFairId, _interviewSessions[0].id, _interviewSessions[0].candidate);
                    }
                }

                setLoading(false);
            }
        },
        [jobFair?.id, user?.id, boothId, lastExecutedKey, interviewSessions]
    )

    useEffect(() => {
        if (!jobFair?.id || !user?.id)
            return;

        boothService.getBoothByRecruiter(jobFair.id, user.id).then(booth => setBoothId(booth?.id));

    }, [jobFair?.id, user?.id])

    useEffect(() => {

        if (!jobFair?.id || !user?.id || !boothId)
            return;

        getInterviewSession().then(async () => {

            const getResumesResult = await resumeService.getResumeList(jobFair.id, boothId);
            if (getResumesResult && Array.isArray(getResumesResult)) {
                let resumeArray: ICandidateResumeItem[] = []
                for (let resumeItem of getResumesResult) {
                    if (resumeItem) {
                        const getDocumentByKeyResult = await getFileUrlFromS3(resumeItem.resumeS3Key)

                        resumeArray.push({
                            jobSeeker: (await vjfpUserService.getById(resumeItem.jobSeekerId)) as VJFPUser,
                            fileName: resumeItem.fileName,
                            resumeUrl: getDocumentByKeyResult.fileUrl,
                            jobTitle: resumeItem.jobTitle,
                            notes: resumeItem.notes
                        });
                    }
                }

                setResumes(resumeArray);
            }
        })

    }, [jobFair?.id, user?.id, boothId]);



    const downloadCSV = () => {
        if (jobFair?.id && boothId)
            interviewSessionService.getCSVInterviewsByBoothId(jobFair.id, boothId)
                .then((data) => {
                    if (data) {
                        const blobUrl = window.URL.createObjectURL(new Blob([data]));
                        saveAs(blobUrl, `history-${moment().format('yyyy-MM-DD')}-job-fair.csv`);
                    }
                });
    }

    useEffect(() => {
        if (activeInterviewSession) {
            if (activeInterviewSession.chatTranscript) {
                setChatRecords(parse(activeInterviewSession.chatTranscript) as HMSMessage[]);
            } else {
                setChatRecords([]);
            }
        }
        else {
            setChatRecords([]);
        }
    }, [activeInterviewSession])

    const onActiveInterviewSessionIdChanged = (jobFairId: string, id?: string, candidate?: any) => {
        if (candidate) {
            setSelectedCandidate(candidate);
        }

        if (id)
            interviewSessionService.getById(jobFairId, id).then(async _interviewSession => {
                if (_interviewSession?.candidateId) {
                    setActiveInterviewSession(_interviewSession);
                    setChatCandidate((await vjfpUserService.getById(_interviewSession.candidateId)));
                }
                else {
                    setActiveInterviewSession(undefined);
                    setChatCandidate(undefined);
                }
            });
    }

    return (
        <div className="chatPage">
            <AppTopNav title={props.title} breadcrumbs={props.breadcrumbs}>
            </AppTopNav>

            <div className="container-fluid">
                <Row className="mt-10">
                    {boothId && (<RecruiterBoothMatrix boothId={boothId}></RecruiterBoothMatrix>)}
                </Row>

                <Row className="mt-10">
                    <Col md={5}>


                        <div className="jobSeekerListing">
                            <div style={{ textAlign: 'right' }}><a onClick={() => {

                                downloadCSV();
                            }}><i className="fas fa-download text-primary"></i> </a></div>
                            <Tabs
                                id="tabs"
                                defaultActiveKey="myConversations"
                                mountOnEnter={true}
                                unmountOnExit={true}
                                onSelect={(k) => {
                                    switch(k){
                                        case 'resumes':
                                            if(resumes?.length){
                                                setSelectedCandidate(resumes[0].jobSeeker);
                                            }
                                        break;
                                        case 'myConversations':
                                            if (interviewSessions?.length) {
                                                setSelectedCandidate( interviewSessions[0].candidate);
                                            }
                                        break;
                                    }
                                }}
                            >
                                <Tab eventKey="myConversations" title="Conversations">
                                    {!loading && interviewSessions && interviewSessions.length === 0 ?
                                        <div className="d-flex border m-10 p-10 justify-content-center text-align-center">
                                            <Mailbox2 color="grey" size={30} />
                                            <p className="m-3">No Conversations</p>
                                        </div> :
                                        <BlockUi tag="div" blocking={loading}>
                                            <Table interviewSessionItems={interviewSessions || []} onActiveInterviewSessionIdChanged={onActiveInterviewSessionIdChanged}></Table>
                                            <div className="text-center">{lastExecutedKey && <Button onClick={getInterviewSession} >Load more</Button>}</div>
                                        </BlockUi>
                                    }
                                </Tab>
                                <Tab eventKey="resumes" title="Resumes and Notes Left at Booth">
                                    {resumes && resumes.length === 0 ?
                                        <div className="d-flex border m-10 p-10 justify-content-center text-align-center">
                                            <Mailbox2 color="grey" size={30} />
                                            <p className="m-3"> No Resumes found</p>
                                        </div> :
                                        <TableResumes jobFairId={jobFair?.id||'0'} resumes={resumes} onActiveInterviewSessionIdChanged={onActiveInterviewSessionIdChanged}></TableResumes>}
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                    <Col md={7} >
                        <div className="historyPage">
                           <CandidateProfileForHistory candidate={selectedCandidate} chatRecords={chatRecords}
                                    score={activeInterviewSession?.candidateScore} maxScore={activeInterviewSession?.candidateMaxScore}
                                    chatCandidate={chatCandidate} recruiterZoomId={activeInterviewSession?.recruiterZoomId || 0}></CandidateProfileForHistory>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}


function Table(props: { activeInterviewSession?: InterviewSession, interviewSessionItems: InterviewSessionsItem[], onActiveInterviewSessionIdChanged: (jobFairId: string, id: string | undefined, candidate: any | undefined) => void }) {

    return (<div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
                <tr className="fw-bolder text-muted">
                    <th className="min-w-100px">Name {props.activeInterviewSession?.id}</th>
                    <th className="min-w-100px">Rating</th>
                    <th className="min-w-200px">Notes</th>
                    <th className="min-w-200px">Interviewer</th>
                </tr>
            </thead>
            <tbody>
                {props.interviewSessionItems.map((_isession, index) => <tr key={index} className={classNames({ "bg-light": props.activeInterviewSession?.id === _isession.id })}>
                    <td>
                        <div className="d-flex align-items-center">
                            {_isession?.candidate?.avatar && _isession?.candidate?.avatar !== 'NULL' ?
                                <Image src={_isession.candidate.avatar} />
                                :
                                <div className="symbol symbol-50px">
                                    <div className={"symbol-label fs-2 fw-bold bg-" + getStyleByIndex(index) + " text-inverse-" + getStyleByIndex(index)}>{getInitials(_isession?.candidate?.firstName, _isession?.candidate?.lastName)}</div>
                                </div>
                            }
                            <div className="d-flex ms-3 justify-content-start flex-column">
                                <a onClick={() => { props.onActiveInterviewSessionIdChanged(_isession.jobFairId, _isession.id, _isession.candidate) }} className="text-dark fw-bolder text-hover-primary fs-6">{_isession?.candidate?.firstName} {_isession?.candidate?.lastName}</a>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div className="d-flex flex-column w-100 me-2">
                            <span className="me-2 fs-7 fw-bold">{_isession?.reviewUser?.rating}</span>
                        </div>
                    </td>
                    <td>
                        {_isession?.reviewUser?.notes}
                    </td>
                    <td>
                        {_isession?.recruiter?.firstName || ''} {_isession?.recruiter?.lastName || ''}
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>);
}


function TableResumes(props: { resumes?: ICandidateResumeItem[] , jobFairId: string, onActiveInterviewSessionIdChanged: (jobFairId: string, id: string | undefined, candidate: any | undefined) => void }) {
    return (<table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <thead>
            <tr className="fw-bolder text-muted">
                <th className="min-w-100px">Candidate</th>
                <th className="min-w-100px">Resume</th>
                <th className="min-w-100px">Job Title & Notes</th>
            </tr>
        </thead>
        <tbody>
            {props.resumes && props.resumes.map((resumeDetail, index) => <tr key={index}>
                <td>
                    <div className="d-flex align-items-center">
                        {resumeDetail?.jobSeeker?.avatar ? <Image src={resumeDetail?.jobSeeker.avatar} /> : <div className="symbol symbol-50px">
                            <div className={"symbol-label fs-2 fw-bold bg-" + getStyleByIndex(index) + " text-inverse-" + getStyleByIndex(index)}>{getInitials(resumeDetail?.jobSeeker?.firstName, resumeDetail?.jobSeeker?.lastName)}</div>
                        </div>}
                        <div className="d-flex ms-3 justify-content-start flex-column">
                            <a onClick={() => { props.onActiveInterviewSessionIdChanged(props.jobFairId, undefined, resumeDetail.jobSeeker) }} className="text-dark fw-bolder text-hover-primary fs-6 mb-2">{resumeDetail?.jobSeeker?.firstName} {resumeDetail?.jobSeeker?.lastName}</a>
                            <span className="text-muted fw-bold text-muted d-block fs-7">{resumeDetail?.jobSeeker?.designation}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <a href={resumeDetail.resumeUrl}><i className="fas fa-download text-primary"></i> {resumeDetail.fileName}  </a>
                </td>
                <td>
                    <p>[{resumeDetail.jobTitle || "-"}] - {resumeDetail.notes}</p>
                </td>
            </tr>)}
        </tbody>
    </table>);
}

export default RecruiterHistory;
