import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useFormik } from 'formik'
import { Col, Container, Row, Tab, Tabs, Card, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PencilFill, TrashFill, Folder2Open, Trash } from "react-bootstrap-icons";
import * as Yup from 'yup'

import { BasePageProps, Roles } from "app/types";
import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BoothService } from "app/services/BoothService";
import { useAuth } from "app/context/auth-context";
import styles from './ManageBoothInfo.module.scss';
import { IUploadCompanyDocumentsInput, CompanyDocumentService } from "app/services/CompanyDocumentsService";
import { useAppContext } from "app/context/app-context";
import { AlertModal } from "app/modules/shared/modals/AlertModal";
import 'react-block-ui/style.css';
import { Booth, Question} from "app/API";
import { useParams } from "react-router-dom";
import RecruiterBoothMatrix from "../RecruiterBoothMatrix";
import GroupCard from "./GroupCard/GroupCard";
import QueueQuestionFormComponent from "./QueueQuestions/QueueQuestions";

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024; //10 MB

interface IGeneralInfoForm {
    description: string
    hotJobSummary: string
    defaultInterviewDuration: number
}

interface IQueueQuestionForm {
    queueQuestion: Array<QuestionModel>
    minimumScore: number
}

const generalInfoFormSchema = Yup.object().shape({
    description: Yup.string().required(),
    hotJobSummary: Yup.string().required(),
})

const queueQuestionFormSchema = Yup.object().shape({
    queueQuestion: Yup.array().required(),
    minimumScore: Yup.number().required().min(0),
});

const generalInfoFormInitialValues: IGeneralInfoForm = {
    description: '',
    hotJobSummary: '',
    defaultInterviewDuration: 10
}

const queueQuestionFormInitialValues: IQueueQuestionForm = {
    queueQuestion: [],
    minimumScore: 0
}

const companyDocumentsInitialValues: IUploadCompanyDocumentsInput = {
    fileName: '',
}
type PageParams = {
    boothId: string
}

interface QuestionModel extends Question {
    isEdit: boolean
}

interface ManageBoothInfoProps extends BasePageProps {
    showMatrix: boolean
}

export const ManageBoothInfo = (props: ManageBoothInfoProps) => {
    let params = useParams<PageParams>();

    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const boothService = new BoothService();
    const companyDocumentService = new CompanyDocumentService();
    const [booth, setBooth] = useState<Booth | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showQuestionsPreviewModal, setShowQuestionsPreviewModal] = useState<boolean>(false);
    const [uploadCompanyDocumentFiles, setUploadCompanyDocumentFiles] = useState<IUploadCompanyDocumentsInput[]>([]);
    const [listCompanyDocumentFile, setListCompanyDocumentFile] = useState<any[]>([]);
    const [deleteCompanyDocumentFile, setDeleteCompanyDocumentFile] = useState<string[]>([]);
    const [showSubmittedAlert, setShowSubmittedAlert] = useState<boolean>(false);

    const companyGeneralInfoForm = useFormik({
        initialValues: generalInfoFormInitialValues,
        validationSchema: generalInfoFormSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)

            if (jobFair?.id && booth?.id) {
                boothService.updateBoothInfo(jobFair.id, booth.id, values.description, values.hotJobSummary, values.defaultInterviewDuration)
                    .then(() => {
                        setLoading(false);
                        setShowSubmittedAlert(true);
                    })
                    .catch(() => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Something went wrong.')
                    });
            }
        },
    });

    const companyDocumentsForm = useFormik({
        initialValues: companyDocumentsInitialValues,
        // validationSchema: manageBoothSchema,
        onSubmit: () => {
            if (!booth?.id)
                return;
            let companyDocumentPromises: Array<Promise<any>> = [];
            //upload Document files
            companyDocumentPromises.push(companyDocumentService.uploadDocumentsForBooth(booth.id, uploadCompanyDocumentFiles, MAX_FILE_UPLOAD_SIZE));

            //delete Document Files
            companyDocumentPromises.push(companyDocumentService.deleteDocuments(deleteCompanyDocumentFile));

            Promise.all(companyDocumentPromises).then(() => {
                companyDocumentService.getAllDocumentsByBooth(booth.id).then((companyDocumentResponse: any) => {
                    setListCompanyDocumentFile(companyDocumentResponse);
                });
            });
        },
    });

    useEffect(() => {
        if (!user || !jobFair?.id) return;
        setUploadCompanyDocumentFiles([])
        setListCompanyDocumentFile([])
        setDeleteCompanyDocumentFile([])

        boothService.getBoothById(jobFair?.id, params.boothId).then(async (_booth: Booth | undefined) => {
            if (!_booth)
                return;
            setBooth(_booth);
        });
    }, [jobFair, user, params.boothId]);

    useEffect(() => {
        if (!booth || !jobFair?.id)
            return;

        companyGeneralInfoForm.setValues({
            ...booth,
            description: booth.description || '',
            hotJobSummary: booth.hotJobSummary || '',
            defaultInterviewDuration: booth.chatDuration ? booth.chatDuration / 60000 : 10
        });

        companyDocumentService.getAllDocumentsByBooth(booth.id).then((companyDocumentResponse: any) => {
            setListCompanyDocumentFile(companyDocumentResponse);
        });
    }, [booth]);

    const handleCompanyDocumentDelete = (index: number) => {
        let listCompanyDocumentArray: any = [...listCompanyDocumentFile]
        let deleteCompanyDocumentArray: any = [...deleteCompanyDocumentFile]

        deleteCompanyDocumentArray.push(
            listCompanyDocumentArray[index].key
        )

        listCompanyDocumentArray.splice(index, 1)

        setListCompanyDocumentFile(listCompanyDocumentArray)
        setDeleteCompanyDocumentFile(deleteCompanyDocumentArray)
    }

    const handleUploadCompanyDocumentDelete = (index: number) => {
        let newData = [...uploadCompanyDocumentFiles]
        newData.splice(index, 1)
        setUploadCompanyDocumentFiles(newData)

    }

    const handleUploadCompanyDocumentEditClick = (index: number) => {
        let newData = [...uploadCompanyDocumentFiles]
        newData[index].isEdit = !newData[index].isEdit;
        setUploadCompanyDocumentFiles(newData)

    }

    const handleUploadCompanyDocumentEditChange = (e: any, index: number) => {
        let newData = [...uploadCompanyDocumentFiles]
        if (newData[index].fileObj) {
            newData[index].fileName = e.target.value;
        } else {
            newData[index].fileLink = e.target.value;
        }
        setUploadCompanyDocumentFiles(newData)

    }

    const handleFileSelected = ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>
        , index: number) => {
        if (files && files.length) {

            let fileArray = Array.from(files);
            let isNewDocPushed = false;

            let fileDoc: Array<IUploadCompanyDocumentsInput> = [...uploadCompanyDocumentFiles];
            for (let fileItem of fileArray) {
                if (fileItem.size <= MAX_FILE_UPLOAD_SIZE) {
                    if (fileDoc.length > index) {
                        fileDoc[index].fileName = fileItem.name
                        fileDoc[index].fileObj = fileItem
                        fileDoc[index].isEdit = false
                    } else {
                        fileDoc.push({
                            fileName: fileItem.name,
                            fileObj: fileItem,
                            isEdit: false
                        });
                    }
                    isNewDocPushed = true;
                } else {
                    console.log(fileItem?.name + " - is larger than 10MB. This file will not be uploaded.")
                }

            }

            if (isNewDocPushed) {
                fileDoc.push({
                    fileName: '',
                    fileObj: null,
                    isEdit: false
                });
            }

            setUploadCompanyDocumentFiles(fileDoc);

        }
    }

    const renderUploadDocumentComponent = (index: number) => {

        if (uploadCompanyDocumentFiles.length === index
            || uploadCompanyDocumentFiles[index].fileName === '') {
            return (<div id={`upload-${index}`} key={index}>
                <hr />
                <div className='fv-row my-5 d-flex align-items-center'>
                    <label className='form-control form-control-lg'>
                        {uploadCompanyDocumentFiles.length > index ?
                            (uploadCompanyDocumentFiles[index].fileName ?
                                uploadCompanyDocumentFiles[index].fileName :
                                'No file selected')
                            : 'No file selected'}
                    </label>
                    <label className={styles.browse}>
                        <input
                            type="file"
                            accept="application/pdf"
                            required
                            onChange={(e) => handleFileSelected(e, index)} />
                        <span className={styles.spanWrapper}>
                            <Folder2Open fill="#18924f" size="20px" />
                            Browse
                        </span>
                    </label>

                    {companyDocumentsForm.touched.fileName && companyDocumentsForm.errors.fileName && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert'>{companyDocumentsForm.errors.fileName}</span>
                        </div>
                    )}
                </div>

            </div>
            )
        } else {
            return (<div id={`upload-${index}`} key={index}>
                <hr />

                <div className='fv-row my-5 d-flex align-items-center'>
                    <input
                        disabled={!(uploadCompanyDocumentFiles.length > index
                            && uploadCompanyDocumentFiles[index].isEdit)}
                        className='form-control form-control-lg'
                        type="text"
                        name='fileLink'
                        value={uploadCompanyDocumentFiles.length > index ?
                            (uploadCompanyDocumentFiles[index].fileObj ?
                                uploadCompanyDocumentFiles[index].fileName
                                : uploadCompanyDocumentFiles[index].fileLink)
                            : ''}
                        onChange={(e) => handleUploadCompanyDocumentEditChange(e, index)}
                    />
                    <Button
                        disabled={(uploadCompanyDocumentFiles.length > index
                            && uploadCompanyDocumentFiles[index].isEdit)}
                        id='handle-add-files'
                        variant="success"
                        className={styles.editIcon}
                        style={{ margin: "0 0 0 8px" }}
                        onClick={() => handleUploadCompanyDocumentEditClick(index)}
                    >
                        <PencilFill fill="#ffa600" size="20px" />

                    </Button>

                    <Button
                        disabled={!(uploadCompanyDocumentFiles.length > 0
                            && uploadCompanyDocumentFiles[index].fileName != '')}
                        id='handle-add-files'
                        variant="success"
                        className={styles.addIcon}
                        style={{ margin: "0 0 0 8px" }}
                        onClick={() => handleUploadCompanyDocumentDelete(index)}
                    >
                        <TrashFill fill="#ff0040" size="20px" />

                    </Button>

                    {companyDocumentsForm.touched.fileName && companyDocumentsForm.errors.fileName && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert'>{companyDocumentsForm.errors.fileName}</span>
                        </div>
                    )}
                </div>
            </div>
            )
        }

    }

    return (
        <div className="manage-booth-info">
            <AppTopNav title={props.title} breadcrumbs={props.breadcrumbs}>

            </AppTopNav>
            <Container>
                <Row className="mt-10 ms-4 me-10">
                    {props.showMatrix && booth?.id && (<RecruiterBoothMatrix boothId={booth.id}></RecruiterBoothMatrix>)}
                </Row>
                <Row>
                    <Col md={12}>
                        <Tabs defaultActiveKey="generalInfo" className='pt-10 w-100 px-9'>
                            <Tab id="tabs" eventKey="generalInfo" title="General Information">
                                <div className='pb-10 w-100 px-9'>
                                    <Card style={{ width: '100%' }}>
                                        <Card.Body>
                                            <form
                                                className='form'
                                                onSubmit={companyGeneralInfoForm.handleSubmit}
                                                noValidate
                                            >
                                                {companyGeneralInfoForm.status && (
                                                    <div className='mb-lg-15 alert alert-danger'>
                                                        <div className='alert-text font-weight-bold'>{companyGeneralInfoForm.status}</div>
                                                    </div>
                                                )}

                                                <div className='fv-row mb-10'>
                                                    <label className='form-label fs-6 fw-bolder text-dark'>Company Description</label> <i className='text-dark'> (contact admin@corporategray.com if changes are needed)</i>
                                                    <textarea
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Description'
                                                        {...companyGeneralInfoForm.getFieldProps('description')}
                                                        rows={5}
                                                        name='description'
                                                        autoComplete='off'
                                                        readOnly={true}
                                                    />
                                                    {companyGeneralInfoForm.touched.description && companyGeneralInfoForm.errors.description && (
                                                        <div className='fv-plugins-message-container'>
                                                            <span role='alert'>{companyGeneralInfoForm.errors.description}</span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='fv-row mb-10'>
                                                    <label className='form-label fs-6 fw-bolder text-dark'>Hot Jobs Summary</label> <i className='text-dark'>(contact admin@corporategray.com if changes are needed)</i>
                                                    <textarea
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Hot Jobs Summary'
                                                        {...companyGeneralInfoForm.getFieldProps('hotJobSummary')}
                                                        rows={5}
                                                        name='hotJobSummary'
                                                        autoComplete='off'
                                                        readOnly={true}
                                                    />
                                                    {companyGeneralInfoForm.touched.hotJobSummary && companyGeneralInfoForm.errors.hotJobSummary && (
                                                        <div className='fv-plugins-message-container'>
                                                            <span role='alert'>{companyGeneralInfoForm.errors.hotJobSummary}</span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='fv-row mb-10'>
                                                    <label className='form-label fs-6 fw-bolder text-dark'>Default Interview Duration</label>
                                                    <input type='number' pattern='[0-9]{0,3}'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Default Interview Duration'
                                                        {...companyGeneralInfoForm.getFieldProps('defaultInterviewDuration')}
                                                        name='defaultInterviewDuration'
                                                        autoComplete='off'
                                                    />
                                                    {companyGeneralInfoForm.touched.defaultInterviewDuration && companyGeneralInfoForm.errors.defaultInterviewDuration && (
                                                        <div className='fv-plugins-message-container'>
                                                            <span role='alert'>{companyGeneralInfoForm.errors.defaultInterviewDuration}</span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='d-flex text-center'>
                                                    <Button
                                                        id="manage-booth-buttons-submit-general-info-form"
                                                        variant="dark"
                                                        type='submit'
                                                        className='ml-5 mb-5'
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </form>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Tab>
                          
                            <Tab id="tabs" eventKey="fileName" title="Company Documents">
                                <div className='pb-10 w-100 px-9'>
                                    <Card style={{ width: '100%' }}>
                                        <Card.Body>
                                            <form
                                                className='form'
                                                onSubmit={companyDocumentsForm.handleSubmit}
                                                noValidate
                                            >
                                                {companyDocumentsForm.status && (
                                                    <div className='mb-lg-15 alert alert-danger'>
                                                        <div className='alert-text font-weight-bold'>{companyDocumentsForm.status}</div>
                                                    </div>
                                                )}

                                                <i style={{ color: "#000" }}>Upload documents to give job seeker more information about your company.</i>

                                                <hr style={{ margin: "1rem -2.25rem", opacity: 0.15 }} />
                                                {listCompanyDocumentFile && listCompanyDocumentFile.map((item: any, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div key={`div-${index}`} className='fv-row my-5 d-flex justify-content-between'>

                                                                <div className={styles.filenameWrapper}>
                                                                    <img alt='' className='w-30px me-3' src={'/media/svg/files/pdf.svg'} />
                                                                    <a href={item.fileUrl}>{item.fileName}</a>
                                                                </div>

                                                                <Button
                                                                    key={`delete-button-${index}`}
                                                                    id={`handle-delete-icons-${index}`}
                                                                    onClick={() => handleCompanyDocumentDelete(index)}
                                                                    variant="none"
                                                                    className={styles.removeFileIcon}
                                                                >
                                                                    <TrashFill key={`trash-${index}`} id={`trash-${index}`} fill="#ff0040" size="20px" />
                                                                    <span>Remove File</span>
                                                                </Button>
                                                            </div>
                                                            <hr style={{ margin: "1rem -2.25rem", opacity: 0.15 }} />
                                                        </div>
                                                    )
                                                })}

                                                {(!listCompanyDocumentFile || !listCompanyDocumentFile.length) && (
                                                    <>
                                                        <p>No Documents Found.</p>
                                                        <hr style={{ margin: "1rem -2.25rem", opacity: 0.15 }} />
                                                    </>
                                                )}

                                                <h5>Upload Document</h5>

                                                {renderUploadDocumentComponent(0)}

                                                {uploadCompanyDocumentFiles && uploadCompanyDocumentFiles.map((uploadDoc, index) => {
                                                    if (index === 0) return null;
                                                    return renderUploadDocumentComponent(index)
                                                })
                                                }

                                                <div className='d-flex text-center'>

                                                    <Button
                                                        id="manage-booth-buttons-submit-document-form"
                                                        variant="dark"
                                                        type='submit'
                                                        className='ml-5 mb-5'
                                                    >
                                                        Save Changes
                                                    </Button>
                                                </div>
                                            </form>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Tab>
                            <Tab id="tabs" eventKey="subBooths" title="Groups">
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <label className='form-label fs-6 fw-bolder text-dark'>Groups</label>
                                        <div>
                                            {booth && booth?.recruiters &&
                                                <GroupCard parentBoothName={booth.name} unassignedRecruiters={booth.recruiters} groups={booth.subBooths || []} onSave={(groups) => {
                                                    if (jobFair?.id)
                                                        boothService.updateBooth(jobFair.id, { ...booth, subBooths: groups }).then(() => {
                                                            setShowSubmittedAlert(true);
                                                        });

                                                }}></GroupCard>
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab id="tabs" eventKey="queueQuestion" disabled={booth?.subBooths != null && booth.subBooths.length > 0} title="Queue Questions">
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <label className='form-label fs-6 fw-bolder text-dark'>Queue Questions</label>
                                        <p>Enter or edit the queue questions below that job seekers will be asked when they enter your booth queue.</p>
                                        {booth && <QueueQuestionFormComponent booth={booth} saveMinScore={(score) => {
                                            new BoothService().updateBooth(jobFair!.id, {
                                                id: booth.id,
                                                minimumScore: score
                                            });
                                            setBooth((_booth) => {
                                                if (_booth)
                                                    return { ..._booth, minimumScore: score };
                                            });
                                        }}></QueueQuestionFormComponent>}
                                    </Card.Body>
                                </Card>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
            <AlertModal show={showSubmittedAlert} title="Changes Saved" onOK={() => { setShowSubmittedAlert(false) }}>

            </AlertModal>
        </div >
    );
}
