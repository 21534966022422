import { BasePageProps, Roles } from "app/types";
import { Booth, EndReason, InterviewSession, QueueUser, QueueUserStatus, GroupType, UserStatus, VJFPUser, VideoSessionStatus } from "app/API";
import { BoxArrowInUpRight, ChatQuoteFill, PencilSquare, Recycle } from "react-bootstrap-icons";
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ChangeQueueUserPubsub, LocalAppEventPubSub, UpdatePresencePubsub } from "app/app-events";
import { useCallback, useEffect, useRef, useState } from "react";
import { getInitials, getUserStatus, UrlParamReplace } from "app/helpers/MiscUtils";
import { setupAmplifyPresenceSubscriptions, setupInterviewSessionSubscriptionsByRecruiterId, setupQueueSubscriptionsByBoothId } from "app/amplify-subscriptions";

import { AppModal } from "app/modules/shared/modals/AppModal";
import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BoothService, ActiveBoothViewCounterState } from "app/services/BoothService";
import { JobSeekerListItem } from "app/modules/shared/profile/JobSeekerListItem";
import { MessageService } from "app/services/MessageService";
import { QueueUserService } from "app/services/QueueUserService";
import { RecruiterListItem } from "app/modules/shared/profile/RecruiterListItem";
import { RecruiterRoutes } from "../RecruiterPage";
import { VJFPUserService } from "app/services/VJFPUserService";
import { useAuth } from "app/context/auth-context";
import { useHistory } from "react-router-dom";
import { useAppContext } from "app/context/app-context";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { AlertModal } from "app/modules/shared/modals/AlertModal";
import { LocalAppEventType } from "app/app-models";
import _ from "lodash";
import { useDebounceFn } from "app/hooks";
import { QuestionAnswerService } from "app/services/QuestionAnswerService";
import useIsMounted from "app/hooks/useIsMounted";
import { RxSubscription } from "app/RxSubscriptions";
import { AvailabilitySelector } from "app/modules/shared/availability-selector/AvailabilitySelector";
import { InterviewSessionService } from "app/services/InterviewSessionService";

type Disposable = { dispose: () => void; };
const disposables: Disposable[] = [];
const questionAnswerService = new QuestionAnswerService();
export const RecruiterBoothView = (props: BasePageProps) => {
    console.log('re-rendering recruiter booth view');
    const { user } = useAuth();
    const { jobFair } = useAppContext()
    const [loading, setLoading] = useState<boolean>(false);
    const [booth, setBooth] = useState<Booth | undefined>(undefined);

    const [showBroadCastModal, setBroadCastModal] = useState<boolean>(false);
    const [broadCastType, setBroadCastType] = useState<"Booth" | "Queue">("Booth")
    const [broadCastMessage, setBroadCastMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [totalQuestions, setTotalQuestions] = useState<number | null>(null);
    const messageService = new MessageService();
    const queueUserService = new QueueUserService();
    const history = useHistory();
    const [queueUsers, setQueueUsers] = useState<QueueUser[]>([]);
    const [VJFPUsers, setVJFPUsers] = useState<VJFPUser[]>([]);
    const [recruiters, setRecruiters] = useState<VJFPUser[]>([]);
    const [presenceSubscription, setPresenceSubscription] = useState<Map<number, Disposable>>(new Map<number, Disposable>());
    const [disableStartInterview, setDisableStartInterview] = useState<boolean>(false);

    const [refreshDisable, setRefreshDisable] = useState<boolean>(false);
    const isMounted = useIsMounted('RecruiterBoothView');
    const refreshing = useRef<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [showEnded, setShowEnded] = useState<boolean>(false);
    const quInterviewTimeoutRef = useRef<NodeJS.Timeout | undefined>();

    /* Setup  (page load)
       * - get booth for current user (recruiter)
       * - list job seekers in booth. 
       * - list all recruiters in booth.
       * - Calculate 'Job Seeker Booth State' for each job seeker in booth and set as initial booth state. (See Below)
       * 
       * - Subscribe to all queue users for current jobfair (additions and deletions) 
       *   . For queue user's in the current booth
       *       -- When Job Seeker added: 
       *          -- Subscribe to the job seeker's presence updates
       *          -- Calculate Job Seeker's Booth Status
       * 
       *       -- When Job Seeker removed: Dispose the Job Seeker's presence updates
       * 
       *   . For queue user updates in other booths
       *      -- Determine if user is present in current booth
       *          present: Calculate User's Booth Status
       *          not present: Skip.
       *     
       * - Subscribe Job Seeekers presence updates (status changes)
       *      -- When presence changed: Caluclate Job Seeker's Booth Status by refreshing Queue
       * 
       * - For each user calculate 'user's booth state'. This is the state that should be applied to the user based on the user's state in all queues.  (see Calculate User's Booth State)
       * 
       *
       *  Calculate User's booth state: 
       *    Assumption: Booth Job Seekers are sorted by priority (-1 interviewing, 0 next to interview, 1 invited, >= 2 Score)
       *    Calculate the number of "free next-to-interview slots" this is the number of: current-users-in-next-to-interview-state + current-users-intreviewing 'minus' the-number-of-logged-in-recruiters
       *    Fetch all the user's queue participations
       *      -- if any participatin is in status "Interviewing" or "next to interview" (queue user priority IN [-1, 0]) set the user's status as busy and continue with next. 
       *      -- if there are available next-to-interview slots, set the user's booth status in next to interview: update priority to 0
       */


    // Get Booth for user (recruiter)
    useEffect(() => {
        if (!jobFair?.id || !user?.id)
            return;

        (async () => {
            const boothService = new BoothService();

            const _booth = await boothService.getBoothByRecruiter(jobFair.id, user.id)
            if (!isMounted()) return;
            if (_booth?.id) {
                setBooth(_booth);
                window.sessionStorage.setItem('recruiterBoothId', _booth.id);
            }
        })();

        return () => {
            window.sessionStorage.removeItem('recruiterBoothId');
        }
    }, [jobFair?.id, user?.id]);

    // List all Job Seekers for the current's Booth Queue
    // List all recruiters for the current booth
    useEffect(() => {

        if (!jobFair?.id || !booth?.id)
            return;

        setLoading(true);

        refresh().then(() => {
            if (!isMounted()) return;
            setLoading(false);
            setInterval(async () => {
                if (!refreshing.current) {
                    await refresh();
                }
            }, 10000);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })

        const _groups = booth.id.split('-');
    
        questionAnswerService.getAllQueueQuestionsByBoothId(jobFair.id, _groups.length == 2 ? _groups[1]: booth.id).then(
            queueQuestions => setTotalQuestions(queueQuestions?.length || 0)
        );
    }, [jobFair?.id, booth?.id]);

    // Subscribe to Queue Users
    // Subscribe to Booth Recruiters

    useEffect(() => {

        if (!jobFair?.id || !booth?.id)
            return;

        // // Setting up Queue User Subscriptions
        const queueSubscriptionDisposable = setupQueueSubscriptionsByBoothId(booth.id);

        const subscription = RxSubscription.onChangeQueueUserByBoothIdObservable().subscribe(async (event) => {
            if (!event?.value) return;
            await refresh(false, true, false);
        });

        const subscriptionBoothViewCounter = ActiveBoothViewCounterState.getBoothViewCounterObservable().subscribe(async counter => {
            if (!isMounted()) return;
            await refresh(false, true, true);
        });

        let disposable = UpdatePresencePubsub.subscribe(async (eventType, event) => {
            if (!isMounted()) return;
            if (!event) return;
            if (event.roles?.includes(Roles.Recruiter)) {
                await refresh(false, true, true);
            }
            // else if (event.roles?.includes(Roles.Applicant))
            //     await refresh(false, true, false);
        });

        disposables.push(disposable);

        disposable = LocalAppEventPubSub.subscribe(async (eventType, event) => {
            if (!isMounted()) return;
            if (event.type === LocalAppEventType.AmplifySubscriptionError) {
                setTimeout(() => { window.location.reload() }, 2000);
            }
            if (event.type === LocalAppEventType.AvailabilityUpdateEvent) {
                setTimeout(async () => { await _debouncedRefresh.run(); }, 2000);
            }
        });

        disposables.push(disposable);

        return (() => {
            subscription.unsubscribe();
            disposables.forEach(disposable => {
                if (disposable)
                    disposable.dispose();
            });
            subscriptionBoothViewCounter.unsubscribe();
            if (queueSubscriptionDisposable) queueSubscriptionDisposable.dispose();
        });

    }, [booth?.id, jobFair?.id]);


    const _refresh = async (refreshQueueUsers: boolean = true, refreshRecruiters: boolean = true) => {
        if (!isMounted()) return;
        if (!jobFair?.id || !booth?.id || !user?.id)
            return;
        console.log(`refreshing queue at ${new Date()}`);
        try {
            refreshing.current = true;

            const vjfpService = new VJFPUserService();
            const queueUserService = new QueueUserService();

            const getQueueUsers = async () => {
                const queueUsers: QueueUser[] | undefined = await queueUserService.getQueueUsersByBoothId(jobFair.id, booth.id);
                return queueUsers || [];
            }

            const getVJFPUsers = async (users: QueueUser[]) => {
                const vjfpUsers = await vjfpService.getByIds(users.map(x => x.vjfpUserId));
                return vjfpUsers || [];
            }

            const getBoothRecruiters = async () => {
                const recruiterIds: number[] = [];
                if (booth.recruiters?.length) {
                    booth.recruiters.forEach(r => {
                        if (r) {
                            recruiterIds.push(r);
                        }
                    });
                    const recruiters = await vjfpService.getByIds(recruiterIds);
                    if (recruiters?.length)
                        return recruiters.sort(function (a, b) {
                            const p1 = a.presenceStatus === UserStatus.LOGGED_IN ? 1 : 0;
                            const p2 = b.presenceStatus === UserStatus.LOGGED_IN ? 1 : 0;
                            return p2 - p1;
                        });
                }
                return [];
            }

            const disposeAndRemovePresenceSubscription = (removedUsers: VJFPUser[]) => {
                if (!isMounted()) return;
                setPresenceSubscription((prev) => {
                    const newState: Map<number, Disposable> = new Map(prev);
                    removedUsers.forEach(r => {
                        newState.get(r.id)?.dispose();
                        newState.delete(r.id);
                    });
                    return newState;
                });
            }

            // By default refreshQueueUsers is true
            if (refreshQueueUsers) {

                // Fetch Booth's Queue Users from DB 
                try {
                    const _queueUsers = (await getQueueUsers() || []).filter(v => v.vjfpUserId != null);

                    const vjfpJobSeekers = await getVJFPUsers(_queueUsers)

                    // J1,J2,J3  --- (J1,J2,J4)  -- J3 to be removed and j4 to be added 
                    // VJFPUsers(from state) - vjfpJobSeekers = removed Users
                    const vjfpJobSeekersFiltered = vjfpJobSeekers.filter(v => v) as VJFPUser[];
                    const removedUsers = VJFPUsers.filter(({ id: id1 }) => !vjfpJobSeekersFiltered.some(({ id: id2 }) => id2 === id1));
                    disposeAndRemovePresenceSubscription(removedUsers);

                    vjfpJobSeekersFiltered.forEach(j => {
                        if (!presenceSubscription.get(j.id)) {
                            presenceSubscription.set(j.id, setupAmplifyPresenceSubscriptions([j.id]));
                        }
                    });

                    if (!isMounted()) return;
                    setVJFPUsers(vjfpJobSeekersFiltered || []);

                    const _qu = _queueUsers.find(x => x.priority === -1 && x.interviewer === user.id);
                    if (_qu && !quInterviewTimeoutRef.current) {
                        quInterviewTimeoutRef.current = setTimeout(async () => {
                            try {
                                if (!isMounted()) return;
                                quInterviewTimeoutRef.current = undefined;

                                const interviewSessionService = new InterviewSessionService();
                                const i = await interviewSessionService.getById(jobFair.id, _qu.id);

                                if (i.status === VideoSessionStatus.IN_PROGRESS) {
                                    const url = UrlParamReplace(RecruiterRoutes.Interview, ':sessionId', i.id);
                                    history.push(url);
                                } else if (i.status === VideoSessionStatus.ENDED) {
                                    RxSubscription.clearInterviewSessionByRecruiterId();
                                    setShowEnded(true);

                                    if (i.endReason === EndReason.TIMED_OUT) {
                                        setAlertMessage('Interview ended -- This session timed out due to the job seeker not joining the session.');
                                    }
                                }
                            } catch (error) {
                                console.error('Error handling interview session:', error);
                            }
                        }, 60000);
                    }

                    if (!isMounted()) return;
                    setQueueUsers(_queueUsers);
                }
                catch (error) {
                    console.error(error);
                }
                finally {
                    refreshing.current = false;
                }
            }

            // By default refreshQueueUsers is true
            if (refreshRecruiters) {
                try {
                    // Fetch Booth's Recruiters
                    const boothRecruiters = await getBoothRecruiters();
                    const boothRecruitersFiltered = boothRecruiters.filter(v => v) as VJFPUser[];
                    const removedUsers = recruiters.filter(({ id: id1 }) => !boothRecruitersFiltered.some(({ id: id2 }) => id2 === id1));
                    disposeAndRemovePresenceSubscription(removedUsers);
                    if (!isMounted()) return;

                    boothRecruitersFiltered.forEach(j => {
                        if (!presenceSubscription.get(j.id)) {
                            presenceSubscription.set(j.id, setupAmplifyPresenceSubscriptions([j.id]));
                        }
                        if (j.id == user.id) {

                            if (!j.isAvailableForChat) {
                                setDisableStartInterview(true);
                            } else {
                                setDisableStartInterview(false);
                            }
                        }
                    });
                    if (!isMounted()) return;
                    setRecruiters(boothRecruitersFiltered || []);
                } catch (error) {
                    console.error(error);
                }
                finally {
                    refreshing.current = false;
                }
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            refreshing.current = false;
        }
    }

    const _debouncedRefresh = useDebounceFn(_refresh, 2000);

    const refresh = async (showLoader: boolean = false, refreshQueueUsers: boolean = true, refreshRecruiters: boolean = true) => {
        if (!isMounted()) return;
        if (refreshing.current) {
            return;
        }

        if (showLoader) {
            setLoading(true);
        }
        try {
            await _debouncedRefresh.run(refreshQueueUsers, refreshRecruiters);
        }
        finally {
            setLoading(false);
        }
    }

    const navigateToManageBooth = () => {
        if (booth?.id)
            history.push(UrlParamReplace(RecruiterRoutes.ManageBoothInfo, ':boothId', booth.parentBoothId || booth.id));
    }

    const navigateToBoothPreview = () => {
        if (booth?.id)
            history.push(UrlParamReplace(RecruiterRoutes.BoothPreview, ':boothId', booth.id));
    }

    const processQueue = async () => {
        if (!jobFair?.id)
            return;

        try {
            setRefreshDisable(true);
            refresh(true);
            setTimeout(() => setRefreshDisable(false), 5000);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (!jobFair || !user || !booth?.id) return;

        const disposable = setupInterviewSessionSubscriptionsByRecruiterId(user.id);

        const subscription = RxSubscription.onChangeInterviewSessionByRecruiterIdObservable().subscribe((event) => {
            if (!event?.value) return;

            const interviewSession = event.value;

            if (interviewSession.status === VideoSessionStatus.IN_PROGRESS) {
                const url = UrlParamReplace(RecruiterRoutes.Interview, ':sessionId', interviewSession.id);
                history.push(url);
            } else if (interviewSession.status === VideoSessionStatus.ENDED) {
                RxSubscription.clearInterviewSessionByRecruiterId();
                setShowEnded(true);
                if (interviewSession.endReason === EndReason.TIMED_OUT) {
                    setAlertMessage('Interview ended -- This session timed out due to the job seeker not joining the session.');
                }
            }
        });

        return () => {
            disposable?.dispose();
            subscription?.unsubscribe();
            console.log('Disposing onChangeInterviewSessionByRecruiterIdObservable');
        };
    }, [booth?.id, jobFair, user]);


    const sendBroadcastMessage = async () => {
        if (!jobFair?.id)
            return;

        setBroadCastModal(false);
        if (broadCastMessage && booth && user) {
            const messageBroadcasted = await messageService.broadcastMessage(jobFair.id, broadCastType === "Queue" ? "queue_" + booth.id : booth.id, broadCastMessage, user.id);
            if (broadCastType === "Booth") {
                // boothService.getBoothsVisitors(booth.id).then(bv => {
                //     bv?.forEach((v: BoothVisitor | null) => {
                //         if (messageBroadcasted?.id && v?.vjfpUserId)
                //             messageService.createMessageRecipient(messageBroadcasted.id, v.vjfpUserId, jobFair.id);
                //     })
                // });
            }
            else {
                queueUserService.getQueueUsersByBoothId(jobFair.id, booth.id).then(qu => {
                    qu?.forEach((u: any) => {
                        if (messageBroadcasted?.id && u?.vjfpUserId)
                            messageService.createMessageRecipient(messageBroadcasted.id, u.vjfpUserId, jobFair.id);
                    });
                });
            }
        }
    }

    const closeBroadCastModal = () => {
        setBroadCastModal(false)
    };

    const getvjfpUserStatus = (vjfpUser: VJFPUser | undefined, queueUserStatus?: QueueUserStatus | null) => {
        if (vjfpUser?.isAvailableForChat === false)
            return UserStatus.AWAY;
        const userStatus = vjfpUser?.presenceStatus || UserStatus.LOGGED_OUT;
        if (userStatus === UserStatus.LOGGED_IN) {
            return queueUserStatus || userStatus;
        }
        return userStatus;
    }

    return (
        <div className="chatPage recruiterDashboard">
            {booth?.id && <AppTopNav title={booth?.name} breadcrumbs={props.breadcrumbs}>

                <AvailabilitySelector />
                <OverlayTrigger overlay={<Tooltip id="boothInfo">Manage Booth Info</Tooltip>}>
                    <Button className="m-2" onClick={navigateToManageBooth}><PencilSquare /><small className="ms-2">Booth Info</small></Button>
                </OverlayTrigger>

                <OverlayTrigger overlay={<Tooltip id="jobseekerBoothView">Booth Preview</Tooltip>}>
                    <Button className="m-2" onClick={navigateToBoothPreview}><BoxArrowInUpRight /><small className="ms-2">Booth Preview</small></Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="refresh">Refresh Queue</Tooltip>}>
                    <Button variant="danger" className="m-2" disabled={refreshDisable} onClick={processQueue}><Recycle /><small className="ms-2">Refresh Queue</small></Button>
                </OverlayTrigger>

            </AppTopNav>}
            <Container>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col className={"listTitle"}>
                                <h5>{/*{booth?.name}*/} <span style={{ fontWeight: "normal" }}>Booth Queue:</span> {queueUsers?.length || 0} in queue</h5>
                                {/* <span title="Refresh Queue" onClick={() => refresh(true)} style={{cursor: "pointer"}} className="float-end m-2"><ArrowClockwise size={20}/></span> */}
                            </Col>

                        </Row>
                        <BlockUi id="boothLoadingContainer" tag="div" className={"jobSeekerListing"} blocking={loading}>
                            {queueUsers.map((candidate: QueueUser, index: number) => {
                                const vjfpUser = VJFPUsers.find(v => v.id === candidate.vjfpUserId);
                                const interviewer = recruiters.find(v => v.id === candidate.interviewer)
                                return (
                                    <JobSeekerListItem
                                        key={index}
                                        index={index}
                                        initials={getInitials(vjfpUser?.firstName, vjfpUser?.lastName)}
                                        name={vjfpUser?.firstName + ' ' + vjfpUser?.lastName}
                                        score={candidate.score || 0}
                                        maxScore={totalQuestions || 0}
                                        isInvited={candidate.isInvited || false}
                                        address={vjfpUser?.address || ''}
                                        summary={vjfpUser?.summary || ''}
                                        interviewer={interviewer?.firstName && interviewer?.lastName ? interviewer.firstName + ' ' + interviewer.lastName : ''}
                                        status={getvjfpUserStatus(vjfpUser, candidate.status)}
                                        onInterviewClick={user?.id === candidate.interviewer ? () => {
                                            const url = UrlParamReplace(RecruiterRoutes.Interview, ':sessionId', candidate.id);
                                            history.push(url);
                                        } : undefined}
                                    />
                                )
                            })}
                            {queueUsers.length === 0 && <p> No candidates waiting to interview </p>}
                        </BlockUi>
                    </Col>
                    <Col md={6}>
                        <div className="listTitle">
                            <h5>Team Members</h5>
                        </div>
                        <BlockUi tag="div" className={"jobSeekerListing recruiterList"} blocking={loading}>
                            {recruiters.map((vjfpUser, index) => vjfpUser &&
                                <RecruiterListItem index={index} key={index}
                                    initials={getInitials(vjfpUser.firstName, vjfpUser.lastName)}
                                    name={vjfpUser.firstName + ' ' + vjfpUser.lastName}
                                    designation={vjfpUser.job_title || 'Recruiter'}
                                    address={vjfpUser.address || ''}
                                    status={getUserStatus(vjfpUser)}
                                    greetings={vjfpUser.greeting || ''} />)
                            }
                        </BlockUi>

                        <AppModal show={showBroadCastModal} onSuccess={sendBroadcastMessage} onHide={closeBroadCastModal} title="Broadcast message" variant="PlainText" successText="Broadcast" rejectText="No Thanks">

                            <Form>
                                <Form.Group className="mb-3" controlId="broadcastTo">
                                    <Form.Label className="fs-4 fw-bold">Broadcast To</Form.Label>
                                    <div className="mt-1">
                                        <Form.Check id="broad_queue" inline onChange={() => { setBroadCastType("Queue") }} label="Queue" checked={broadCastType === "Queue"} name="broadcastGroup" type="radio" />
                                        <Form.Check id="broad_queue" inline onChange={() => { setBroadCastType("Booth") }} label="Booth" checked={broadCastType === "Booth"} name="broadcastGroup" type="radio" />
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="broadcastMsg">
                                    <Form.Label className="fs-4 fw-bold">Broadcast Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="" onChange={(e) => {
                                        setBroadCastMessage(e.target.value);
                                    }} />
                                </Form.Group>
                            </Form>

                        </AppModal>

                        <AlertModal show={!!errorMessage} title={errorMessage} onOK={() => setErrorMessage("")} ></AlertModal>

                    </Col>
                </Row>
            </Container>
            <AlertModal show={showEnded} title="Interview ended" okText='OK' onOK={() => setShowEnded(false)}>
                {alertMessage}
            </AlertModal>
        </div>
    );
}
