import * as Yup from 'yup';
import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";

import styles from './QuestionnaireView.module.scss';
import { useEffect } from 'react';

interface QuestionnareProps {
    queueQuestions: Array<IQueueQuestionAnswerItem>
    boothName: string
    isPreview?: boolean
    onAnswer?: Function
    onSubmit?: any
    onCancel?: Function

    
}

export interface IQueueQuestionAnswerItem {
    queueQuestionId: string
    question: string
    answer?: boolean
}

const queueQuestionAnswerFormInitialValues: Array<IQueueQuestionAnswerItem> = [{
    queueQuestionId: '',
    question: '',
    answer: false
}]

const queueQuestionAnswerFormSchema = Yup.array(Yup.object().shape({
    answer: Yup.boolean().oneOf([true, false]).required()
}))


export function QuestionnaireView(props: QuestionnareProps) {

    const queueQuestionAnswerForm = useFormik({
        initialValues: queueQuestionAnswerFormInitialValues,
        validationSchema: queueQuestionAnswerFormSchema,
        onSubmit: props.onSubmit
    });

    const onAnswerChanged = (changedValue: boolean, index: number) => {
        let newValues = [...queueQuestionAnswerForm.values];
        newValues[index].answer = false;
        queueQuestionAnswerForm.setValues(newValues)
        if (props.onAnswer) {
            queueQuestionAnswerForm.validateForm()
            props.onAnswer(changedValue, index)
        }
    }

    useEffect(() => { queueQuestionAnswerForm.setValues(props.queueQuestions) }, []);

    return (
        <div>
            <div className={styles.modalHeader}>
                <h4>Queue Questionnaire</h4>
                <p className={styles.questionsModalSubtitle}><em>Answer these questions to be placed in the {props.boothName} queue.</em></p>
            </div>
            <hr />
            <form
                onSubmit={queueQuestionAnswerForm.handleSubmit}
                noValidate
            >
                {queueQuestionAnswerForm.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{queueQuestionAnswerForm.status}</div>
                    </div>
                )}
                {props.queueQuestions && props.queueQuestions.map((queueQuestion, index) => (
                    <div key={`question-div-${index}`}>
                        <div className={styles.queueQuestion}>
                            <p>{index + 1}. {queueQuestion.question}</p>
                            <div >
                                <Form.Check
                                    inline
                                    disabled={props.isPreview}
                                    label="Yes"
                                    name={`group-${index}`}
                                    type='radio'
                                    id={`answer-yes-${index}`}
                                    onChange={(e) => {
                                        onAnswerChanged(true, index)
                                    }}
                                />
                                <Form.Check
                                    inline
                                    disabled={props.isPreview}
                                    label="No"
                                    name={`group-${index}`}
                                    type='radio'
                                    id={`answer-no-${index}`}
                                    onChange={(e) => {
                                        onAnswerChanged(false, index)
                                    }}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>
                ))}
                <div className={styles.questionsModalFooter}>
                    <Button
                        disabled={props.isPreview}
                        variant="outline-dark"
                        onClick={(e) => {
                            if (props.onCancel)
                                props.onCancel(e)
                        }}
                        className={styles.questionsModalCloseButton}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!queueQuestionAnswerForm.isValid || props.isPreview}
                        type="submit"
                        variant="dark">
                        Enter Queue
                    </Button>
                </div>
            </form>
        </div>
    );
}