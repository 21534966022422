import React, { useState, useEffect } from 'react';
import { JobfairService } from 'app/services/JobfairService';
import { useAuth } from 'app/context/auth-context';
import { JobFair } from 'app/types';
import CreateEditJobFairModal from './CreateEditJobFairModal';
import { Pencil, Trash } from "react-bootstrap-icons";
import { Button } from 'react-bootstrap-v5';
import { AppModal } from 'app/modules/shared/modals/AppModal';
import { AlertModal } from 'app/modules/shared/modals/AlertModal';
import { KTSVG } from '_metronic/helpers';

const ManageJobFairs: React.FC = () => {
  const [jobFairs, setJobFairs] = useState<JobFair[]>([]);
  const [showModal, setShowModal] = useState(false);
  const jobFairService = new JobfairService();
  const { user } = useAuth();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [jobFairToDelete, setJobFairToDelete] = useState<JobFair | null>(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState<string | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedJobFair, setSelectedJobFair] = useState<JobFair | undefined>();
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    fetchJobFairs();
  }, []);

  const fetchJobFairs = async () => {
    setIsLoading(true);
    if (!user?.id) return;
    const fairs = await jobFairService.getJobFairs(user.id.toString());
    const sortedFairs = fairs.sort((a, b) => {
      const dateTimeA = new Date(`${a.startDate}T${a.startTime}`);
      const dateTimeB = new Date(`${b.startDate}T${b.startTime}`);
      return dateTimeA.getTime() - dateTimeB.getTime();
    });
    setJobFairs(sortedFairs);
    setIsLoading(false);
  };

  const handleCreateJobFair = async (values: any) => {
    if (!user?.id) return;
    await jobFairService.createJobFair(user.id.toString(), values);
    fetchJobFairs();
    setSelectedJobFair(undefined);
  };

  const handleDeleteJobFair = async (jobFair: JobFair) => {
    setJobFairToDelete(jobFair);
    setShowDeleteAlert(true);
  };

  const handleEditJobFair = async (jobFair: JobFair) => {
    setSelectedJobFair(jobFair);
    setShowModal(true);
  }

  const confirmDeleteJobFair = async () => {
    if (jobFairToDelete && jobFairToDelete.paymentStatus === 'UnPaid') {
      await jobFairService.deleteJobFair(jobFairToDelete.companyId, jobFairToDelete.id);
      fetchJobFairs();
    }
    setShowDeleteAlert(false);
    setJobFairToDelete(null);
  };

  const handlePayment = async (jobFair: JobFair) => {
    setIsPaymentLoading(jobFair.id);
    try {
      const externalPaymentUrl = await jobFairService.createPaymentLink(jobFair.companyId, jobFair.id);

      if (externalPaymentUrl) {
        window.location.href = `${externalPaymentUrl}?client_reference_id=${jobFair.companyId}&prefilled_email=${encodeURIComponent(user?.email || '')}`;
      } else {
        console.error('Invalid response from create-payment-link API');
        setErrorMessage('An error occurred with the payment gateway. Please try again later.');
        setShowErrorModal(true);
      }
    } catch (error) {
      setErrorMessage('An error occurred with the payment gateway. Please try again later.');
      setShowErrorModal(true);
    }
    finally {
      setIsPaymentLoading(null);
    }
  };

  return (
    <div className='card'>
      <div className='card-header border-0 pt-6'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Manage Job Fairs</span>
        </h3>
        <div className='card-toolbar'>
          <button className='btn btn-sm btn-light-primary' onClick={() => setShowModal(true)}>
            <i className='ki-duotone ki-plus fs-2'></i>Create New Job Fair
          </button>
        </div>
      </div>
      <div className='card-body py-4'>
        {isLoading ? (<div className='d-flex justify-content-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>) : (
          <div className='row'>
            {jobFairs.length > 0 ? (
              <>{
                jobFairs.map((fair: JobFair) => (
                  <div key={fair.id} className='col-md-6 col-xl-4'>
                    <div className='card border border-2 border-gray-300 border-hover bg-light'>
                      <div className='card-header border-0 pt-9 ribbon ribbon-start ribbon-clip'>
                        <div className='card-title ms-3'>
                          <span>{fair.name}</span>
                        </div>
                        <div className='toolbar'>
                          <Button variant='link' color='danger' onClick={() => handleEditJobFair(fair)}>
                            <Pencil></Pencil>
                          </Button>
                          {fair.paymentStatus == 'UnPaid' &&
                            <Button variant='link' onClick={() => handleDeleteJobFair(fair)}>
                              <Trash></Trash>
                            </Button>}
                        </div>
                        {fair.paymentStatus == 'Paid' &&
                          <div className={`ribbon-label`}>
                            Paid
                            <span className="ribbon-inner bg-success"></span>
                          </div>
                        }
                      </div>
                      <div className='card-body p-9'>
                        <p className='fw-bold fs-5 mt-1 mb-7'>
                          <KTSVG path='/media/icons/duotone/General/Scale.svg' className='svg-icon svg-icon-2 me-2' />Duration: {fair.durationHours}h {fair.durationMinutes}m
                        </p>
                        <div className='d-flex flex-row mb-5'>

                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                            <div className='d-flex'>
                              <div>
                                <KTSVG path='/media/icons/duotone/Interface/Calendar.svg' className='svg-icon svg-icon-2 me-2' />
                              </div>
                              <div>
                                <div className='fs-7 text-gray-500'>Start Date</div>
                                <div className='fs-7 text-gray-800 fw-bolder'>{fair.startDate}</div>
                              </div>
                            </div>
                          </div>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                            <div className='d-flex'>
                              <div>
                                <KTSVG path='/media/icons/duotone/Code/Time-schedule.svg' className='svg-icon svg-icon-2 me-2' />
                              </div>
                              <div>
                                <div className='fs-7 text-gray-500'>Start Time</div>
                                <div className='fs-7 text-gray-800 fw-bolder'>{fair.startTime}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {fair.paymentStatus === 'UnPaid' && (
                          <div className='d-flex justify-content-center'>
                            <Button
                              variant='success'
                              size='sm'
                              onClick={() => handlePayment(fair)}
                              disabled={isPaymentLoading === fair.id}
                            >
                              {isPaymentLoading === fair.id ? 'Processing...' : 'Pay now'}
                            </Button>

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              }</>
            ) : (
              <div style={{ minWidth: '700px', minHeight: '300px' }} className='border border-dashed border-width-3 border-gray-300 d-flex flex-column justify-content-center align-items-center'>
                <div className='text-muted mb-5'>No Job Fairs events added yet!</div>
                <div className='fs-6 fw-semibold mb-5'>Create job fairs to manage your virtual recruitment events.</div>
                <Button variant="primary" onClick={() => setShowModal(true)}>Add Job Fair</Button>
              </div>
            )}
          </div>)}
      </div>
      <CreateEditJobFairModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedJobFair(undefined);
        }}
        onSubmit={handleCreateJobFair}
        jobFair={selectedJobFair}
      />
      <AppModal
        show={showDeleteAlert}
        onSuccess={confirmDeleteJobFair}
        onReject={() => setShowDeleteAlert(false)}
        onHide={() => setShowDeleteAlert(false)}
        title="Delete this Job Fair?"
      > Are you sure to delete this job fair event
      </AppModal>
      <AlertModal
        show={showErrorModal}
        onOK={() => setShowErrorModal(false)}
        title="Payment Error"
      >
        {errorMessage}
      </AlertModal>
    </div>
  );
};

export default ManageJobFairs;
