import { ChangeInvitePubsub, LocalAppEventPubSub } from 'app/app-events';
import { LocalAppEventType } from 'app/app-models';
import { CGOInvite } from 'app/types';
import axios from 'axios';
import { config } from 'config';

export class InviteService {
  async getByUserId (jobFairId: string, userId: number) {
    try {
      const result = await axios.get<Array<CGOInvite>>(`${config.cgoBaseUrl}/invites/by_applicant?jobfair_id=${jobFairId}&applicant_id=${userId}`);
      if (result.data?.length) {
        ChangeInvitePubsub.publish({
          count: result.data.filter(x => x.status === 'PENDING').length,
          id: result.data[0].id,
          applicant_id: userId,
          booth_info_id: result.data[0].booth_info_id,
          topicKey: '',
          jobfair_id: Number(jobFairId),
          company_name: result.data[0].company_name,
          recruiter_id: result.data[0].recruiter_id,
          status: result.data[0].status
        });
      }
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getByInvitedById (jobFairId: string, invitedBy: number) {
    try {
      const result = await axios.get<Array<CGOInvite>>(`${config.cgoBaseUrl}/invites/by_recruiter?jobfair_id=${jobFairId}&recruiter_id=${invitedBy}`);
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getPendingInvitesByUserIdAndBoothId (jobFairId: string, userId: number, boothsInfoId: number) {
    try {
      const result = await axios.get<Array<CGOInvite>>(`${config.cgoBaseUrl}/invites/by_user_and_booth?jobfair_id=${jobFairId}&booth_info_id=${boothsInfoId}&user_id=${userId}`);
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  async updateStatus (jobFairId: string, userId: number, boothsInfoId: number, newStatus: 'ACCEPTED' | 'REJECTED' | 'PENDING') {
    try {
      const items = await this.getPendingInvitesByUserIdAndBoothId(jobFairId, userId, boothsInfoId) as CGOInvite[];

      if (items.length > 0) {
        if (newStatus === 'ACCEPTED' || newStatus === 'REJECTED') {
          // Just for the sake of pubsub and update the invite count
          LocalAppEventPubSub.publish({
            type: LocalAppEventType.InviteCountUpdateEvent,
            message: 'none'
          });
        }
        await axios.post(`${config.cgoBaseUrl}/invites/${items[0].id}/update_status?status=${newStatus}`);
      } else throw Error('NO INVITE WITH MATCHING CRITERIA IN DATABASE');
    } catch (err) {
      console.log(err);
    }
  }
}
