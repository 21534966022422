import * as Icon from 'react-bootstrap-icons';

import { useEffect, useState } from "react";

interface RatingProps {
    title: String,
    initialRating: number,
    onRatingChange: (newRating: number) => void
    rateContainerClass?: string
    starIconSize?: number;
}

export const Rating = (props: RatingProps) => {

    const [rating, setRating] = useState(props.initialRating);
    const [ratingHoverValue, setRatingHoverValue] = useState(props.initialRating);
    const stars = Array(5).fill(props.initialRating);

    const rate = (value: number) => {
        setRating(value);
        setRatingHoverValue(value);
        props.onRatingChange(value);
    }
    const rateMouseOver = (newRatingValue: number) => {
        setRatingHoverValue(newRatingValue);
    };
    const rateMouseLeave = () => {
        setRatingHoverValue(rating);
    }

    useEffect(() => {
        setRatingHoverValue(props.initialRating);
        setRating(props.initialRating);
    }, [props.initialRating])

    const colors = {
        orange: "#FFBA5A",
        grey: "#a9a9a9"
    };

    return (
        <>
            <b>{props.title}</b>
            <div className={props.rateContainerClass || ''} style={{ display: "flex", flexDirection: "row", }}>
                {stars.map((_, index) => {
                    return (
                        (ratingHoverValue || rating) > index ?
                            <Icon.StarFill
                                key={index}
                                size={props.starIconSize || 18}
                                onClick={() => rate(index + 1)}
                                onMouseOver={() => rateMouseOver(index + 1)}
                                onMouseLeave={rateMouseLeave}
                                color={colors.orange}
                                style={{ marginRight: 5, cursor: "pointer" }}
                            /> :
                            <Icon.Star
                                key={index}
                                size={props.starIconSize || 18}
                                onClick={() => rate(index + 1)}
                                onMouseOver={() => rateMouseOver(index + 1)}
                                onMouseLeave={rateMouseLeave}
                                color={colors.orange}
                                style={{ marginRight: 5, cursor: "pointer" }}
                            />
                    )
                })}
            </div>

        </>
    );
};