import { useEffect } from "react";
import useSound from "use-sound";
import { toAbsoluteUrl } from "_metronic/helpers";

export default function Sound(props: {counter: number}) {
  const [play] = useSound(toAbsoluteUrl('/media/sounds/incoming-call.mp3'), { interrupt: true });

  useEffect(() => {
    play();
  }, [props.counter, play]);
  return null;
}