import { useAuth } from 'app/context/auth-context';
import EmployerAdminPage from 'app/modules/employer-admin/EmployerAdminPage';
import { adminUrl, employerAdminUrl, jobSeekerUrl, recruiterUrl, Roles } from 'app/types';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FallbackView } from '../../_metronic/partials';

export function PrivateRoutes () {
  const JSPage = lazy(() => import('../modules/job-seeker/JSPage'));
  const AdminPage = lazy(() => import('../modules/admin/AdminPage'));
  const RecruiterPage = lazy(() => import('../modules/recruiter/RecruiterPage'));

  const { user } = useAuth();

  const jobSeekerRoutes = (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={jobSeekerUrl} component={JSPage} />
        <Redirect from='/auth' to={jobSeekerUrl} />
      </Switch>
    </Suspense>
  );

  const adminRoutes = (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={adminUrl} component={AdminPage} />
        <Redirect from='/auth' to={adminUrl} />
        </Switch>
    </Suspense>
  );

  const recruiterRoutes = (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={recruiterUrl} component={RecruiterPage} />
        <Redirect from='/auth' to={recruiterUrl} />
        <Redirect to={recruiterUrl} />
       </Switch>
    </Suspense>
  );

  const employerAdminRoutes = (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={employerAdminUrl} component={EmployerAdminPage} />
        <Redirect from='/auth' to={employerAdminUrl} />
        <Redirect to={employerAdminUrl} />
       </Switch>
    </Suspense>
  );

  if (user?.roles?.includes(Roles.Applicant)) { return jobSeekerRoutes; }
  if (user?.roles?.includes(Roles.Admin)) { return adminRoutes; }
  if (user?.roles?.includes(Roles.EmployerAdmin)) { return employerAdminRoutes; }
  if (user?.roles?.includes(Roles.Recruiter)) { return recruiterRoutes; }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  );
}
