import { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from 'app/context/auth-context';
import { CheckAll } from 'react-bootstrap-icons';
import { MessageReceipts } from 'app/API';
import { Button } from 'react-bootstrap-v5';
import { MessageService } from 'app/services/MessageService';
import { CreateMessagePubsub } from 'app/app-events';
import moment from "moment";
import { useAppContext } from "app/context/app-context";

function generateUUID() {
  const uuidTemplate = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return uuidTemplate.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function HeaderNotificationPanel() {
  const messageService = new MessageService();
  const [notifications, setNotifications] = useState<MessageReceipts[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [unReadNotification, setUnReadNotification] = useState<boolean | null>(null);
  const { user } = useAuth();
  const { jobFair } = useAppContext();

  const markAllRead = () => {
    notifications.forEach(n => {
      messageService.markMessageRead(jobFair!.id, n.id);
      n.read = true;
    });

    setNotifications([...notifications]);
    setUnReadNotification(false);
  };

  const markRead = (notification: MessageReceipts) => {
    messageService.markMessageRead(jobFair!.id, notification.id);
    notification.read = true;
    setUnReadNotification(notifications.filter(x => !x.read).length > 0);
  };

  const truncateString = (str: String) => {
    if (str.length <= 20) {
      return str;
    }
    return str.slice(0, 20) + '...';
  };

  useEffect(() => {
    if (user?.username && jobFair?.id) {
      messageService.getMessageRecipientsByRecipientId(jobFair.id, user.id).then(notifications => {
        if (notifications && notifications.length) {
          setUnReadNotification(notifications.filter(x => !(x?.read)).length > 0);
          setNotifications(notifications.sort((a, b) => {
            const i = (+!!(a?.read)) - (+!!(b?.read));
            if (i == 0) {
              return moment(b?.createdAt).diff(a?.createdAt, 's');
            }
            return i;
          }) as MessageReceipts[]);
        }
      });
    }

  }, [user, counter]);

  useEffect(() => {
    const createMessageSubscription = CreateMessagePubsub.subscribe((type, message) => {
        // Create a new MessageReceipts object with the embedded message
        const newMessageReceipt: MessageReceipts = {
          __typename: 'MessageReceipts',
          id: generateUUID(),
          messageId: message.id,
          message: message,
          recipientId: user!.id,
          jobFairId: jobFair!.id,
          updatedAt: moment().unix().toString(),
          createdAt: moment().unix().toString(),
          read: false
        };

    // Update notifications and unReadNotification
    setNotifications(prevNotifications => [...prevNotifications, newMessageReceipt]);
    setUnReadNotification(true);
  });
    return () => {
      createMessageSubscription.dispose();
      console.log('CreateMessagePubsub disposed');
    };
  }, []);


  return (<div className="notifications menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    {unReadNotification ? <button onClick={() => setCounter(counter + 1)} className="btn btn-icon btn-danger position-relative me-5">
      <span className="svg-icon svg-icon-muted svg-icon-2hx">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black" />
          <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black" />
        </svg>
        <span className="position-absolute top-50 start-100 translate-middle  badge badge-circle badge-primary">
          {notifications.filter(x => !x.read).length}
        </span>
      </span>
    </button> :
      <button onClick={() => setCounter(counter + 1)} className="btn btn-icon btn-primary position-relative me-5">
        <span className="svg-icon svg-icon-muted svg-icon-2hx">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black" />
            <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black" />
          </svg>
        </span>
      </button>}
    <div className="notification menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
      <div className="d-flex flex-column bgi-no-repeat rounded-top">
        <div className="title text-white mt-1">
          <h2 className="text-white fw-bold mt-2 mb-2">Notifications</h2>
          <div className="d-flex align-items-center justify-content-between">
            {notifications.filter(x => !x.read).length ? (
              <>
                <span>{notifications.filter(x => !x.read).length} unread notification(s)</span>
                <Button onClick={markAllRead} variant="link text-inverse-info">Mark all as read</Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="tab-content">
        <div className="tab-pane fade active show" id="kt_topbar_notifications_1" role="tabpanel">

          <div className="scroll-y mh-325px my-5 px-8">
            {notifications.map((notification, index) => (<div key={'main' + index} className="d-flex flex-stack py-4">
              <div key={index} className="d-flex align-items-center">
                <div className="mb-0 me-2">
                  <OverlayTrigger overlay={<Tooltip id="announcememt">{notification.message.message}</Tooltip>}>
                    <div className="text-gray-400 fs-7">{truncateString(notification.message.message)}</div>
                  </OverlayTrigger>
                </div>
              </div>
              <span>
                <span className="badge badge-light fs-8">{moment(notification.createdAt).fromNow()}</span>
                {notification.read ? <span className="text-success m-1"><CheckAll /></span> :
                  <span>
                    <OverlayTrigger overlay={<Tooltip id="announcememt"> Mark as read</Tooltip>}>
                      <Form.Check id="broad_queue" inline onChange={() => markRead(notification)} name="broadcastGroup" type="radio" />
                    </OverlayTrigger>
                  </span>}
              </span>
            </div>))}
          </div>

        </div>
      </div>
    </div>
  </div>);
}
