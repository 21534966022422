/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';

import { Button, Card, Col, Form, Modal, Nav, Row } from 'react-bootstrap'
import { BasePageProps, BoothWithState, dropzoneError, IBoothResumeSelected, IBoothResumeUploaded, IBoothTab, IQueueQuestionAnswerForm, JobFairStatus, Roles } from "app/types";
import { Booth, GroupType, QuestionAnswer, UserStatus, VJFPUser, VideoSessionStatus } from "app/API";
import { CompanyDocumentService, IUploadCompanyDocumentsInput } from 'app/services/CompanyDocumentsService';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { QueuePolicy, QueuePolicyError } from "app/helpers/QueuePolicy";
import { useCallback, useEffect, useState } from "react";
import { getInitials, isUserStatusMatched, presenseSorter, UrlParamReplace } from 'app/helpers/MiscUtils';

import { AlertModal } from 'app/modules/shared/modals/AlertModal';
import { BoothService } from "app/services/BoothService";
import DropzoneComponent from 'app/modules/shared/dropzone-component/DropzoneComponent';
import { InviteService } from "app/services/InviteService";
import { QueueUserService } from "app/services/QueueUserService";
import { ResumeService } from 'app/services/resumeService';
import { VJFPUserService } from 'app/services/VJFPUserService';
import { getCSSVariableValue } from '_metronic/assets/ts/_utils';
import { getPriorityFromScore } from "app/helpers/ScoreUtils";
import styles from './JSBoothDetail.module.scss'
import { useAuth } from "app/context/auth-context";
import { useFormik } from "formik";
import { AppModal } from 'app/modules/shared/modals/AppModal';
import { useAppContext } from 'app/context/app-context';
import 'react-block-ui/style.css';
import { setupAmplifyPresenceSubscriptions } from 'app/amplify-subscriptions';
import { UpdatePresencePubsub } from 'app/app-events';
import { PubSubEventType } from 'app/services/PubSub';
import { Mutex } from 'async-mutex';
import { getFileUrlFromS3 } from 'app/helpers/awsUtils';
import { config } from 'config';
import { JSRoutes } from '../JSPage';
import { QuestionAnswerService } from 'app/services/QuestionAnswerService';
import useIsMounted from 'app/hooks/useIsMounted';
import { AppTopNav } from 'app/modules/shared/AppTopNav';
import { RecruiterListItem } from 'app/modules/shared/profile/RecruiterListItem';
import BlockUi from 'react-block-ui';
import { ArrowClockwise, BoxArrowRight } from 'react-bootstrap-icons';
import { KTSVG } from '_metronic/helpers';
import { RxSubscription } from 'app/RxSubscriptions';
import GroupSelector from '../modals/GroupSelector';


type PageParams = {
    boothId: string
}

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024; //10 MB

const dropZoneErrors: dropzoneError[] = [
    {
        errorCode: 'file-too-large',
        getErrorMsg: (file: any) => file.name + ' is larger than 10 MB. Please select a smaller file.'
    },
    {
        errorCode: 'too-many-files',
        getErrorMsg: (file: any) => 'You cannot upload more than ' + 1 + 'file(s).'
    },
    {
        errorCode: 'file-invalid-type',
        getErrorMsg: (file: any) => file.name + ' is not allowed. Only pdf file is allowed.'
    }
]

const getDropzoneErrorMessages = (fileRejections: any) => {

    let errorMsgs: Set<string> = new Set()
    let isErrorFound = false

    for (let fileRejection of fileRejections) {
        for (let errorObj of fileRejection.errors) {
            for (const errorItem of dropZoneErrors) {
                if (errorObj.code === errorItem.errorCode) {
                    isErrorFound = true
                    errorMsgs.add(errorItem.getErrorMsg(fileRejection.file))
                }
            }

            if (!isErrorFound) {
                isErrorFound = false
                errorMsgs.add("Something went wrong!")
            }
        }
    }

    return [...errorMsgs]
}

export const JSBoothDetail = (props: BasePageProps) => {
    let history = useHistory();
    const location = useLocation<BoothWithState>();

    const { jobFair } = useAppContext();
    let params = useParams<PageParams>();

    const boothService = new BoothService();
    const resumeService = new ResumeService();
    const queueUserService = new QueueUserService();
    const inviteService = new InviteService();
    const vjfpUserService = new VJFPUserService()
    const companyDocumentService = new CompanyDocumentService();

    const BOOTH_DETAIL_TABS: IBoothTab[] = [
        {
            title: "About Company",
            onClick: (e: any, index: number) => { return setActiveTabIndex(index) }
        },
        {
            title: "Company Documents",
            onClick: (e: any, index: number) => { return setActiveTabIndex(index) }
        }
    ]

    const [booth, setBooth] = useState<Booth | undefined>(undefined);
    const [companyImageUrl, setCompanyImageUrl] = useState<string>('');
    const [isInvited, setInvited] = useState(false);
    const [isEntered, setEntered] = useState(false);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();
    const [queueQuestions, setQueueQuestions] = useState<Array<QuestionAnswer>>([]);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [showLeaveQueueModal, setShowLeaveQueueModal] = useState<boolean>(false);
    const [isNextInQueue, setIsNextInQueue] = useState<boolean>(false);
    const [showQuestionsModal, setShowQuestionsModal] = useState<boolean>(false);
    const [showQueuePolicyError, setShowQueuePolicyError] = useState<boolean>(false);
    const [recruiters, setRecruiters] = useState<(VJFPUser | undefined)[]>([])

    const [queueCount, setQueueCount] = useState<number | undefined>(undefined);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [listCompanyDocumentFile, setListCompanyDocumentFile] = useState<any[]>([]);
    const [boothResumeUploaded, setBoothResumeUploaded] = useState<IBoothResumeUploaded>({ id: '', fileName: '', fileKey: '', jobTitle: '', notes: '', fileUrl: undefined });
    const [boothResumeSelected, setBoothResumeSelected] = useState<IBoothResumeSelected>({ selectedFileName: '', selectedFileKey: '', jobTitle: '', notes: '', uploadProgress: 0, isFileUploading: false });
    const [showUploadUserResumeModal, setShowUploadUserResumeModal] = useState<boolean>(false);
    const [showUploadAlert, setShowUploadAlert] = useState<boolean>(false);
    const [showFileAlert, setShowFileAlert] = useState<boolean>(false);
    const [fileAlertMsg, setFileAlertMsg] = useState<string>('');
    const [isEndedByRecruiter, setIsEndedByRecruiter] = useState<boolean>(false);
    const [groupSelectorModalShow, setGroupSelectorModalShow] = useState(false);
    const [isSubGroupsConfigured, setIsSubGroupsConfigured] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<GroupType | null>(null);
    const [adjustedBoothId, setAdjustedBoothId] = useState<string>('');
    const isMounted = useIsMounted('JSBoothDetail');
    const questionAnswerService = new QuestionAnswerService();
    const queueQuestionAnswerFormInitialValues: IQueueQuestionAnswerForm = {
        queueQuestion: [],
        boothId: ''
    };

    // Read and Set Booth from location state's object or from API 
    useEffect(() => {
        if (!jobFair?.id || !params?.boothId)
            return;

        if (location.state?.booth) {
            setBooth(location.state.booth);
            setLoading(false);

            const newState = location.state as BoothWithState;
            newState.booth = undefined;
            window.history.replaceState({ ...newState }, document.title)
        }
        else {
            (async () => {
                const b = await boothService.getBoothById(jobFair.id, params.boothId, true);
                if (!isMounted())
                    return;

                if (b) {
                    setBooth(b);
                    setLoading(false);
                }
                else
                    history.push(UrlParamReplace(JSRoutes.Lobby, '', ''));
            })();
        }
    }, [jobFair?.id, params?.boothId, location.state?.booth]);

    const showGroupModal = useCallback(
        () => {
            if (!booth) return false;

            const _isSubGroupsConfigured = !!(booth?.subBooths?.length);
            setIsSubGroupsConfigured(_isSubGroupsConfigured);

            setAdjustedBoothId(selectedGroup?.id ? booth.id + '-' + selectedGroup.id : booth.id)

            if (_isSubGroupsConfigured && !selectedGroup) {
                setGroupSelectorModalShow(true);
                return true;
            }
            return false;
        },
        [booth, selectedGroup]
    );

    useEffect(() => {
        if (booth?.boothsInfoId && !selectedGroup) {
            const _selectedGroup = window.localStorage.getItem(booth.boothsInfoId + '_' + 'selectedGroup');
            if(_selectedGroup){
                setSelectedGroup(JSON.parse(_selectedGroup));
            }
        }
    }, [booth?.boothsInfoId, selectedGroup]);

    // Read and Set IsEntered state from location state's object ot from API
    useEffect(() => {
        if (!booth || !jobFair?.id || !user?.id)
            return;

        if (location.state?.boothState?.isSubscribed) {
            setEntered(true);

            const newState = location.state as BoothWithState;
            if (newState.boothState?.isSubscribed)
                newState.boothState.isSubscribed = false;
            window.history.replaceState({ ...newState }, document.title)
        }
        else {
            (async () => {
                const _queueUser = await queueUserService.getQueueUserByBoothIdAndJobSeekerId(jobFair.id, params.boothId, user.id);

                if (!isMounted())
                    return;
                if (_queueUser)
                    setEntered(true);
            })();
        }

    }, [jobFair?.id, user?.id, booth, location.state?.boothState?.isSubscribed]);

    //  Set Company image Url
    useEffect(() => {
        if (!jobFair?.id || !booth)
            return;
        (async () => {
            if (location.state?.boothState?.companyImageUrl) {
                setCompanyImageUrl(location.state.boothState.companyImageUrl);
                const newState = location.state as BoothWithState;
                if (newState.boothState?.companyImageUrl)
                    newState.boothState.companyImageUrl = '';
                window.history.replaceState({ ...newState }, document.title)
                return;
            }
            const _companyImageUrl = booth?.cgoCompanyId ? (await getFileUrlFromS3('employer_logos/' + booth.cgoCompanyId + '.jpg', 'employer_logos/')).fileUrl.toString() : '';
            if (!isMounted())
                return;
            setCompanyImageUrl(_companyImageUrl);
        })();
    }, [jobFair?.id, booth, location.state?.boothState?.companyImageUrl])

    // Read and Set IsInvited state from location state's object ot from API
    useEffect(() => {
        if (!booth || !jobFair?.id || !user?.id)
            return;

        (async () => {
            const _invites = await inviteService.getPendingInvitesByUserIdAndBoothId(jobFair.id, user.id, booth.boothsInfoId);
            if(booth.subBooths == null || booth.subBooths.length == 0){
                const _isInvited = (_invites)?.length === 1;
                setInvited(_isInvited);
            }

            if(selectedGroup){
                if(_invites?.some(x=> selectedGroup.recruiters?.some(y=> y == x.recruiter_id))){
                    setInvited(true);
                  }
            }
            if (!isMounted())
                return;
            
        })();

    }, [jobFair?.id, user?.id, booth, selectedGroup]);

    // Set Queue Users Count
    useEffect(() => {
        if (!jobFair?.id || !adjustedBoothId)
            return;
        (async () => {
            const count = await queueUserService.countQueueUsersByBoothId(jobFair.id, adjustedBoothId, false, user?.id);

            if (!isMounted())
                return;

            setQueueCount(count);
        })();

    }, [jobFair?.id, adjustedBoothId]);

    // Set Recruiters
    useEffect(() => {
        if (!booth?.id || !jobFair?.id)
            return;

        (async () => {

            let _recruiters: VJFPUser[] = [];
            const _recruitersIds = ((selectedGroup != null ? selectedGroup.recruiters : booth?.recruiters) || []) as Array<number>;

            if (_recruitersIds.length) {
                _recruiters = (await vjfpUserService.getByIds(_recruitersIds)) || [];
            }

            const disposables = setupAmplifyPresenceSubscriptions(_recruitersIds);

            setRecruiters(_recruiters);

            const updatePresenceSubscription = UpdatePresencePubsub.subscribe((eventType, event) => {
                // event is vjfpUser object, now vjfpUser have presenceStatus
                if (!isMounted())
                    return;
                if (eventType === PubSubEventType.Amplify && event.roles?.includes(Roles.Recruiter)) {
                    setRecruiters(prevRecruiters => {
                        const r = prevRecruiters.find(r => r?.id === event.id);
                        if (r && !isUserStatusMatched(r, event)) {
                            r.presenceStatus = event.presenceStatus;
                            r.isAvailableForChat = event.isAvailableForChat;
                            return [...prevRecruiters];
                        }
                        return prevRecruiters;
                    });
                }
            });

            return () => {
                disposables.dispose();
                updatePresenceSubscription.dispose();
            }

        })();
    }, [booth?.id, jobFair?.id, selectedGroup]);

    useEffect(() => {
        if (!jobFair?.id || !user?.id)
            return;
        RxSubscription.onChangeInterviewSessionByCandidateIdObservable().subscribe(async (event) => {
            if (event && event.mode === 'update' && event.value.status === VideoSessionStatus.ENDED && isMounted()) {
                if (event.value.endedBy !== user.id && isMounted()) {
                    setIsEndedByRecruiter(true);
                    setEntered(false);
                    refreshCount();
                }
                RxSubscription.clearInterviewSessionByCandidateId();
            }
        });
    }, [jobFair?.id, user?.id, isMounted]);

    // Get Resume List

    const initResumeInfo = useCallback(
        async (boothId: string | undefined, userId: number | undefined) => {
            if (!boothId || !userId || !jobFair?.id) return;

            const listResumeResult = await resumeService.getResumeList(jobFair.id, boothId, userId);
            if (!isMounted())
                return;
            if (listResumeResult && listResumeResult.length > 0 && listResumeResult[0]) {

                const documentResult = await getFileUrlFromS3(listResumeResult[0].resumeS3Key)
                if (!isMounted())
                    return;
                setBoothResumeUploaded({
                    id: listResumeResult[0]?.id || '',
                    fileKey: listResumeResult[0]?.resumeS3Key || '',
                    fileName: listResumeResult[0]?.fileName || '',
                    fileUrl: documentResult?.fileUrl || '',
                    jobTitle: '',
                    notes: ''
                });
            }
        },
        [booth, user]
    )


    // Set Company Documents 
    useEffect(() => {
        if (!booth?.id || !user?.id)
            return;

        companyDocumentService.getAllDocumentsByBooth(booth.id).then((companyDocumentResponse: any) => {
            if (!isMounted())
                return;
            setListCompanyDocumentFile(companyDocumentResponse);
        });

        initResumeInfo(booth.id, user.id);
    }, [booth?.id, user?.id]);

    const queueQuestionAnswerFormSchema = Yup.object().shape({
        queueQuestion: Yup.array(Yup.object().shape({
            answer: Yup.boolean().oneOf([true, false]).required()
        })).required(),
    })

    
    const acceptInviteAndEnterQueue = async (booth: Booth) => {
        setLoading(true);
        if (booth && user && user.id && jobFair?.id) {
            // Priority 1 means Invited candidates and pass null for score
          
            const isSuccess = await queueUserService.EnterQueue(jobFair.id, adjustedBoothId, user.id, 1, null, (queueQuestions?.length || 0), true);
            if (!isMounted())
                return;
            if (isSuccess) {
                await inviteService.updateStatus(jobFair.id, user.id, booth.boothsInfoId, "ACCEPTED");
                setEntered(true);
            }
        }
        setLoading(false);
    }

    const rejectInvite = async (booth: Booth) => {
        setShowRejectModal(true);
    }

    const refreshCount = async () => {
        if (booth && jobFair?.id && user?.id) {
            const queueUserCount = await queueUserService.countQueueUsersByBoothId(jobFair.id, adjustedBoothId, true, user.id);
            if (!isMounted())
                return;
            setQueueCount(queueUserCount);
        }
    }

    const enterQueue = async () => {
        if (booth && adjustedBoothId && user && user.id && jobFair?.id) {
            // setScoreModal(true);
            const isPresent = !!(await queueUserService.getQueueUserByBoothIdAndJobSeekerId(jobFair.id, adjustedBoothId, user.id));
            if (!isMounted())
                return;
            if (isPresent) {
                setEntered(true);
                const queueUserCount = await queueUserService.countQueueUsersByBoothId(jobFair.id, adjustedBoothId);
                if (!isMounted())
                    return;
                setQueueCount(queueUserCount);
                return;
            }

            let queueQuestions = await questionAnswerService.getAllQueueQuestionsByBoothId(jobFair.id, selectedGroup?.id || booth.id);
            if (!isMounted())
                return;
            let queueQuestionArray: QuestionAnswer[] = [];
            if (queueQuestions) {
                for (let queueQuestion of queueQuestions) {
                    if (!queueQuestion) continue;
                    queueQuestionArray.push({
                        QuestionId: queueQuestion.id,
                        name: queueQuestion.name,
                        id: queueQuestion.id,
                        rank: queueQuestion.rank,
                        answer: undefined,
                        __typename: 'QuestionAnswer'
                    });
                }
            }

            setQueueQuestions(queueQuestionArray);

            queueQuestionAnswerForm.setValues({ queueQuestion: queueQuestionArray, boothId: adjustedBoothId })
            queueQuestionArray.length ? setShowQuestionsModal(true) : queueQuestionAnswerForm.handleSubmit();
        }
    }

    const leaveQueue = async () => {
        if (adjustedBoothId && user && user.id && jobFair?.id) {
            const qu = await queueUserService.getQueueUserByBoothIdAndJobSeekerId(jobFair.id, adjustedBoothId, user.id);
            if (!qu) return false;

            if (qu.priority === 0) {
                setIsNextInQueue(true);
            } else {
                setIsNextInQueue(false);
            }
            setShowLeaveQueueModal(true);
        }
    }

    const handleLeavequeue = async () => {
        if (adjustedBoothId && user && user.id && jobFair?.id) {
            setLoading(true);
            const isLeaved = await queueUserService.PullUser(jobFair.id, adjustedBoothId, user.id);
            if (isLeaved) {
                setEntered(false);
            }
            const queueUserCount = await queueUserService.countQueueUsersByBoothId(jobFair.id, adjustedBoothId)
            setQueueCount(queueUserCount);
            setLoading(false);
            setShowLeaveQueueModal(false);
        }
    }

    const handleAnswerChange = (isYes: boolean, index: number) => {
        let queueQuestionArray = [...queueQuestions];
        queueQuestionArray[index].answer = isYes;
        setQueueQuestions(queueQuestionArray);
        queueQuestionAnswerForm.validateForm()
    }

    const closeRejectModel = () => { setShowRejectModal(false) };
    const rejectInvitation = async (booth: Booth) => {
        setShowRejectModal(false);
        if (booth && user && user.id && jobFair?.id) {
            setLoading(true);

            await inviteService.updateStatus(jobFair.id, user.id, booth.boothsInfoId, "REJECTED");
            setInvited(false);

            setLoading(false);
        }
    };

    const closeQuestionsModal = () => setShowQuestionsModal(false);

    const queueQuestionAnswerForm = useFormik({
        initialValues: queueQuestionAnswerFormInitialValues,
        validationSchema: queueQuestionAnswerFormSchema,
        onSubmit: async (values) => {
            if (!jobFair?.id || !booth?.id || !user?.id) return;
            if (values.queueQuestion.some(x => x.answer === undefined || x.name === null)) {
                alert('You must make a choice.');
                return false;
            }
            setLoading(true);
            let score = 0;
            await questionAnswerService.createOrUpdateAllQuestionAnswers(jobFair.id, selectedGroup?.id || booth.id, user.id, values.queueQuestion.map(x => {
                score += x.answer ? 1 : 0
                return {
                    id: x.id,
                    name: x.name,
                    rank: x.rank,
                    QuestionId: x.QuestionId,
                    answer: x.answer || false
                };
            }));

            setShowQuestionsModal(false);

            try {
                new QueuePolicy(selectedGroup?.minimumScore == null?  booth?.minimumScore || 0 : 0).validate(score);
                if (user && jobFair?.id && booth) {
                    const isSuccess = await queueUserService.EnterQueue(jobFair.id, adjustedBoothId, user.id, getPriorityFromScore(score, queueQuestions?.length || 0), score, queueQuestions?.length || 0);
                    if (isSuccess) {
                        setEntered(true);
                        const queueUserCount = await queueUserService.countQueueUsersByBoothId(jobFair.id, adjustedBoothId)
                        setQueueCount(queueUserCount);
                    }
                }
            }
            catch (err) {
                if (err instanceof QueuePolicyError) {
                    setShowQueuePolicyError(true);
                    return;
                }
            }
            finally {
                setLoading(false);
            }
        }
    });

    const LeaveQueueModal =
        (<AppModal show={showLeaveQueueModal} variant="YesNo" onHide={() => setShowLeaveQueueModal(false)} title={'Confirm'} onSuccess={handleLeavequeue} onReject={() => setShowLeaveQueueModal(false)}>
            {isNextInQueue ? 'You are Next In Queue ... Are you sure you want to leave queue?' : 'Are you sure you want to leave queue?'}
        </AppModal>);

    const QueuePolicyModal = (
        <AlertModal size="lg" show={showQueuePolicyError}
            onOK={() => setShowQueuePolicyError(false)}>
            <p>You did not meet the minimum score to interview with this company.</p>
            <p>Instead, you have the option to leave your resume and a note for these recruiters to view after the event.</p>
        </AlertModal>);

    const RejectConfirmModal =
        (<AppModal show={showRejectModal} variant="YesNo" onHide={closeRejectModel} title={'Confirm'} onSuccess={() => { booth && rejectInvitation(booth) }} onReject={closeRejectModel}>
            Are you sure you want to decline Invite?
        </AppModal>);

    const queueQuestionsModal =
        (<Modal size="lg" show={showQuestionsModal} onHide={closeQuestionsModal} centered>
            <Modal.Header>
                <div className="questions-modal-header">
                    <h4>Queue Questionnaire</h4>
                    <p className="questions-modal-subtitle"><em>Answer these questions to be placed in the booth's queue.</em></p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form
                    className='form'
                    onSubmit={queueQuestionAnswerForm.handleSubmit}
                    noValidate
                >
                    {queueQuestionAnswerForm.status && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{queueQuestionAnswerForm.status}</div>
                        </div>
                    )}
                    {queueQuestions.map((queueQuestion, index) => (
                        <div key={`question-div-${index}`}>
                            <div className="queue-question">
                                <p>{index + 1}. {queueQuestion.name}</p>
                                <div >
                                    <Form.Check

                                        inline
                                        label="Yes"
                                        name={`group-${index}`}
                                        type='radio'
                                        id={`answer-yes-${index}`}
                                        onChange={() => handleAnswerChange(true, index)}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        name={`group-${index}`}
                                        type='radio'
                                        id={`answer-no-${index}`}
                                        onChange={() => handleAnswerChange(false, index)}
                                    />
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                    <div className="questions-modal-footer">
                        <Button variant="outline-dark" onClick={closeQuestionsModal} className="questions-modal-close-button">Cancel</Button>
                        <Button disabled={!queueQuestionAnswerForm.isValid} type="submit" variant="dark" className="questions-modal-submit-button">Enter Queue</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>);

    const getAboutCompanyView = () => {
        return (
            <div>
                <h3> {selectedGroup != null ? 'Group' : 'Company'} description</h3>
                <p style={{ whiteSpace: 'pre-line' }}>{selectedGroup != null ? selectedGroup?.description : booth?.description}</p>
                <h3> Hot Jobs Summary</h3>
                <p style={{ whiteSpace: 'pre-line' }}>{selectedGroup != null ? selectedGroup?.hotJobSummary : booth?.hotJobSummary}</p>
            </div>
        )
    }

    const getCompanyDocumentsView = () => {

        return listCompanyDocumentFile && listCompanyDocumentFile.map((item, index) => {
            return (
                <div key={`company-doc-${index}`}>
                    <div className='fv-row my-5 d-flex justify-content-between'>

                        <div className={styles.filenameWrapper}>
                            <img alt='' className='w-30px me-3' src={'/media/svg/files/pdf.svg'} />
                            <span>{item.fileName}</span>
                        </div>

                        <a href={item.fileUrl}>

                            <Button
                                id={`handle-delete-icons-${index}`}
                                className={styles.downloadFileIcon}
                                variant="none">
                                <i className="fa fa-download" style={{ color: getCSSVariableValue('--bs-success') }}></i>
                                Download
                            </Button>
                        </a>
                    </div>
                    <hr style={{ margin: "1rem -2.25rem", opacity: 0.15 }} />
                </div >
            )
        })

    }

    const onDocUploadProgress = (loadedContent: number, totalContent: number) => {
        let selectedBoothResume = { ...boothResumeSelected }
        selectedBoothResume.uploadProgress = (loadedContent / totalContent) * 100
        if (selectedBoothResume.uploadProgress === 100) {
            selectedBoothResume.isFileUploading = false
        } else {
            selectedBoothResume.isFileUploading = true
        }
        setBoothResumeSelected(selectedBoothResume)
    }

    const onResumeUploadDrop =
        (acceptedFiles: any, fileRejections: any, event: any) => {

            let ret = getDropzoneErrorMessages(fileRejections);

            if (ret && ret.length > 0) {
                setFileAlertMsg(ret[0]);
                setShowFileAlert(true);
                return;
            }

            if (acceptedFiles.length > 0) {
                let resumeFile: IUploadCompanyDocumentsInput = {
                    fileName: acceptedFiles[0].name,
                    fileObj: acceptedFiles[0]
                }

                setBoothResumeSelected({
                    selectedFileName: acceptedFiles[0].name,
                    selectedFileKey: '',
                    jobTitle: boothResumeSelected.jobTitle,
                    notes: boothResumeSelected.notes,
                    uploadProgress: 0,
                    isFileUploading: false
                })

                companyDocumentService.uploadResumeToBooth(booth?.id, user?.username, resumeFile, onDocUploadProgress)
                    .then((s3UploadResult: any) => {

                        if (boothResumeSelected.selectedFileKey) {
                            companyDocumentService.deleteDocuments(boothResumeSelected.selectedFileKey)
                                .then(
                                    (documentDeletionResult) => {
                                    }
                                )
                        }

                        let selectedResume = { ...boothResumeSelected }
                        setBoothResumeSelected({
                            selectedFileName: acceptedFiles[0].name,
                            selectedFileKey: s3UploadResult[0].key,
                            notes: selectedResume.notes,
                            jobTitle: selectedResume.jobTitle,
                            uploadProgress: 100,
                            isFileUploading: false
                        })

                    })
            }
        };

    const handleResumeModalCancel = () => {
        companyDocumentService.deleteDocuments(boothResumeSelected.selectedFileKey)
        setShowUploadUserResumeModal(false)
        initResumeInfo(booth?.id, user?.id)
    }

    const handleResumeModalSave = () => {
        if (!jobFair?.id || !booth?.id)
            return;

        if (boothResumeSelected.selectedFileKey !== "" && boothResumeUploaded.id) {
            companyDocumentService.deleteDocuments(boothResumeUploaded.fileKey)
            resumeService.updateResume(jobFair.id, booth.id, boothResumeUploaded.id,
                boothResumeSelected.selectedFileName,
                boothResumeSelected.selectedFileKey,
                boothResumeSelected.jobTitle,
                boothResumeSelected.notes)
                .then((result: any) => {
                    initResumeInfo(booth?.id, user?.id)
                    setShowUploadUserResumeModal(false)
                    setShowUploadAlert(true);
                })
        } else if (boothResumeSelected.selectedFileKey === "" && boothResumeUploaded.id) {
            resumeService.updateResume(jobFair.id, booth.id, boothResumeUploaded.id,
                boothResumeUploaded.fileName,
                boothResumeUploaded.fileKey,
                boothResumeUploaded.jobTitle,
                boothResumeUploaded.notes)
                .then((result: any) => {
                    initResumeInfo(booth?.id, user?.id)
                    setShowUploadUserResumeModal(false)
                    setShowUploadAlert(true);
                })
        } else {
            resumeService.addResumeToBooth(jobFair.id, booth?.id, user?.id,
                boothResumeSelected.selectedFileName,
                boothResumeSelected.selectedFileKey,
                boothResumeSelected.jobTitle,
                boothResumeSelected.notes)
                .then((result: any) => {
                    initResumeInfo(booth?.id, user?.id)
                    setShowUploadUserResumeModal(false)
                    setShowUploadAlert(true);
                })
        }
    }

    const closeAlertModal = () => {
        setShowUploadAlert(false);
    }

    const uploadUserResume = (
        <Modal
            centered
            className="uploadModel"
            size="lg"
            show={showUploadUserResumeModal}
            onHide={handleResumeModalCancel}>
            <Modal.Body>
                {boothResumeUploaded && boothResumeUploaded.fileName && (<div className="saveFileWrap">
                    <p> Your resume on file is: <strong>{boothResumeUploaded.fileName}</strong></p>
                    <a href={boothResumeUploaded.fileUrl}>
                        <button>
                            <i className="fas fa-download"></i>
                            Download
                        </button>
                    </a>
                </div>)}

                <div className="currentUpload">
                    {boothResumeUploaded && boothResumeUploaded.fileName && (<div>
                        <em>Or</em>
                        <h5>Upload a different resume for this company</h5>
                    </div>)}

                    <DropzoneComponent
                        accept="application/pdf"
                        maxSize={MAX_FILE_UPLOAD_SIZE}
                        maxFiles={1}
                        preventDropOnDocument={true}
                        onDrop={(acceptedFiles: any, fileRejections: any, event: any) => onResumeUploadDrop(acceptedFiles, fileRejections, event)}
                    />

                    <h6>File size not to exceed 10 MB and must be .pdf file type.</h6>
                </div>

                {boothResumeSelected.uploadProgress > 0 && <div className="prograssBar">
                    <div className="prograss" style={{ width: boothResumeSelected.uploadProgress + "%" }}></div>
                    <strong>{boothResumeSelected.selectedFileName}</strong>

                    <p style={{ marginBottom: !boothResumeSelected.isFileUploading ? "1rem" : 0 }}>
                        {boothResumeSelected.isFileUploading && (<span>Uploading...</span>)}
                        <button
                            disabled={boothResumeSelected.isFileUploading}
                            onClick={() => {
                                companyDocumentService.deleteDocuments(boothResumeSelected.selectedFileKey)
                                    .then(
                                        (documentDeletionResult) => {
                                            setBoothResumeSelected({
                                                selectedFileName: '',
                                                selectedFileKey: '',
                                                jobTitle: boothResumeSelected.jobTitle,
                                                notes: boothResumeSelected.notes,
                                                uploadProgress: 0,
                                                isFileUploading: false
                                            })
                                        }
                                    )
                            }}>
                            <i className="fas fa-trash-alt">
                            </i>
                        </button>
                    </p>

                </div>}
                <div className="form-group">
                    <label htmlFor="">Leave a note to include company's job title of interest (* Required)</label>
                    <input
                        disabled={boothResumeUploaded.fileName === "" && boothResumeSelected.selectedFileKey === ""}
                        placeholder={"Enter the job title you are interested in at this company"}
                        value={boothResumeSelected.jobTitle}
                        onChange={(e) => {
                            let selectedResume = { ...boothResumeSelected }
                            let uploadedResume = { ...boothResumeUploaded }
                            setBoothResumeSelected({
                                ...selectedResume,
                                jobTitle: e.target.value
                            })
                            setBoothResumeUploaded({
                                ...uploadedResume,
                                jobTitle: e.target.value
                            })
                        }}
                        className={styles.jobTitleInput}
                    ></input>
                    {/* {boothResumeSelected.selectedFileKey !== "" && <span style={{ marginLeft: "1rem" }}>(Required)</span>} */}
                    <textarea
                        disabled={boothResumeUploaded.fileName === "" && boothResumeSelected.selectedFileKey === ""}
                        placeholder={"Type your note here..."}
                        value={boothResumeSelected.notes}
                        onChange={(e) => {
                            let selectedResume = { ...boothResumeSelected }
                            let uploadedResume = { ...boothResumeUploaded }
                            setBoothResumeSelected({
                                ...selectedResume,
                                notes: e.target.value
                            })
                            setBoothResumeUploaded({
                                ...uploadedResume,
                                notes: e.target.value
                            })
                        }}
                    ></textarea>
                </div>
                <div className="modal-footer">
                    <button type="button"
                        className="btn btn-light"
                        onClick={handleResumeModalCancel}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        disabled={user?.roles?.includes(Roles.Recruiter)
                            || (boothResumeUploaded.fileKey === "" && boothResumeSelected.selectedFileKey === "")
                            || (boothResumeUploaded.jobTitle === "" && boothResumeSelected.jobTitle === "")}
                        className="btn btn-success"
                        onClick={handleResumeModalSave}>
                        Save
                    </button>
                </div>
            </Modal.Body>
        </Modal >)

    return (
        <div className="chatPage BoothDetailPage">
            <AppTopNav title={booth?.name} breadcrumbs={props.breadcrumbs}>
            </AppTopNav>

            <div className="container">
                {user?.roles?.includes(Roles.Recruiter) && props?.breadcrumbs &&
                    <Row>
                        <div className="cardWrap" style={{ marginTop: '15px' }}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex flex-wrap flex-sm-nowrap shortDetail">
                                        <div className="me-7 mb-5">
                                            <h4>This a preview of how your booth looks to job seekers <a href={props.breadcrumbs[0]?.url}>Back to Your Booth</a></h4>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                }
                <Row>
                    <Col md={7}>
                        <div className="cardWrap">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex flex-wrap flex-sm-nowrap shortDetail">
                                        <div className="me-7 mb-4">
                                            <div className="border border-gray-500 symbol symbol-200px symbol-lg-200px symbol-fixed position-relative">
                                                <img crossOrigin='anonymous' src={companyImageUrl} alt="" />
                                                <div className="position-absolute translate-middle bottom-0 start-100 mb-0 bg-success rounded-circle border border-4 border-white h-20px w-20px">
                                                </div>
                                            </div>
                                        </div>
                                        <BlockUi id="jsBoothDetailLoading" tag="div" blocking={loading} className="flex-grow-1">
                                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                                            {booth?.name} {selectedGroup ? ' > ' + selectedGroup.name : ''} 
                                                        </span>
                                                        {booth && selectedGroup && <Button id="btnExitGroup" disabled={isEntered} className="ms-10" variant="danger" size="sm" onClick={() => { 
                                                                        setSelectedGroup(null);
                                                                        window.localStorage.removeItem(booth.boothsInfoId + '_' + 'selectedGroup');
                                                                        }}>
                                                                            <BoxArrowRight className="me-2" size={16} /> <span>Leave Group</span>
                                                                    </Button>}
                                                        {/*<KTSVG path="/media/icons/duotone/General/Shield-check.svg" className="svg-icon svg-icon-1 svg-icon-primary" />*/}
                                                    </div>
                                                    <div className="d-flex align-items-center mb-4">

                                                    </div>
                                                    <div className="d-flex align-items-center mb-4">
                                                        <div>
                                                            {!isEntered && !isInvited && 
                                                                <div className="nameBtnWrap">
                                                                    <p className={"pb-4"}>Enter booth queue to interview with a recruiter.</p>
                                                                    <Button id="btnEnterQueue" className="m-2 m-lg-0" disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} variant="primary" size="sm" onClick={() => { if (!showGroupModal()) enterQueue() }}>
                                                                        {!selectedGroup && booth?.subBooths?.length ? "Select group" : "Enter Queue"}
                                                                    </Button>
                                                                   
                                                                    {(selectedGroup || booth?.subBooths == null || booth.subBooths.length === 0) && <><em># of job seekers waiting in queue: {(queueCount && queueCount > 0) ? queueCount : 0}</em>
                                                                        <span title="Refresh waiting in queue" onClick={() => refreshCount()} style={{ cursor: "pointer" }} className="float-end m-2"><ArrowClockwise size={20} /></span></>}
                                                                </div>
                                                            }
                                                            {!isEntered && isInvited && (booth?.subBooths == null || booth.subBooths.length == 0) && 
                                                                <>
                                                                    <span>You have been invited to this queue by a recruiter. Please accept to enter the booth queue.</span>
                                                                    <div className="d-flex align-items-center">
                                                                        <Button disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="m-2" size="sm" variant="success" onClick={() => { booth && acceptInviteAndEnterQueue(booth) }}>
                                                                            <KTSVG path="/media/icons/duotone/Code/Done-circle.svg" className="svg-icon-muted svg-icon-1" />
                                                                            Accept
                                                                        </Button>
                                                                        <Button disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="m-2" size="sm" style={{ marginLeft: 20 }} variant="danger" onClick={() => { booth && rejectInvite(booth) }}>
                                                                            <KTSVG path="/media/icons/duotone/Code/Error-circle.svg" className="svg-icon-muted svg-icon-1" />
                                                                            Decline
                                                                        </Button>
                                                                    </div>
                                                                </>}
                                                                {!isEntered && isInvited && selectedGroup &&
                                                                <>
                                                                    <span>You have been invited to this queue by a recruiter. Please accept to enter the booth queue.</span>
                                                                    <div className="d-flex align-items-center">
                                                                        <Button disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="m-2" size="sm" variant="success" onClick={() => { booth && acceptInviteAndEnterQueue(booth) }}>
                                                                            <KTSVG path="/media/icons/duotone/Code/Done-circle.svg" className="svg-icon-muted svg-icon-1" />
                                                                            Accept
                                                                        </Button>
                                                                        <Button disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="m-2" size="sm" style={{ marginLeft: 20 }} variant="danger" onClick={() => { booth && rejectInvite(booth) }}>
                                                                            <KTSVG path="/media/icons/duotone/Code/Error-circle.svg" className="svg-icon-muted svg-icon-1" />
                                                                            Decline
                                                                        </Button>
                                                                    </div>
                                                                </>}
                                                            {isEntered &&
                                                                <div className="nameBtnWrap">
                                                                    <p className={"pb-4"}>You have joined the queue for this booth.</p>
                                                                    <Button id="btnLeaveQueue" disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="m-2 m-lg-0" size="sm" variant="danger" onClick={() => { leaveQueue() }}>Leave queue</Button>
                                                                    <em># of job seekers waiting in queue: {(queueCount && queueCount > 0) ? queueCount : 0}</em>
                                                                    <span title="Refresh waiting in queue" onClick={() => refreshCount()} style={{ cursor: "pointer" }} className="float-end m-2"><ArrowClockwise size={20} /></span>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </BlockUi>
                                    </div>
                                    <div className="d-flex overflow-auto h-50px tabLinkWrap">
                                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">

                                            {BOOTH_DETAIL_TABS.map((tab, index: number) => {
                                                return (
                                                    <Nav.Item key={`booth-tab-${index}`} >
                                                        <a style={{ pointerEvents: user?.roles?.includes(Roles.Recruiter) && tab.title === BOOTH_DETAIL_TABS[1].title ? 'none' : 'auto' }} onClick={(e: any) => tab.onClick(e, index)} className={"nav-link text-active-primary me-6" + (activeTabIndex === index ? " active" : "")} >
                                                            {tab.title}
                                                        </a>
                                                    </Nav.Item>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </Card.Body>
                                <BlockUi tag="div" className="bodyWrap" blocking={loading}>

                                    {/* About Company View - index 0 */}
                                    {BOOTH_DETAIL_TABS[activeTabIndex] === BOOTH_DETAIL_TABS[0] &&
                                        getAboutCompanyView()
                                    }

                                    {/* Company Documents View - index 0 */}
                                    {BOOTH_DETAIL_TABS[activeTabIndex] === BOOTH_DETAIL_TABS[2] &&
                                        getCompanyDocumentsView()
                                    }
                                </BlockUi>
                            </Card>
                        </div>

                    </Col>
                    <Col md={5}>
                        <div className="notificationWrap">
                            <p>During the event, if you are unable to interview with a recruiter, you have the option here to drop off your resume and a note that this company’s recruiters can access after the event.
                            </p>
                            <button disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="click" onClick={() => setShowUploadUserResumeModal(true)}>Click Here</button>
                            <button disabled={user?.roles?.includes(Roles.Recruiter) || jobFair?.status !== JobFairStatus.Started} className="link" onClick={() => setShowUploadUserResumeModal(true)}>
                                <i className="fas fa-external-link-alt"></i>
                            </button>

                            {/*<a href="" className="link">
                                <KTSVG path="/media/icons/duotone/Files/Export.svg" className="svg-icon svg-icon-2x svg-icon-success" />
                            </a>*/}
                        </div>
                        <BlockUi tag="div" className="jobSeekerListing recruiterList" blocking={loading}>
                            <h1>Booth Representatives</h1>
                            <div className="wrap">

                                {recruiters && [...recruiters].sort((a, b) => presenseSorter(a?.presenceStatus, b?.presenceStatus)).map((recruiter: VJFPUser | undefined, index) => recruiter &&
                                    <RecruiterListItem index={index} key={index}
                                        initials={getInitials(recruiter.firstName, recruiter.lastName)}
                                        name={recruiter.firstName + ' ' + recruiter.lastName}
                                        designation={recruiter.job_title || 'Recruiter'}
                                        address={recruiter.address || ''}
                                        status={recruiter?.presenceStatus || UserStatus.LOGGED_OUT}
                                        greetings={recruiter.greeting || ''} />)
                                }
                            </div>
                        </BlockUi>
                    </Col>
                </Row>
            </div>

            <AlertModal title="Your resume and note will be available to this booth’s recruiters after the event."
                show={showUploadAlert}
                onOK={closeAlertModal}>
            </AlertModal>
            <AlertModal title="Interview has ended"
                show={isEndedByRecruiter}
                onOK={async () => {
                    setIsEndedByRecruiter(false);
                    setEntered(false);
                }}>
            </AlertModal>

            <AlertModal title={fileAlertMsg}
                show={showFileAlert}
                onOK={() => setShowFileAlert(false)}>
            </AlertModal>

            <GroupSelector show={groupSelectorModalShow} groups={booth?.subBooths || []} onSelectGroup={(selectedGroup) => {
                setSelectedGroup(selectedGroup);
                if (booth?.boothsInfoId) {
                    window.localStorage.setItem(booth.boothsInfoId + '_' + 'selectedGroup', JSON.stringify(selectedGroup));
                }
                setGroupSelectorModalShow(false);
            }} ></GroupSelector>

            {RejectConfirmModal}
            {queueQuestionsModal}
            {QueuePolicyModal}
            {uploadUserResume}
            {LeaveQueueModal}
        </div>
    );
}