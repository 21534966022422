import React, { lazy, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { JSBoothDetail } from '../job-seeker/components/JSBoothDetail'
import { RecruiterBoothView } from './components/RecruiterBoothView'
import RecruiterHistory from './components/RecruiterHistory'
const RecruiterInterview = lazy(() => import('./components/RecruiterInterview'));

import RecruiterInvites from './components/RecruiterInvites'
import { ManageBoothInfo } from '../shared/manage-booth/ManageBoothInfo'
import { AppModal } from '../shared/modals/AppModal'
import { useAppContext } from 'app/context/app-context'
import { InterviewSession, EndReason, VideoSessionStatus } from 'app/API'
import {  InterviewSessionService } from 'app/services/InterviewSessionService'
import { QueueUserService } from 'app/services/QueueUserService'
import { useAuth } from 'app/context/auth-context'
import { recruiterUrl } from 'app/types'
import { setupAmplifyMessageSubscription } from 'app/amplify-subscriptions';
import { UrlParamReplace } from 'app/helpers/MiscUtils'
import useIsMounted from 'app/hooks/useIsMounted'
import { HMSRoomProvider } from '@100mslive/react-sdk'
import { RxSubscription } from 'app/RxSubscriptions'

export const RecruiterRoutes = {
  Booth: `${recruiterUrl}/booth`,
  Interview: `${recruiterUrl}/interview/:sessionId`,
  ManageBoothInfo: `${recruiterUrl}/manage-booth-info/:boothId`,
  DeviceTest: `${recruiterUrl}/device-test`,
  BoothPreview: `${recruiterUrl}/booth-preview/:boothId`,
  History: `${recruiterUrl}/history`,
  Invites: `${recruiterUrl}/invites`,
  WaitingLobby: `${recruiterUrl}/waiting/:boothId`,
}

const recruiterBreadCrumbs = [{
  text: 'Booth',
  url: UrlParamReplace(RecruiterRoutes.Booth, '', '')
}
]

const manageBoothInfoBreadCrumbs = [
  {
    text: 'Booth',
    url: UrlParamReplace(RecruiterRoutes.Booth, '', '')
  },
  {
    text: 'Manage Booth Information',
    url: UrlParamReplace(RecruiterRoutes.ManageBoothInfo, '', '')
  },
]


interface RecruiterPageHOCProps {
  children: React.ReactNode;
  withContinuePopup?: boolean;
}

const RecruiterPageHOC = (hocProps: RecruiterPageHOCProps) => {

  const interviewSessionService = new InterviewSessionService();
  const { user } = useAuth();
  const { jobFair } = useAppContext();
  const [activeInterviewSession, setActiveInterviewSession] = useState<InterviewSession | undefined>(undefined);
  const history = useHistory();
  const location = useLocation();
  const queueUserService = new QueueUserService();


  const isMounted = useIsMounted('RecruiterPageHOC');

  useEffect(() => {
    if (!user || !jobFair?.id)
      return;

    const messageSubs = setupAmplifyMessageSubscription(`${jobFair.id}_Recruiter`);

    return () => {
      messageSubs?.dispose();
    }
  }, [user, jobFair?.id])

  useEffect(() => {

    if (!jobFair?.id || !user?.id)
      return;


    const subscription = RxSubscription.onChangeInterviewSessionByRecruiterIdObservable().subscribe(changeEventInterviewSession => {
      if(changeEventInterviewSession?.mode === 'update' && changeEventInterviewSession.value?.status === VideoSessionStatus.IN_PROGRESS){
        setActiveInterviewSession(changeEventInterviewSession.value);
      }
      else if(changeEventInterviewSession?.mode === 'update' && (changeEventInterviewSession.value?.status === VideoSessionStatus.ENDING || changeEventInterviewSession.value?.status === VideoSessionStatus.ENDED)){
        setActiveInterviewSession(undefined);
      }
    });

    const getActive = async () => {
      if (!isMounted())
        return;
      setActiveInterviewSession(undefined);
      await interviewSessionService.getActiveInterviewsByRecruiter(jobFair.id, user.id).then(interviewSessions => {
        if (!isMounted()) return;
        if (interviewSessions?.length) {
          setActiveInterviewSession(interviewSessions[0]);
         //ActiveInterviewSessionState.setInterviewSession(interviewSessions[0]);
        }
      });
    }
    getActive();

    return () => {
      subscription.unsubscribe();
    }

  }, [jobFair, user]);

  return (<>
    {hocProps.withContinuePopup !== false && <AppModal variant="PlainText" successText="Continue session" rejectText="End Session" show={!!activeInterviewSession && activeInterviewSession.status === VideoSessionStatus.IN_PROGRESS && location.pathname !== UrlParamReplace(RecruiterRoutes.Interview, ':sessionId', activeInterviewSession?.id || '')} title="Alert"
      onSuccess={() => {
        if (activeInterviewSession) {
          history.push(UrlParamReplace(RecruiterRoutes.Interview + '?startVideo=true&startAudio=true', ':sessionId', activeInterviewSession.id));
        }
        setActiveInterviewSession(undefined);
      }}

      onReject={async () => {

        // Pull user from selected booth
        if (jobFair?.id && activeInterviewSession && activeInterviewSession.boothId && user) {
          try {
           const interviewSession = await queueUserService.endInterview(jobFair.id, activeInterviewSession.boothId, activeInterviewSession.id, user.id, EndReason.ENDED);
           if(interviewSession) {
            setActiveInterviewSession(undefined);
          }
          } catch (err) {
            console.log('Could not pull user, please try again')
          }
        }
      }}
    >
      You are still in an interview, please click "Continue session" or "End session"?
    </AppModal>
    }
    {hocProps.children}
  </>);
}


const RecruiterPage: React.FC = (props) => {

  return (
    <Switch>
      <Route path={RecruiterRoutes.Booth} render={() => <RecruiterPageHOC><RecruiterBoothView title="Booth" /></RecruiterPageHOC>} />
      <Route path={RecruiterRoutes.Interview} render={() => <RecruiterPageHOC withContinuePopup={false}>
        <HMSRoomProvider>
          <RecruiterInterview title="Interview" breadcrumbs={[...recruiterBreadCrumbs, { text: 'Interview' }]} />
        </HMSRoomProvider>
      </RecruiterPageHOC>} />
      <Route path={RecruiterRoutes.ManageBoothInfo} render={() => <RecruiterPageHOC withContinuePopup={false}> <ManageBoothInfo title="Manage Booth Information" breadcrumbs={manageBoothInfoBreadCrumbs} showMatrix={false} /> </RecruiterPageHOC>} />

      <Route path={RecruiterRoutes.BoothPreview} render={() => <RecruiterPageHOC> <JSBoothDetail title="Booth Preview" breadcrumbs={[...recruiterBreadCrumbs, { text: 'Booth Preview' }]} /> </RecruiterPageHOC>} />
      <Route path={RecruiterRoutes.History} render={() => <RecruiterPageHOC> <RecruiterHistory title="History" breadcrumbs={[...recruiterBreadCrumbs, { text: 'History' }]} /></RecruiterPageHOC>} />
      <Route path={RecruiterRoutes.Invites} render={() => <RecruiterPageHOC> <RecruiterInvites title="Invites" breadcrumbs={[...recruiterBreadCrumbs, { text: 'Invitations' }]} /></RecruiterPageHOC>} />
      <Redirect from={recruiterUrl} to={RecruiterRoutes.Booth} />
    </Switch>
  )
}

export default RecruiterPage
