import { Storage } from 'aws-amplify';

export async function getFileObjectFromS3 (key: string) {
  return await Storage.get(key, { download: true })
    .then((resp: any) => { return URL.createObjectURL(resp.Body); })
    .catch((err) => {
      throw err;
    });
}

export function getFileUrlFromS3 (key: string, folder: string = 'attached_resumes/') {
  return new Promise<{
    key: string,
    fileUrl: string,
    fileName: string
  }>((resolve, reject) => {
    if (key) {
      Storage.get(key)
        .then((fileUrl) => {
          let fileName: Array<string> | string = key.split('/');
          if (fileName.length > 0) {
            fileName = fileName[fileName.length - 1];

            resolve({
              key,
              fileUrl,
              fileName
            });
          } else {
            console.error('fileName not found - ' + key);
            reject(new Error('fileName not found - ' + key));
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    } else {
      reject(new Error('key is required!'));
    }
  });
}

export function uploadFileToS3 (destinationName: string,
  fileObj: any,
  updateProgressCallback: Function = () => { }) {
  if (!destinationName || !fileObj) {
    console.error('destinationName and fileObj are required!');
  }

  return new Promise((resolve, reject) => {
    Storage.put(destinationName, fileObj, {
      progressCallback (progress: any) {
        if (updateProgressCallback) {
          updateProgressCallback(progress.loaded/* loadedContent */, progress.total/* totalContent */);
        }
      }
    })
      .then((result) => {
        resolve(result);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

export function getFilesFromS3 (destination: string) {
  if (!destination) {
    console.error('destination is required!');
  }

  return new Promise((resolve, reject) => {
    const fileUrlPromises: Array<Promise<any>> = [];
    Storage.list(destination)
      .then((fileList) => {
        for (const fileListItem of fileList) {
          if (fileListItem && fileListItem.key) {
            fileUrlPromises.push(getFileUrlFromS3(fileListItem.key));
          }
        }
        Promise.all(fileUrlPromises).then((fileDetailList) => {
          resolve(fileDetailList);
        });
      });
  });
}

export async function getLatestResume (fileName: string) {
  if (!fileName) {
    console.error('fileName is required!');
  }

  const fileList = await Storage.list(fileName);
  if (!fileList?.length) { return null; }
  if (fileList?.length === 1) { return fileList[0].key; };

  // a = 1 Jan , b = 2 Jan
  // Asc sort should have -ve value a-b = -ve
  // Desc sort should have +ve value a-b = +ve or b-a = -ve
  fileList.sort((a, b) => {
    if (a.lastModified && b.lastModified) { return b.lastModified.getTime() - a.lastModified.getTime(); } else return 0;
  });

  return fileList[0]?.key;
}

export function deleteFileFromS3 (fileKey: string) {
  if (!fileKey) {
    console.error('fileKey is required!');
  }

  return new Promise((resolve, reject) => {
    Storage.remove(fileKey)
      .then((result) => {
        resolve(result);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}
