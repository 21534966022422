import { useEffect, useState } from 'react';
import Switch from "react-switch";

import { VJFPUserService } from 'app/services/VJFPUserService';
import { useAuth } from 'app/context/auth-context';
import { useAppContext } from 'app/context/app-context';
import { JobFairStatus, Roles } from 'app/types';
import { RxSubscription } from 'app/RxSubscriptions';

function AvailabilitySelector() {
  const vjfpService = new VJFPUserService();
  const { user } = useAuth();
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const { jobFair } = useAppContext();

  const updateVjfpUserStatus = async (isAvailable: boolean) => {
    setIsAvailable(isAvailable);
    if (user?.id && jobFair?.id) {
     await vjfpService.updateAvailability(jobFair.id, user.id, isAvailable);
    }
  };

  useEffect(() => {
    const getVjfpIsAvailable = async () => {
      if (jobFair?.id && user?.id) {
        const vjfpuser = await vjfpService.getById(user.id);
        if(vjfpuser?.isAvailableForChat){
          await vjfpService.updateAvailability(jobFair.id, user.id, false);
        }
      }
    };
    getVjfpIsAvailable();
   }, [jobFair?.id,user?.id]);

   useEffect(() => {
    if(!user?.id) 
      return;
     const sub = RxSubscription.onUpdateVJFPUserByIdObservable().subscribe((value)=> {
      if(value && value.id === user?.id){
        setIsAvailable(value.isAvailableForChat === true);
      }
     });
     return () => {
       sub.unsubscribe();
     }
   }, [user?.id]);

  useEffect(() => {
    let _isEnabled: boolean = false;
    if(jobFair != null && user?.roles?.includes(Roles.Recruiter) && (jobFair.status === JobFairStatus.Started || jobFair.status === JobFairStatus.ClosingSession))
      _isEnabled = true;
    setIsEnabled(_isEnabled)
  }, [jobFair, user]);

  return (
    <label>
      <span className='form-label fs-6 fw-bolder text-dark align-middle'>{isAvailable? "": "Turn on to start next interview :"} </span>
      <Switch onChange={(value) => updateVjfpUserStatus(value)} checked={isAvailable} offColor={'#CD001A'}/>
    </label>
  );
}
export { AvailabilitySelector };
