import { getCommonAxiosCommon } from 'app/helpers/MiscUtils';
import { NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';

export interface Organization {
  id: string;
  companyName: string;
  companyDetails: string;
  jobBoardLink: string;
  companyLogo: string;
  companyDocuments: string[];
}

export class OrgService {
  private baseUrl = `${config.vjfpNGRestAPI}/organizations`;
  async createOrg (orgData: Partial<Organization>): Promise<Organization> {
    const result = await axios.post<NgAPIResponse<Organization>>(
      this.baseUrl,
      orgData,
      await getCommonAxiosCommon()
    );

    if (!result.data?.isSuccess) {
      console.error(result.data.errors);
      throw new Error('Failed to create organization');
    }

    return result.data.data;
  }

  async getOrgById (orgId: number): Promise<Organization> {
    const result = await axios.get<NgAPIResponse<Organization>>(
      `${this.baseUrl}/${orgId}`,
      await getCommonAxiosCommon()
    );

    if (!result.data?.isSuccess) {
      console.error(result.data.errors);
      throw new Error('Failed to get organization');
    }

    return result.data.data;
  }

  async updateOrg (orgId: number, orgData: Partial<Organization>): Promise<Organization> {
    const result = await axios.put<NgAPIResponse<Organization>>(
      `${this.baseUrl}/${orgId}`,
      orgData,
      await getCommonAxiosCommon()
    );

    if (!result.data?.isSuccess) {
      console.error(result.data.errors);
      throw new Error('Failed to update organization');
    }

    return result.data.data;
  }

  async deleteOrg (orgId: number): Promise<void> {
    const result = await axios.delete<NgAPIResponse<void>>(
      `${this.baseUrl}/${orgId}`,
      await getCommonAxiosCommon()
    );

    if (!result.data?.isSuccess) {
      console.error(result.data.errors);
      throw new Error('Failed to delete organization');
    }
  }
}
