const FIVE_MINUTES_IN_MS = 1000 * 60 * 5; // unit milli seconds

export const HEART_BEAT_FREQUENCY_IN_S = 60; // unit seconds

type DateUtilsType = {
    clockOffset: number;
    getAWSCurrentDate: ()=> Date;
    getDateWithClockOffset: ()=> Date;
    getClockOffset: ()=> number;
    getHeaderStringFromDate: (date?: Date)=> string;
    getDateFromHeaderString: (header: string) => Date;
    isClockSkewed: (serverDate: Date)=> boolean;
    isClockSkewError: (error: any)=> Boolean;
    setClockOffset: (offset: number)=> void;
    getUnixNowDateTime: (offsetInSeconds: number)=> number;
}

export const DateUtils: DateUtilsType = {
  /**
     * Milliseconds to offset the date to compensate for clock skew between device & services
     */
  clockOffset: 0,

  getAWSCurrentDate () {
    return DateUtils.getDateFromHeaderString(DateUtils.getHeaderStringFromDate());
  },

  getDateWithClockOffset () {
    if (DateUtils.clockOffset) {
      return new Date(new Date().getTime() + DateUtils.clockOffset);
    } else {
      return new Date();
    }
  },

  /**
     * @returns {number} Clock offset in milliseconds
     */
  getClockOffset () {
    return DateUtils.clockOffset;
  },

  getHeaderStringFromDate (date: Date = DateUtils.getDateWithClockOffset()) {
    // eslint-disable-next-line no-useless-escape
    return date.toISOString().replace(/[:\-]|\.\d{3}/g, '');
  },

  getDateFromHeaderString (header: string) {
    const toMatch = header.match(
      /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2}).+/
    );

    if (!toMatch) {
      throw new Error('Date Parse error');
    }

    const [, year, month, day, hour, minute, second] = toMatch;
    return new Date(
      Date.UTC(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hour),
        Number(minute),
        Number(second)
      )
    );
  },

  isClockSkewed (serverDate: Date) {
    // API gateway permits client calls that are off by no more than ±5 minutes
    return (
      Math.abs(
        serverDate.getTime() - DateUtils.getDateWithClockOffset().getTime()
      ) >= FIVE_MINUTES_IN_MS
    );
  },

  isClockSkewError (error: any) {
    if (!error.response || !error.response.headers) {
      return false;
    }

    const { headers } = error.response;

    return Boolean(
      ['BadRequestException', 'InvalidSignatureException'].includes(
        headers['x-amzn-errortype']
      ) &&
            (headers.date || headers.Date)
    );
  },

  /**
     * @param {number} offset Clock offset in milliseconds
     */
  setClockOffset (offset: number) {
    DateUtils.clockOffset = offset;
  },

  getUnixNowDateTime (offsetInSeconds: number) {
    const nowTimestamp = Math.floor((new Date()).getTime() / 1000);
    return nowTimestamp + offsetInSeconds;
  }
};
