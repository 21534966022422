import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TrashFill, PlusLg, PencilFill } from 'react-bootstrap-icons';
import styles from './QueueQuestions.module.scss';
import { useFormik } from 'formik';
import { Booth, GroupType, Question, QuestionInput, UpdateBoothInput } from 'app/API';
import * as Yup from 'yup'
import { IQueueQuestionAnswerItem, QuestionnaireView } from '../../questionnaire-view/QuestionnaireView';
import { QuestionAnswerService } from "app/services/QuestionAnswerService";
import { useAuth } from 'app/context/auth-context';
import { useAppContext } from 'app/context/app-context';
import { BoothService } from 'app/services/BoothService';

const MAX_ALLOWED_QUESTIONS = 5;

interface IQueueQuestionForm {
    queueQuestion: Array<QuestionModel>
    minimumScore: number
}

interface QuestionModel extends Question {
    isEdit: boolean
}

const queueQuestionFormSchema = Yup.object().shape({
    queueQuestion: Yup.array().required(),
    minimumScore: Yup.number().required().min(0),
});

const queueQuestionFormInitialValues: IQueueQuestionForm = {
    queueQuestion: [],
    minimumScore: 0
}

const QueueQuestionFormComponent: React.FC<{ booth: Booth | GroupType, saveMinScore: (score: number)=> void }> = ({ booth, saveMinScore }) => {
    const questionAnswerService = new QuestionAnswerService();

    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const { jobFair } = useAppContext();

    const [showQuestionsPreviewModal, setShowQuestionsPreviewModal] = useState<boolean>(false);
    const [questionsPreview, setQuestionsPreview] = useState<IQueueQuestionAnswerItem[]>([]);
    const prevQuestionModels = useRef<QuestionModel[]>([]);
    const newQuestionRef = useRef<HTMLInputElement | null>(null);

    const queueQuestionForm = useFormik({
        initialValues: queueQuestionFormInitialValues,
        validationSchema: queueQuestionFormSchema,
        onSubmit: (values) => {
            setQuestionsPreview(values.queueQuestion.map(x => {
                return {
                    queueQuestionId: x.id,
                    answer: undefined,
                    question: x.name
                };
            }))
            setShowQuestionsPreviewModal(true);
        }
    });

    const handleQueueQuestionChange = (e: any, index: number) => {
        let _questions = [...queueQuestionForm.values.queueQuestion]
        _questions[index] = {
            id: _questions[index].id,
            name: e.target.value,
            isEdit: true,
            __typename: 'Question',
            rank: index
        };

        queueQuestionForm.setValues({ queueQuestion: _questions, minimumScore: queueQuestionForm.values.minimumScore }, true);
    }

    const handleQueueQuestionEdit = (index: number, id: string) => {
        queueQuestionForm.values.queueQuestion.forEach(val => { val.isEdit = false });
        if (!queueQuestionForm.values.queueQuestion[index].id)
            queueQuestionForm.values.queueQuestion[index].id = index.toString();
        queueQuestionForm.values.queueQuestion[index].isEdit = true;
        queueQuestionForm.setValues({
            queueQuestion: queueQuestionForm.values.queueQuestion,
            minimumScore: queueQuestionForm.values.minimumScore
        });
    }

    const handleQueueQuestionDelete = (index: number) => {
        queueQuestionForm.values.queueQuestion.splice(index, 1);
        queueQuestionForm.setValues({
            queueQuestion: queueQuestionForm.values.queueQuestion,
            minimumScore: queueQuestionForm.values.minimumScore
        });
    }

    const submitQueueQuestionForm = async () => {

        const questionAnswerService = new QuestionAnswerService();

        if (!jobFair?.id || !booth?.id)
            return;

        setLoading(true);
        const questions: QuestionInput[] = queueQuestionForm.values.queueQuestion.map(x => {
            return {
                id: x.id,
                name: x.name,
                rank: x.rank,
            };
        });


        const convertToModel = (q: (Question | null)[] | null | undefined, isEdit = false): QuestionModel[] => {
            let qm: QuestionModel[] = [];
            if (q && q?.length) {
                q.forEach(x => {
                    if (x)
                        qm.push({
                            id: x?.id,
                            name: x?.name,
                            rank: x?.rank,
                            isEdit,
                            __typename: 'Question'
                        });
                });
            }
            return qm;
        }

        try {
            if (questions.length == 0) {
                await questionAnswerService.deleteAllQuestions(jobFair.id, booth.id);
                prevQuestionModels.current = [];
            }
            const _questions = await questionAnswerService.createOrUpdateAllQuestions(jobFair.id, booth.id, questions);

            if (_questions)
                prevQuestionModels.current = convertToModel(_questions);

            setShowQuestionsPreviewModal(false);

            if (booth.minimumScore != queueQuestionForm.values.minimumScore) {
                saveMinScore(queueQuestionForm.values.minimumScore);
            }

        } catch (error) {
            queueQuestionForm.setSubmitting(false);
            queueQuestionForm.setStatus('Something went wrong.');
        }
        setLoading(false);
    }

    const onCancelForm = () => {
        setShowQuestionsPreviewModal(false);

        queueQuestionForm.setValues({
            queueQuestion: [...prevQuestionModels.current],
            minimumScore: booth?.minimumScore || 0
        });
    };

    const handleQueueQuestionAdd = (e: any) => {

        const newValue: QuestionModel = {
            __typename: 'Question',
            id: (queueQuestionForm.values.queueQuestion?.length || 0).toString(),
            name: newQuestionRef.current?.value || '',
            isEdit: false,
            rank: queueQuestionForm.values.queueQuestion?.length || 0
        };

        if (newQuestionRef.current?.value)
            newQuestionRef.current.value = '';

        queueQuestionForm.setValues({
            queueQuestion: [...queueQuestionForm.values.queueQuestion, newValue],
            minimumScore: queueQuestionForm.values.minimumScore
        }, true);
    }

    const queueQuestionsPreviewModal =
        (<Modal size="lg" show={showQuestionsPreviewModal} onHide={() => {
            setShowQuestionsPreviewModal(false);
        }}>
            <Modal.Header className="questions-modal-header">
                <h4>Queue Questionnaire Preview</h4>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.questionnairePreviewContainer}>
                    <QuestionnaireView
                        boothName={booth.name}
                        queueQuestions={questionsPreview}
                        isPreview={true}
                    />

                </div>

                <div className='d-flex pt-10'>
                    <Button
                        id="manage-booth-buttons-cancel-question-form"
                        variant="light"
                        // className={styles.questionsModalCloseButton}
                        className='btn mr-5 mb-5'
                        onClick={() => { setShowQuestionsPreviewModal(false); }}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="manage-booth-buttons-submit-question-form"
                        variant="dark"
                        type='submit'
                        // className='ml-5'
                        className='ml-5 mb-5'
                        onClick={() => submitQueueQuestionForm()}
                    >
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>);

    useEffect(() => {
        if (!jobFair?.id || !booth?.id) return;

        setShowQuestionsPreviewModal(false);

        questionAnswerService.getAllQueueQuestionsByBoothId(jobFair.id, booth.id).then((questions: (Question | null)[]) => {
            if (!questions) return;
            const questionModels: QuestionModel[] = [];

            questions.forEach(q => {
                if (!q) return;
                const qm = q as QuestionModel;
                qm.isEdit = false;
                questionModels.push(qm);
            })
            queueQuestionForm.setValues({
                queueQuestion: questionModels,
                minimumScore: booth.minimumScore
            });
            prevQuestionModels.current = [...questionModels];
        });

    }, [jobFair?.id, booth?.id]);


    return (
        <div>
            <form
                className='form'
                onSubmit={queueQuestionForm.handleSubmit}
                noValidate
            >
                {queueQuestionForm.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{queueQuestionForm.status}</div>
                    </div>
                )}
                {queueQuestionForm.values.queueQuestion && queueQuestionForm.values.queueQuestion.map((item: QuestionModel, index) => {
                    return (
                        <div key={`div-${index}`} className='fv-row my-5 d-flex align-items-center'>
                            <input
                                key={`input-${index}`}
                                disabled={!item.isEdit}
                                className='form-control form-control-lg'
                                placeholder='type here to add the question...'
                                value={item?.name || ''}
                                onChange={(e) => handleQueueQuestionChange(e, index)}
                                type="text"
                                name={`${index}`}
                                autoComplete='off'
                            />
                            <Button
                                disabled={item.isEdit}
                                key={`edit-button-${index}`}
                                id={`handle-edit-icons-${index}`}
                                onClick={() => handleQueueQuestionEdit(index, item.id)}
                                variant="none"
                                className={styles.editIcon}
                            >
                                <PencilFill key={`pencil-${index}`} id={`pencil-${index}`} fill="#ffa600" size="20px" />
                            </Button>
                            <Button
                                key={`delete-button-${index}`}
                                id={`handle-delete-icons-${index}`}
                                onClick={() => handleQueueQuestionDelete(index)}
                                variant="none"
                                className={styles.deleteIcon}
                            >
                                <TrashFill key={`trash-${index}`} id={`trash-${index}`} fill="#ff0040" size="20px" />
                            </Button>
                            {queueQuestionForm.touched.queueQuestion && queueQuestionForm.errors.queueQuestion && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert'>{queueQuestionForm.errors.queueQuestion}</span>
                                </div>
                            )}
                        </div>
                    )
                })}

                {(queueQuestionForm.values.queueQuestion?.length || 0) < MAX_ALLOWED_QUESTIONS &&
                    (<div className='fv-row my-5 d-flex align-items-center'>
                        <input
                            className='form-control form-control-lg'
                            placeholder='type here to add the question...'
                            type="text"
                            name='queueQuestionCreate'
                            autoComplete='off'
                            ref={newQuestionRef}
                            autoFocus />
                        {<Button
                            disabled={((queueQuestionForm.values.queueQuestion?.length || 0 + 1) >= MAX_ALLOWED_QUESTIONS)}
                            id='handle-add-icons'
                            variant="success"
                            className={styles.addIcon}
                            onClick={handleQueueQuestionAdd}
                        >
                            <PlusLg fill="#18924f" size="20px" />
                        </Button>}
                    </div>)}

                <h6>Job seeker must meet this minimum score to be placed in booth queue.</h6>
                <div className='fv-row mt-2 mb-5'>
                    <input
                        id="number-input-field"
                        className='form-control form-control-lg'
                        placeholder='type here to add the question...'
                        {...queueQuestionForm.getFieldProps('minimumScore')}
                        type="number"
                        name='minimumScore'
                        min={0}
                        max={(queueQuestionForm.values.queueQuestion?.length || 0) + ((newQuestionRef.current?.value || '').length ? 1 : 0)}
                        autoComplete='off'
                    />

                    {queueQuestionForm.touched.minimumScore && queueQuestionForm.errors.minimumScore && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert'>{queueQuestionForm.errors.minimumScore}</span>
                        </div>
                    )}
                </div>

                <div className='d-flex text-center'>
                    <Button
                        id="manage-booth-buttons-cancel-question-form"
                        variant="light"
                        className='btn mr-5 mb-5'
                        onClick={onCancelForm}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="manage-booth-buttons-submit-question-form"
                        variant="dark"
                        type='submit'
                        className='ml-5 mb-5'
                    >
                        Preview &amp; Save
                    </Button>
                </div>
            </form>
            {queueQuestionsPreviewModal}
        </div>
    );
};

export default QueueQuestionFormComponent;
