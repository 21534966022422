import { JobSeekerMenuItems } from './JobSeekerMenuItems'
import { RecruiterMenuItems } from './RecruiterMenuItems'
import { AdminMenuItems } from './AdminMenuItems'

import { Roles } from 'app/types'
import { useAuth } from 'app/context/auth-context'

export function MenuInner() {
  const { user } = useAuth();
  return (
    <>
      {user?.roles?.includes(Roles.Applicant) && JobSeekerMenuItems()}
      {user?.roles?.includes(Roles.Recruiter) && RecruiterMenuItems()}
      {user?.roles?.includes(Roles.Admin) && AdminMenuItems()}
    </>
  )
}
