import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'


import { ManageBoothInfo } from '../shared/manage-booth/ManageBoothInfo'
import { employerAdminUrl } from 'app/types'
import { UrlParamReplace } from 'app/helpers/MiscUtils'
import PaymentSuccess from './components/PaymentSuccess'
import EmployerAdminDashboard from './components/EmployerAdminDashboard'

export const EmployerAdminRoutes = {
  ManageBoothInfo: `${employerAdminUrl}/manage-booth-info/:boothId`,
  Dashboard: `${employerAdminUrl}/dashboard`,
  CheckoutPage: `${employerAdminUrl}/checkout`,
  PaymentSuccess: `${employerAdminUrl}/payment-success`,
}

const manageBoothInfoBreadCrumbs = [
  {
    text: 'Manage Booth Information',
    url: UrlParamReplace(EmployerAdminRoutes.ManageBoothInfo, '', '')
  },
]

interface EAPageHOCProps {
  children: React.ReactNode;
  withContinuePopup?: boolean;
}

const EAPageHOC = (hocProps: EAPageHOCProps) => {
  return (<>
    {hocProps.children}
  </>);
}

const EmployerAdminPage: React.FC = (props) => {
  
  return (
    <Switch>
      <Route path={EmployerAdminRoutes.ManageBoothInfo} render={() => <EAPageHOC withContinuePopup={false}> <ManageBoothInfo title="Manage Booth Information" breadcrumbs={manageBoothInfoBreadCrumbs} showMatrix={false} /> </EAPageHOC>} />
      <Route path={EmployerAdminRoutes.Dashboard} render={() => <EAPageHOC withContinuePopup={false}> <EmployerAdminDashboard /> </EAPageHOC>} />
      <Route path={EmployerAdminRoutes.PaymentSuccess} render={() => <EAPageHOC withContinuePopup={false}> <PaymentSuccess /></EAPageHOC>} />

      <Redirect from={employerAdminUrl} to={EmployerAdminRoutes.Dashboard} />
      <Redirect to={EmployerAdminRoutes.Dashboard} />
    </Switch>
  )
}

export default EmployerAdminPage
