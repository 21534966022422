import { JobFair } from 'app/types';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface Props {
  children?: ReactNode;
}

interface AppContextType {
  jobFair?: JobFair;
  setJobFair: Dispatch<SetStateAction<JobFair | undefined>>
}

const AppContext = createContext<AppContextType | undefined>(undefined);

const useApp = () => {
  const [jobFair, setJobFair] = useState<JobFair | undefined>(undefined);

  return { jobFair, setJobFair };
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export const AppProvider = ({ children }: Props) => {
  const appValue = useApp();

  return <AppContext.Provider value={appValue}>{children}</AppContext.Provider>;
};
