import { navigate } from "app/helpers/MiscUtils";

export function CustomMenuItem({ title, externalLink, newTab, onClick }: { title: string, externalLink?: string, newTab: boolean , onClick?: () => void }) {
  
    return (<div className="menu-item me-lg-1">
      <a className="menu-link py-3" onClick={() => { externalLink ? navigate(externalLink,newTab) : (onClick && onClick()) }}>
        <span className="menu-title">{title}</span>
      </a>
    </div>);
  }
  