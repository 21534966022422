import { Button, ButtonGroup, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  CameraVideo,
  CameraVideoOff,
  Check,
} from 'react-bootstrap-icons';

import { MediaDevice } from '@zoom/videosdk';
import classNames from 'classnames';

interface CameraButtonProps {
  isStartedVideo: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
}
const CameraButton = (props: CameraButtonProps) => {
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    onCameraClick,
    onSwitchCamera,
  } = props;
  const onMenuItemClick = (deviceId: string) => {
    onSwitchCamera(deviceId);
  };
  const menu = cameraList && cameraList.length > 0 && (
    <Dropdown.Menu>
      <Dropdown.Header key={"select_camera"}>Select a Camera</Dropdown.Header>
      {cameraList.map((item) => (
        <Dropdown.Item onClick={() => { onMenuItemClick(item.deviceId) }} key={item.deviceId}>
          <div>{item.deviceId === activeCamera && <Check />}
            {item.label}</div>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );

  return (
    <div className={classNames('camera-footer', className)}>
      {isStartedVideo && menu ? (
        <Dropdown as={ButtonGroup}>
          <Button onClick={onCameraClick} title="click to stop camera" variant="success"> 
            <CameraVideo /> on</Button>
          <Dropdown.Toggle split  variant="success" id="dropdown-split-basic" ></Dropdown.Toggle>
          {menu}
        </Dropdown>
      ) : (
        <Button variant={ isStartedVideo? 'success':'danger'} onClick={onCameraClick} >
          {isStartedVideo ? <><CameraVideo /> <small>On</small></> :  <><CameraVideoOff />  <small>off</small></>}
        </Button>
      )}
    </div>   
  );
};
export default CameraButton;
