/* eslint-disable typescript-enum/no-enum */
/* eslint-disable no-unused-vars */

/*
*Usage

We will maintain all PubSub instances in app-events.ts
ex -
export const CreateDummyPubsub = new PubSub<Dummy, any>();
export const UpdateDummyPubsub = new PubSub<Dummy, DummyArgs>();

Any component may start subscription on useEffect and cleanp the subscription via return cleanup();
*/

import { AppModel } from '../types';

// Disposable Pattern , helps to unsubscribe after subsription
export interface Disposable {
  dispose(): any;
}

/* Local events are data generated by App for other component to subscribe
Amplify events are data recieved as a result of subscription from Amplify
*/

export enum PubSubEventType { Local = 'Local', Amplify = 'Amplify'};

// Subscriber's callback type defination
export interface Listener<T, TArg> {
  (eventType: PubSubEventType, event: T, arg?: TArg): any;
}

/* Typesage Generic Pubsub class */
export class PubSub<T extends AppModel, TArg> {
    // Recieve data from amplify
    paused: boolean;

    constructor () {
      this.paused = false;
    }

    pause () {
      this.paused = true;
    }

    resume () {
      this.paused = false;
    }

    onAmplifyData (data: T, arg?: TArg) {
      if (!this.paused) { this.emit(PubSubEventType.Amplify, data, arg); }
    }

    // Publish data locally with data and additional arguments that might be required for other components
    // Topic Key is optional, in case of publishing message -- publish(message,null,'session_id_1')
    publish (data: T, additionalArgumemts?: TArg, topicKey?: string) {
      data.topicKey = topicKey || '';
      this.emit(PubSubEventType.Local, data, additionalArgumemts);
    }

    // Publish data to all subscribers / listners
    private emit = (eventType: PubSubEventType, event: T, arg?: TArg) => {
      /** Update any general listeners */
      this.listeners.forEach((listener) => listener(eventType, event, arg));

      /** Clear the `once` queue */
      if (this.listenersOncer.length > 0) {
        const toCall = this.listenersOncer;
        this.listenersOncer = [];
        toCall.forEach((listener) => listener(eventType, event, arg));
      }
    }

    private listeners: Listener<T, TArg>[] = []; // maintains list of all Listeners

    private listenersOncer: Listener<T, TArg>[] = []; // maintains list of all Listener Oncer, listnere is unsubscribed after 1st use

    // Subscribe with disposable callback
    subscribe = (listener: Listener<T, TArg>): Disposable => {
      this.listeners.push(listener);
      return {
        dispose: () => this.unsubscribe(listener)
      };
    }

    // Subscribe once, no client depedency to unsubscribe / dispose
    subscribeOnce = (listener: Listener<T, TArg>): void => {
      this.listenersOncer.push(listener);
    }

    // removes listner from list
    unsubscribe = (listener: Listener<T, TArg>) => {
      const callbackIndex = this.listeners.indexOf(listener);
      if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
    }

    // Pipe to any other stream function
    pipe = (te: PubSub<T, TArg>): Disposable => {
      return this.subscribe((t, e, a) => te.emit(t, e, a));
    }
}
