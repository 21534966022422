import './profile.scss';

import { Badge, Col, Image, Row } from "react-bootstrap";

import { UserStatus } from "app/API";
import { getStyleByIndex } from 'app/helpers/MiscUtils';

type RecruiterProfileInfoProps = {
    index: number;
    profilePicUrl?: string;
    name?: string;
    companyName?: string;
    designation?: string;
    initials?: string;
    rating?: number;
    address?: string;
    greetings?: string;
    status?: UserStatus;
    children?: React.ReactNode;
}

export const RecruiterListItem = (props: RecruiterProfileInfoProps) => {
    const bgMap: Map<UserStatus, string> = new Map<UserStatus, string>();
    bgMap.set(UserStatus.AWAY, 'warning');
    bgMap.set(UserStatus.DISCONNECTED, 'danger')
    bgMap.set(UserStatus.LOGGED_OUT, 'secondary')
    bgMap.set(UserStatus.LOGGED_IN, 'info')

    let userStatus: UserStatus = props.status || UserStatus.LOGGED_OUT;

    if (userStatus === UserStatus.DISCONNECTED) {
        userStatus = UserStatus.LOGGED_OUT;
    }

    return (
        <div className="jobSeekerListItem">
            <div className="imageWrap">
                {props.profilePicUrl ?
                    <Image src={props.profilePicUrl} />
                    :
                    <div className="symbol symbol-50px">
                        <div className={"symbol-label fs-2 fw-bold bg-" + getStyleByIndex(props.index) + " text-inverse-" + getStyleByIndex(props.index)}>{props.initials}</div>
                    </div>
                }
            </div>
            <div className="d-flex align-items-center">
                <div className="rightItem">
                    <h4>{props.name}</h4>
                    {props.companyName && <h5> {props.companyName}</h5>}
                    <h6> {props.designation || 'Recruiter'}</h6>
                    {props.address && <em>{props.address}</em>}
                    {props.greetings && <p> {props.greetings}</p>}
                    {props.status && <Badge className={`me-auto mx-2 badge-${bgMap.get(userStatus)}`} ><small>{userStatus.replaceAll('_', ' ')}</small></Badge>}
                </div>
                {props.children &&
                    <div className="rightItem" >
                        {props.children}
                    </div>}
            </div>
        </div>
    );
}