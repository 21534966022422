import { Resume } from 'app/API';
import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { CGOResume, NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';

const EndPoints = {
  ResumesByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/resumes/:boothId`,
  ResumesByJobFairIdAndBoothIdAndId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/resumes/:boothId/:id`
};

export class ResumeService {
  async getApplicantResume (applicantId: number) {
    const result = await axios.get<CGOResume>(`${config.cgoBaseUrl}/resumes/by_applicant?applicant_id=${applicantId}`);
    return result.data;
  }

  async convertDocument (s3Key: string) {
    const result = await axios.post(`${config.vjfpNGRestAPI}/documents/convert`, {
      fileName: s3Key
    });
    return result;
  }

  async getResumeList (jobFairId: string, boothId?: string, userId?: number) {
    try {
      if (jobFairId && boothId) {
        let url = UrlBuilder(EndPoints.ResumesByJobFairIdAndBoothId, { jobFairId, boothId });
        if (userId) {
          url += `?jobSeekerId=${userId}`;
        }
        const result = await axios.get<NgAPIResponse<Resume[]>>(url, await getCommonAxiosCommon());
        return result.data.data;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async addResumeToBooth (jobFairId: string, boothId: string | undefined,
    userId: number | undefined,
    fileName: string,
    resumeS3Key: string,
    jobTitle: string,
    notes: string = '') {
    try {
      const result = await axios.post<NgAPIResponse<Resume[]>>(UrlBuilder(EndPoints.ResumesByJobFairIdAndBoothId, { jobFairId, boothId }), {
        boothId,
        jobFairId,
        jobSeekerId: userId,
        fileName,
        resumeS3Key,
        jobTitle,
        notes
      }, await getCommonAxiosCommon());

      return result?.data?.data;
    } catch (err) {
      console.error(err);
    }
  }

  async updateResume (jobFairId: string, boothId: string, resumeId: string,
    fileName: string,
    resumeS3Key: string,
    jobTitle: string | null | undefined,
    notes: string | null | undefined) {
    try {
      const result = await axios.put<NgAPIResponse<Resume>>(UrlBuilder(EndPoints.ResumesByJobFairIdAndBoothIdAndId, { jobFairId, boothId, id: resumeId }), {
        fileName,
        resumeS3Key,
        jobTitle,
        notes
      }, await getCommonAxiosCommon());
      return result?.data?.data;
    } catch (err) {
      console.error(err);
    }
  }
}
