
import VideoFooter from './components/video-footer';

import { selectLocalPeer, selectRemotePeers, useHMSStore, useVideo } from '@100mslive/react-sdk';


type VideoContainerProps = {
    showEndChatModal: () => void;
}

const VideoContainer = (props: VideoContainerProps) => {

    const _localPeer = useHMSStore(selectLocalPeer);
    const { videoRef } = useVideo({
        trackId: useHMSStore(selectRemotePeers)?.find(x=>x.roleName !== _localPeer?.roleName)?.videoTrack
    });

    const useSelfVideo = useVideo({
        trackId:_localPeer?.videoTrack
    });

    return (
        <>
            <video
                ref={videoRef}
                className="video-canvas"
                id="video-canvas"
                autoPlay
                muted
                playsInline
            />

            <video
                className="self-video-canvas"
                id="self-video-canvas"
                ref={useSelfVideo.videoRef}
                autoPlay
                muted
                playsInline
            />
            <div className="controlPan">
                <div className="userThumb" ></div>
                <VideoFooter showEndChatModal={async () => {
                    props.showEndChatModal();
                }} />
                <div className="extraControls"></div>
            </div>
        </>
    );
};

export default VideoContainer;
