import { GroupType } from 'app/API';
import React from 'react';
import { Border } from 'react-bootstrap-icons';
import { Modal, Button, Container, Card } from 'react-bootstrap-v5';

interface GroupSelectorProps {
    show: boolean;

    groups: GroupType[];
    onSelectGroup: (group: GroupType) => void;
}

const GroupSelector: React.FC<GroupSelectorProps> = ({ show, groups, onSelectGroup }) => {
    return (
        <Modal size='lg' centered = {true} show={show} onHide={() => { }} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Select a Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='d-flex p-5 flex-wrap justify-content-center'>
                    {groups.map((group) => (
                        <Card key={group.id} className='m-2' style={{border: '1px solid lightgrey'}}>
                            <Card.Header className='mt-2 text-info'>{group.name}</Card.Header>
                            <Card.Body>
                                <p><strong className='text-muted'>Group Description:</strong></p> 
                                <p>{group.description || 'No description available'}</p>
                                <p className='mt-2'><strong className='text-muted'>Hot Job Summary:</strong></p> 
                                <p>{group.hotJobSummary || 'No summary available'}</p>
                            </Card.Body>
                            <Card.Footer className="d-flex align-content-center">
                            <Button variant="info" onClick={() => onSelectGroup(group)}>
                                Select
                            </Button>
                            </Card.Footer>
                        </Card>
                    ))}
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default GroupSelector;
