import { Button, Modal } from "react-bootstrap"
import { Check, X } from "react-bootstrap-icons"

type AppModalProps = {
    show: boolean,
    title: string,
    children?: React.ReactNode,
    variant?: 'YesNo' | 'OKCancel' | 'PlainText',
    onHide?: () => void,
    onReject?: () => void,
    onSuccess?: () => void,
    rejectText?: string,
    successText?: string,
    successBtnVariant?: string,
    rejectBtnVariant?: string,
    disableRejectBtn?: boolean
}

export const AppModal = (props: AppModalProps) => {
    return (<Modal className="modal-custom" tabIndex={-1} show={props.show} onHide={props.onHide} centered>
        <Modal.Header closeButton={!!props.onHide}>
            <Modal.Title><h5>{props.title}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
            <Button variant={props.successBtnVariant ? props.successBtnVariant : "dark"} onClick={props.onSuccess}> {props.variant !== 'PlainText' &&
                <span className="svg-icon svg-icon-1"><Check /></span>}
                <span className="ml-1">{props.successText ? props.successText : (props.variant === 'YesNo' ? 'Yes' : 'OK')}</span></Button>
            {(!(props.disableRejectBtn === true)) && <Button variant={props.rejectBtnVariant ? props.rejectBtnVariant : "link"} onClick={props.onReject}> {props.variant !== 'PlainText' &&
                <span className="svg-icon svg-icon-1"><X /></span>}
                <span className="ml-1 text-muted">{props.rejectText ? props.rejectText : (props.variant === 'YesNo' ? 'No' : 'Cancel')}</span></Button>}
        </Modal.Footer>
    </Modal>)
}