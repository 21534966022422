import moment from 'moment';

export class TimeService {
  async getServerTime () {
    try {
      let delta = localStorage.getItem('DeltaTimeDiff');

      if (delta === null) { delta = await this.loadDelta(); }

      const browserTime = moment().unix();

      if (delta) {
        return browserTime + parseInt(delta);
      }
      return 0;
    } catch (err) {
      console.error(err);
    }
  }

  async loadDelta () {
    try {
      const serverTime = Number(localStorage.getItem('Ws_TimeStamp'));
      if (serverTime) {
        const browserTime = moment().unix();
        const timeDiff = serverTime - browserTime;
        localStorage.setItem('DeltaTimeDiff', String(timeDiff));
        return String(timeDiff);
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  }
}
