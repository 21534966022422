import { Observable, Subscription } from 'rxjs';

import { InterviewSession, VJFPUser } from './API';
import { ChangeInterviewSessionPubsub, ChangeInvitePubsub, ChangeQueueUserPubsub, CreateMessagePubsub, UpdatePresencePubsub } from './app-events';
import { RxSubscription } from './RxSubscriptions';
import { VJFPCache } from './services/CacheUtils';
import { CGOInviteAppModel, ChangeEvent } from './types';

type VJFPSubscription = {
  key: string;
  value: Subscription;
  dispose: () => void;
};

const listOfSubscriptions: VJFPSubscription[] = [];

function subscribeAndHandle<T> (
  key: string,
  observable: Observable<T>,
  callback: (value: T) => void
): VJFPSubscription {
  const subscriptionKey = key;
  const existingSubscription = listOfSubscriptions.find(x => x.key === subscriptionKey);

  if (existingSubscription) {
    return existingSubscription;
  }

  const subscription = observable.subscribe((value: T) => {
    if (!value) {
      return;
    }
    callback(value);
  });

  const newSubscription: VJFPSubscription = {
    key: subscriptionKey,
    value: subscription,
    dispose: () => {
      const index = listOfSubscriptions.findIndex(x => x.key === subscriptionKey);
      if (index !== -1) {
        listOfSubscriptions.splice(index, 1);
        subscription.unsubscribe();
        console.log(`${key} - unsubscribed`);
      }
    }
  };

  listOfSubscriptions.push(newSubscription);

  return newSubscription;
}

export function setupQueueSubscriptionsByBoothId (boothId: string) {
  RxSubscription.subscribe('onChangeQueueUserByBoothId', { boothId });

  return subscribeAndHandle(
    `CREATE_QUEUE_USER_BOOTH_${boothId}`,
    RxSubscription.onChangeQueueUserByBoothIdObservable(),
    value => {
      if (value) { ChangeQueueUserPubsub.onAmplifyData(value, null); }
    }
  );
}

export function setupQueueSubscriptionsByCandidateId (candidateId: number) {
  RxSubscription.subscribe('onChangeQueueUserByCandidateId', { candidateId });

  return subscribeAndHandle(
    `CREATE_QUEUE_USER_CANDIDATE_${candidateId}`,
    RxSubscription.onChangeQueueUserByCandidateIdObservable(),
    value => ChangeQueueUserPubsub.onAmplifyData(value, null)
  );
}

export function setupAmplifyPresenceSubscriptions (userIds: Array<number>) {
  const disposables = userIds.map(userId => {
    RxSubscription.subscribe('onUpdateVJFPUserById', { userId });

    const presenceSubscriptionKey = `PRESENCE_${userId}`;

    return subscribeAndHandle(
      presenceSubscriptionKey,
      RxSubscription.onUpdateVJFPUserByIdObservable(),
      value => {
        const lastCachedUserInfo = VJFPCache.getItem(`vjfpUser-${value.id}`) as VJFPUser;
        if (
          lastCachedUserInfo &&
          lastCachedUserInfo.presenceStatus === value.presenceStatus &&
          lastCachedUserInfo.isAvailableForChat === value.isAvailableForChat
        ) {
          return;
        }

        VJFPCache.setItem(`vjfpUser-${value.id}`, value);
        UpdatePresencePubsub.onAmplifyData(value, null);
      }
    );
  });

  return {
    dispose: () => {
      disposables.forEach(disposable => disposable.dispose());
    }
  };
}

export function setupInviteSubscriptions (candidateId: number) {
  const inviteSubscriptionKey = `INVITE_${candidateId}`;
  RxSubscription.subscribe('onChangeInviteByCandidateId', { candidateId });

  return subscribeAndHandle<CGOInviteAppModel>(
    inviteSubscriptionKey,
    RxSubscription.onChangeInviteByCandidateIdObservable(),
    value => ChangeInvitePubsub.onAmplifyData(value, null)
  );
}

export function setupAmplifyMessageSubscription (topicId: string) {
  const createMessageSubscriptionKey = `CREATE_MESSAGE_${topicId}`;
  RxSubscription.subscribe('onCreateMessageByTopicId', { topicId });

  return subscribeAndHandle(
    createMessageSubscriptionKey,
    RxSubscription.onCreateMessageByTopicIdObservable(),
    value => {
      if (value !== null) {
        CreateMessagePubsub.onAmplifyData(value, null);
      }
    }
  );
}

export function setupInterviewSessionSubscriptionsByCandidateId (candidateId: number) {
  const changeInterviewSessionByCandidateIdSubscriptionKey = `changeInterviewSessionByCandidateId_${candidateId}`;
  RxSubscription.subscribe('onChangeInterviewSessionByCandidateId', { candidateId });

  return subscribeAndHandle(
    changeInterviewSessionByCandidateIdSubscriptionKey,
    RxSubscription.onChangeInterviewSessionByCandidateIdObservable(),
    (value: ChangeEvent<InterviewSession> | null) => {
      if (value !== null) {
        ChangeInterviewSessionPubsub.onAmplifyData(value, null);
      }
    }
  );
}

export function setupInterviewSessionSubscriptionsByRecruiterId (recruiterId: number) {
  const changeInterviewSessionByRecruiterIdSubscriptionKey = `changeInterviewSessionByRecruiterId_${recruiterId}`;
  RxSubscription.subscribe('onChangeInterviewSessionByRecruiterId', { recruiterId });

  return subscribeAndHandle(
    changeInterviewSessionByRecruiterIdSubscriptionKey,
    RxSubscription.onChangeInterviewSessionByRecruiterIdObservable(),
    (value: ChangeEvent<InterviewSession> | null) => {
      if (value !== null) {
        ChangeInterviewSessionPubsub.onAmplifyData(value, null);
      }
    }
  );
}

export function clearAllAmplifySubscriptions () {
  listOfSubscriptions.forEach(x => x.dispose());
  listOfSubscriptions.length = 0;
}
