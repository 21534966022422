import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      ReactGA.initialize('UA-214917412-1');
      initialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (!initialized?.current || !location) { return; }
    ReactGA.pageview(location.pathname + location.search);

    return () => {

    };
  }, [initialized?.current, location]);
};

export default usePageTracking;
