import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Amplify, Storage } from 'aws-amplify';
import { useAuth } from 'app/context/auth-context';
import { OrgService, Organization } from 'app/services/OrgService';

Amplify.configure({
  Storage: {
    AWSS3: {
      region: 'us-east-1',
      bucket: 'videojobfairs-docs'
    }
  }
});

const DragDropArea: React.FC<{ onFilesDrop: (files: File[]) => void }> = ({ onFilesDrop }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    onFilesDrop(files);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      onClick={handleClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        border: `2px dashed ${isDragging ? '#007bff' : '#ccc'}`,
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isDragging ? '#f8f9fa' : 'white',
      }}
    >
      <p>Drag and drop company documents here, or click to select files</p>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={(e) => onFilesDrop(Array.from(e.target.files || []))}
        style={{ display: 'none' }}
      />
    </div>
  );
};

const OrganizationForm: React.FC<{ existingOrg: Organization | null, onSave: (() => void) }> = (props) => {
  const [logoThumbnail, setLogoThumbnail] = useState<string | null>(null);
  const [uploadedDocuments, setUploadedDocuments] = useState<File[]>([]);
  const [existingDocuments, setExistingDocuments] = useState<Array<{ key: string, name: string, url: string }>>([]);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useAuth();
  const orgService = new OrgService();

  const formik = useFormik({
    initialValues: {
      companyName: props.existingOrg?.companyName || '',
      companyDetails: props.existingOrg?.companyDetails || '',
      jobBoardLink: props.existingOrg?.jobBoardLink || '',
      companyLogo: null as File | null,
      companyDocuments: [] as File[],
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .max(100, 'Company Name must be 100 characters or less')
        .required('Company Name is required'),
      companyDetails: Yup.string().max(5000, 'Company Details must be 5000 characters or less'),
      jobBoardLink: Yup.string().max(500, 'Link to job board must be 500 characters or less'),
      companyLogo: Yup.mixed().required('Company logo is required'),
      companyDocuments: Yup.array().of(Yup.mixed()),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        if (!user?.id) {
          return;
        }
        let logoPath;

        if (values.companyLogo) {
          const file = values.companyLogo;
          const fileExtension = file.name.split('.').pop();
          logoPath = await Storage.put(`${user.id}/company_logo/logo.${fileExtension}`, file, {
            contentType: file.type
          });
        }

        // Upload Company Documents to S3 (if any)
        const documentKeys = await Promise.all(
          values.companyDocuments.map((doc) =>
            Storage.put(`${user.id}/company_docs/${doc.name}`, doc, {
              contentType: doc.type,
            })
          )
        );

        // Sending other form data to the backend via Axios
        const orgData = {
          id: `${user.id}`,
          companyName: values.companyName,
          companyDetails: values.companyDetails,
          jobBoardLink: values.jobBoardLink,
          companyLogo: logoPath?.key || props.existingOrg?.companyLogo,
          companyDocuments: documentKeys.map(doc => doc.key),
        };

        if (props.existingOrg) {
          await orgService.updateOrg(user.id, orgData);
        } else {
          await orgService.createOrg(orgData);
          props.onSave();
        }
        console.log('Organization saved successfully');
      } catch (error) {
        console.error('Error submitting form', error);
      }
      finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (props.existingOrg) {
      try {
        // Set initial form values
        formik.setValues({
          companyName: props.existingOrg.companyName || '',
          companyDetails: props.existingOrg.companyDetails || '',
          jobBoardLink: props.existingOrg.jobBoardLink || '',
          companyLogo: null,
          companyDocuments: [],
        });
        // Set logo thumbnail if exists
        if (props.existingOrg.companyLogo) {
          try {
            (async () => {
              const logoUrl = await Storage.get(props.existingOrg!.companyLogo, { expires: 60 });
              setLogoThumbnail(logoUrl);
            })();
          } catch (error) {
            console.error('Error fetching logo:', error);
          }
        }

        (async () => {
          const existingDocs = await Promise.all(
            (props.existingOrg!.companyDocuments || []).map(async (docKey) => {
              const url = await Storage.get(docKey, { expires: 3600 });
              return { key: docKey, name: docKey.split('/').pop() || '', url };
            }));
          setExistingDocuments(existingDocs);

        })();
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    }
  }, [props.existingOrg]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0) {
      if (fieldName === 'companyLogo') {
        const file = files[0];
        formik.setFieldValue(fieldName, file);
        setIsLogoUploading(true);
        // Create thumbnail
        const reader = new FileReader();
        reader.onload = (e) => {
          setLogoThumbnail(e.target?.result as string);
          setIsLogoUploading(false);
        };
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      } else if (fieldName === 'companyDocuments') {
        const newDocuments = Array.from(files);
        formik.setFieldValue(fieldName, [...formik.values.companyDocuments, ...newDocuments]);
        setUploadedDocuments(prevDocs => [...prevDocs, ...newDocuments]);
      }
    }
  };

  const handleDocumentDelete = (index: number) => {
    const updatedDocuments = uploadedDocuments.filter((_, i) => i !== index);
    setUploadedDocuments(updatedDocuments);
    formik.setFieldValue('companyDocuments', updatedDocuments);
  };

  // Update your handleDocumentDelete function:
  const handleExistingDocumentDelete = async (index: number) => {
    const docToDelete = existingDocuments[index];
    try {
      await Storage.remove(docToDelete.key);
      const updatedDocs = existingDocuments.filter((_, i) => i !== index);
      setExistingDocuments(updatedDocs);
    } catch (error) {
      console.error('Error deleting document from S3:', error);
    }
  };

  const handleFilesDrop = (files: File[]) => {
    const newDocuments = [...formik.values.companyDocuments, ...files];
    formik.setFieldValue('companyDocuments', newDocuments);
    setUploadedDocuments(newDocuments);
  };

  return (
    <div className="card">
      <div className="card-header py-3">
        <h3 className="card-title text-dark">Organization Details</h3>
      </div>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid me-lg-15 mb-10 mb-lg-0">
              <div className="mb-10">
                <label htmlFor="companyName" className="form-label fw-bold">Company Name</label>
                <input
                  id="companyName"
                  name="companyName"
                  type="text"
                  maxLength={100}
                  className={`form-control ${formik.touched.companyName && formik.errors.companyName ? 'is-invalid' : ''}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                />
                {formik.touched.companyName && formik.errors.companyName ? (
                  <div className="invalid-feedback">{formik.errors.companyName}</div>
                ) : null}
              </div>

              <div className="row mb-10">
                <div className="col-lg-12">
                  <label htmlFor="companyDetails" className="form-label fw-bold">Company Details</label>
                  <textarea
                    id="companyDetails"
                    name="companyDetails"
                    maxLength={5000}
                    className={`form-control ${formik.touched.companyDetails && formik.errors.companyDetails ? 'is-invalid' : ''}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyDetails}
                    rows={5}
                  />
                  {formik.touched.companyDetails && formik.errors.companyDetails ? (
                    <div className="invalid-feedback">{formik.errors.companyDetails}</div>
                  ) : null}
                </div>
              </div>

              <div className="row mb-10">
                <div className="col-lg-12">
                  <label htmlFor="jobBoardLink" className="form-label fw-bold">Link to Job Board</label>
                  <input
                    id="jobBoardLink"
                    name="jobBoardLink"
                    type="text"
                    maxLength={500}
                    className={`form-control ${formik.touched.jobBoardLink && formik.errors.jobBoardLink ? 'is-invalid' : ''}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.jobBoardLink}
                  />
                  {formik.touched.jobBoardLink && formik.errors.jobBoardLink ? (
                    <div className="invalid-feedback">{formik.errors.jobBoardLink}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="separator-container d-none d-lg-flex">
              <div className="separator separator-solid bg-secondary h-100">&nbsp;</div>
            </div>

            <div className="flex-lg-row-fluid ms-lg-15">
              <div className="row mb-10">
                <div className="col-lg-12">
                  <label htmlFor="companyLogo" className="form-label fw-bold">Company Logo</label>
                  <div className="d-flex align-items-center">
                    <label htmlFor="companyLogo" className="btn btn-primary me-3">
                      <i className="fas fa-upload me-2"></i>Select Logo
                      <input
                        id="companyLogo"
                        name="companyLogo"
                        type="file"
                        accept="image/*"
                        className="d-none"
                        onChange={(e) => handleFileChange(e, 'companyLogo')}
                      />
                    </label>
                    {isLogoUploading ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : logoThumbnail && (
                      <div className="ms-3">
                        <img
                          src={logoThumbnail}
                          alt="Logo preview"
                          crossOrigin="anonymous"
                          style={{ maxWidth: '100px', maxHeight: '100px' }}
                          onError={(e) => console.error('Error loading image:', e)}
                        />
                      </div>
                    )}
                  </div>
                  {formik.touched.companyLogo && formik.errors.companyLogo ? (
                    <div className="invalid-feedback d-block">{formik.errors.companyLogo}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-10">
                <div className="col-lg-12">
                  <label className="form-label fw-bold">Upload Company Documents</label>
                  <DragDropArea onFilesDrop={handleFilesDrop} />
                  {uploadedDocuments.length > 0 && (
                    <ul className="list-group mt-2">
                      {uploadedDocuments.map((doc, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                          {doc.name}
                          <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDocumentDelete(index)}>
                            <i className="fas fa-trash"></i>
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}

                  {existingDocuments.length > 0 && (
                    <ul className="list-group mt-2">
                      {existingDocuments.map((doc, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                          {doc.name}
                          <button type="button" className="btn btn-sm btn-danger" onClick={() => handleExistingDocumentDelete(index)}>
                            <i className="fas fa-trash"></i>
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
          {isSubmitting && (
            <div className="blockui-overlay" style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.05)',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <div className="blockui-message">
                <span className="spinner-border text-primary"></span>
                <span className="ms-3">Processing...</span>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default OrganizationForm;
