import { Link } from "react-router-dom";

type Navigation = {
    text: string,
    url?: string
}

type AppBreadcrumbProps = {
    navigations: Navigation[],
    isReadonly?: boolean
}

export function AppBreadcrumb(props: AppBreadcrumbProps) {
    return (<ol className="breadcrumb text-muted fs-6 fw-bold">
        {props.navigations.map((nav, index) =>
            <li key={index} className={"breadcrumb-item pe-3" + (index === (props.navigations.length - 1) ? "text-muted" : "")}>
                {nav.url ? (<Link to={nav.url} className="pe-3" style={props.isReadonly ? {pointerEvents: "none"} : {pointerEvents: "auto"}}>{nav.text}</Link>) : nav.text}
            </li>)
        }</ol>);
}