import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { checkIsActive, KTSVG } from '../../../helpers'
import { transform } from 'lodash'
import { UrlParamReplace } from 'app/helpers/MiscUtils'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  highlight?: boolean
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  highlight = false
}) => {
  const { pathname } = useLocation()

  const transformUrl = (url: string) => url.includes(":jobFairId") ? UrlParamReplace(url, '', '') : url;

  return (
    <div className='menu-item me-lg-1'>
      <Link
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, transformUrl(to)),
        })}
        to={transformUrl(to)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className='menu-title'>{title} {highlight?<sup className='blink'>✉</sup>:<></>}</span>

        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export { MenuItem }
