import { DeviceType, useDevices } from '@100mslive/react-sdk';
import React, { useState } from 'react';

interface Option {
    value: string;
    label: string;
}

interface FormProps {

}

interface FormData {
    selectedCamera: Option | null;
    selectedMicrophone: Option | null;
    selectedOutputAudioDevice: Option | null;
}


const DeviceSettings: React.FC<FormProps> = () => {
    const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();

    const handleCameraChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const deviceId = event.target.value;
        updateDevice({
            deviceId,
            deviceType: DeviceType.videoInput
        });
    };

    const handleAudioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const deviceId = event.target.value;
        updateDevice({
            deviceId,
            deviceType: DeviceType.audioInput
        });
    };


    const handleAudioOutputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const deviceId = event.target.value;
        updateDevice({
            deviceId,
            deviceType: DeviceType.audioOutput
        });
    };

    return (
        <div>
            {allDevices.videoInput && < div className="p-2">
                <b>Camera Device : </b>
                <select value={selectedDeviceIDs.videoInput} onChange={handleCameraChange}>
                    <option value="">Choose an option</option>
                    {allDevices.videoInput.map((option) => (
                        <option key={option.deviceId} value={option.deviceId}>
                            {option.label.split(' ').reduce((a, b) => (a + b).length < 50 ? a + ' ' + b : a)}
                        </option>
                    ))}
                </select>
            </div>}
            {allDevices.audioInput && <div className="p-2">
                <b>Microphone : </b>
                <select value={selectedDeviceIDs.audioInput} onChange={handleAudioChange}>
                    <option value="">Choose an option</option>
                    {allDevices.audioInput.map((option) => (
                        <option key={option.deviceId} value={option.deviceId}>
                            {option.label.split(' ').reduce((a, b) => (a + b).length < 50 ? a + ' ' + b : a)}
                        </option>
                    ))}
                </select>
            </div>}
            {allDevices.audioOutput && <div className="p-2">
                <b>Audio output : </b>
                <select value={selectedDeviceIDs.audioOutput} onChange={handleAudioOutputChange}>
                    <option value="">Choose an option</option>
                    {allDevices.audioOutput.map((option) => (
                        <option key={option.deviceId} value={option.deviceId}>
                            {option.label.split(' ').reduce((a, b) => (a + b).length < 50 ? a + ' ' + b : a)}
                        </option>
                    ))}
                </select>
            </div>}
        </div >
    );
};

export default DeviceSettings;
