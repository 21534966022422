import {PageLink} from '../../../_metronic/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'

import AdminLobby from './components/AdminLobby'
import React from 'react'
import { ManageBoothInfo } from '../shared/manage-booth/ManageBoothInfo'
import { adminUrl } from 'app/types'

export const AdminRoutes = {
    
    Lobby : `${adminUrl}/lobby`,
    BoothDetail: `${adminUrl}/lobby/booth/:boothId`,
}

const adminBreadCrumbs: Array<PageLink> = [
  {
    title: 'Lobby',
    path: AdminRoutes.Lobby,
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AdminPage: React.FC = () => {
  return (
    <>
     
      <Switch>
        <Route path={AdminRoutes.BoothDetail}>
          <ManageBoothInfo title="Manage Booth Information" showMatrix={true} />
        </Route>
        <Route path={AdminRoutes.Lobby}>
          <AdminLobby title="Event Lobby" />
        </Route>        
           
        <Redirect from='/admin' to={AdminRoutes.Lobby} />
        <Redirect to={AdminRoutes.Lobby} />
      </Switch>
    </>
  )
}

export default AdminPage
