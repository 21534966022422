import { useCallback, useEffect, useRef } from 'react';

function useIsMounted (componentName = '') {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if (componentName.length > 0) {
      console.log(`Mounted - ${componentName}`);
    }

    return () => {
      isMounted.current = false;
      if (componentName.length > 0) { console.log(`UnMounted - ${componentName}`); }
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export default useIsMounted;
