/* eslint-disable multiline-ternary */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useAuth } from 'app/context/auth-context';
import usePageTracking from 'app/usePageTracking';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { Logout } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

const Routes: FC = () => {
  const { user } = useAuth();
  usePageTracking();
  return (
    <Switch>
      {!user ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <Route>
         <PublicRoutes />
        </Route>
      ) : (
        /* Otherwise redirect to root page (`/`) */
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {user === null ? (
        /* Redirect to `/auth` when user is not authorized */
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  );
};

export { Routes };
