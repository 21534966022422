import { CreateMessageInput, CreateMessageReceiptsInput, Message, MessageReceipts } from 'app/API';
import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';

const EndPoints = {
  BroadcastMessage: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/messages`,
  MessageReceipt: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/messages/receipt`,
  MessageReceipts: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/messages/receipts`,
  GetMessageReceipts: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/messages/receipts/:recipientId`,
  markAsRead: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/messages/receipts/mark-as-read/:messageReceiptId`
};

export class MessageService {
  async broadcastMessage (jobFairId: string, topicKey: string, message: string, broadCasterUserId: number) {
    try {
      const input: CreateMessageInput = {
        topicId: topicKey,
        message,
        createdBy: broadCasterUserId
      };

      const result = await axios.post<NgAPIResponse<Message>>(UrlBuilder(EndPoints.BroadcastMessage, { jobFairId }), input, await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async createMessageRecipient (messageId: string, recipientId: number, jobFairId: string) {
    try {
      const input: CreateMessageReceiptsInput = {
        messageId,
        recipientId,
        read: false,
        jobFairId
      };
      const result = await axios.post<NgAPIResponse<Message>>(UrlBuilder(EndPoints.MessageReceipt, { jobFairId }), input, await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async createMessageRecipients (messageId: string, recipientIds: Array<number>, jobFairId: string) {
    try {
      const result = await axios.post<NgAPIResponse<MessageReceipts>>(UrlBuilder(EndPoints.MessageReceipts, { jobFairId }), {
        messageId,
        recipientIds,
        read: false,
        jobFairId
      }, await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getMessageRecipientsByRecipientId (jobFairId: string, recipientId: number) {
    try {
      const result = await axios.get<NgAPIResponse<MessageReceipts[]>>(UrlBuilder(EndPoints.GetMessageReceipts, { jobFairId, recipientId }), await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async markMessageRead (jobFairId: string, messageReceiptId: string) {
    try {
      const result = await axios.get<NgAPIResponse<void>>(UrlBuilder(EndPoints.markAsRead, { jobFairId, messageReceiptId }), await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async deleteAllMessageRecipientsByJobFairId (jobFairId: string): Promise<boolean> {
    try {
      const result = await axios.delete<NgAPIResponse<void>>(UrlBuilder(EndPoints.MessageReceipts, { jobFairId }), await getCommonAxiosCommon());
      return result.data?.isSuccess;
    } catch (err) {
      console.log(err);
    }
    return false;
  }
}
