import { AppBreadcrumb } from "./AppBreadcrumb";

type AppTopNavProps = {
    title?: string;
    badge?: string;
    breadcrumbs?: {
        text: string,
        url?: string
    }[];
    children?: React.ReactNodeArray;
    isBreadcrumbsReadonly?: boolean
}


export function AppTopNav(props: AppTopNavProps) {
    return (<>
        <div className="topNav">
            <div className="container-fluid">
                <div className="flex">
                    <div className="title">
                        <h1>{props.title} {props.badge && <div className="symbol symbol-25px symbol-circle">
                            <div className="symbol-label fs-6 fw-bold bg-danger text-inverse-danger">{props.badge}</div>
                        </div>
                        }</h1>

                        {props.breadcrumbs && <AppBreadcrumb isReadonly={props.isBreadcrumbsReadonly} navigations={props.breadcrumbs} />}
                    </div>
                    <div className="toolbar">
                    </div>
                    <div className="actionPan">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </>);
}