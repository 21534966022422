import { ChatReceiver, ChatRecord } from "./chat-types";
import { KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from "react";

import ChatContext from 'app/context/chat-context';
import ChatMessageItem from "./components/ChatMessageItem";
import { InterviewSessionService } from "app/services/InterviewSessionService";
import { LeftExpandSVG } from "../svgs/LeftExpandSVG";
import { Roles } from "app/types";
import produce from "immer";
import { useAuth } from "app/context/auth-context";
import { parse } from "zipson/lib";
import { useAppContext } from "app/context/app-context";


/* Chat component*/

export const Chat = ({sendBy, interviewSessionId, particpantName, participantInitials}: {sendBy?: string, interviewSessionId?: string, particpantName?: string, participantInitials?: string}) => {
    const { user } = useAuth();  // Get User from Auth
    const {jobFair} = useAppContext();
    const [chatRecords, setChatRecords] = useState<ChatRecord[]>([]) // Chat Record is Chat Message that happens between zoom participants
    const chatWrapRef = useRef<HTMLDivElement | null>(null); // Html element reference
    const txtInputRef = useRef<HTMLInputElement | null>(null); // Html element reference
    const [chatDraft, setChatDraft] = useState<string>(''); // Send Chat Message Input Text Box Draft
    const [chatReceivers, setChatReceivers] = useState<ChatReceiver[]>([]); // Chat Receivers are participants on other side to receive chat messages
    const [chatUser, setChatUser] = useState<ChatReceiver | null>(null); // Chat User to whom we need to send chat message

    const [isHost, setIsHost] = useState<boolean>(false);

    const [selfId, setSelfId] = useState<number>(0); // Set Self Zoom Id, 0 Means not set

    //const zmClient = zoomContext?.zoomObj.client;

    const chatClient = useContext(ChatContext); 

    // This hook is triggered when the zoom client participants changes
    // useParticipantsChange(zmClient, () => {

    //     // Set Chat revievers again on participant list update
    //     if (chatClient) {
    //         setChatReceivers(chatClient.getReceivers());
    //     }
    //     if(zmClient)
    //         setIsHost(zmClient.isHost());
    // });

    // Send Chat Button Handler
    const sendChat = (e: any) => {
        if (chatUser && chatDraft) {
            chatClient?.send(chatDraft, chatUser?.userId);
            setChatDraft('');  // Make the draft message empty for fresh writeup
        }
    }

    // Handle "Enter" press on input text
    const sendChatOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && chatReceivers?.length>1)
            sendChat(e);
    }

    // Update Chat draft on every key press
    const onChatInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.trim().length > 0) {
                setChatDraft(event.target.value);
            } else {
                setChatDraft('');
            }
        },
        []
    );

    useEffect(() => {
        if(!jobFair?.id) return;
        const getChatTrans = async () => {
            if (interviewSessionId) {
                const interview = new InterviewSessionService();
                const _interviewSession = await interview.getById(jobFair.id, interviewSessionId);
                if (_interviewSession) {
                    if (_interviewSession.chatTranscript) {
                        setChatRecords(parse(_interviewSession.chatTranscript));
                    }
                }
            }
        }
        if (interviewSessionId !== undefined && interviewSessionId !== null) {
            getChatTrans();
        } else {
            console.log('No interview sesion id', interviewSessionId);
        }

    }, [interviewSessionId,jobFair?.id])

    useEffect(() => {
        if(!jobFair?.id) return;
        if (((interviewSessionId !== undefined && interviewSessionId !== null) || (chatRecords !== undefined && chatRecords !== null)) && chatUser && selfId && user) {
            const interview = new InterviewSessionService();
            if (user.roles?.includes(Roles.Recruiter) && interviewSessionId) {
                //interview.updateChat(jobFair.id, interviewSessionId, chatRecords, selfId, chatUser.userId);
            }
        } else {
            console.log('Update chat effect pre condition not met');
        }
    }, [jobFair?.id, chatRecords, interviewSessionId, chatUser, selfId, user])

    // When Chat Message is receievd from Zoom
    const onChatMessage = useCallback(
        (payload: ChatRecord) => {

            // Set Chat Records 
            setChatRecords(
                produce((records: ChatRecord[]) => {

                    const length = records.length;

                    if (length > 0) {
                        const lastRecord = records[length - 1];
                        if (
                            payload.sender.userId === lastRecord.sender.userId &&   // last message is of same user
                            payload.receiver.userId === lastRecord.receiver.userId && // last message is from same user
                            payload.timestamp - lastRecord.timestamp < 1000 * 60 * 5 // timestamp is within 5 mins
                        ) {
                            if (Array.isArray(lastRecord.message)) {
                                lastRecord.message.push(payload.message as string);
                            } else {
                                lastRecord.message = [lastRecord.message, payload.message as string];
                            }
                        } else {
                            records.push(payload);
                        }
                    } else {
                        records.push(payload);
                    }
                }),
            );
            if (chatWrapRef.current) {
                chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight); // Scroll to end on message received
            }
        },
        [chatWrapRef],
    );

    // Set Chat User on getting Chat receivers 
    useEffect(() => {
        if (chatUser) {
            const index = chatReceivers.findIndex(
                (user) => user.userId === chatUser.userId,
            );
            if (index === -1) {
                setChatUser(chatReceivers[0]);
            }
        } else {
            if (chatReceivers.length > 0) {
                setChatUser(chatReceivers[1]);
            }
        }
    }, [chatReceivers, chatUser]);

    // Set Chat Client and hook zoom 'chat-on-message' 
    // useEffect(() => {
    //     if (!user || !zmClient)
    //         return;

    //     zmClient.on('chat-on-message', onChatMessage);
    //     return () => {
    //         zmClient.off('chat-on-message', onChatMessage);  // unsunscibe from 'chat-on-message' event subscription
    //     };
    // }, [zmClient, onChatMessage]);

    // Set Self Id when ZoomState becomes Joined, else zmClient.getSessionInfo().userId will return NAN
    // useEffect(() => {
    //     if (!zmClient || !chatClient)
    //         return;

    //     if (zoomContext.zoomObj?.state === ZoomState.Joined)
    //         setSelfId(zmClient.getSessionInfo().userId);
    // }, [zmClient, chatClient, zoomContext?.zoomObj.state]);

    return (
        <div className="card" id="kt_chat_messenger">
            <div className="card-header" id="kt_chat_messenger_header">
                <div className="title">
                    <button data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark"
                        data-bs-placement="auto" title="Expand Chat" className="fullChat">
                        <LeftExpandSVG />
                    </button>
                    <h4>Chat</h4>
                </div>
            </div>
            <div className="card-body" id="kt_chat_messenger_body" ref={chatWrapRef}>
                {chatRecords.map((record) => (
                    <ChatMessageItem
                        sendBy={sendBy}
                        record={record}
                        currentUserId={selfId}
                        key={record.timestamp}
                        participantName={particpantName}
                        participantInitials={participantInitials}
                    />
                ))}
            </div>
            <div className="card-footer pt-4" id="kt_chat_messenger_footer">
                <input type="text" className="form-control form-control-flush mb-3" data-kt-element="input" placeholder="Type a message" onChange={onChatInput} value={chatDraft} onKeyUp={sendChatOnEnter} ref={txtInputRef} >
                </input>
                <div className="d-flex flex-stack">
                    <div className={"d-flex align-items-center me-2"}></div>
                    {chatClient && <button disabled={chatReceivers?.length<2} className="btn primary small" type="button" onClick={sendChat}>Send</button>}
                </div>
            </div>
        </div>
    );
}