/* eslint-disable no-unused-vars */
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/stylesheet.scss';

import { Chart, registerables } from 'chart.js';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import logger, { LoggerContainer, useLoggerApi } from 'logrock';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { App } from './app/App';
import store, { persistor } from './setup/redux/Store';

TimeAgo.addDefaultLocale(en);

// Redux
// https://github.com/rt2zz/redux-persist

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/* const mock = */
/*
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables);

ReactDOM.render(
  <LoggerContainer
    sessionID={sessionStorage.getItem('VJFPUserId') || undefined}
    limit={1000} // Stack length limit. On overflow, the first element will be removed
    getCurrentDate={() => {
      // Critical error date
      return moment()
        .format('YYYY-MM-DD HH:mm:ss');
    }}
    onError={stackData => {
      console.log(stackData);
    }}
   >
    <MetronicI18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <App basename={PUBLIC_URL} />
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
  </LoggerContainer>,
  document.getElementById('root')
);
