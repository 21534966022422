import { deleteFileFromS3, getFilesFromS3, uploadFileToS3 } from 'app/helpers/awsUtils';

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024; // 10 MB
const COMPANY_DOC_FOLDER = 'company_docs';
const RESUME_FOLDER = 'resumes';

export interface IUploadCompanyDocumentsInput {
  fileName?: string
  fileObj?: any
  isEdit?: boolean
  fileLink?: string
}

export class CompanyDocumentService {
  async getAllDocumentsByBooth (boothId: string) {
    try {
      const result = await getFilesFromS3(`${boothId}/${COMPANY_DOC_FOLDER}`);
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  async uploadDocumentsForBooth (boothId: string, companyDocuments: Array<IUploadCompanyDocumentsInput>, maxFileUploadSize: number = MAX_FILE_UPLOAD_SIZE) {
    try {
      return new Promise((resolve, reject) => {
        const uploadDocumentPromises = [];
        if (companyDocuments) {
          for (const doc of companyDocuments) {
            if (doc.fileName && doc.fileObj) {
              if (doc.fileObj.size <= maxFileUploadSize) {
                uploadDocumentPromises.push(uploadFileToS3(`${boothId}/${COMPANY_DOC_FOLDER}/${doc.fileName}`, doc.fileObj));
              } else {
                console.error(doc?.fileName + ' - is larger than 10MB. This file will not be uploaded.');
              }
            } else {
              console.error('file name and file object is required to upload the file');
            }
          }
        }

        return Promise.all(uploadDocumentPromises).then((result) => {
          resolve(result);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  async uploadLogoToBooth (companyDocuments: IUploadCompanyDocumentsInput,
    onUploadProgress: Function = () => { },
    maxFileUploadSize: number = MAX_FILE_UPLOAD_SIZE) {
    try {
      return new Promise((resolve, reject) => {
        const uploadDocumentPromises = [];
        if (companyDocuments && companyDocuments.fileName && companyDocuments.fileObj) {
          if (companyDocuments.fileObj.size <= maxFileUploadSize) {
            let fileName: Array<string> | string = companyDocuments.fileName.split('.');

            if (fileName.length > 0) {
              fileName = fileName.join('.');
            } else {
              console.error('File without extension found. File name - ', companyDocuments.fileName);
            }
            uploadDocumentPromises.push(
              uploadFileToS3(
                `employer_logos/${fileName}`,
                companyDocuments.fileObj,
                (loadedContent: any, totalContent: any) => {
                  onUploadProgress(loadedContent, totalContent);
                }));
          } else {
            console.error(companyDocuments?.fileName + ' - is larger than 10MB. This file will not be uploaded.');
          }
        } else {
          console.error('file name and file object is required to upload the file');
        }

        return Promise.all(uploadDocumentPromises).then((result) => {
          resolve(result);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  async uploadResumeToBooth (boothId: string | undefined,
    jobSeekerId: string | undefined,
    companyDocuments: IUploadCompanyDocumentsInput,
    onUploadProgress: Function = () => { },
    maxFileUploadSize: number = MAX_FILE_UPLOAD_SIZE) {
    try {
      return new Promise((resolve, reject) => {
        const uploadDocumentPromises = [];

        if (!boothId || !jobSeekerId) {
          console.error('boothId and jobSeekerId are required to upload the resume file');
          return;
        }

        if (companyDocuments && companyDocuments.fileName && companyDocuments.fileObj) {
          if (companyDocuments.fileObj.size <= maxFileUploadSize) {
            let fileName: Array<string> | string = companyDocuments.fileName.split('.');

            if (fileName.length > 0) {
              fileName[fileName.length - 2] += '-' + (new Date()).getTime();
              fileName = fileName.join('.');
            } else {
              console.error('File without extension found. File name - ', companyDocuments.fileName);
            }
            uploadDocumentPromises.push(
              uploadFileToS3(
                `${boothId}/${RESUME_FOLDER}/${jobSeekerId}/${fileName}`,
                companyDocuments.fileObj,
                (loadedContent: any, totalContent: any) => {
                  onUploadProgress(loadedContent, totalContent);
                }));
          } else {
            console.error(companyDocuments?.fileName + ' - is larger than 10MB. This file will not be uploaded.');
          }
        } else {
          console.error('file name and file object is required to upload the file');
        }

        return Promise.all(uploadDocumentPromises).then((result) => {
          resolve(result);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  async deleteDocuments (companyDocumentKeys: Array<string> | string) {
    try {
      return new Promise((resolve, reject) => {
        const deleteDocumentPromises = [];
        if (companyDocumentKeys) {
          if (!Array.isArray(companyDocumentKeys)) companyDocumentKeys = [companyDocumentKeys];
          for (const documentKey of companyDocumentKeys) {
            deleteDocumentPromises.push(deleteFileFromS3(documentKey));
          }
        }
        return Promise.all(deleteDocumentPromises).then((result) => {
          resolve(result);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }
}
