import { Alert, Button, Modal } from "react-bootstrap-v5"
import { CheckLg, XLg } from "react-bootstrap-icons"

import { KTSVG } from "_metronic/helpers"

type AlertModalProps = {
    show: boolean,
    title?: string,
    size?: "lg"|"sm"|"xl",
    children?: React.ReactNode
    onOK?: () => void,
    okText?: string
}

export const AlertModal = (props: AlertModalProps) => {
    return (<Modal size={props.size} show={props.show} onHide={props.onOK} centered>
        <Modal.Body>

            <Alert variant="primary" className="d-flex align-items-center p-5">
                <KTSVG path="/media/icons/duotone/Code/Info-circle.svg" className="svg-icon-primary svg-icon-2hx me-3" />
                <div className="d-flex flex-column">
                    {props.title && <h5 className="mb-1">{props.title}</h5>}
                    <span>{props.children}</span>
                </div>

            </Alert>
            <div className="d-flex justify-content-center">
                <Button variant="dark" onClick={props.onOK}> {props.okText || "OK"}</Button>
            </div>
        </Modal.Body>
    </Modal>)
}