import { DateUtils } from 'app/helpers/DateUtils';
import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse, QueueUserV1 } from 'app/types';
import axios from 'axios';
import { config } from 'config';
import moment from 'moment';

import { EndReason, InterviewSession, QueueUser, QueueUserStatus, VideoSessionStatus } from '../API';
import { InterviewSessionService } from './InterviewSessionService';

const EndPoints = {
  QueueUsersByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/byBoothId/:boothId`,
  QueueUsersByBoothIdAndVjfpUserId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/byBoothIdAndVjfpUserId/:boothId/:candidateId`,
  QueueUsersByVjfpUserId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/byVJFPUserId/:vjfpUserId`,
  QueueUsersByJobFairIdAndBoothIdAndId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/byBoothIdAndId/:boothId/:id`,
  QueueUsersProcess: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/process`,
  QueueUserStartInterview: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/startInterview`,
  QueueUserEndInterview: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/endInterview`,
  QueueUserUpdateJobSeekerJoined: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/queueUsers/updateJobSeekerJoined`
};

/*      -1 Priority means In-Interview
         0 Priority means Next-In-Queue
         1 Priority means Invited candidates
         2-7 Priority means Score based candidates
*/
export class QueueUserService {
  async processQueue (jobFairId: string) {
    const result = await axios.get<NgAPIResponse<boolean>>(
      UrlBuilder(EndPoints.QueueUsersProcess, { jobFairId }),
      await getCommonAxiosCommon()
    );

    return result.data.data;
  }

  async deleteCandidate (jobFairId: string, boothId: string, candidate: QueueUserV1) {
    try {
      const result = await axios.delete<NgAPIResponse<QueueUser>>(
        UrlBuilder(EndPoints.QueueUsersByJobFairIdAndBoothIdAndId, {
          jobFairId,
          boothId,
          id: candidate.id
        }),
        await getCommonAxiosCommon()
      );

      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getQueueUserByBoothIdAndJobSeekerId (
    jobFairId: string,
    boothId: string,
    candidateId: number
  ) {
    const result = await axios.get<NgAPIResponse<QueueUser[]>>(
      UrlBuilder(EndPoints.QueueUsersByBoothIdAndVjfpUserId, { jobFairId, boothId, candidateId }),
      await getCommonAxiosCommon()
    );

    if (result.data?.data?.length) {
      return result.data.data[0];
    }
  }

  async getQueueUsersByJobSeekerId (
    jobFairId: string,
    vjfpUserId: number,
    interviewingAndNextInInterviewOnly?: boolean
  ) {
    try {
      // const filter: ModelQueueUserFilterInput = {
      //   vjfpUserId: { eq: userId },
      //   jobFairId: { eq: jobFairId }
      // };
      let url = UrlBuilder(EndPoints.QueueUsersByVjfpUserId, { jobFairId, vjfpUserId });
      if (interviewingAndNextInInterviewOnly) {
        // filter.priority = {
        //   le: 0
        // };
        url += '?filter=NextInInterview';
      }

      // const result = (await API.graphql(graphqlOperation(listQueueUsers, {
      //   filter
      // }))) as GraphQLResult<ListQueueUsersQuery>;

      const result = await axios.get<NgAPIResponse<QueueUser[]>>(url, await getCommonAxiosCommon());

      return (result.data?.data || []).sort((a, b) => {
        if (a.priority !== b.priority) {
          return a.priority - b.priority;
        } else {
          // If priorities are equal, sort by createdAt
          return Number(a.createdAt) - Number(b.createdAt);
        }
      });
      // return await this.mergeVJFPUsers(jobFairId, result?.data?.listQueueUsers?.items) as (QueueUserV1 | undefined)[]; ;
    } catch (err) {
      console.log(err);
    }
  }

  async EnterQueue (
    jobFairId: string,
    boothId: string,
    jobseekerId: number,
    priority: number,
    score: number | null,
    maxScore: number,
    isInvited?: boolean
  ) {
    let isSuccess = false;
    try {
      const url = UrlBuilder(EndPoints.QueueUsersByJobFairIdAndBoothId, {
        jobFairId,
        boothId
      });
      const qu = {
        jobFairId,
        vjfpUserId: jobseekerId,
        boothId,
        priority: priority,
        score: score,
        status: QueueUserStatus.IN_QUEUE,
        maxScore,
        isInvited: isInvited || false
      };
      const result = await axios.post<NgAPIResponse<QueueUser>>(
        url,
        qu,
        await getCommonAxiosCommon()
      );

      if (!result.data.isSuccess) {
        console.error(result.data.errors);
      }
      isSuccess = result.data.isSuccess;
    } catch (err) {
      console.log(err);
    }

    return isSuccess;
  }

  async PullUser (jobFairId: string, boothId: string, userId: number) {
    const isSuccess = false;
    const queueUser = await this.getQueueUserByBoothIdAndJobSeekerId(jobFairId, boothId, userId);

    try {
      if (queueUser) {
        const url = UrlBuilder(EndPoints.QueueUsersByJobFairIdAndBoothIdAndId, {
          jobFairId,
          boothId,
          id: queueUser.id
        });

        const result = await axios.delete<NgAPIResponse<QueueUser>>(
          url,
          await getCommonAxiosCommon()
        );
        if (result.data.isSuccess) {
          return queueUser;
        }
      }
    } catch (err) {
      console.log(err);
    }

    return isSuccess;
  }

  async leaveQueueFromAllBooths (jobFairId: string, jobseekerId: number) {
    try {
      const queueUsers = await this.getQueueUsersByJobSeekerId(jobFairId, jobseekerId);
      if (queueUsers?.length) {
        const promises = queueUsers.map(async (qu) => {
          if (qu) {
            try {
              await this.PullUser(jobFairId, qu.boothId, jobseekerId);
            } catch (err) {
              console.log('leaveQueueFromAllBooths->', err);
            }
          }
        });
        await Promise.all(promises);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getAllSubscribedBoothsByJobSeeker (jobFairId: string, userId: number): Promise<string[]> {
    try {
      return (
        (await this.getQueueUsersByJobSeekerId(jobFairId, userId))?.map((x) => x?.boothId || '') ||
        []
      );
    } catch (err) {
      console.log(err);
    }

    return [];
  }

  async countQueueUsersByBoothId (
    jobFairId: string,
    boothId: string,
    isRefresh = false,
    vjfpUserId?: number
  ): Promise<number> {
    let url = `${EndPoints.QueueUsersByJobFairIdAndBoothId}/${isRefresh ? 'sync-count' : 'count'}`;
    if (vjfpUserId) {
      url += '?vjfpUserId=' + vjfpUserId;
    }
    const result = await axios.get<NgAPIResponse<number>>(
      UrlBuilder(url, { jobFairId, boothId: boothId }),
      await getCommonAxiosCommon()
    );
    if (result.data.isSuccess) {
      return result.data.data;
    } else {
      return 0;
    }
  }

  async getQueueUsersByBoothId (jobFairId: string, boothId: string) {
    try {
      const result = await axios.get<NgAPIResponse<QueueUser[]>>(
        UrlBuilder(EndPoints.QueueUsersByJobFairIdAndBoothId, { jobFairId, boothId }),
        await getCommonAxiosCommon()
      );
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async startInterview (jobFairId: string, boothId: string, recruiterId: number) {
    try {
      await axios.post<void>(
        UrlBuilder(EndPoints.QueueUserStartInterview, { jobFairId }),
        { jobFairId, boothId, recruiterId },
        await getCommonAxiosCommon()
      );
    } catch (err) {
      console.log(err);
    }
  }

  async endInterview (jobFairId: string, boothId: string, id: string, endedBy: number, endReason: EndReason) {
    const interviewSession = await new InterviewSessionService().getById(jobFairId, id);

    if (interviewSession && endedBy) {
      const key = `chat-${interviewSession.id}`;
      const chatText = localStorage.getItem(key);
      localStorage.removeItem(key);

      const result = await axios.post<NgAPIResponse<InterviewSession>>(
        UrlBuilder(EndPoints.QueueUserEndInterview, { jobFairId, boothId }),
        {
          jobFairId,
          queueUserId: interviewSession.queueUserId,
          interviewSessionId: id,
          status: VideoSessionStatus.ENDED,
          chatDuration: moment(DateUtils.getAWSCurrentDate()).diff(
            interviewSession.createdAt,
            'seconds'
          ),
          chatTranscript: chatText,
          endReason,
          endedBy: interviewSession.endedBy ? undefined : endedBy,
          recruiterId: interviewSession.recruiterId
        },
        await getCommonAxiosCommon()
      );

      if (result?.data?.isSuccess !== true || result?.data?.data?.status !== VideoSessionStatus.ENDING) {
        throw new Error('API /endInterview failed');
      }
      return result.data.data;
    }
  }

  // async endInterview (jobFairId: string, boothId: string, recruiterId: number) {
  //   try {
  //     const result = await axios.post<NgAPIResponse<QueueUser>>(
  //       UrlBuilder(EndPoints.QueueUserEndInterview, { jobFairId, boothId }),
  //       { jobFairId, boothId, recruiterId },
  //       await getCommonAxiosCommon()
  //     );
  //     return result.data?.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async updateJobSeekerJoined (jobFairId: string, vjfpUserId: number, queueUserId: string, recruiterId: number, deletePendingInterviewState:boolean = false) {
    try {
      const result = await axios.post<NgAPIResponse<QueueUser>>(
        UrlBuilder(EndPoints.QueueUserUpdateJobSeekerJoined, { jobFairId }),
        { jobFairId, vjfpUserId, queueUserId, recruiterId, deletePendingInterviewState },
        await getCommonAxiosCommon()
      );
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }
}
