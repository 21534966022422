
/*  -1 Priority means In-Interview
         0 Priority means Next-In-Queue
         1 Priority means Invited candidates
         2-7 Priority means Score based candidates
    */

export const getPriorityFromScore = (score: number, maxScore:number) => {
  if (score > maxScore) { throw new Error('SCORE CANNOT BE GREATER THAN MAX_SCORE:' + maxScore); }
  // if score is MAX_SCORE.. i.e 5 , priority should be 2 as priority of invited candidate is 1
  return (maxScore - score) + 2;
};
