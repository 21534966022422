import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { JobFair, JobFairStatus, NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';
import moment from 'moment';

import { TimeService } from './TimeService';

const timeService = new TimeService();

const EndPoints = {
  JobFairs: `${config.vjfpNGRestAPI}/organizations/:companyId/jobfairs`,
  JobFairById: `${config.vjfpNGRestAPI}/organizations/:companyId/jobfairs/:id`,
  CreatePaymentLink: `${config.vjfpNGRestAPI}/organizations/:companyId/jobfairs/:id/create-payment-link`
};

export class JobfairService {
  async createJobFair (companyId: string, jobFair: Omit<JobFair, 'id' | 'companyId' | 'createdAt' | 'paymentStatus' | 'status'>): Promise<JobFair> {
    const result = await axios.post<NgAPIResponse<JobFair>>(
      EndPoints.JobFairs,
      { ...jobFair, companyId },
      await getCommonAxiosCommon()
    );
    return result.data.data;
  }

  async getJobFairById (companyId: string, id: string): Promise<JobFair | undefined> {
    const result = await axios.get<NgAPIResponse<JobFair>>(
      UrlBuilder(EndPoints.JobFairById, { companyId, id }),
      await getCommonAxiosCommon()
    );
    return this.applyStatus(result.data.data);
  }

  async updateJobFair (jobFair: JobFair): Promise<JobFair> {
    const result = await axios.put<NgAPIResponse<JobFair>>(
      UrlBuilder(EndPoints.JobFairById, { companyId: jobFair.companyId, id: jobFair.id }),
      jobFair,
      await getCommonAxiosCommon()
    );
    return result.data.data;
  }

  async deleteJobFair (companyId: string, id: string): Promise<void> {
    await axios.delete(
      UrlBuilder(EndPoints.JobFairById, { companyId, id }),
      await getCommonAxiosCommon()
    );
  }

  async getJobFairs (companyId: string): Promise<JobFair[]> {
    const result = await axios.get<NgAPIResponse<JobFair[]>>(
      UrlBuilder(EndPoints.JobFairs, { companyId }),
      await getCommonAxiosCommon()
    );
    return result.data.data ? (Object.keys(result.data.data).length ? result.data.data : []) : [];
  }

  async createPaymentLink (companyId: string, id: string, redirectUrl?: string): Promise<string> {
    const response = await axios.post<NgAPIResponse<{url:string}>>(
      UrlBuilder(EndPoints.CreatePaymentLink, { companyId, id }),
      {
        redirectUrl
      },
      await getCommonAxiosCommon()
    );
    return response?.data?.data?.url;
  }

  async applyStatus (item: JobFair): Promise<JobFair|undefined> {
    if (!item) return item;
    const timeZone = item.timezone === 'EDT' ? '-0400' : '-0500';
    console.log(`timezone parsed  - ${timeZone} (${item.timezone})`);
    const startTime = moment(`${item?.startDate} ${item?.startTime} ${timeZone}`, 'yyyy-MM-DD h:mm:ss a ZZ');
    const endTime = startTime.clone().add(item.durationHours, 'h').add(item.durationMinutes, 'm');
    const extendedEndTime = endTime.clone().add(1.5, 'h');

    const localStorageDate = window.localStorage.getItem('currentDate');
    const localStorageTime = window.localStorage.getItem('currentTime');
    const localStorageDateTime = [localStorageDate, localStorageTime].filter(x => x).join(' ');
    const currentServerDateTime = moment.unix((await timeService.getServerTime()) || new Date().getTime());
    const currentDateTime = localStorageDateTime?.length ? moment(localStorageDateTime) : currentServerDateTime;

    let status = JobFairStatus.Ended;
    if (currentDateTime.isBetween(startTime, endTime)) {
      status = JobFairStatus.Started;
    } else if (currentDateTime.isBetween(endTime, extendedEndTime)) {
      status = JobFairStatus.ClosingSession;
    } else if (currentDateTime.isBefore(startTime)) {
      status = JobFairStatus.Upcoming;
    } else if (currentDateTime.isAfter(extendedEndTime)) {
      status = JobFairStatus.Ended;
    }

    return {
      ...item,
      status
    };
  }
}
