/* eslint-disable no-unused-vars */
/* eslint-disable typescript-enum/no-enum */
import { InterviewSession, Message, QueueUser, VJFPUser } from './API';
import { AppModel } from './types';

// All definations of App Model to have type safety in Pubsub
export interface IPresence extends VJFPUser, AppModel {}

export interface IQueueUser extends QueueUser, AppModel {}

export interface IMessage extends Message {}

export interface IInterviewSession extends InterviewSession, AppModel {}

export enum LocalAppEventType {
    General = 'General',
    AmplifySubscriptionError = 'AmplifySubscriptionError',
    LeaveEvent = 'LeaveEvent',
    AvailabilityUpdateEvent = 'AvailabilityUpdateEvent',
    InviteCountUpdateEvent = 'InviteCountUpdateEvent',
}

export interface ILocalAppEvent extends AppModel {
    message : string,
    type: LocalAppEventType,
    errorObject?: String
}
