import React, { lazy, useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { InterviewCallModal } from './modals/InterviewCallModal';
import { JSBoothDetail } from './components/JSBoothDetail'
import JSHistory from './components/JSHistory';
const JSInterview = lazy(() => import('./components/JSInterview'));
import JSInvitations from './components/JSInvitations';
import JSLobby from './components/JSLobby'
import { setupAmplifyMessageSubscription, setupInterviewSessionSubscriptionsByCandidateId, setupInviteSubscriptions } from 'app/amplify-subscriptions';
import { useAuth } from 'app/context/auth-context';
import { useAppContext } from 'app/context/app-context';
import { AppModal } from '../shared/modals/AppModal';
import { jobSeekerUrl } from 'app/types';
import { Notifications } from 'app/modules/shared';
import { UrlParamReplace } from 'app/helpers/MiscUtils';
import { VideoSessionStatus } from 'app/API';
import useIsMounted from 'app/hooks/useIsMounted';
import { HMSRoomProvider } from '@100mslive/react-sdk';
import { RxSubscription } from 'app/RxSubscriptions';

export const JSRoutes = {
  Lobby: `${jobSeekerUrl}/lobby`,
  BoothDetail: `${jobSeekerUrl}/lobby/booth/:boothId`,
  DeviceTest: `${jobSeekerUrl}/device-test`,
  Interview: `${jobSeekerUrl}/interview/:sessionId`,
  RateCompany: `${jobSeekerUrl}/interview/:sessionId/rate-company`,
  Invitations: `${jobSeekerUrl}/invitations`,
  History: `${jobSeekerUrl}/history`
}

const jobseekerBreadCrumbs = [{
  text: 'Lobby',
  url: UrlParamReplace(JSRoutes.Lobby, '', '')
}
];


interface JSPageHOCProps {
  children: React.ReactNode;
  withContinuePopup?: boolean;
}

const JSPageHOC = (hocProps: JSPageHOCProps) => {

  const { user } = useAuth();
  const { jobFair } = useAppContext();
  const history = useHistory();
  const location = useLocation()
  const [showContinuePopup, setShowContinuePopup] = useState(false);
  const store = useRef<{ interviewSessionId?: string }>({ interviewSessionId: undefined });

  const isMounted = useIsMounted('JSPage');

  useEffect(() => {
    if (!user?.id || !jobFair?.id)
      return;
    let timeOutRef: NodeJS.Timeout;
    const messageSubscriptions = setupAmplifyMessageSubscription(`${jobFair.id}_Applicant`);
    const inviteSubscriptions = setupInviteSubscriptions(user.id);
    const subscription = RxSubscription.onChangeInterviewSessionByCandidateIdObservable().subscribe(changeEvent => {
      if (!isMounted()) return;
      const _interviewSessionId = changeEvent?.value?.id;
      if (!_interviewSessionId)
        return;
      store.current.interviewSessionId = _interviewSessionId;
      let path = UrlParamReplace(JSRoutes.Interview, ':jobFairId', jobFair.id);
      path = UrlParamReplace(path, ':sessionId', _interviewSessionId);
      if (timeOutRef) clearTimeout(timeOutRef);
      timeOutRef = setTimeout(() => {
        const _newShowContinuePopup = changeEvent?.value?.status === VideoSessionStatus.IN_PROGRESS && location.pathname !== path;
        if (showContinuePopup !== _newShowContinuePopup)
          setShowContinuePopup(_newShowContinuePopup);
      }, 3000);
    });
    
    const interviewSessionSubscription = setupInterviewSessionSubscriptionsByCandidateId(user.id);

    return () => {
      subscription.unsubscribe();
      if (messageSubscriptions) messageSubscriptions.dispose();
      if (inviteSubscriptions) inviteSubscriptions.dispose();
      if (interviewSessionSubscription) interviewSessionSubscription.dispose();
      //if (createInterviewSessionSubscription) createInterviewSessionSubscription.unsubscribe();
      if (timeOutRef) clearTimeout(timeOutRef);
    }
  }, [user?.id, jobFair?.id, isMounted])

  const onContinueSessionButtonClick = () => {
    history.push(UrlParamReplace(JSRoutes.Interview + '?startVideo=true&startAudio=true', ':sessionId', store?.current?.interviewSessionId || ''))
    setShowContinuePopup(false);
  };

  return (<>
    <Notifications></Notifications>
    <InterviewCallModal />
    {hocProps.withContinuePopup !== false && <AppModal disableRejectBtn={true} variant="PlainText" successText="Continue session" rejectText="Cancel" show={showContinuePopup} title="Alert"
      onSuccess={onContinueSessionButtonClick}
      onReject={async () => { }}
    >
      You are still in an interview, please click "Continue session".
    </AppModal>}
    {hocProps.children}
  </>);
}

const JSPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path={JSRoutes.BoothDetail} render={() => <JSPageHOC><JSBoothDetail title="" breadcrumbs={[...jobseekerBreadCrumbs, { text: 'Booth Detail' }]} /></JSPageHOC>} />
        <Route path={JSRoutes.Lobby} render={() => <JSPageHOC><JSLobby title="Event Lobby" /></JSPageHOC>} />
        <Route path={JSRoutes.Interview} render={() =>
          <JSPageHOC withContinuePopup={false}>
            <HMSRoomProvider>
              <JSInterview title="Interview" breadcrumbs={[...jobseekerBreadCrumbs, { text: 'Interview' }]} />
            </HMSRoomProvider>
          </JSPageHOC>} />
        <Route path={JSRoutes.Invitations} render={() => <JSPageHOC><JSInvitations title="Invites" breadcrumbs={[...jobseekerBreadCrumbs, { text: 'Invitations' }]} /></JSPageHOC>} />
        <Route path={JSRoutes.History} render={() => <JSPageHOC><JSHistory title="History" breadcrumbs={[...jobseekerBreadCrumbs, { text: 'History' }]} /></JSPageHOC>} />
        <Redirect from={jobSeekerUrl} to={JSRoutes.Lobby} />
      </Switch>
    </>
  )
}

export default JSPage
