import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './GroupCard.module.scss';
import { VJFPUserService } from 'app/services/VJFPUserService';
import { uniqueId } from 'lodash';
import { Tab, Tabs } from 'react-bootstrap-v5';
import QueueQuestionFormComponent from '../QueueQuestions/QueueQuestions';
import { GroupType } from 'app/API';
import { BoothService } from 'app/services/BoothService';
import { nanoid } from 'nanoid'

interface RecruiterProps {
    name: string;
    vjfpUserId: number;
    index: number;
    moveRecruiter: (fromIndex: number | 'available', toIndex: number | 'available', recruiterIndex: number) => void;
    source: number | 'available';
    handleDelete?: () => void;
}

interface GroupProps {
    group: GroupType;
    index: number;
    recruiterNames: Map<number, string>;
    moveRecruiter: (fromIndex: number | 'available', toIndex: number | 'available', recruiterIndex: number) => void;
    handleEdit: () => void;
    handleDelete: () => void;
}

interface GroupModel {
    unassignedRecruiters: (number | null)[];
    groups: GroupType[];
}

interface GroupCardProps {
    unassignedRecruiters: (number | null)[];
    groups: GroupType[];
    onSave: (groups: GroupType[]) => void;
    parentBoothId?: string;
    parentBoothName : string;
}

const ItemTypes = {
    RECRUITER: 'recruiter',
};

const Recruiter: React.FC<RecruiterProps> = ({ name, index, moveRecruiter, source, handleDelete }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.RECRUITER,
        item: { name, index, source },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div ref={drag} className={styles.recruiter} style={{ opacity: isDragging ? 0.5 : 1 }}>
            {name}
            {handleDelete && (
                <Button variant="link" onClick={handleDelete} className="float-end"><i className="bi bi-trash"></i></Button>
            )}
        </div>
    );
};

const Group: React.FC<GroupProps> = ({ recruiterNames, group, index, moveRecruiter, handleEdit, handleDelete }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.RECRUITER,
        drop: (item: any) => moveRecruiter(item.source, index, item.index),
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <Card ref={drop} className={styles.groupCard + " bg-light text-dark"} style={{ opacity: isOver ? 0.7 : 1 }}>
            <Card.Header style={{ alignItems: 'center', padding: '0 1em' }}>
                <span> {group.name}</span>
                <span>
                    <Button variant="link" onClick={handleDelete} className="float-end"><i className="bi bi-trash"></i></Button>
                    <Button variant="link" onClick={handleEdit} className="float-end"><i className="bi bi-pencil"></i></Button>
                </span>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                <div>Recruiters</div>
                <div style={{ border: '1px dashed grey', margin: '5px', minHeight: '100px' }}>
                    {group.recruiters?.length === 0 ?
                        <div className='h-100 d-flex align-items-center justify-content-center' title='Drag & drop from Unassigned Recruiters'>
                            <p className='text-center text-muted'>No recruiters assigned. </p></div> :
                        <>{group.recruiters?.map((recruiter, idx) => (
                            <Recruiter
                                key={idx}
                                name={recruiterNames.get(recruiter!) || ''}
                                index={idx}
                                vjfpUserId={recruiter!}
                                moveRecruiter={moveRecruiter}
                                source={index}
                                handleDelete={() => {
                                    if (window.confirm('Are you sure you want to delete this recruiter?')) {
                                        moveRecruiter(index, 'available', idx);
                                    }
                                }}
                            />
                        ))}
                        </>}
                </div>
            </Card.Body>
        </Card>
    );
};

const UnassignedRecruiters: React.FC<{ recruiterNames: Map<number, string>, unassignedRecruiters: (number | null)[], moveRecruiter: (fromIndex: number | 'available', toIndex: number | 'available', recruiterIndex: number) => void }> = ({ recruiterNames, unassignedRecruiters, moveRecruiter }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.RECRUITER,
        drop: (item: any) => moveRecruiter(item.source, 'available', item.index),
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} className={styles.groupCard + " m-5 p-5 bg-light"} style={{ opacity: isOver ? 0.7 : 1 }}>
            <label className='mb-5'>Unassigned recruiters</label>
            {unassignedRecruiters.map((recruiter, idx) => (
                <Recruiter
                    key={idx}
                    vjfpUserId={recruiter!}
                    name={recruiterNames.get(recruiter!) || ''}
                    index={idx}
                    moveRecruiter={moveRecruiter}
                    source="available"
                />
            ))}
        </div>
    );
};

const GroupCard: React.FC<GroupCardProps> = (props: GroupCardProps) => {
    const [groupModel, setGroupModel] = useState<GroupModel>({ groups: props.groups, unassignedRecruiters: props.unassignedRecruiters });
    const [show, setShow] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupDescription, setNewGroupDescription] = useState('');
    const [newHotJobSummary, setNewHotJobSummary] = useState('');
    const [newChatDuration, setNewChatDuration] = useState(600000);
    const [newMinimumScore, setNewMinimumScore] = useState(0);
    const [recruitersNames, setRecruiterNames] = useState(new Map<number, string>());

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleEditShow = (index: number) => {
        const group = groupModel.groups[index];
        setNewGroupName(group.name);
        setNewGroupDescription(group.description ?? '');
        setNewHotJobSummary(group.hotJobSummary ?? '');
        setNewChatDuration(group.chatDuration);
        setNewMinimumScore(group.minimumScore);
        setEditIndex(index);
        handleShow();
    };

    const addGroup = () => {
        if (editIndex !== null) {
            const updatedGroups = [...groupModel.groups];
            updatedGroups[editIndex] = {
                ...updatedGroups[editIndex],
                name: newGroupName,
                description: newGroupDescription,
                hotJobSummary: newHotJobSummary,
                chatDuration: newChatDuration,
                minimumScore: newMinimumScore,
            };
            setGroupModel({ ...groupModel, groups: updatedGroups });
        } else {
            const newGroup: GroupType = {
                id: nanoid(),
                name: newGroupName,
                chatDuration: newChatDuration,
                minimumScore: newMinimumScore,
                description: newGroupDescription,
                hotJobSummary: newHotJobSummary,
                recruiters: [],
                sortOrder: null,
            };
            setGroupModel({ ...groupModel, groups: [...groupModel.groups, newGroup] });
        }
        resetForm();
        handleClose();
    };

    const resetForm = () => {
        setNewGroupName('');
        setNewGroupDescription('');
        setNewHotJobSummary('');
        setNewChatDuration(600000);
        setNewMinimumScore(0);
        setEditIndex(null);
    };

    const deleteGroup = (index: number) => {
        if (window.confirm('Are you sure you want to delete this group?')) {
            const newGroups = [...groupModel.groups];
            const [removedGroup] = newGroups.splice(index, 1);
            setGroupModel({
                unassignedRecruiters: [...groupModel.unassignedRecruiters, ...removedGroup.recruiters!],
                groups: newGroups
            });
        }
    };

    const moveRecruiter = (fromIndex: number | 'available', toIndex: number | 'available', recruiterIndex: number) => {
        const newGroups = [...groupModel.groups];
        let recruiter: number;

        if (fromIndex === 'available') {
            recruiter = groupModel.unassignedRecruiters.splice(recruiterIndex, 1)[0]!;
        } else {
            recruiter = newGroups[fromIndex].recruiters?.splice(recruiterIndex, 1)[0]!;
        }

        if (toIndex === 'available') {
            groupModel.unassignedRecruiters.push(recruiter);
        } else {
            newGroups[toIndex].recruiters?.push(recruiter);
        }

        setGroupModel({ ...groupModel, groups: newGroups });
    };

    const handleSave = () => {
        props.onSave(groupModel.groups);
    };

    useEffect(() => {
        const map = new Map<number, string>();
        const vjfpUserService = new VJFPUserService();
        const allRecruiters = [...props.unassignedRecruiters.map(x => x!), ...props.groups.flatMap(x => x.recruiters!)];
        vjfpUserService.getByIds(allRecruiters.map(x => x!)).then(users => {
            if (!users) return;
            users.forEach(x => {
                map.set(x.id, x.firstName + ' ' + x.lastName);
            });
            setRecruiterNames(map);
        });

        setGroupModel(model => {
            const assignedRecruiters = new Set();
            model.groups.forEach(group => {
                group.recruiters!.forEach(recruiter => assignedRecruiters.add(recruiter));
            });
            return { ...model, unassignedRecruiters: model.unassignedRecruiters.filter(r => !assignedRecruiters.has(r)) };
        });

    }, [props.unassignedRecruiters, props.groups])

    return (
        <DndProvider backend={HTML5Backend}>
            <Card>
                <Card.Body>
                    <div className={styles.groupContainer}>
                        <UnassignedRecruiters recruiterNames={recruitersNames} unassignedRecruiters={groupModel.unassignedRecruiters} moveRecruiter={moveRecruiter} />
                        {groupModel.groups.length == 0 ?
                            <div className={styles.dottedBox}>
                                <Card.Text className="text-muted">
                                    <div className="mb-5 d-flex justify-content-center">No Group added yet!</div>

                                    Use “Groups” to align your recruiters with their respective part of the organization. </Card.Text>
                                <div className="mt-5 d-flex justify-content-center">
                                    <Button onClick={handleShow}>Add Group</Button>
                                </div>
                            </div> :
                            <>
                                <div className={styles.groupsList}>
                                    {groupModel.groups.map((group, index) => (
                                        <Group
                                            key={index}
                                            recruiterNames={recruitersNames}
                                            group={group}
                                            index={index}
                                            moveRecruiter={moveRecruiter}
                                            handleEdit={() => handleEditShow(index)}
                                            handleDelete={() => deleteGroup(index)}
                                        />
                                    ))}
                                    <div className={styles.groupCard + " m-5 p-5 " + styles.dottedBox}>
                                        <Card.Text className="text-muted"></Card.Text>
                                        <div className="mt-5 d-flex justify-content-center align-items-center h-75">
                                            <Button variant='secondary' onClick={() => { resetForm(); handleShow(); }}> + Add Group</Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button onClick={handleSave} className="mt-3">Save</Button>
                    </div>
                </Card.Body>
            </Card>

            <Modal show={show} size='lg' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex !== null ? 'Edit Group' : 'Add Group'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="generalInfo">
                        <Tab id="tabs" eventKey="generalInfo" title="General Information">
                            <div className='pb-10 w-100 px-9'>
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <Form>
                                            <Form.Group controlId="formGroupName">
                                                <Form.Label>Group Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter group name"
                                                    value={newGroupName}
                                                    onChange={(e) => setNewGroupName(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formGroupDescription">
                                                <Form.Label>Group Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Enter group description"
                                                    value={newGroupDescription}
                                                    onChange={(e) => setNewGroupDescription(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formHotJobSummary">
                                                <Form.Label>Hot Job Summary</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Enter hot job summary"
                                                    value={newHotJobSummary}
                                                    onChange={(e) => setNewHotJobSummary(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formChatDuration">
                                                <Form.Label>Chat Duration (minutes)</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter chat duration"
                                                    value={newChatDuration / 60000}
                                                    onChange={(e) => setNewChatDuration(Number(e.target.value) * 60000)}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Tab>
                        <Tab id="tabs" eventKey="queueQuestion" disabled={editIndex == null} title="Queue Questions">
                            <div className='pb-10 w-100 px-9'>
                                <Card style={{ width: '100%' }}>
                                    <Card.Body>
                                        <label className='form-label fs-6 fw-bolder text-dark'>Queue Questions</label>
                                        <p>Enter or edit the queue questions below that job seekers will be asked when they enter your group queue.</p>
                                        {editIndex !== null && <QueueQuestionFormComponent booth={ {... groupModel.groups[editIndex], name: `${props.parentBoothName} /,l
                                         km- ${groupModel.groups[editIndex].name}`} } saveMinScore={(score) => {
                                            setNewMinimumScore(score);
                                        }}></QueueQuestionFormComponent>}
                                    </Card.Body>
                                </Card>
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={!newGroupName} onClick={addGroup}>
                        {editIndex !== null ? 'Update Group' : 'Add Group'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </DndProvider>
    );
};

export default GroupCard;
