import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import { InterviewSession, Message, QueueUser, VJFPUser } from './API';
import { CGOInviteAppModel, ChangeEvent } from './types';

type BackendSubscriptionCommand = {
  command: 'subscribe' | 'unsubscribe'
  subscriptionName: string
  props: any
}

class RxSubscriptionCls {
  onUpdateVJFPUserByIdSubject: Subject<VJFPUser>;

  onChangeQueueUserByBoothIdSubject: BehaviorSubject<ChangeEvent<QueueUser>|null>;

  onChangeQueueUserByCandidateIdSubject: Subject<ChangeEvent<QueueUser>>;

  onChangeInterviewSessionByCandidateIdSubject: BehaviorSubject<ChangeEvent<InterviewSession>|null>;

  onChangeInterviewSessionByRecruiterIdSubject: BehaviorSubject<ChangeEvent<InterviewSession>|null>;

  onChangeInviteByCandidateIdSubject: Subject<CGOInviteAppModel>;

  onCreateMessageByTopicIdSubject : BehaviorSubject<Message|null>;

  BackendSubscriptionSubject: ReplaySubject<BackendSubscriptionCommand | undefined>;
  constructor () {
    this.BackendSubscriptionSubject = new ReplaySubject<BackendSubscriptionCommand | undefined>();

    this.onUpdateVJFPUserByIdSubject = new Subject<VJFPUser>();

    this.onChangeQueueUserByBoothIdSubject = new BehaviorSubject<ChangeEvent<QueueUser>|null>(null);
    this.onChangeQueueUserByCandidateIdSubject = new Subject<ChangeEvent<QueueUser>>();
    this.onChangeInterviewSessionByCandidateIdSubject = new BehaviorSubject<ChangeEvent<InterviewSession>|null>(null);
    this.onChangeInterviewSessionByRecruiterIdSubject = new BehaviorSubject<ChangeEvent<InterviewSession>|null>(null);
    this.onChangeInviteByCandidateIdSubject = new Subject<CGOInviteAppModel>();
    this.onCreateMessageByTopicIdSubject = new BehaviorSubject<Message| null>(null);
  }

  getBackendSubscriptionObservable () { return this.BackendSubscriptionSubject.asObservable(); }

  subscribe (name: string, props: any) {
    this.BackendSubscriptionSubject.next({ command: 'subscribe', subscriptionName: name, props });
    console.log('added subscription', props);
  }

  unsubscribe (name: string, props: any) {
    this.BackendSubscriptionSubject.next({ command: 'unsubscribe', subscriptionName: name, props });
    console.log('removed subscription', props);
  }

  notify (subscriptionName: string, body: any) {
    console.log('received subscription value', subscriptionName, body);
    switch (subscriptionName) {
      case 'onUpdateVJFPUserById':
        this.onUpdateVJFPUserByIdSubject.next(body);
        break;
      case 'onChangeQueueUserByCandidateId':
        this.onChangeQueueUserByCandidateIdSubject.next(body);
        break;
      case 'onChangeQueueUserByBoothId':
        this.onChangeQueueUserByBoothIdSubject.next(body);
        break;
      case 'onChangeInterviewSessionByCandidateId':
        this.onChangeInterviewSessionByCandidateIdSubject.next(body);
        break;
      case 'onChangeInterviewSessionByRecruiterId':
        this.onChangeInterviewSessionByRecruiterIdSubject.next(body);
        break;
      case 'onChangeInviteByCandidateId':
        this.onChangeInviteByCandidateIdSubject.next(body);
        break;
      case 'onCreateMessageByTopicId':
        this.onCreateMessageByTopicIdSubject.next(body);
        break;
    }
  }

  onUpdateVJFPUserByIdObservable () { return this.onUpdateVJFPUserByIdSubject.asObservable(); }

  onChangeQueueUserByBoothIdObservable () { return this.onChangeQueueUserByBoothIdSubject.asObservable(); }

  onChangeQueueUserByCandidateIdObservable () { return this.onChangeQueueUserByCandidateIdSubject.asObservable(); }

  onChangeInterviewSessionByCandidateIdObservable () { return this.onChangeInterviewSessionByCandidateIdSubject.asObservable(); }

  onChangeInterviewSessionByRecruiterIdObservable () { return this.onChangeInterviewSessionByRecruiterIdSubject.asObservable(); }

  onChangeInviteByCandidateIdObservable () { return this.onChangeInviteByCandidateIdSubject.asObservable(); }

  onCreateMessageByTopicIdObservable () { return this.onCreateMessageByTopicIdSubject.asObservable(); }

  clearInterviewSessionByCandidateId () {
    this.onChangeInterviewSessionByCandidateIdSubject.next(null);
  }

  clearInterviewSessionByRecruiterId () {
    this.onChangeInterviewSessionByRecruiterIdSubject.next(null);
  }

  clearOnChangeQueueUserByBoothIdObservable () {
    this.onChangeQueueUserByBoothIdSubject.next(null);
  }
}

export const RxSubscription = new RxSubscriptionCls();
