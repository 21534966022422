/* eslint-disable no-unused-vars */
/* eslint-disable typescript-enum/no-enum */

export class QueuePolicyError extends Error {
    code: string = '';
    constructor (code: string, message: string) {
      super(message);
      this.code = code;
    }
}

export enum ErrorCodes {
    THRESHOLD_ERROR = 'THRESHOLD_ERROR'
}

export class QueuePolicy {
    threshold: number;
    constructor (threshold: number) {
      this.threshold = threshold;
    }

    // Invited user will have score 6 and will be placed on top of queue
    validate (score: number) {
      if (score < this.threshold) {
        throw new QueuePolicyError(ErrorCodes.THRESHOLD_ERROR, 'This company’s job opportunities do not appear to be a match with your skills.');
      }
    }
}
