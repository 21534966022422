import { Badge, Card, Col, Image, Row } from "react-bootstrap";
import React, { ReactElement } from "react";

import { KTSVG } from "_metronic/helpers";
import { Roles } from "app/types";
import styles from './BoothCard.module.scss';
import { useAuth } from "app/context/auth-context";
import { BookmarkCheckFill } from "react-bootstrap-icons";

interface BoothProps {
    children?: Array<ReactElement>;
    isFeatured: boolean
    logo?: string;
}

interface BoothCardDescriptionProps {
    children?: string;
}
interface BoothCardTitleProps {
    children?: string;
    isInQueue?: boolean;
}
interface BoothCardBodyProps {
    children?: React.ReactNode;
    jobsCount?: number;
    waitingToChatCount?: number;
    recruitersCount?: number;
    loggedInRecruitersCount?: number;
    
    currentQueueCount?: string;
}
const BoothCard = (props: BoothProps) => {
    return (
        <Card className="border border-2 border-gray-300 border-hover">
            <Card.Header className="border-0 pt-9">
                <Card.Title className="m-0">
                    <Image width={100} crossOrigin="anonymous" src={props.logo} />
                </Card.Title>
                <div className="card-toolbar">
                    {props.isFeatured && <span className="fw-bolder badge px-4 py-3 featuredBtn">Featured</span>}
                </div>

            </Card.Header>
            <Card.Body className="p-9">
                {props.children}
            </Card.Body>
        </Card>
    );
}

const BoothCardTitle = (props: BoothCardTitleProps) => {
    return (
        <>
            <div className="fs-3 fw-bolder text-dark">
                {props.children}
            </div>
            {props.isInQueue && <div><span className="fw-bolder text-success"><BookmarkCheckFill className="m-1" /> In Queue </span></div>}
        </>
    )
}
const BoothCardDescription = (props: BoothCardDescriptionProps) => {
    return (
        <div className={styles.description}>{props.children}</div>
    )
}

const BoothCardBody = (props: BoothCardBodyProps) => {
    const { user } = useAuth();
    const stats = [

        {
            isVisible: user?.roles?.includes(Roles.Recruiter),
            icon: '/media/icons/duotone/General/Timer.svg',
            value: (props.waitingToChatCount || 0).toString(),
            text: 'Waiting To Chat'
        }

    ];
    if (user?.roles?.includes(Roles.Admin)) {
        stats.push(
            {
                isVisible: props.recruitersCount !== undefined,
                icon: '/media/icons/duotone/Communication/Group.svg',
                value: `${(props.loggedInRecruitersCount || 0)} / ${props.recruitersCount || 0}`,
                text: 'Recruiters'
            },
            {
                isVisible: props.currentQueueCount !== undefined,
                icon: '/media/icons/duotone/Communication/Group.svg',
                value: `${(props.currentQueueCount)}`,
                text: '# in queue'
            });
    }
    else {
        stats.push(
            {
                isVisible: props.recruitersCount !== undefined,
                icon: '/media/icons/duotone/Communication/Group.svg',
                value: `${(props.recruitersCount || 0)}`,
                text: 'Recruiters'
            })
    }
    return (
        <div>
            <Row className="g-0">
                {stats.filter(x => x.isVisible).map((stat, index) =>
                    <Col key={index} >
                        <div className="d-flex align-items-center me-2">
                            <div className="symbol symbol-50px me-3">
                                <div className="symbol-label bg-light-info">
                                    <KTSVG
                                        path={stat.icon}
                                        className="svg-icon svg-icon-1 svg-icon-primary"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="fs-4 text-dark fw-bolder">
                                    {stat.value}
                                </div>
                                <div className="fs-7 text-muted fw-bold">
                                    {stat.text}
                                </div>
                            </div>
                        </div>
                    </Col>)}
            </Row>
            <Row>
                <Col> {props.children}</Col>
            </Row>
        </div>
    )
}

export default Object.assign(
    BoothCard,
    {
        Title: BoothCardTitle,
        Description: BoothCardDescription,
        Body: BoothCardBody
    }
)