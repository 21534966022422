import './video-footer.scss';

import {
  MutableRefObject,
} from 'react';

import CameraButton from './camera';
import { EndChatButton } from '../../custom-buttons/EndChatButton';
import MicrophoneButton from './microphone';
import classNames from 'classnames';
import { Roles } from 'app/types';
import { useAuth } from 'app/context/auth-context';
import { useAVToggle } from '@100mslive/react-sdk';

interface VideoFooterProps {
  className?: string;
  shareRef?: MutableRefObject<HTMLCanvasElement | null>;
  sharing?: boolean;
  showEndChatModal: () => void;
}


const VideoFooter = (props: VideoFooterProps) => {

  const {
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    toggleAudio,
    toggleVideo
  } = useAVToggle();

  const { user } = useAuth();

  const { className } = props;


  return (
    <div className={classNames('controls', className)}>

      <MicrophoneButton className="audio"
        isMuted={!isLocalAudioEnabled}
        onMicrophoneClick={() => { if (toggleAudio) { toggleAudio() } }}
      />

      {user?.roles?.includes(Roles.Recruiter) && <EndChatButton showEndChatModal={props.showEndChatModal} />}
      <CameraButton className="camera"
        isStartedVideo={isLocalVideoEnabled}
        onCameraClick={() => { if (toggleVideo) toggleVideo() }}
        onSwitchCamera={() => { }}
        cameraList={[]}
        activeCamera={undefined} />
    </div>

  );
};
export default VideoFooter;
