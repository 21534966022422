/* eslint-disable no-nested-ternary */

import './microphone.scss';

import { Button } from 'react-bootstrap';
import {
  Mic,
  MicMute
} from 'react-bootstrap-icons';
import classNames from 'classnames';

interface MicrophoneButtonProps {
  isMuted: boolean;
  onMicrophoneClick: () => void;
  className?: string;

}
const MicrophoneButton = (props: MicrophoneButtonProps) => {
  const {
    isMuted,
    className,
    onMicrophoneClick,
  } = props;

  return (
    <div className={classNames('', className)}>
      <Button variant={isMuted ? 'danger' : 'success'} onClick={onMicrophoneClick}>  {isMuted ? <><MicMute /> <small>off</small></> : <><Mic />  <small>on</small></>}</Button>
    </div >
  );
};

export default MicrophoneButton;
