import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { CGOUser, NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';

import { UpdateVJFPUserInput, UserStatus, VJFPUser } from '../API';
import { VJFPCache } from './CacheUtils';

const EndPoint = {
  vjfpUsers: `${config.vjfpNGRestAPI}/vjfpUsers/`,
  vjfpUserById: `${config.vjfpNGRestAPI}/vjfpUsers/:id`,
  vjfpUserByEmailId: `${config.vjfpNGRestAPI}/vjfpUsers/byEmailId/:emailId`,
  vjfpUserByMultipleIds: `${config.vjfpNGRestAPI}/vjfpUsers?userIds=`
};
type SignupUser = {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  roles?: string[]
  presenceStatus?: string
}

export class VJFPUserService {
  async createUser (user: SignupUser) {
    await axios.post(EndPoint.vjfpUsers, user, await getCommonAxiosCommon());
  }

  async getById (userId: number): Promise<VJFPUser | undefined> {
    const lastCachedUserInfo: VJFPUser = VJFPCache.getItem('vjfpUser-' + userId) as VJFPUser;
    if (lastCachedUserInfo?.presenceStatus) {
      return Promise.resolve(lastCachedUserInfo);
    }

    try {
      const result = await axios.get<NgAPIResponse<VJFPUser>>(
        UrlBuilder(EndPoint.vjfpUserById, { id: userId.toString() }),
        await getCommonAxiosCommon()
      );
      if (
        result?.data.data?.presenceStatus &&
        (lastCachedUserInfo?.presenceStatus !== result?.data.data?.presenceStatus ||
          lastCachedUserInfo.isAvailableForChat !== result?.data.data.isAvailableForChat)
      ) {
        VJFPCache.setItem('vjfpUser-' + userId, result?.data);
      }
      if (!result.data?.isSuccess) {
        console.log(result.data?.errors);
      }
      return result.data?.data as VJFPUser;
    } catch (err) {
      console.log(err);
    }
  }

  async getByEmail (emailId: string): Promise<VJFPUser | undefined> {
    try {
      const result = await axios.get<NgAPIResponse<VJFPUser>>(
        UrlBuilder(EndPoint.vjfpUserByEmailId, { emailId }),
        await getCommonAxiosCommon()
      );
      if (result?.data.data?.presenceStatus) {
        VJFPCache.setItem('vjfpUser-' + result?.data.data?.id, result?.data);
      }
      if (!result.data?.isSuccess) {
        console.log(result.data?.errors);
      }
      return result.data?.data as VJFPUser;
    } catch (err) {
      console.log(err);
    }
  }

  async getByIds (userIds: number[]): Promise<VJFPUser[] | undefined> {
    if (userIds?.length === 1) {
      const vjfpUser = await this.getById(userIds[0]);
      if (vjfpUser) return [vjfpUser];
      return [];
    }
    try {
      const lastCachedMap = new Map<number, VJFPUser>();
      userIds.forEach((u) => {
        const lastCachedUserInfo = VJFPCache.getItem('vjfpUser-' + u);
        if (lastCachedUserInfo?.presenceStatus) {
          lastCachedMap.set(u, lastCachedUserInfo as VJFPUser);
        }
      });
      const _userIdsNotInCache = userIds.filter((x) => !lastCachedMap.has(x));
      if (_userIdsNotInCache.length === 0) {
        return Array.from(lastCachedMap, ([key, value]) => value);
      }
      const notCachedUsersResult = await axios.get<NgAPIResponse<VJFPUser[]>>(
        `${EndPoint.vjfpUsers}?ids=${_userIdsNotInCache.join(',')}`,
        await getCommonAxiosCommon()
      );
      notCachedUsersResult.data.data.forEach((_vjfpUser) => {
        VJFPCache.setItem('vjfpUser-' + _vjfpUser.id, _vjfpUser);
      });

      if (!notCachedUsersResult.data?.isSuccess) {
        console.log(notCachedUsersResult.data?.errors);
      }
      const cachedUsers = Array.from(lastCachedMap, ([key, value]) => value);
      return [...notCachedUsersResult.data.data, ...cachedUsers];
    } catch (err) {
      console.log(err);
    }
  }

  async getUsersByJobFairId (jobFairId: string): Promise<CGOUser[]> {
    try {
      const offset = window.localStorage.sync_offset || 0;
      const limit = window.localStorage.sync_limit || 999999;
      const result = await axios.get<CGOUser[]>(
        `${config.cgoBaseUrl}/jobfairs/${jobFairId}/registered_users?limit=${limit}&offset=${offset}`
      );

      return result?.data;
    } catch (err) {
      console.log(err);
    }
    return Promise.resolve([]);
  }

  async updateAvailability (jobFairId: string, id: number, isAvailableForChat: boolean) {
    try {
      const axiosConfig = await getCommonAxiosCommon();
      const result = await axios.put<NgAPIResponse<VJFPUser>>(
        `${config.vjfpNGRestAPI}/vjfpUsers/${id}`,
        {
          id,
          isAvailableForChat,
          presenceStatus: UserStatus.LOGGED_IN,
          lastPresenceJobFairId: jobFairId
        },
        axiosConfig
      );

      if (!result.data?.isSuccess) {
        console.log(result.data?.errors);
      }

      return result?.data.data;
    } catch (err) {
      console.log(err);
    } finally {
      VJFPCache.removeItem('vjfpUser-' + id);
    }
  }

  async updateStatus (jobFairId: string, id: number, presenceStatus: UserStatus) {
    try {
      const input: UpdateVJFPUserInput = {
        id,
        presenceStatus: presenceStatus,
        lastPresenceJobFairId: jobFairId
      };

      if (input.presenceStatus === UserStatus.LOGGED_OUT) {
        input.isAvailableForChat = true;
      }

      const axiosConfig = await getCommonAxiosCommon();
      const result = await axios.put<NgAPIResponse<VJFPUser>>(
        `${config.vjfpNGRestAPI}/vjfpUsers/${id}`,
        input,
        axiosConfig
      );
      if (!result.data?.isSuccess) {
        console.log(result.data?.errors);
      }
      return result?.data?.data;
    } catch (err) {
      console.log(err);
    } finally {
      VJFPCache.removeItem('vjfpUser-' + id);
    }
  }
}
