import { Col, Image, Row } from "react-bootstrap-v5";
import { InterviewSession, ReviewUser, VJFPUser, Booth } from "app/API";
import { getInitials, getStyleByIndex, UrlParamReplace } from "app/helpers/MiscUtils";
import { useEffect, useState } from "react";
import { BoothService } from "app/services/BoothService";
import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BasePageProps } from "app/types";
import ChatMessageItem from "app/modules/shared/chat/components/ChatMessageItem";
import { ChatRecord } from "app/modules/shared/chat/chat-types";
import { InterviewSessionService } from "app/services/InterviewSessionService";
import { JSRoutes } from "../JSPage";
import { Mailbox2 } from "react-bootstrap-icons";
import { ReviewUserService } from "app/services/ReviewUserService";
import { VJFPUserService } from "app/services/VJFPUserService";
import { parse } from "zipson/lib";
import { useAuth } from "app/context/auth-context";
import { useHistory } from "react-router";
import { useAppContext } from "app/context/app-context";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from "moment";
import { HMSMessage } from "@100mslive/react-sdk";
import { ChatMessage } from "app/modules/shared/100ms-chat/chat-message";
interface InterviewSessionsItem extends InterviewSession {
    recruiter?: VJFPUser;
    reviewUser?: ReviewUser;
    boothsInfo?: Booth;
}

const interviewSessionService = new InterviewSessionService();

const JSHistory = (props: BasePageProps) => {

    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const reviewUserService = new ReviewUserService();
    const vjfpUserService = new VJFPUserService();
    const [interviewSessions, setInterviewSessions] = useState<InterviewSessionsItem[]>([]);
    const [chatRecords, setChatRecords] = useState<HMSMessage[]>([]);
    const [activeInterviewSession, setActiveInterviewSession] = useState<InterviewSession | undefined>(undefined)
    const [chatRecruiter, setChatRecruiter] = useState<VJFPUser | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const boothService = new BoothService();

    useEffect(() => {

        if (user?.username && jobFair?.id) {
            setLoading(true);
            const run = async () => {
                const _interviewSessions = await interviewSessionService.getInterviewsByJobseeker(jobFair.id, user.id) as InterviewSessionsItem[];
                if (_interviewSessions) {
                    _interviewSessions.sort((a, b) => {
                        return moment(b?.createdAt).diff(a?.createdAt, 's');
                    });
                    for (let item of _interviewSessions) {
                        if (item?.candidateId)
                            item.recruiter = await vjfpUserService.getById(item?.recruiterId);

                        const _booth = await boothService.getBoothById(jobFair.id, item.boothId);
                        if (_booth && _booth != null) {
                            item.boothsInfo = _booth;
                        }
                    }
                    setInterviewSessions(_interviewSessions);
                    if (_interviewSessions.length > 0) {
                        onActiveInterviewSessionIdChanged(jobFair.id, _interviewSessions[0].id);
                    }
                }

                setLoading(false);
            }
            run();
        }
    }, [user]);

    useEffect(() => {
        if (activeInterviewSession) {
            if (activeInterviewSession.chatTranscript) {
                setChatRecords(parse(activeInterviewSession.chatTranscript));
            }
            else 
                setChatRecords([]);
        }
    }, [activeInterviewSession])

    const onActiveInterviewSessionIdChanged = (jobFairId: string, id?: string) => {
        if (id)
            interviewSessionService.getById(jobFairId, id).then(async _interviewSession => {
                if (_interviewSession) {
                    setActiveInterviewSession(_interviewSession);
                    setChatRecruiter((await vjfpUserService.getById(_interviewSession.recruiterId)));
                }
                else {
                    setActiveInterviewSession(undefined);
                    setChatRecruiter(undefined);
                }
            });
    }


    return (
        <div className="chatPage">
            <AppTopNav title={props.title} breadcrumbs={props.breadcrumbs}>
            </AppTopNav>

            <div className="container-fluid">

                <Row className="mt-10">
                    <Col md={7}>
                        <BlockUi tag="div" className="jobSeekerListing" blocking={loading}>
                            <div>
                                <h6>My Chats</h6>
                            </div>
                            {!loading && interviewSessions && interviewSessions.length === 0 ?
                                <div className="d-flex border m-10 p-10 justify-content-center text-align-center">
                                    <Mailbox2 color="grey" size={30} />
                                    <p className="m-3"> No Conversations</p>
                                </div> :
                                <Table interviewSessions={interviewSessions} onActiveInterviewSessionIdChanged={onActiveInterviewSessionIdChanged}></Table>}
                        </BlockUi>
                    </Col>
                    <Col md={5} >

                        <div className="jobSeekerListing">
                            <div>
                                <h6>{chatRecruiter?.firstName} {chatRecruiter?.lastName}</h6>
                                <p className="lh-base">{chatRecruiter?.designation}</p>
                                <p className="lh-base text-muted fw-lighter fst-italic">{chatRecruiter?.address}</p>
                            </div>
                            <div className="mt-6 me-n5 pe-5 h-200px" data-kt-element="messages" data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, md: true}" data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                                data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
                                data-kt-scroll-offset="-2px">
                                {chatRecords.map((record) => (
                                    <ChatMessage key={record.id} message={record} />
                                ))}
                                {!chatRecords || chatRecords.length === 0 && <p className="text-muted">There was no text chat during this conversation.</p>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}


function Table(props: { interviewSessions: InterviewSessionsItem[], onActiveInterviewSessionIdChanged: (jobFairId: string, id: string | undefined) => void }) {
    const history = useHistory();

    function navigateToBoothDetail(boothId?: string): void {
        if (boothId)
            history.push(UrlParamReplace(JSRoutes.BoothDetail, ':boothId', boothId));
    }

    return (<div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
                <tr className="fw-bolder text-muted">
                    <th className="min-w-100px">Recruiter</th>
                    <th className="min-w-100px">Company</th>
                    {/* <th className="min-w-100px">My Rating</th>
                    <th className="min-w-200px">My Notes</th> */}
                </tr>
            </thead>
            <tbody>
                {props.interviewSessions.map((_iSession, index) => <tr key={index}>
                    <td>
                        <div className="d-flex align-items-center">
                            {_iSession?.recruiter?.avatar ?
                                <Image src={_iSession.recruiter.avatar} />
                                :
                                <div className="symbol symbol-50px">
                                    <div className={"symbol-label fs-2 fw-bold bg-" + getStyleByIndex(index) + " text-inverse-" + getStyleByIndex(index)}>{getInitials(_iSession?.recruiter?.firstName, _iSession?.recruiter?.lastName)}</div>
                                </div>
                            }
                            <div className="d-flex ms-3 justify-content-start flex-column">
                                <a onClick={() => { props.onActiveInterviewSessionIdChanged(_iSession.jobFairId, _iSession?.id) }} className="text-dark fw-bolder text-hover-primary fs-6">{_iSession?.recruiter?.firstName} {_iSession?.recruiter?.lastName}</a>
                                <span className="text-muted fw-bold text-muted d-block fs-7">{_iSession?.recruiter?.designation}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a onClick={() => { navigateToBoothDetail(_iSession?.boothId); }} className="text-dark fw-bolder text-hover-primary d-block fs-6">{_iSession?.boothsInfo?.name}</a>
                    </td>
                    {/* <td>
                        <div className="d-flex flex-column w-100 me-2">
                            <span className="me-2 fs-7 fw-bold">{_iSession?.reviewUser?.rating}</span>
                        </div>
                    </td>
                    <td>
                        {_iSession?.reviewUser?.notes}
                    </td> */}
                </tr>)}
            </tbody>
        </table>
    </div>);
}


export default JSHistory;

