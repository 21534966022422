import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";

import { JSRoutes } from "../JSPage";
import { Preview } from "app/modules/shared";
import { useAuth } from "app/context/auth-context";
import { useHistory } from "react-router-dom";
import { UrlParamReplace } from "app/helpers/MiscUtils";
import { EndReason, InterviewSession, VideoSessionStatus } from "app/API";
import { InterviewSessionService } from "app/services/InterviewSessionService";
import { useAppContext } from "app/context/app-context";
import { AlertModal } from 'app/modules/shared/modals/AlertModal';
import useIsMounted from 'app/hooks/useIsMounted';
import { QueueUserService } from "app/services/QueueUserService";
import Sound from "app/modules/shared/Sound";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { BoothService } from "app/services/BoothService";
import { VJFPUserService } from "app/services/VJFPUserService";
import { RxSubscription } from "app/RxSubscriptions";
import { LocalAppEventPubSub } from "app/app-events";
import { LocalAppEventType } from "app/app-models";

const COUNTDOWN_TIMEOUT = 21;
export const InterviewCallModal = () => {
  const [showInterviewCallingModal, setShowInterviewCallingModal] = useState<boolean>(false);
  const [isStartedAudio, setIsStartedAudio] = useState<boolean>(false);
  const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);
  const [interviewSession, setInterviewSession] = useState<InterviewSession | null>(null);
  const history = useHistory();
  const { user } = useAuth();
  const subscribedIds = useRef<Set<string>>(new Set<string>());
  const { jobFair } = useAppContext();
  const [showSessionTimeOutModal, setShowSessionTimeOutModal] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [recruiterName, setRecruiterName] = useState<string>('');
  const [countdownSeconds, setCountdownSeconds] = useState<number>(COUNTDOWN_TIMEOUT);

  const isMounted = useIsMounted('InterviewCallModal');
  
  useEffect(() => {
    if(!showInterviewCallingModal)
     return;
    if(countdownSeconds <= 0){
      handleTimeout();
      return;
    }
    const timeoutId = setTimeout(()=> {
      if(isMounted())
        setCountdownSeconds(countdownSeconds-1);
    },1000);

    return ()=> clearTimeout(timeoutId);
  }, [countdownSeconds, showInterviewCallingModal, isMounted]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const subscription = RxSubscription.onChangeInterviewSessionByCandidateIdObservable().subscribe(async changeEventInterviewSession => {
      if (changeEventInterviewSession?.mode !== 'create')
        return;

      const interviewSessionObj = changeEventInterviewSession?.value;
      if (!isMounted() || !interviewSessionObj || subscribedIds.current.has(interviewSessionObj.id) || interviewSessionObj.status !== VideoSessionStatus.AWAITING) {
        return;
      }

      if(sessionStorage.getItem('isInterviewCallModalActive-' + interviewSessionObj.id) != null)
        return;

      console.log(interviewSessionObj.recruiterId + ' Recruiter calling');
      if (interviewSessionObj.candidateId !== user.id) {
        return;
      }
      setRoomId(interviewSessionObj.roomId);

      // This is to avoid double join issue (to avoid corner case);
      setShowInterviewCallingModal(true);

      sessionStorage.setItem('isInterviewCallModalActive-' + interviewSessionObj.id, '1');
      
      subscribedIds.current.add(interviewSessionObj.id);
      setInterviewSession(interviewSessionObj);
      if (jobFair?.id) {
        const booth = await new BoothService().getBoothById(jobFair.id, interviewSessionObj.boothId);
        setCompanyName(booth?.name || '');
        const recruiterUser = await new VJFPUserService().getById(interviewSessionObj.recruiterId);
        setRecruiterName(recruiterUser?.firstName + ' ' + recruiterUser?.lastName);
      }
    
    });

    return () => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }, [user, isMounted]);

  const handleTimeout = async () => {
    if (isMounted()) {
     
      localStorage.removeItem('isInterviewCallModalActive');
      setShowSessionTimeOutModal(true);
      setShowInterviewCallingModal(false);
      setCountdownSeconds(COUNTDOWN_TIMEOUT);
      await endSession();
    }
  };

  const endSession = useCallback(async () => {
    if (!jobFair?.id || !user?.id || !interviewSession?.id)
      return;
    await new QueueUserService().endInterview(jobFair.id, interviewSession.boothId, interviewSession?.id, user?.id, EndReason.TIMED_OUT);

  }, [jobFair?.id, user?.id, interviewSession]);

  const acceptCall = useCallback(
    async () => {
      if (!jobFair?.id || !interviewSession?.id) return;
      const _isStartedAudio = isStartedAudio;
      const _isStartedVideo = isStartedVideo;
      const i = await new InterviewSessionService().getById(jobFair.id, interviewSession.id);
      if(i?.status !== 'AWAITING')
        navigateToLobby();
      const _interviewSession = await new InterviewSessionService().updateStatus(jobFair.id, interviewSession.id, VideoSessionStatus.IN_PROGRESS);
      if (_interviewSession) {
        if (!isMounted()) return;
        const url = UrlParamReplace(JSRoutes.Interview, ':sessionId', interviewSession.id)
        if (user?.id) {
          await new QueueUserService().updateJobSeekerJoined(jobFair.id, user.id, _interviewSession.queueUserId, _interviewSession.recruiterId);
        }
        if (!isMounted())
          return;

        setShowInterviewCallingModal(false);
        setCountdownSeconds(COUNTDOWN_TIMEOUT);
        history.push(`${url}?startVideo=${_isStartedVideo}&startAudio=${_isStartedAudio}`);
      }
    },
    [interviewSession?.id],
  )

  const navigateToLobby = () => {
    setShowSessionTimeOutModal(false);
    history.push(UrlParamReplace(JSRoutes.Lobby, '', ''));
  }
  return (
    <>
      <Modal show={showInterviewCallingModal}>
        <Modal.Header>
          <Modal.Title>Video Call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>

            <Row>
              <Col>
              {countdownSeconds%3 ==0 && <Sound counter={countdownSeconds % 3} />}
                <HMSRoomProvider>
                  <Preview
                    roomId={roomId}
                    isPreviewActive={showInterviewCallingModal}
                    onAudioStarted={(value: boolean) => setIsStartedAudio(value)}
                    onVideoStarted={(value: boolean) => setIsStartedVideo(value)}
                    showDeviceSetting={false}
                  />
                </HMSRoomProvider>
              </Col>
            </Row>
            <Row>
              <p className="h4">
                <strong className="text-success">{companyName}</strong> - Recruiter (<strong className="text-success" >{recruiterName}</strong>) is waiting for interview, please click <strong className="text-success">Join</strong>.
              </p>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
            Seconds remaining before this session times out: {countdownSeconds}
            </Row>
          </Container>
          <Button variant='success' onClick={acceptCall}>
            Join
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal title="Interview ended -- This session has timed out."
        show={showSessionTimeOutModal}
        onOK={navigateToLobby}>
      </AlertModal>
    </>
  )
};