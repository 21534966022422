import { VJFPUserService } from 'app/services/VJFPUserService';
import { Amplify, Auth } from 'aws-amplify';
console.log(Amplify);
// User Login
export async function login(email: string, password: string) {
  try {
    const user = await Auth.signIn(email, password);
    return user;
  } catch (error) {
    console.error('Error signing in', error);
    throw error; // Handle errors in your app
  }
}

// User Registration
export async function register(email: string, firstname: string, lastname: string, password: string, role: string){
  try {
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email, // Required attribute
        given_name: firstname,
        family_name: lastname,
       'custom:role': role, // Use the 'custom:' prefix
      },
    });
    return user;
  } catch (error) {
    console.error('Error signing up', error);
    throw error; // Handle errors in your app
  }
}

export async function confirmRegistration(email: string, confirmationCode: string) {
  try {
    const result = await Auth.confirmSignUp(email, confirmationCode);
    return result;
  } catch (error) {
    console.error('Error confirming registration', error);
    throw error; // Handle errors in your app
  }
}

// Request Password Reset
export async function requestPassword(email: string) {
  try {
    const result = await Auth.forgotPassword(email);
    return result;
  } catch (error) {
    console.error('Error requesting password reset', error);
    throw error; // Handle errors in your app
  }
}

// Get Current Authenticated User
export async function getUserByToken() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    console.error('Error fetching authenticated user', error);
    throw error; // Handle errors in your app
  }
}