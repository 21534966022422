import { Button, Container, Row, OverlayTrigger, Tooltip, Form, Modal } from "react-bootstrap";
import { Megaphone, Trash, Upload } from "react-bootstrap-icons";
import { AppModal } from "app/modules/shared/modals/AppModal";
import { MessageService } from "app/services/MessageService";
import { useAuth } from "app/context/auth-context";
import React, { useEffect, useRef, useState } from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from "array-move";

import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BasePageProps } from "app/types";
import BoothCard from "app/modules/shared/booth-card/BoothCard";
import { BoothService } from "app/services/BoothService";
import { VJFPUserService } from "app/services/VJFPUserService";

import styles from './AdminLobby.module.scss';
import { useHistory } from "react-router-dom";
import { useAppContext } from "app/context/app-context";
import { Booth, UserStatus } from "app/API";
import { getFileUrlFromS3 } from "app/helpers/awsUtils";
import { CompanyDocumentService, IUploadCompanyDocumentsInput } from 'app/services/CompanyDocumentsService';
import DropzoneComponent from 'app/modules/shared/dropzone-component/DropzoneComponent';
import { AdminRoutes } from "../AdminPage";
import { UrlParamReplace } from "app/helpers/MiscUtils";
import { QueueUserService } from "app/services/QueueUserService";


const SortableItem = SortableElement(({ value }: { value: React.ReactNode }) => <div className="m-2" style={{width: '30%'}}>{value}</div>);

const SortableList = SortableContainer(({ items }: { items: React.ReactNode[] }) => {
    return (
        <div className="d-flex flex-wrap">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});

interface BoothsWithLiveRecruiterCount extends Booth {
    loggedInRecruitersCount?: number
}

interface IBoothLogoSelected {
    selectedFileName: string
    selectedFileKey: string
    uploadProgress: number
    isFileuploading: boolean
}
interface dropzoneError {
    errorCode: string,
    getErrorMsg: Function
}

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024; //10 MB

const dropZoneErrors: dropzoneError[] = [
    {
        errorCode: 'file-too-large',
        getErrorMsg: (file: any) => file.name + ' is larger than ' + MAX_FILE_UPLOAD_SIZE + ' Bytes.'
    },
    {
        errorCode: 'too-many-files',
        getErrorMsg: (file: any) => 'You cannot upload more than ' + 1 + 'file(s).'
    },
    {
        errorCode: 'file-invalid-type',
        getErrorMsg: (file: any) => file.name + 'is not allowed. Only pdf file is allowed.'
    }
]

const getDropzoneErrorMessages = (fileRejections: any) => {

    let errorMsgs: Set<string> = new Set()
    let isErrorFound = false

    for (let fileRejection of fileRejections) {
        for (let errorObj of fileRejection.errors) {
            for (const errorItem of dropZoneErrors) {
                if (errorObj.code === errorItem.errorCode) {
                    isErrorFound = true
                    errorMsgs.add(errorItem.getErrorMsg(fileRejection.file))
                }
            }

            if (!isErrorFound) {
                isErrorFound = false
                errorMsgs.add("Something went wrong!")
            }
        }
    }

    return [...errorMsgs]
}

const AdminLobby = (props: BasePageProps) => {

    const [booths, setBooths] = useState<(BoothsWithLiveRecruiterCount | undefined | null)[] | null | undefined>([]);
    const boothService = new BoothService();
    const { jobFair } = useAppContext();
    const vjfpUserService = new VJFPUserService();
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(true);
    const [showBroadCastModal, setBroadCastModal] = useState<boolean>(false);
    const [showClearNotificationModal, setShowClearNotificationModal] = useState<boolean>(false);
    const [notificationJobfairId, setNotificationJobfairId] = useState<string | undefined>(undefined);
    const [broadCastType, setBroadCastType] = useState<"Recruiter" | "Applicant" | "both">("Recruiter")
    const [broadCastMessage, setBroadCastMessage] = useState("");
    const [showConfirmEmptyQueue, setShowConfirmEmptyQueue] = useState<boolean>(false);
    const currentBooth = useRef<string>();
    const { user } = useAuth();
    const [showUploadModal, setUploadModal] = useState<boolean>(false);
    const [boothLogoSelected, setBoothLogoSelected] = useState<IBoothLogoSelected>({ selectedFileName: '', selectedFileKey: '', uploadProgress: 0, isFileuploading: false });
    const companyDocumentService = new CompanyDocumentService();

    const onSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        if (!jobFair?.id) {
            return;
        }
        setShowLoading(true);
        if (booths) {
            const boothsAfterSort = arrayMoveImmutable(booths, oldIndex, newIndex);

            for (var i = 0; i < booths.length; i++) {
                if (booths[i]?.id === boothsAfterSort[i]?.id) {
                    continue;
                }
                // @ts-ignore: Object is possibly 'null'.
                boothsAfterSort[i].sortOrder = i;

                // @ts-ignore: Object is possibly 'null'.
                await boothService.updateSortOrder(jobFair.id, boothsAfterSort[i].id, i);
            }
            setShowLoading(false);
            setBooths([...boothsAfterSort]);
        }

    }

    const sendBroadcastMessage = async () => {
        if (!jobFair?.id)
            return;

        setBroadCastModal(false);
        const vjfpService = new VJFPUserService();
        const messageService = new MessageService();

        if (broadCastMessage && broadCastType) {
            const getAllUsers = await vjfpService.getUsersByJobFairId(jobFair.id);
        
            if (getAllUsers) {
                const userRoles: Record<string, string[]> = {
                    Recruiter: ["employer_admin", "recruiter"],
                    Applicant: ["applicant"]
                };
        
                let targetUserRoles: string[] = [];
                let messageTopicSuffix: string = "";
        
                if (broadCastType === 'Recruiter' || broadCastType === 'Applicant') {
                    targetUserRoles = userRoles[broadCastType];
                    messageTopicSuffix = broadCastType;
                } else {
                    targetUserRoles = [...userRoles.Recruiter, ...userRoles.Applicant];
                    messageTopicSuffix = "Both";
                }
        
                const getUsersByType = getAllUsers.filter(user =>
                    targetUserRoles.some(role => user.roles?.includes(role))
                );
        
                if (getUsersByType.length > 0 && user) {
                    const messageTopic: string = `${jobFair.id}_${messageTopicSuffix}`;
                    const messageBroadcasted = await messageService.broadcastMessage(jobFair.id, messageTopic, broadCastMessage, user?.id);
        
                    if (messageBroadcasted) {
                        messageService.createMessageRecipients(messageBroadcasted.id, getUsersByType.map(user => user.id), jobFair.id);
                    }
                }
            }
        }
    }

    const closeBroadCastModal = () => {
        setBroadCastModal(false)
    }

    const handleUploadModalCancel = () => {
        companyDocumentService.deleteDocuments(boothLogoSelected.selectedFileKey);
        setUploadModal(false);
    }

    const handleResumeModalSave = () => {
        setUploadModal(false);
    }

    const onDocUploadProgress = (loadedContent: number, totalContent: number) => {
        let selectedLogo = { ...boothLogoSelected }
        selectedLogo.uploadProgress = (loadedContent / totalContent) * 100
        if (selectedLogo.uploadProgress === 100) {
            selectedLogo.isFileuploading = false
        } else {
            selectedLogo.isFileuploading = true
        }
        setBoothLogoSelected(selectedLogo)
    }

    const onLogoUploadDrop =
        (acceptedFiles: any, fileRejections: any, event: any) => {

            let ret = getDropzoneErrorMessages(fileRejections)

            if (acceptedFiles.length > 0) {
                let resumeFile: IUploadCompanyDocumentsInput = {
                    fileName: acceptedFiles[0].name,
                    fileObj: acceptedFiles[0]
                }

                setBoothLogoSelected({
                    selectedFileName: acceptedFiles[0].name,
                    selectedFileKey: '',
                    uploadProgress: 0,
                    isFileuploading: false
                })

                companyDocumentService.uploadLogoToBooth(resumeFile, onDocUploadProgress)
                    .then((s3UploadResult: any) => {

                        let selectedLogo = { ...boothLogoSelected }
                        setBoothLogoSelected({
                            selectedFileName: acceptedFiles[0].name,
                            selectedFileKey: s3UploadResult[0].key,
                            uploadProgress: 100,
                            isFileuploading: false
                        })

                    })
            }
        };

    useEffect(() => {

        const run = async () => {

            if (!jobFair?.id) {
                return;
            }

            const booths = (await boothService.getAllBooths(jobFair.id)) as BoothsWithLiveRecruiterCount[]
            if (booths) {
                const sortedBooths = booths.sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0)) as BoothsWithLiveRecruiterCount[];
                setBooths(sortedBooths);
                setShowLoading(false);
                const allRecruiterIds: number[] = [];
                for (const b of sortedBooths) {
                    if (b) {
                        b.companyImageUrl = b?.cgoCompanyId ? (await getFileUrlFromS3('employer_logos/' + b.cgoCompanyId + '.jpg', 'employer_logos/')).fileUrl.toString() : '';
                        if (b?.recruiters) {
                            allRecruiterIds.push(... (b.recruiters as number[]));
                        }
                    }
                }
                setBooths([...sortedBooths]);
                const vjfpUsers = await vjfpUserService.getByIds(allRecruiterIds);
                if (vjfpUsers)
                    for (const b of sortedBooths) {
                        if (b?.recruiters) {
                            b.recruiters.forEach(rId => {
                                if (vjfpUsers.find(x => x.id === rId)?.presenceStatus === UserStatus.LOGGED_IN) {
                                    b.loggedInRecruitersCount = (b.loggedInRecruitersCount || 0) + 1;
                                }
                            });
                        }
                    }
                setBooths([...sortedBooths]);
            }
        }

        run();
    }, [jobFair]);

    const onBoothDetail = (boothId: string) => {
        history.push(UrlParamReplace(AdminRoutes.BoothDetail, ':boothId', boothId));
    }

    const emptyQueue = async (boothId: string) => {
        if (!jobFair?.id) return;
        const queueUserService = new QueueUserService();
        const allQueueUsers = await queueUserService.getQueueUsersByBoothId(jobFair.id, boothId);
        if (!allQueueUsers) return;
        for (const qu of allQueueUsers) {
            await queueUserService.deleteCandidate(jobFair.id, boothId, qu);
        }
        setShowConfirmEmptyQueue(false);
    }

    const onCLearNotifications = async () => {
        setShowClearNotificationModal(false);
        if (notificationJobfairId) {
            const result = await new MessageService().deleteAllMessageRecipientsByJobFairId(notificationJobfairId);
            if (result) {
                window.alert('Success clearing Notifications for job fair id ' + notificationJobfairId);
                setNotificationJobfairId(undefined);
            }
            else {
                window.alert('Error clearing Notifications for job fair id ' + notificationJobfairId);
            }
        }
    }

    return (
        <div className="chatPage">
            <AppTopNav title={props.title} breadcrumbs={props.breadcrumbs}>
               
                <OverlayTrigger overlay={<Tooltip id="announcememt"> Post a recruiters or jobseekers announcements</Tooltip>}>
                    <Button className="m-2" onClick={() => setBroadCastModal(true)}>
                        <Megaphone /><small className="ms-2">Announcements</small>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="clearnotifications">Clear Notifications</Tooltip>}>
                    <Button className="m-2" onClick={() => setShowClearNotificationModal(true)}>
                        <Trash /><small className="ms-2">Clear Notifications</small>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="uploadLogo">Upload company logo's</Tooltip>}>
                    <Button className="m-2" onClick={() => setUploadModal(true)}>
                        <Upload /><small className="ms-2">Upload Logo</small>
                    </Button>
                </OverlayTrigger>
            </AppTopNav>

            <div className="container-fluid">
                <Container className="mt-4 lobbyCard">
                    {/* <AdminOverallMatrix></AdminOverallMatrix> */}
                    <Row>
                        {showLoading ?
                            (<span>Loading...</span>) :
                            (<>{booths && <SortableList axis="xy" onSortEnd={onSortEnd} items={
                                booths.map<React.ReactNode>((b, index) => {
                                    return (<div key={"card-" + index}>
                                        <BoothCard isFeatured={b?.isFeatured || false} logo={b?.companyImageUrl}>
                                            <BoothCard.Title>{b?.name}</BoothCard.Title>
                                            <BoothCard.Description>{(b?.description?.length || 0) > 150 ? b?.description?.substr(0, 150) + '...' : (b?.description) || ''}</BoothCard.Description>
                                            <BoothCard.Body jobsCount={0} recruitersCount={b?.recruiters?.length || 0} loggedInRecruitersCount={b?.loggedInRecruitersCount || 0} currentQueueCount={`${b?.QueueCount || "0"}`}>
                                                <Button variant="success" className="w-50 mt-3 py-3" onClick={() => { onBoothDetail(b?.id || '0') }}>Manage Booth</Button>
                                                {b?.id && <Button variant="danger" className="w-30 mt-3 py-3" onClick={() => { currentBooth.current = b.id; setShowConfirmEmptyQueue(true); }}>Empty Queue</Button>}
                                            </BoothCard.Body>
                                        </BoothCard>
                                    </div>)
                                })} />}
                            </>)}
                    </Row>
                    <AppModal show={showBroadCastModal} onSuccess={sendBroadcastMessage} onHide={closeBroadCastModal} title="Broadcast message" variant="PlainText" successText="Broadcast" rejectText="No Thanks">
                        <Form>
                            <Form.Group className="mb-3" controlId="broadcastTo">
                                <Form.Label className="fs-4 fw-bold">Broadcast To</Form.Label>
                                <div className="mt-1">
                                    <Form.Check id="broad_queue" inline onChange={() => { setBroadCastType("Recruiter") }} label="Recruiter" checked={broadCastType === "Recruiter"} name="broadcastGroup" type="radio" />
                                    <Form.Check id="broad_queue" inline onChange={() => { setBroadCastType("Applicant") }} label="JobSeeker" checked={broadCastType === "Applicant"} name="broadcastGroup" type="radio" />
                                    <Form.Check id="broad_queue" inline onChange={() => { setBroadCastType("both") }} label="Both" checked={broadCastType === "both"} name="broadcastGroup" type="radio" />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="broadcastMsg">
                                <Form.Label className="fs-4 fw-bold">Broadcast Message</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="" onChange={(e) => {
                                    setBroadCastMessage(e.target.value);
                                }} />
                            </Form.Group>
                        </Form>
                    </AppModal>

                    <AppModal show={showClearNotificationModal} onSuccess={onCLearNotifications} onHide={() => { setShowClearNotificationModal(false); }} onReject={() => { setShowClearNotificationModal(false); }} title="Clear Notification" variant="PlainText" successText="Delete now" rejectText="No Thanks">
                        <Form>
                            <Form.Group className="mb-3" controlId="clearNotification">
                                <Form.Label className="fs-4 fw-bold">Clear Notification</Form.Label>
                                <div className="mt-1">
                                    <Form.Control type="number" id="jobFairId" placeholder="Input job fair id" value={notificationJobfairId} onChange={(e: any): void => {
                                        setNotificationJobfairId(e.target.value);
                                    }} />
                                </div>
                            </Form.Group>
                        </Form>
                    </AppModal>

                    <Modal centered className="uploadModel" size="lg" show={showUploadModal} onHide={handleUploadModalCancel}>
                        <Modal.Body>
                            <div className="currentUpload">
                                <DropzoneComponent
                                    accept="image/jpeg, image/png"
                                    maxSize={MAX_FILE_UPLOAD_SIZE}
                                    maxFiles={1}
                                    preventDropOnDocument={true}
                                    onDrop={(acceptedFiles: any, fileRejections: any, event: any) => onLogoUploadDrop(acceptedFiles, fileRejections, event)}
                                />
                                <h6>File size not to exceed 10 MB and must be .jpeg,.jpg,.png.. file type.</h6>
                            </div>

                            {boothLogoSelected.uploadProgress > 0 && <div className="prograssBar">
                                <div className="prograss" style={{ width: boothLogoSelected.uploadProgress + "%" }}></div>
                                <strong>{boothLogoSelected.selectedFileName}</strong>

                                <p style={{ marginBottom: !boothLogoSelected.isFileuploading ? "1rem" : 0 }}>
                                    {boothLogoSelected.isFileuploading && (<span>Uploading...</span>)}
                                    <button
                                        disabled={boothLogoSelected.isFileuploading}
                                        onClick={() => {
                                            companyDocumentService.deleteDocuments(boothLogoSelected.selectedFileKey)
                                                .then(
                                                    () => {
                                                        setBoothLogoSelected({
                                                            selectedFileName: '',
                                                            selectedFileKey: '',
                                                            uploadProgress: 0,
                                                            isFileuploading: false
                                                        })
                                                    }
                                                )
                                        }}>
                                        <i className="fas fa-trash-alt">
                                        </i>
                                    </button>
                                </p>
                            </div>}

                            <div className="modal-footer">
                                <button type="button"
                                    className="btn btn-light"
                                    onClick={handleUploadModalCancel}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    disabled={boothLogoSelected.selectedFileKey === ""}
                                    className="btn btn-success"
                                    onClick={handleResumeModalSave}>
                                    Save
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <AppModal show={showConfirmEmptyQueue} title="Confirm" variant="PlainText"
                        onSuccess={() => { if (currentBooth.current) emptyQueue(currentBooth.current) }} onReject={() => { setShowConfirmEmptyQueue(false); }} onHide={() => { setShowConfirmEmptyQueue(false); }}
                        successText="Empty Queue" rejectText="No, Thanks">
                        Are you sure you want to empty booth?
                    </AppModal>
                </Container>
            </div>
        </div>
    );
}

export default AdminLobby;