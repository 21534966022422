import { HMSMessage } from '@100mslive/react-sdk';
import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse } from 'app/types';
import axios, { AxiosRequestConfig } from 'axios';
import { config } from 'config';
import { stringify } from 'zipson';

import {
  CreateInterviewSessionInput,
  InterviewSession,
  PaginatedRecord,
  UpdateInterviewSessionInput,
  VideoSessionStatus
} from '../API';

const EndPoints = {
  InterviewSessionByJobFairIdAndId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/:id`,
  InterviewSessionsListByJobFairIdAndCandidateId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/byCandidateId/:candidateId`,
  InterviewSessionsListByJobFairIdAndRecruiterId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/byRecruiterId/:recruiterId`,
  InterviewSessionsListByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/byBoothId/:boothId`,
  GetCSVByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/csv/byBoothId/:boothId`,
  InterviewSessionsListByJobFairIdAndQueueUserId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/byQueueUserId/:queueUserId`,
  InterviewSessionsListByJobFairId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions`,
  InterviewSessionsSignatureByJobFairId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/interviewSessions/signature`,
  Get100msAuthTokenByJobFairId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/InterviewSessions/auth-token`
};
export class InterviewSessionService {
  async getById (jobFairId: string, id: string) {
    const result = await axios.get<NgAPIResponse<InterviewSession>>(
      UrlBuilder(EndPoints.InterviewSessionByJobFairIdAndId, { jobFairId, id }),
      await getCommonAxiosCommon()
    );

    return result.data?.data;
  }

  async getActiveInterviewsByRecruiter (jobFairId: string, recruiterId: number) {
    const result = await axios.post<NgAPIResponse<InterviewSession[]>>(
      UrlBuilder(EndPoints.InterviewSessionsListByJobFairIdAndRecruiterId + '?filter=active', {
        jobFairId,
        recruiterId
      }),
      { lastEvaluatedKey: null },
      await getCommonAxiosCommon()
    );
    return result.data?.data;
  }

  async getCSVInterviewsByBoothId (jobFairId: string, boothId: string) {
    const config: AxiosRequestConfig = await getCommonAxiosCommon();
    config.responseType = 'blob';
    const result = await axios.get(
      UrlBuilder(EndPoints.GetCSVByJobFairIdAndBoothId, {
        jobFairId,
        boothId
      }),
      config
    );
    return result.data;
  }

  async getInterviewsByBoothId (jobFairId: string, boothId: string, lastEvaluatedKey?: any) {
    const url = EndPoints.InterviewSessionsListByJobFairIdAndBoothId;

    const result = await axios.post<NgAPIResponse<PaginatedRecord<InterviewSession[]>>>(
      UrlBuilder(url, {
        jobFairId,
        boothId
      }),
      {
        lastEvaluatedKey
      },
      await getCommonAxiosCommon()
    );

    return result.data?.data;
  }

  async getInterviewsByJobseeker (jobFairId: string, candidateId: number) {
    const result = await axios.get<NgAPIResponse<InterviewSession[]>>(
      UrlBuilder(EndPoints.InterviewSessionsListByJobFairIdAndCandidateId, {
        jobFairId,
        candidateId
      }),
      await getCommonAxiosCommon()
    );

    return result.data?.data;
  }

  async getActiveInterviewByJobseeker (jobFairId: string, candidateId: number) {
    const result = await axios.get<NgAPIResponse<InterviewSession[]>>(
      UrlBuilder(EndPoints.InterviewSessionsListByJobFairIdAndCandidateId + '?filter=active', {
        jobFairId,
        candidateId
      }),
      await getCommonAxiosCommon()
    );

    return result.data?.data;
  }

  async updateChat (
    jobFairId: string,
    id: string,
    messages: HMSMessage[],
    recruiterZoomId: number,
    jobSeekerZoomId?: number
  ) {
    return this._update(jobFairId, id, {
      id,
      recruiterZoomId,
      jobSeekerZoomId,
      chatTranscript: stringify(messages)
    });
  }

  async updateDurationAdded (jobFairId: string, id: string, durationAdded: number) {
    return this._update(jobFairId, id, {
      id,
      durationAdded
    });
  }

  async create (
    boothId: string,
    jobFairId: string,
    recruiterId: number,
    candidateId: number,
    queueUserId: string,
    candidateScore?: number | null,
    candidateMaxScore?: number | null
  ): Promise<InterviewSession | undefined> {
    try {
      const input: CreateInterviewSessionInput = {
        boothId,
        jobFairId,
        status: VideoSessionStatus.AWAITING,
        recruiterId,
        candidateId,
        durationAdded: 0,
        candidateScore,
        candidateMaxScore,
        queueUserId
      };
      try {
        const result = await axios.post<NgAPIResponse<InterviewSession>>(
          UrlBuilder(EndPoints.InterviewSessionsListByJobFairId, { jobFairId }),
          input,
          await getCommonAxiosCommon()
        );

        if (!result.data?.isSuccess) {
          console.error(result.data.errors);
        }

        return result.data?.data;
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getSignature (jobFairId: string, topic: string, password: string) {
    const result = await axios.post<NgAPIResponse<string>>(
      UrlBuilder(EndPoints.InterviewSessionsSignatureByJobFairId, { jobFairId }),
      {
        topic,
        password
      },
      await getCommonAxiosCommon()
    );

    return result.data.data;
  }

  async get100msAuthToken (
    jobFairId: string,
    userId: string,
    roomId: string,
    role: 'candidate' | 'interviewer'
  ) {
    const result = await axios.post<NgAPIResponse<string>>(
      UrlBuilder(EndPoints.Get100msAuthTokenByJobFairId, { jobFairId }),
      {
        roomId,
        userId,
        role
      },
      await getCommonAxiosCommon()
    );
    return result.data.data;
  }

  async updateStatus (jobFairId: string, id: string, status: VideoSessionStatus) {
    const input: UpdateInterviewSessionInput = { id, status };
    if (status === VideoSessionStatus.IN_PROGRESS) {
      input.interviewStartedAt = Math.round(new Date().getTime() / 1000);
    }
    return this._update(jobFairId, id, input);
  }

  private async _update (
    jobFairId: string,
    id: string,
    updateInput: UpdateInterviewSessionInput
  ): Promise<InterviewSession | undefined> {
    try {
      const result = await axios.put<NgAPIResponse<InterviewSession>>(
        UrlBuilder(EndPoints.InterviewSessionByJobFairIdAndId, { jobFairId, id }),
        updateInput,
        await getCommonAxiosCommon()
      );

      if (!result.data?.isSuccess) {
        console.error(result.data.errors);
      }

      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }
}
