export const config = {
  cgoBaseUrl: 'http://localhost:3011/auth/login',
  vjfpBaseUrl: 'http://localhost:3011/auth/login',
  vjfpNGRestAPI: 'https://7p009q1er9.execute-api.us-east-1.amazonaws.com/default',
  gaKey: 'G-4F6C2NNSL0',
  socketUrl: 'wss://hkmdtdqmo9.execute-api.us-east-1.amazonaws.com/qa/',
  enableAnalytics: false,
  payPalClientId: 'sb'
  // payPalClientId: 'ATWsol43sBTJYyXBm0JFZwx09w2Spp6WCjsC5hh_KIPLSHMVB_1nbg44Ob5Rv9tpgHbJLAKu-HLKpNjT'
};
