import './preview.scss';

import { useEffect, useState } from 'react';

import CameraButton from 'app/modules/shared/video/components/camera';
import MicrophoneButton from 'app/modules/shared/video/components/microphone';
import useIsMounted from 'app/hooks/useIsMounted';

import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  useAVToggle,
  useHMSActions,
  useHMSStore,
  usePreviewJoin,
  useVideo,
} from "@100mslive/react-sdk";
import { useAuth } from 'app/context/auth-context';
import { useAppContext } from 'app/context/app-context';
import { InterviewSessionService } from 'app/services/InterviewSessionService';
import { Roles } from 'app/types';
import DeviceSettings from '../video/DeviceSettings';

type PreviewContainerProps = {
  width?: number;
  roomId: string;
  onVideoStarted?: (value: boolean) => void;
  onAudioStarted?: (value: boolean) => void;
  isPreviewActive?: boolean;
  showDeviceSetting? : boolean
}

const PreviewContainer = (props: PreviewContainerProps) => {
  const { user } = useAuth();
  const { jobFair } = useAppContext()
  const localPeer = useHMSStore(selectLocalPeer);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle();
  const isMounted = useIsMounted('PreviewContainer');
  const [authToken, setAuthToken] = useState<string>('');
  const hmsActions = useHMSActions();
  const [isPreviewStarted, setIsPreviewStarted] = useState<boolean>(false)
  useEffect(() => {
    if (!jobFair?.id || !user?.id || !props.roomId) return;

    new InterviewSessionService().get100msAuthToken(jobFair.id, user.id.toString(), props.roomId, user.roles?.includes(Roles.Recruiter) ? 'interviewer' : 'candidate')
      .then(token =>
        setAuthToken(token)
      );

  }, [jobFair?.id, user?.id, props.roomId])

  const { videoRef } = useVideo({ trackId: localPeer?.videoTrack, attach: true });

  const { preview } = usePreviewJoin({
    token: authToken,
    initEndpoint: undefined,
    initialSettings: {
      isAudioMuted: false,
      isVideoMuted: false
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        console.log('error- preview');
      }
    },
  });

  useEffect(() => {
    if (props.onVideoStarted) {
      props.onVideoStarted(isVideoOn);
    }
  }, [isVideoOn])

  useEffect(() => {
    if (props.onAudioStarted) {
      props.onAudioStarted(isLocalAudioEnabled);
    }

  }, [isLocalAudioEnabled])

  useEffect(() => {
    if (authToken === '') return;

    if (props.isPreviewActive) {
      setIsPreviewStarted(true);
      preview();
    }
    if (isPreviewStarted && !props.isPreviewActive) {
      hmsActions.leave().finally(() => {
        setIsPreviewStarted(false);
      })
    }
  }, [authToken, props.isPreviewActive, isPreviewStarted]);

  return (
    <>
      <div className="video-preview">
        <video className='video-canvas' autoPlay muted playsInline controls={false} ref={videoRef} />
        <div className="controlPan">
          <div className="userThumb"></div>
          <div className="controls">
            <MicrophoneButton className="audio"
              isMuted={!isLocalAudioEnabled}
              onMicrophoneClick={() => { if (toggleAudio) toggleAudio(); }}
            />
            <CameraButton className="camera"
              isStartedVideo={isLocalVideoEnabled}
              onCameraClick={() => { if (toggleVideo) toggleVideo(); }}
              onSwitchCamera={() => { }}
              cameraList={[]}
              activeCamera={undefined}
            />
          </div>
          <div className="extraControls"></div>
        </div>
      </div>
      <div className="mt-10 mb-10 d-flex border border-success">
        {props.showDeviceSetting && <DeviceSettings></DeviceSettings>}
      </div>
    </>
  );
}

export default PreviewContainer;
