import { getInitials, MappingBranch, MappingClearance, MappingEducation, MappingGeography, MappingType } from 'app/helpers/MiscUtils';

import { VJFPUser } from 'app/API';
import { toAbsoluteUrl } from '_metronic/helpers';
import { useEffect, useState } from 'react';
import { ResumeService } from 'app/services/resumeService';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFileObjectFromS3, getFileUrlFromS3, getLatestResume, deleteFileFromS3 } from 'app/helpers/awsUtils';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { BoothService } from "app/services/BoothService";
import { useAppContext } from "app/context/app-context";
import { useAuth } from "app/context/auth-context";
import { QuestionAnswerService } from 'app/services/QuestionAnswerService';
import { Accordion } from 'react-bootstrap';
import { HMSMessage } from '@100mslive/react-sdk';
import { ChatMessage } from 'app/modules/shared/100ms-chat/chat-message';

interface IQueueQuestionAnswers {
    question?: string | null
    answer?: boolean | undefined
}

export function CandidateProfileForHistory({ candidate, score, maxScore, chatRecords, recruiterZoomId, chatCandidate }: { candidate?: VJFPUser, score?: number | null, maxScore?: number | null, chatRecords?: HMSMessage[], recruiterZoomId: number, chatCandidate?: VJFPUser }) {
    const resumeService = new ResumeService();
    const [resumePDFUrl, setResumePDFUrl] = useState<string | undefined>(undefined);
    const boothService = new BoothService();
    const { jobFair } = useAppContext();
    const { user } = useAuth();
    const [questionAnswers, setQuestionAnswers] = useState<(IQueueQuestionAnswers)[]>([]);
    const questionAnswerService = new QuestionAnswerService();

    const saveFile = () => {
        if (resumePDFUrl && candidate) {
            axios({
                url: resumePDFUrl,
                method: 'GET',
                responseType: 'blob'
            })
                .then((response) => {
                    if (response?.data) {
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        saveAs(blobUrl, `resume_${candidate?.lastName || ''}_${candidate?.firstName || ''}.pdf`);
                    }
                });
        }

    };

    const getUrlFromS3 = async (s3Key: string) => {
        const fileNameWithoutExtension = s3Key.replace(/\.[^/.]+$/, '');



        const { fileUrl } = await getFileUrlFromS3(fileNameWithoutExtension + '.pdf') as {
            key: string,
            fileUrl: string,
            fileName: string
        };
        setResumePDFUrl(fileUrl);
    };

    async function convertToPdf(s3Key: string) {
        try {
            await resumeService.convertDocument('public/' + s3Key);
            getUrlFromS3(s3Key);
        } catch (err) {
            console.log(err);
        }
    }

    async function getUrlAndSetState(fileNameWithoutExtension: string) {
        const { fileUrl } = await getFileUrlFromS3(`${fileNameWithoutExtension}.pdf`);
        if (typeof fileUrl === 'string') { setResumePDFUrl(fileUrl); }
    }

    useEffect(() => {
        if (!candidate) { return; }

        const run = async () => {
            const applicantResume = await resumeService.getApplicantResume(candidate?.id);
            let questionAnswerArray: IQueueQuestionAnswers[] = [];

            if (applicantResume?.id && applicantResume?.attached_resume_file_name) {
                //const ext = applicantResume.attached_resume_file_name.slice((applicantResume.attached_resume_file_name.lastIndexOf('.') - 1 >>> 0) + 2);
                const fileNameWithoutExtension = `attached_resumes/${applicantResume.id}_resume`;
                // const s3Key = `${fileNameWithoutExtension}.${ext}`;
                try {
                    // removes extension
                    // const fileNameWithoutExtension = s3Key.replace(/\.[^/.]+$/, "");
                    const s3Key = await getLatestResume(fileNameWithoutExtension);
                    if (s3Key?.endsWith('.pdf') || s3Key?.endsWith('.PDF')) {
                        await getFileObjectFromS3(s3Key);
                        await getUrlAndSetState(fileNameWithoutExtension);
                    }
                    else if (s3Key && (!s3Key.endsWith('/'))) {
                        try {
                            await deleteFileFromS3(`${fileNameWithoutExtension}.pdf`);
                        }
                        catch (err) {
                        }
                        await convertToPdf(s3Key);
                        await getUrlAndSetState(fileNameWithoutExtension);
                    }

                } catch (err) {
                    console.log(err);

                }
            }

            if (jobFair?.id && user) {
                const booth = await boothService.getBoothByRecruiter(jobFair.id, user.id);
                if (booth?.id && candidate) {
                    const allQueueQuestionAnswer = await questionAnswerService.getAllQuestionsAnswersByBoothAndUserId(jobFair.id, booth.id, candidate.id);
                    if (allQueueQuestionAnswer?.answers?.length) {
                        for (let questionItem of allQueueQuestionAnswer.answers) {
                            questionAnswerArray.push({
                                question: questionItem?.name,
                                answer: questionItem?.answer || false
                            });
                        }
                        setQuestionAnswers(questionAnswerArray);
                    } else {
                        setQuestionAnswers([]);
                    }
                }
            }
        };

        run();
    }, [candidate]);

    return (
        <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
                <Accordion.Header>
                    <div className='vjfp-flex-center'>
                        {candidate?.avatar && candidate?.avatar !== 'NULL'
                            ? <img src={toAbsoluteUrl('/media/interview/jobSeeker.png')} alt="Kate Willson" />
                            : <div className="symbol">
                                <div className="symbol-label fs-1 fw-bold bg-info text-inverse-info">{getInitials(candidate?.firstName, candidate?.lastName)}</div>
                            </div>
                        }
                        <div style={{ 'marginLeft': '10px' }}>
                            {candidate && <h1>{candidate.firstName + ' ' + candidate.lastName}</h1>}
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    {candidate && <div className="profileSnippet">
                        <h3 className="headlineClamp">Profile</h3>
                        <ul>
                            <li><label>Headline</label> <strong>{candidate.summary}</strong></li>
                            <li><label>Email</label> <strong><a href={'mailto:' + candidate.emailId}>{candidate.emailId}</a></strong></li>
                            <li><label>Branch of Service</label> <strong>{MappingBranch.get(candidate?.branch_of_service || '0') || '-'}</strong></li>
                            <li><label>Type of Applicant/Last Rank</label> <strong>{MappingType.get(candidate?.type_of_applicant || '0') || '-'}</strong></li>
                            <li><label>Education Level</label> <strong>{MappingEducation.get(candidate?.education_level || '0') || '-'}</strong></li>
                            <li><label>Security Clearance</label> <strong>{MappingClearance.get(candidate?.security_clearance || '0') || '-'}</strong></li>
                            <li><label>Geographic Preference</label> <strong>{MappingGeography.get(candidate?.geographic_preference || '0') || '-'}</strong></li>
                            <li><label>Availability Date</label> <strong>{candidate && candidate.availability_date ? candidate.availability_date.split(' ')[0] : '-'}</strong></li>
                        </ul>
                    </div>}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
                <Accordion.Header>
                    <h4>Resume</h4>
                    {resumePDFUrl &&
                        <a data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-placement="top"
                            title="Download Resume" className="btn icon" onClick={(event) => { saveFile(); event.stopPropagation(); }}>
                            <svg id="direct-download" xmlns="http://www.w3.org/2000/svg" width="16.797" height="13.997" viewBox="0 0 16.797 13.997">
                                <path id="Path_31" data-name="Path 31" d="M10.2,11.8a.524.524,0,0,1-.379-.162L6.145,7.787A.525.525,0,0,1,6.525,6.9H8.449V2.875A.876.876,0,0,1,9.324,2h1.75a.876.876,0,0,1,.875.875V6.9h1.925a.525.525,0,0,1,.379.887l-3.674,3.849A.524.524,0,0,1,10.2,11.8Z" transform="translate(-1.801 -2)" fill="#009ef7" />
                                <path id="Path_32" data-name="Path 32" d="M15.572,20.8H1.225A1.226,1.226,0,0,1,0,19.575v-.35A1.226,1.226,0,0,1,1.225,18H15.572A1.226,1.226,0,0,1,16.8,19.225v.35A1.226,1.226,0,0,1,15.572,20.8Z" transform="translate(0 -6.802)" fill="#009ef7" />
                            </svg>
                        </a>
                    }
                </Accordion.Header>
                <Accordion.Body>
                    {resumePDFUrl
                        ? (
                            <div className="block" style={{
                                opacity: 1,
                                pointerEvents: 'none'
                            }}>
                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                                    <Viewer fileUrl={resumePDFUrl} />
                                </Worker>
                            </div>)
                        : (
                            <div className="noResume">
                                <h4>No Resume Available</h4>
                            </div>
                        )}


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='queueQuestions'>
                <Accordion.Header>
                    <h4>Queue Questions </h4>
                    <strong style={{ marginLeft: '10px' }}> {score && maxScore ? score.toString() + '/' + maxScore.toString() : 'N/A'} </strong>
                </Accordion.Header>
                <Accordion.Body>
                    {score && maxScore && questionAnswers?.length ? <div className='queAns'>
                        <ul>
                            {questionAnswers.map((item, index) => (
                                <li key={'questionAnswers' + index}>
                                    <div className="que">{item.question}</div>
                                    <div className="ans">{item.answer == true ? 'Yes' : 'No'}</div>
                                </li>
                            ))}
                        </ul>
                    </div> : <p className="text-muted">There were no questions configured for this conversation.</p>}

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='chatHistory'>
                <Accordion.Header>
                    <h4>Chat History</h4>
                </Accordion.Header>
                <Accordion.Body>
                    {chatRecords?.length ?
                        <div className="mt-6 me-n5 pe-5" style={{maxHeight: '200px', overflowY: 'scroll'}} data-kt-element="messages" data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, md: true}" data-kt-scroll-max-height="auto"
                            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                            data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
                            data-kt-scroll-offset="-2px">
                            {chatRecords && chatRecords.map((record) => (
                                <ChatMessage key={record.id} message={record}/>
                            ))}
                        </div>
                        : <p className="text-muted">There was no text chat during this conversation.</p>}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >)
}

