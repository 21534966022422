/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type addresses = {
  __typename: "addresses",
  id: number,
  label: string,
  addressable_id?: number | null,
  addressable_type?: string | null,
  street_address1?: string | null,
  street_address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  email?: string | null,
  phone?: string | null,
  mobile?: string | null,
  fax?: string | null,
  website?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  extension?: string | null,
};

export type CreateAddressesInput = {
  id: number,
  label: string,
  addressable_id?: number | null,
  addressable_type?: string | null,
  street_address1?: string | null,
  street_address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  email?: string | null,
  phone?: string | null,
  mobile?: string | null,
  fax?: string | null,
  website?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  extension?: string | null,
};

export type UpdateAddressesInput = {
  id: number,
  label?: string | null,
  addressable_id?: number | null,
  addressable_type?: string | null,
  street_address1?: string | null,
  street_address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  email?: string | null,
  phone?: string | null,
  mobile?: string | null,
  fax?: string | null,
  website?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  extension?: string | null,
};

export type applicants_jobs = {
  __typename: "applicants_jobs",
  id: number,
  applicant_id?: number | null,
  job_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateApplicants_jobsInput = {
  id: number,
  applicant_id?: number | null,
  job_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateApplicants_jobsInput = {
  id: number,
  applicant_id?: number | null,
  job_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type authsub_tokens = {
  __typename: "authsub_tokens",
  id: number,
  token: string,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateAuthsub_tokensInput = {
  id: number,
  token: string,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateAuthsub_tokensInput = {
  id: number,
  token?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type bdrb_job_queues = {
  __typename: "bdrb_job_queues",
  id: number,
  args?: string | null,
  worker_name?: string | null,
  worker_method?: string | null,
  job_key?: string | null,
  taken?: number | null,
  finished?: number | null,
  timeout?: number | null,
  priority?: number | null,
  submitted_at?: string | null,
  started_at?: string | null,
  finished_at?: string | null,
  archived_at?: string | null,
  scheduled_at?: string | null,
  tag?: string | null,
  submitter_info?: string | null,
  runner_info?: string | null,
  worker_key?: string | null,
};

export type CreateBdrb_job_queuesInput = {
  id: number,
  args?: string | null,
  worker_name?: string | null,
  worker_method?: string | null,
  job_key?: string | null,
  taken?: number | null,
  finished?: number | null,
  timeout?: number | null,
  priority?: number | null,
  submitted_at?: string | null,
  started_at?: string | null,
  finished_at?: string | null,
  archived_at?: string | null,
  scheduled_at?: string | null,
  tag?: string | null,
  submitter_info?: string | null,
  runner_info?: string | null,
  worker_key?: string | null,
};

export type UpdateBdrb_job_queuesInput = {
  id: number,
  args?: string | null,
  worker_name?: string | null,
  worker_method?: string | null,
  job_key?: string | null,
  taken?: number | null,
  finished?: number | null,
  timeout?: number | null,
  priority?: number | null,
  submitted_at?: string | null,
  started_at?: string | null,
  finished_at?: string | null,
  archived_at?: string | null,
  scheduled_at?: string | null,
  tag?: string | null,
  submitter_info?: string | null,
  runner_info?: string | null,
  worker_key?: string | null,
};

export type booth_recruiters = {
  __typename: "booth_recruiters",
  id: number,
  booth_info_id?: number | null,
  recruiter_id?: number | null,
};

export type CreateBooth_recruitersInput = {
  id: number,
  booth_info_id?: number | null,
  recruiter_id?: number | null,
  created_at: string,
  updated_at: string,
};

export type UpdateBooth_recruitersInput = {
  id: number,
  booth_info_id?: number | null,
  recruiter_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type booths_info = {
  __typename: "booths_info",
  id: number,
  employer_id?: number | null,
  jobfair_id?: number | null,
  isFeatured?: boolean | null,
  interview_duration_min?: number | null,
  minimum_score?: number | null,
  banner_company_name?: string | null,
  company_profile?: string | null,
  available_positions?: string | null,
};

export type CreateBooths_infoInput = {
  id: number,
  employer_id?: number | null,
  jobfair_id?: number | null,
  isFeatured?: number | null,
  interview_duration_min?: number | null,
  created_at: string,
  updated_at: string,
  minimum_score?: number | null,
};

export type UpdateBooths_infoInput = {
  id: number,
  employer_id?: number | null,
  jobfair_id?: number | null,
  isFeatured?: number | null,
  interview_duration_min?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  minimum_score?: number | null,
};

export type connections = {
  __typename: "connections",
  id: number,
  provider?: string | null,
  unique_identifier?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  company?: string | null,
  headline?: string | null,
  industry?: string | null,
  location?: string | null,
  profile_url?: string | null,
  picture_url?: string | null,
  photo_file_name?: string | null,
  photo_content_type?: string | null,
  photo_file_size?: number | null,
  photo_updated_at?: string | null,
  user_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateConnectionsInput = {
  id: number,
  provider?: string | null,
  unique_identifier?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  company?: string | null,
  headline?: string | null,
  industry?: string | null,
  location?: string | null,
  profile_url?: string | null,
  picture_url?: string | null,
  photo_file_name?: string | null,
  photo_content_type?: string | null,
  photo_file_size?: number | null,
  photo_updated_at?: string | null,
  user_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateConnectionsInput = {
  id: number,
  provider?: string | null,
  unique_identifier?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  company?: string | null,
  headline?: string | null,
  industry?: string | null,
  location?: string | null,
  profile_url?: string | null,
  picture_url?: string | null,
  photo_file_name?: string | null,
  photo_content_type?: string | null,
  photo_file_size?: number | null,
  photo_updated_at?: string | null,
  user_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type contacts = {
  __typename: "contacts",
  id: number,
  employer_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  contacted?: number | null,
  last_contact_date?: string | null,
  created_at: string,
  updated_at: string,
};

export type CreateContactsInput = {
  id: number,
  employer_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  contacted?: number | null,
  last_contact_date?: string | null,
  created_at: string,
  updated_at: string,
};

export type UpdateContactsInput = {
  id: number,
  employer_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  contacted?: number | null,
  last_contact_date?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type delayed_jobs = {
  __typename: "delayed_jobs",
  id: number,
  priority?: number | null,
  attempts?: number | null,
  handler?: string | null,
  last_error?: string | null,
  run_at?: string | null,
  locked_at?: string | null,
  locked_by?: string | null,
  failed_at?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateDelayed_jobsInput = {
  id: number,
  priority?: number | null,
  attempts?: number | null,
  handler?: string | null,
  last_error?: string | null,
  run_at?: string | null,
  locked_at?: string | null,
  locked_by?: string | null,
  failed_at?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateDelayed_jobsInput = {
  id: number,
  priority?: number | null,
  attempts?: number | null,
  handler?: string | null,
  last_error?: string | null,
  run_at?: string | null,
  locked_at?: string | null,
  locked_by?: string | null,
  failed_at?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type educations = {
  __typename: "educations",
  id: number,
  school_name?: string | null,
  field_of_study?: string | null,
  degree?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  notes?: string | null,
  applicant_id?: number | null,
  unique_identifier?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateEducationsInput = {
  id: number,
  school_name?: string | null,
  field_of_study?: string | null,
  degree?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  notes?: string | null,
  applicant_id?: number | null,
  unique_identifier?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateEducationsInput = {
  id: number,
  school_name?: string | null,
  field_of_study?: string | null,
  degree?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  notes?: string | null,
  applicant_id?: number | null,
  unique_identifier?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type employer_stats = {
  __typename: "employer_stats",
  id: number,
  employer_id?: number | null,
  profile_views?: number | null,
  job_views?: number | null,
  banner_clicks?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  job_applicants?: number | null,
};

export type CreateEmployer_statsInput = {
  id: number,
  employer_id?: number | null,
  profile_views?: number | null,
  job_views?: number | null,
  banner_clicks?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  job_applicants?: number | null,
};

export type UpdateEmployer_statsInput = {
  id: number,
  employer_id?: number | null,
  profile_views?: number | null,
  job_views?: number | null,
  banner_clicks?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  job_applicants?: number | null,
};

export type employers = {
  __typename: "employers",
  id: number,
  name?: string | null,
  profile?: string | null,
  comments?: string | null,
  website?: string | null,
  is_federal_employer?: number | null,
  referal_source?: string | null,
  max_recruiters: number,
  number_job_postings_remaining?: number | null,
  job_postings_expire_at?: string | null,
  number_resume_searches_remaining?: number | null,
  resume_search_expire_at?: string | null,
  number_trial_resume_searches_remaining?: number | null,
  trial_resume_search_expire_at?: string | null,
  is_replace_all_on_import?: number | null,
  is_notify_job_postings?: number | null,
  banner_option?: string | null,
  banner_option_start_at?: string | null,
  service_option?: string | null,
  service_option_start_at?: string | null,
  sales_person_id?: number | null,
  track_image_url?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  ticker_symbol?: string | null,
  twitter_handler?: string | null,
  video_url?: string | null,
  default_jobfair_profile?: string | null,
  default_hot_jobs?: string | null,
  default_job_keywords?: string | null,
  linkedin_url?: string | null,
};

export type CreateEmployersInput = {
  id: number,
  name?: string | null,
  profile?: string | null,
  comments?: string | null,
  website?: string | null,
  is_federal_employer?: number | null,
  referal_source?: string | null,
  max_recruiters: number,
  number_job_postings_remaining?: number | null,
  job_postings_expire_at?: string | null,
  number_resume_searches_remaining?: number | null,
  resume_search_expire_at?: string | null,
  number_trial_resume_searches_remaining?: number | null,
  trial_resume_search_expire_at?: string | null,
  is_replace_all_on_import?: number | null,
  is_notify_job_postings?: number | null,
  banner_option?: string | null,
  banner_option_start_at?: string | null,
  service_option?: string | null,
  service_option_start_at?: string | null,
  sales_person_id?: number | null,
  track_image_url?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  ticker_symbol?: string | null,
  twitter_handler?: string | null,
  video_url?: string | null,
  default_jobfair_profile?: string | null,
  default_hot_jobs?: string | null,
  default_job_keywords?: string | null,
  linkedin_url?: string | null,
};

export type UpdateEmployersInput = {
  id: number,
  name?: string | null,
  profile?: string | null,
  comments?: string | null,
  website?: string | null,
  is_federal_employer?: number | null,
  referal_source?: string | null,
  max_recruiters?: number | null,
  number_job_postings_remaining?: number | null,
  job_postings_expire_at?: string | null,
  number_resume_searches_remaining?: number | null,
  resume_search_expire_at?: string | null,
  number_trial_resume_searches_remaining?: number | null,
  trial_resume_search_expire_at?: string | null,
  is_replace_all_on_import?: number | null,
  is_notify_job_postings?: number | null,
  banner_option?: string | null,
  banner_option_start_at?: string | null,
  service_option?: string | null,
  service_option_start_at?: string | null,
  sales_person_id?: number | null,
  track_image_url?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  ticker_symbol?: string | null,
  twitter_handler?: string | null,
  video_url?: string | null,
  default_jobfair_profile?: string | null,
  default_hot_jobs?: string | null,
  default_job_keywords?: string | null,
  linkedin_url?: string | null,
};

export type forums = {
  __typename: "forums",
  id: number,
  name?: string | null,
  description?: string | null,
  last_post_date?: string | null,
  post_count?: number | null,
  created_at: string,
  updated_at: string,
};

export type CreateForumsInput = {
  id: number,
  name?: string | null,
  description?: string | null,
  last_post_date?: string | null,
  post_count?: number | null,
  created_at: string,
  updated_at: string,
};

export type UpdateForumsInput = {
  id: number,
  name?: string | null,
  description?: string | null,
  last_post_date?: string | null,
  post_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type inbox_entries = {
  __typename: "inbox_entries",
  id: number,
  user_id?: number | null,
  resource_id?: number | null,
  resource_type?: string | null,
  added_by?: string | null,
  status?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateInbox_entriesInput = {
  id: number,
  user_id?: number | null,
  resource_id?: number | null,
  resource_type?: string | null,
  added_by?: string | null,
  status?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateInbox_entriesInput = {
  id: number,
  user_id?: number | null,
  resource_id?: number | null,
  resource_type?: string | null,
  added_by?: string | null,
  status?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type invites = {
  __typename: "invites",
  id: number,
  jobfair_id?: number | null,
  booth_info_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  status?: string | null,
  company_name?: string | null,
};

export type CreateInvitesInput = {
  id: number,
  booth_info_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  status?: string | null,
  created_at: string,
  updated_at: string,
};

export type UpdateInvitesInput = {
  id: number,
  booth_info_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  status?: string | null,
};

export type job_applications = {
  __typename: "job_applications",
  id: number,
  applicant_id?: number | null,
  job_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateJob_applicationsInput = {
  id: number,
  applicant_id?: number | null,
  job_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateJob_applicationsInput = {
  id: number,
  applicant_id?: number | null,
  job_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type jobfairs = {
  __typename: "jobfairs",
  id: number,
  category?: string | null,
  date?: string | null,
  start_time?: string | null,
  end_time?: string | null,
  applicant_virtual_jf_help_url?: string | null,
  recruiter_virtual_jf_help_url?: string | null,
  daylight_savings?: string | null,
};

export type CreateJobfairsInput = {
  id: number,
  category?: string | null,
  sponsor?: string | null,
  date?: string | null,
  start_time?: string | null,
  end_time?: string | null,
  fees?: number | null,
  city?: string | null,
  location?: string | null,
  location_url?: string | null,
  external_registration_url?: string | null,
  recommended_hotel?: string | null,
  recommended_hotel_url?: string | null,
  security_clearance_required?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  applicant_registration_url?: string | null,
  applicant_external_registration_url?: string | null,
  ecareer_registration_url?: string | null,
  description?: string | null,
};

export type UpdateJobfairsInput = {
  id: number,
  category?: string | null,
  sponsor?: string | null,
  date?: string | null,
  start_time?: string | null,
  end_time?: string | null,
  fees?: number | null,
  city?: string | null,
  location?: string | null,
  location_url?: string | null,
  external_registration_url?: string | null,
  recommended_hotel?: string | null,
  recommended_hotel_url?: string | null,
  security_clearance_required?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  applicant_registration_url?: string | null,
  applicant_external_registration_url?: string | null,
  ecareer_registration_url?: string | null,
  description?: string | null,
};

export type jobs = {
  __typename: "jobs",
  id: number,
  status?: string | null,
  code?: string | null,
  title: string,
  description: string,
  input_method?: string | null,
  requirements?: string | null,
  expires_at?: string | null,
  company_name?: string | null,
  recruiter_id?: number | null,
  education_level?: string | null,
  experience_required?: number | null,
  payrate?: string | null,
  hr_website_url?: string | null,
  online_application_url?: string | null,
  security_clearance?: string | null,
  travel_requirements?: string | null,
  relocation_cost_paid?: string | null,
  show_company_profile: number,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateJobsInput = {
  id: number,
  status?: string | null,
  code?: string | null,
  title: string,
  description: string,
  input_method?: string | null,
  requirements?: string | null,
  expires_at?: string | null,
  company_name?: string | null,
  recruiter_id?: number | null,
  education_level?: string | null,
  experience_required?: number | null,
  payrate?: string | null,
  hr_website_url?: string | null,
  online_application_url?: string | null,
  security_clearance?: string | null,
  travel_requirements?: string | null,
  relocation_cost_paid?: string | null,
  show_company_profile: number,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateJobsInput = {
  id: number,
  status?: string | null,
  code?: string | null,
  title?: string | null,
  description?: string | null,
  input_method?: string | null,
  requirements?: string | null,
  expires_at?: string | null,
  company_name?: string | null,
  recruiter_id?: number | null,
  education_level?: string | null,
  experience_required?: number | null,
  payrate?: string | null,
  hr_website_url?: string | null,
  online_application_url?: string | null,
  security_clearance?: string | null,
  travel_requirements?: string | null,
  relocation_cost_paid?: string | null,
  show_company_profile?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type messages = {
  __typename: "messages",
  id: number,
  category?: string | null,
  body?: string | null,
  action?: string | null,
  action_url?: string | null,
  recipient_id?: number | null,
  recipient_type?: string | null,
  sender_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateMessagesInput = {
  id: number,
  category?: string | null,
  body?: string | null,
  action?: string | null,
  action_url?: string | null,
  recipient_id?: number | null,
  recipient_type?: string | null,
  sender_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateMessagesInput = {
  id: number,
  category?: string | null,
  body?: string | null,
  action?: string | null,
  action_url?: string | null,
  recipient_id?: number | null,
  recipient_type?: string | null,
  sender_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type ofccp = {
  __typename: "ofccp",
  id: number,
  query_string?: string | null,
  job_code?: string | null,
  job_title?: string | null,
  job_description?: string | null,
  applicant_name?: string | null,
  applicant_modification_date?: string | null,
  applicant_ethnicity?: string | null,
  applicant_gender?: string | null,
  resume_post_date?: string | null,
  resume?: string | null,
  employer_name?: string | null,
  recruiter_name?: string | null,
  recruiter_email?: string | null,
  employer_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateOfccpInput = {
  id: number,
  query_string?: string | null,
  job_code?: string | null,
  job_title?: string | null,
  job_description?: string | null,
  applicant_name?: string | null,
  applicant_modification_date?: string | null,
  applicant_ethnicity?: string | null,
  applicant_gender?: string | null,
  resume_post_date?: string | null,
  resume?: string | null,
  employer_name?: string | null,
  recruiter_name?: string | null,
  recruiter_email?: string | null,
  employer_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateOfccpInput = {
  id: number,
  query_string?: string | null,
  job_code?: string | null,
  job_title?: string | null,
  job_description?: string | null,
  applicant_name?: string | null,
  applicant_modification_date?: string | null,
  applicant_ethnicity?: string | null,
  applicant_gender?: string | null,
  resume_post_date?: string | null,
  resume?: string | null,
  employer_name?: string | null,
  recruiter_name?: string | null,
  recruiter_email?: string | null,
  employer_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type positions = {
  __typename: "positions",
  id: number,
  employmenable_id?: number | null,
  employmenable_type?: string | null,
  company?: string | null,
  industry?: string | null,
  title?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  summary?: string | null,
  unique_identifier?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  ticker?: string | null,
};

export type CreatePositionsInput = {
  id: number,
  employmenable_id?: number | null,
  employmenable_type?: string | null,
  company?: string | null,
  industry?: string | null,
  title?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  summary?: string | null,
  unique_identifier?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  ticker?: string | null,
};

export type UpdatePositionsInput = {
  id: number,
  employmenable_id?: number | null,
  employmenable_type?: string | null,
  company?: string | null,
  industry?: string | null,
  title?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  summary?: string | null,
  unique_identifier?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  ticker?: string | null,
};

export type posts = {
  __typename: "posts",
  id: number,
  title?: string | null,
  body?: string | null,
  forum_id?: number | null,
  user_id?: number | null,
  parent_id?: number | null,
  created_at: string,
  updated_at: string,
};

export type CreatePostsInput = {
  id: number,
  title?: string | null,
  body?: string | null,
  forum_id?: number | null,
  user_id?: number | null,
  parent_id?: number | null,
  created_at: string,
  updated_at: string,
};

export type UpdatePostsInput = {
  id: number,
  title?: string | null,
  body?: string | null,
  forum_id?: number | null,
  user_id?: number | null,
  parent_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type recruiter_stats = {
  __typename: "recruiter_stats",
  id: number,
  recruiter_id?: number | null,
  resume_views?: number | null,
  resume_searches?: number | null,
  login_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateRecruiter_statsInput = {
  id: number,
  recruiter_id?: number | null,
  resume_views?: number | null,
  resume_searches?: number | null,
  login_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateRecruiter_statsInput = {
  id: number,
  recruiter_id?: number | null,
  resume_views?: number | null,
  resume_searches?: number | null,
  login_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type registrations = {
  __typename: "registrations",
  id: number,
  attendant_type?: string | null,
  attendant_id?: number | null,
  jobfair_id?: number | null,
  lunches_required?: number | null,
  available_positions?: string | null,
  include_in_employer_directory?: number | null,
  outlet_required?: number | null,
  resume_search_access?: number | null,
  paid?: number | null,
  banner_company_name?: string | null,
  fax?: string | null,
  url?: string | null,
  security_clearance?: string | null,
  attending?: number | null,
  enable_search?: number | null,
  ad_type?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  company_profile?: string | null,
  wp_option_1?: number | null,
  wp_option_2?: number | null,
  job_keywords?: string | null,
  referral?: string | null,
};

export type CreateRegistrationsInput = {
  id: number,
  attendant_type?: string | null,
  attendant_id?: number | null,
  jobfair_id?: number | null,
  lunches_required?: number | null,
  available_positions?: string | null,
  include_in_employer_directory?: number | null,
  outlet_required?: number | null,
  resume_search_access?: number | null,
  paid?: number | null,
  banner_company_name?: string | null,
  fax?: string | null,
  url?: string | null,
  security_clearance?: string | null,
  attending?: number | null,
  enable_search?: number | null,
  ad_type?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  company_profile?: string | null,
  wp_option_1?: number | null,
  wp_option_2?: number | null,
  job_keywords?: string | null,
  referral?: string | null,
};

export type UpdateRegistrationsInput = {
  id: number,
  attendant_type?: string | null,
  attendant_id?: number | null,
  jobfair_id?: number | null,
  lunches_required?: number | null,
  available_positions?: string | null,
  include_in_employer_directory?: number | null,
  outlet_required?: number | null,
  resume_search_access?: number | null,
  paid?: number | null,
  banner_company_name?: string | null,
  fax?: string | null,
  url?: string | null,
  security_clearance?: string | null,
  attending?: number | null,
  enable_search?: number | null,
  ad_type?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  company_profile?: string | null,
  wp_option_1?: number | null,
  wp_option_2?: number | null,
  job_keywords?: string | null,
  referral?: string | null,
};

export type resumes = {
  __typename: "resumes",
  id: number,
  applicant_id?: number | null,
  attached_resume_file_name?: string | null,
  attached_resume_content_type?: string | null,
};

export type CreateResumesInput = {
  id: number,
  applicant_id?: number | null,
  attached_resume_file_name?: string | null,
  attached_resume_content_type?: string | null,
};

export type UpdateResumesInput = {
  id: number,
  applicant_id?: number | null,
  attached_resume_file_name?: string | null,
  attached_resume_content_type?: string | null,
};

export type schema_migrations = {
  __typename: "schema_migrations",
  version: string,
};

export type CreateSchema_migrationsInput = {
  version: string,
};

export type UpdateSchema_migrationsInput = {
  version: string,
};

export type seminars = {
  __typename: "seminars",
  id: number,
  description?: string | null,
  duration?: string | null,
  time?: string | null,
  jobfair_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type CreateSeminarsInput = {
  id: number,
  description?: string | null,
  duration?: string | null,
  time?: string | null,
  jobfair_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type UpdateSeminarsInput = {
  id: number,
  description?: string | null,
  duration?: string | null,
  time?: string | null,
  jobfair_id?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type sessions = {
  __typename: "sessions",
  id: number,
  session_id: string,
  data?: string | null,
  created_at: string,
  updated_at: string,
};

export type CreateSessionsInput = {
  id: number,
  session_id: string,
  data?: string | null,
  created_at: string,
  updated_at: string,
};

export type UpdateSessionsInput = {
  id: number,
  session_id?: string | null,
  data?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type usage_logs = {
  __typename: "usage_logs",
  id: number,
  user_id?: number | null,
  log_type?: string | null,
  body?: string | null,
  created_at: string,
  updated_at: string,
};

export type CreateUsage_logsInput = {
  id: number,
  user_id?: number | null,
  log_type?: string | null,
  body?: string | null,
  created_at: string,
  updated_at: string,
};

export type UpdateUsage_logsInput = {
  id: number,
  user_id?: number | null,
  log_type?: string | null,
  body?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
};

export type users = {
  __typename: "users",
  id: number,
  type?: string | null,
  roles?: string | null,
  title?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  job_title?: string | null,
  greeting?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
  summary?: string | null,
};

export type CreateUsersInput = {
  id: number,
  type?: string | null,
  roles?: string | null,
  title?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  job_title?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
};

export type UpdateUsersInput = {
  id: number,
  type?: string | null,
  roles?: string | null,
  title?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  job_title?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
};

export type video_interviews = {
  __typename: "video_interviews",
  id: number,
  recruiter_id?: number | null,
  interview_date?: string | null,
  start_time?: string | null,
  slot_duration?: number | null,
  slot_interval?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  email_count?: number | null,
  signal_session?: string | null,
};

export type CreateVideo_interviewsInput = {
  id: number,
  recruiter_id?: number | null,
  interview_date?: string | null,
  start_time?: string | null,
  slot_duration?: number | null,
  slot_interval?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  email_count?: number | null,
  signal_session?: string | null,
};

export type UpdateVideo_interviewsInput = {
  id: number,
  recruiter_id?: number | null,
  interview_date?: string | null,
  start_time?: string | null,
  slot_duration?: number | null,
  slot_interval?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  email_count?: number | null,
  signal_session?: string | null,
};

export type video_sessions = {
  __typename: "video_sessions",
  id: number,
  video_interview_id?: number | null,
  applicant_id?: number | null,
  session_id?: string | null,
  start_time?: string | null,
  attendant_token?: string | null,
  recruiter_token?: string | null,
  attendant_email_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  session_order?: number | null,
  candidate_rating?: number | null,
};

export type CreateVideo_sessionsInput = {
  id: number,
  video_interview_id?: number | null,
  applicant_id?: number | null,
  session_id?: string | null,
  start_time?: string | null,
  attendant_token?: string | null,
  recruiter_token?: string | null,
  attendant_email_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  session_order?: number | null,
  candidate_rating?: number | null,
};

export type UpdateVideo_sessionsInput = {
  id: number,
  video_interview_id?: number | null,
  applicant_id?: number | null,
  session_id?: string | null,
  start_time?: string | null,
  attendant_token?: string | null,
  recruiter_token?: string | null,
  attendant_email_count?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  session_order?: number | null,
  candidate_rating?: number | null,
};

export type CreateTopicInput = {
  id?: string | null,
  key: string,
  model?: string | null,
  subscribers?: Array< string > | null,
};

export type ModelTopicConditionInput = {
  key?: ModelStringInput | null,
  model?: ModelStringInput | null,
  subscribers?: ModelStringInput | null,
  and?: Array< ModelTopicConditionInput | null > | null,
  or?: Array< ModelTopicConditionInput | null > | null,
  not?: ModelTopicConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Topic = {
  __typename: "Topic",
  id: string,
  key: string,
  model?: string | null,
  subscribers?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTopicInput = {
  id: string,
  key?: string | null,
  model?: string | null,
  subscribers?: Array< string > | null,
};

export type DeleteTopicInput = {
  id: string,
};

export type CreateVJFPUserInput = {
  id: number,
  firstName: string,
  lastName?: string | null,
  emailId: string,
  role: string,
  isAvailableForChat?: boolean | null,
  title?: string | null,
  job_title?: string | null,
  address?: string | null,
  designation?: string | null,
  greeting?: string | null,
  avatar?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
  summary?: string | null,
  presenceStatus?: UserStatus | null,
  lastConnectedTimeStamp?: number | null,
  lastDisconnectedTimeStamp?: number | null,
  lastPresenceJobFairId?: string | null,
};

export enum UserStatus {
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  AWAY = "AWAY",
  DISCONNECTED = "DISCONNECTED",
  NEXT_IN_QUEUE = "NEXT_IN_QUEUE",
  IN_INTERVIEW = "IN_INTERVIEW",
  BUSY = "BUSY",
}


export type ModelVJFPUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  emailId?: ModelStringInput | null,
  role?: ModelStringInput | null,
  isAvailableForChat?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  job_title?: ModelStringInput | null,
  address?: ModelStringInput | null,
  designation?: ModelStringInput | null,
  greeting?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  ethnicity?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  availability_date?: ModelStringInput | null,
  branch_of_service?: ModelStringInput | null,
  education_level?: ModelStringInput | null,
  geographic_preference?: ModelStringInput | null,
  security_clearance?: ModelStringInput | null,
  type_of_applicant?: ModelStringInput | null,
  military_status?: ModelStringInput | null,
  us_citizen?: ModelBooleanInput | null,
  willing_to_relocate?: ModelBooleanInput | null,
  employer_id?: ModelIntInput | null,
  picture_url?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  presenceStatus?: ModelUserStatusInput | null,
  lastConnectedTimeStamp?: ModelIntInput | null,
  lastDisconnectedTimeStamp?: ModelIntInput | null,
  lastPresenceJobFairId?: ModelStringInput | null,
  and?: Array< ModelVJFPUserConditionInput | null > | null,
  or?: Array< ModelVJFPUserConditionInput | null > | null,
  not?: ModelVJFPUserConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type VJFPUser = {
  __typename: "VJFPUser",
  id: number,
  firstName: string,
  lastName?: string | null,
  emailId: string,
  roles: [string],
  isAvailableForChat?: boolean | null,
  title?: string | null,
  job_title?: string | null,
  address?: string | null,
  designation?: string | null,
  greeting?: string | null,
  avatar?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
  summary?: string | null,
  presenceStatus?: UserStatus | null,
  lastConnectedTimeStamp?: number | null,
  lastDisconnectedTimeStamp?: number | null,
  lastPresenceJobFairId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVJFPUserInput = {
  id: number,
  firstName?: string | null,
  lastName?: string | null,
  emailId?: string | null,
  roles?: [string] | null,
  isAvailableForChat?: boolean | null,
  title?: string | null,
  job_title?: string | null,
  address?: string | null,
  designation?: string | null,
  greeting?: string | null,
  avatar?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
  summary?: string | null,
  presenceStatus?: UserStatus | null,
  lastConnectedTimeStamp?: number | null,
  lastDisconnectedTimeStamp?: number | null,
  lastPresenceJobFairId?: string | null,
};

export type DeleteVJFPUserInput = {
  id: number,
};

export type CreateReviewUserInput = {
  id?: string | null,
  jobFairId: string,
  boothID: string,
  boothName: string,
  vjfpUserId: number,
  rating: number,
  notes?: string | null,
  ratedBy?: number | null,
  interviewSessionId: string,
};

export type ModelReviewUserConditionInput = {
  jobFairId?: ModelIDInput | null,
  boothID?: ModelIDInput | null,
  boothName?: ModelStringInput | null,
  vjfpUserId?: ModelIntInput | null,
  rating?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  ratedBy?: ModelIntInput | null,
  interviewSessionId?: ModelIDInput | null,
  and?: Array< ModelReviewUserConditionInput | null > | null,
  or?: Array< ModelReviewUserConditionInput | null > | null,
  not?: ModelReviewUserConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ReviewUser = {
  __typename: "ReviewUser",
  id: string,
  jobFairId: string,
  boothID: string,
  boothName: string,
  vjfpUserId: number,
  rating: number,
  notes?: string | null,
  ratedBy?: number | null,
  interviewSessionId: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReviewUserInput = {
  id: string,
  jobFairId?: string | null,
  boothID?: string | null,
  boothName?: string | null,
  vjfpUserId?: number | null,
  rating?: number | null,
  notes?: string | null,
  ratedBy?: number | null,
  interviewSessionId?: string | null,
};

export type DeleteReviewUserInput = {
  id: string,
};

export type CreateResumeInput = {
  id?: string | null,
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
  fileName: string,
  resumeS3Key: string,
  notes?: string | null,
  jobTitle?: string | null,
};

export type ModelResumeConditionInput = {
  jobFairId?: ModelStringInput | null,
  boothId?: ModelIDInput | null,
  jobSeekerId?: ModelIntInput | null,
  fileName?: ModelStringInput | null,
  resumeS3Key?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  and?: Array< ModelResumeConditionInput | null > | null,
  or?: Array< ModelResumeConditionInput | null > | null,
  not?: ModelResumeConditionInput | null,
};

export type Resume = {
  __typename: "Resume",
  id: string,
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
  fileName: string,
  resumeS3Key: string,
  notes?: string | null,
  jobTitle?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateResumeInput = {
  id: string,
  jobFairId?: string | null,
  boothId?: string | null,
  jobSeekerId?: number | null,
  fileName?: string | null,
  resumeS3Key?: string | null,
  notes?: string | null,
  jobTitle?: string | null,
};

export type DeleteResumeInput = {
  id: string,
};

export type CreateBoothInput = {
  id?: string | null,
  boothsInfoId: number,
  jobFairId: string,
  cgoCompanyId?: string | null,
  name: string,
  isFeatured: boolean,
  chatDuration: number,
  companyImageUrl: string,
  minimumScore: number,
  description?: string | null,
  hotJobSummary?: string | null,
  createdBy: string,
  recruiters?: Array< number | null > | null,
  sortOrder?: number | null,
};

export type ModelBoothConditionInput = {
  boothsInfoId?: ModelIntInput | null,
  jobFairId?: ModelStringInput | null,
  cgoCompanyId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isFeatured?: ModelBooleanInput | null,
  chatDuration?: ModelIntInput | null,
  companyImageUrl?: ModelStringInput | null,
  minimumScore?: ModelIntInput | null,
  description?: ModelStringInput | null,
  hotJobSummary?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  recruiters?: ModelIntInput | null,
  sortOrder?: ModelIntInput | null,
  and?: Array< ModelBoothConditionInput | null > | null,
  or?: Array< ModelBoothConditionInput | null > | null,
  not?: ModelBoothConditionInput | null,
};



export interface GroupType {
  id: string;
  name: string;
  chatDuration: number;
  minimumScore: number;
  description?: string | null;
  hotJobSummary?: string | null;
  recruiters?: Array<number | null> | null;
  sortOrder?: number | null;

  parentBoothId?: string;
}

export type Booth = {
  __typename: "Booth",
  id: string,
  boothsInfoId: number,
  jobFairId: string,
  cgoCompanyId?: string | null,
  name: string,
  isFeatured: boolean,
  chatDuration: number,
  companyImageUrl: string,
  minimumScore: number,
  description?: string | null,
  hotJobSummary?: string | null,
  createdBy: string,
  recruiters?: Array< number | null > | null,
  sortOrder?: number | null,
  job_seekers?: ModelQueueUserConnection | null,
  QueueCount?: string,
  subBooths?: GroupType[]
  createdAt: string,
  updatedAt: string,
  parentBoothId?: string;
};

export type ModelQueueUserConnection = {
  __typename: "ModelQueueUserConnection",
  items?:  Array<QueueUser | null > | null,
  nextToken?: string | null,
  scannedCount?: number | null,
  count?: number | null,
};

export type QueueUser = {
  __typename: "QueueUser",
  id: string,
  vjfpUserId: number,
  jobFairId: string,
  boothId: string,
  priority: number,
  score?: number | null,
  createdAt: string,
  nextInQueueSetBy?: number | null,
  interviewer?: number | null,
  version: number,
  status?: QueueUserStatus | null,
  maxScore: number,
  isInvited?: boolean | null,
  updatedAt: string,
};

export enum QueueUserStatus {
  IN_QUEUE = "IN_QUEUE",
  NEXT_IN_QUEUE = "NEXT_IN_QUEUE",
  IN_INTERVIEW = "IN_INTERVIEW",
  BUSY = "BUSY",
}


export type UpdateBoothInput = {
  id: string,
  boothsInfoId?: number | null,
  jobFairId?: string | null,
  cgoCompanyId?: string | null,
  name?: string | null,
  isFeatured?: boolean | null,
  chatDuration?: number | null,
  companyImageUrl?: string | null,
  minimumScore?: number | null,
  description?: string | null,
  hotJobSummary?: string | null,
  createdBy?: string | null,
  recruiters?: Array< number | null > | null,
  sortOrder?: number | null,
  subBooths?: GroupType[]
};

export type DeleteBoothInput = {
  id: string,
};

export type CreateQueueUserInput = {
  id?: string | null,
  vjfpUserId: number,
  jobFairId: string,
  boothId: string,
  priority: number,
  score?: number | null,
  createdAt?: string | null,
  nextInQueueSetBy?: number | null,
  interviewer?: number | null,
  status?: QueueUserStatus | null,
  maxScore: number,
  isInvited?: boolean | null,
};

export type ModelQueueUserConditionInput = {
  vjfpUserId?: ModelIntInput | null,
  jobFairId?: ModelStringInput | null,
  score?: ModelIntInput | null,
  nextInQueueSetBy?: ModelIntInput | null,
  interviewer?: ModelIntInput | null,
  version?: ModelIntInput | null,
  status?: ModelQueueUserStatusInput | null,
  maxScore?: ModelIntInput | null,
  isInvited?: ModelBooleanInput | null,
  and?: Array< ModelQueueUserConditionInput | null > | null,
  or?: Array< ModelQueueUserConditionInput | null > | null,
  not?: ModelQueueUserConditionInput | null,
};

export type ModelQueueUserStatusInput = {
  eq?: QueueUserStatus | null,
  ne?: QueueUserStatus | null,
};

export type UpdateQueueUserInput = {
  id?: string | null,
  vjfpUserId?: number | null,
  jobFairId?: string | null,
  boothId: string,
  priority: number,
  score?: number | null,
  createdAt: string,
  nextInQueueSetBy?: number | null,
  interviewer?: number | null,
  version?: number | null,
  status?: QueueUserStatus | null,
  maxScore?: number | null,
  isInvited?: boolean | null,
  expectedVersion: number,
};

export type DeleteQueueUserInput = {
  boothId: string,
  priority: number,
  createdAt: string,
  expectedVersion: number,
};

export type CreateQuestionsInput = {
  jobFairId: string,
  boothId: string,
  questions?: Array< QuestionInput | null > | null,
};

export type QuestionInput = {
  id: string,
  name: string,
  rank: number,
};

export type ModelQuestionsConditionInput = {
  and?: Array< ModelQuestionsConditionInput | null > | null,
  or?: Array< ModelQuestionsConditionInput | null > | null,
  not?: ModelQuestionsConditionInput | null,
};

export type Questions = {
  __typename: "Questions",
  jobFairId: string,
  boothId: string,
  questions?:  Array<Question | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type Question = {
  __typename: "Question",
  id: string,
  name: string,
  rank: number,
};

export type UpdateQuestionsInput = {
  jobFairId: string,
  boothId: string,
  questions?: Array< QuestionInput | null > | null,
};

export type DeleteQuestionsInput = {
  jobFairId: string,
  boothId: string,
};

export type CreateQuestionAnswersInput = {
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
  answers?: Array< QuestionAnswerInput | null > | null,
};

export type QuestionAnswerInput = {
  id: string,
  QuestionId: string,
  name: string,
  rank: number,
  answer: boolean,
};

export type ModelQuestionAnswersConditionInput = {
  and?: Array< ModelQuestionAnswersConditionInput | null > | null,
  or?: Array< ModelQuestionAnswersConditionInput | null > | null,
  not?: ModelQuestionAnswersConditionInput | null,
};

export type QuestionAnswers = {
  __typename: "QuestionAnswers",
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
  answers?:  Array<QuestionAnswer | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type QuestionAnswer = {
  __typename: "QuestionAnswer",
  id: string,
  QuestionId: string,
  name: string,
  rank: number,
  answer?: boolean,
};

export type UpdateQuestionAnswersInput = {
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
  answers?: Array< QuestionAnswerInput | null > | null,
};

export type DeleteQuestionAnswersInput = {
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
};

export type CreateMessageInput = {
  id?: string | null,
  topicId?: string | null,
  message: string,
  createdBy: number,
};

export type ModelMessageConditionInput = {
  topicKey?: ModelStringInput | null,
  message?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  topicKey?: string | null,
  message: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMessageInput = {
  id: string,
  topicKey?: string | null,
  message?: string | null,
  createdBy?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateMessageReceiptsInput = {
  id?: string | null,
  jobFairId?: string | null,
  messageId: string,
  recipientId: number,
  read: boolean,
};

export type ModelMessageReceiptsConditionInput = {
  jobFairId?: ModelStringInput | null,
  messageId?: ModelIDInput | null,
  recipientId?: ModelIntInput | null,
  read?: ModelBooleanInput | null,
  and?: Array< ModelMessageReceiptsConditionInput | null > | null,
  or?: Array< ModelMessageReceiptsConditionInput | null > | null,
  not?: ModelMessageReceiptsConditionInput | null,
};

export type MessageReceipts = {
  __typename: "MessageReceipts",
  id: string,
  jobFairId?: string | null,
  messageId: string,
  message: Message,
  recipientId: number,
  read: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMessageReceiptsInput = {
  id: string,
  jobFairId?: string | null,
  messageId?: string | null,
  recipientId?: number | null,
  read?: boolean | null,
};

export type DeleteMessageReceiptsInput = {
  id: string,
};

export type CreateInterviewSessionInput = {
  id?: string | null,
  boothId: string,
  jobFairId: string,
  status: VideoSessionStatus,
  recruiterId: number,
  candidateId?: number | null,
  queueUserId: string,
  durationAdded: number,
  chatDuration?: number | null,
  endedBy?: number | null,
  chatTranscript?: string | null,
  recruiterZoomId?: number | null,
  jobSeekerZoomId?: number | null,
  candidateScore?: number | null,
  candidateMaxScore?: number | null,
  endReason?: EndReason | null,
  interviewStartedAt?: number | null,
};

export enum VideoSessionStatus {
  AWAITING = "AWAITING",
  IN_PROGRESS = "IN_PROGRESS",
  ENDED = "ENDED",
  ENDING = "ENDING"
}


export enum EndReason {
  TIMED_OUT = "TIMED_OUT",
  DECLINED = "DECLINED",
  ENDED = "ENDED",
}


export type ModelInterviewSessionConditionInput = {
  boothId?: ModelIDInput | null,
  jobFairId?: ModelStringInput | null,
  status?: ModelVideoSessionStatusInput | null,
  recruiterId?: ModelIntInput | null,
  candidateId?: ModelIntInput | null,
  queueUserId?: ModelIDInput | null,
  durationAdded?: ModelIntInput | null,
  chatDuration?: ModelIntInput | null,
  endedBy?: ModelIntInput | null,
  chatTranscript?: ModelStringInput | null,
  recruiterZoomId?: ModelIntInput | null,
  jobSeekerZoomId?: ModelIntInput | null,
  candidateScore?: ModelIntInput | null,
  candidateMaxScore?: ModelIntInput | null,
  endReason?: ModelEndReasonInput | null,
  interviewStartedAt?: ModelIntInput | null,
  and?: Array< ModelInterviewSessionConditionInput | null > | null,
  or?: Array< ModelInterviewSessionConditionInput | null > | null,
  not?: ModelInterviewSessionConditionInput | null,
};

export type ModelVideoSessionStatusInput = {
  eq?: VideoSessionStatus | null,
  ne?: VideoSessionStatus | null,
};

export type ModelEndReasonInput = {
  eq?: EndReason | null,
  ne?: EndReason | null,
};

export type InterviewSession = {
  __typename: "InterviewSession",
  id: string,
  boothId: string,
  jobFairId: string,
  status: VideoSessionStatus,
  recruiterId: number,
  candidateId?: number | null,
  queueUserId: string,
  durationAdded: number,
  chatDuration?: number | null,
  endedBy?: number | null,
  chatTranscript?: string | null,
  recruiterZoomId?: number | null,
  jobSeekerZoomId?: number | null,
  candidateScore?: number | null,
  candidateMaxScore?: number | null,
  endReason?: EndReason | null,
  interviewStartedAt?: number | null,
  createdAt: string,
  updatedAt: string,
  roomId: string
};

export interface PaginatedRecord<T>  {
items: T,
lastEvaluatedKey: any
}

export type UpdateInterviewSessionInput = {
  id: string,
  boothId?: string | null,
  jobFairId?: string | null,
  status?: VideoSessionStatus | null,
  recruiterId?: number | null,
  candidateId?: number | null,
  queueUserId?: string | null,
  durationAdded?: number | null,
  chatDuration?: number | null,
  endedBy?: number | null,
  chatTranscript?: string | null,
  recruiterZoomId?: number | null,
  jobSeekerZoomId?: number | null,
  candidateScore?: number | null,
  candidateMaxScore?: number | null,
  endReason?: EndReason | null,
  interviewStartedAt?: number | null,
};

export type DeleteInterviewSessionInput = {
  id: string,
};

export type CreateBoothVisitorInput = {
  id?: string | null,
  boothId: string,
  jobFairId: string,
  vjfpUserId: number,
};

export type ModelBoothVisitorConditionInput = {
  boothId?: ModelIDInput | null,
  jobFairId?: ModelStringInput | null,
  vjfpUserId?: ModelIntInput | null,
  and?: Array< ModelBoothVisitorConditionInput | null > | null,
  or?: Array< ModelBoothVisitorConditionInput | null > | null,
  not?: ModelBoothVisitorConditionInput | null,
};

export type BoothVisitor = {
  __typename: "BoothVisitor",
  id: string,
  boothId: string,
  jobFairId: string,
  vjfpUserId: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBoothVisitorInput = {
  id: string,
  boothId?: string | null,
  jobFairId?: string | null,
  vjfpUserId?: number | null,
};

export type DeleteBoothVisitorInput = {
  id: string,
};

export type ModelTopicFilterInput = {
  key?: ModelStringInput | null,
  model?: ModelStringInput | null,
  subscribers?: ModelStringInput | null,
  and?: Array< ModelTopicFilterInput | null > | null,
  or?: Array< ModelTopicFilterInput | null > | null,
  not?: ModelTopicFilterInput | null,
};

export type ModelTopicConnection = {
  __typename: "ModelTopicConnection",
  items:  Array<Topic | null >,
  nextToken?: string | null,
};

export type ModelVJFPUserFilterInput = {
  id?: ModelIntInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  emailId?: ModelStringInput | null,
  role?: ModelStringInput | null,
  isAvailableForChat?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  job_title?: ModelStringInput | null,
  address?: ModelStringInput | null,
  designation?: ModelStringInput | null,
  greeting?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  ethnicity?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  availability_date?: ModelStringInput | null,
  branch_of_service?: ModelStringInput | null,
  education_level?: ModelStringInput | null,
  geographic_preference?: ModelStringInput | null,
  security_clearance?: ModelStringInput | null,
  type_of_applicant?: ModelStringInput | null,
  military_status?: ModelStringInput | null,
  us_citizen?: ModelBooleanInput | null,
  willing_to_relocate?: ModelBooleanInput | null,
  employer_id?: ModelIntInput | null,
  picture_url?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  presenceStatus?: ModelUserStatusInput | null,
  lastConnectedTimeStamp?: ModelIntInput | null,
  lastDisconnectedTimeStamp?: ModelIntInput | null,
  lastPresenceJobFairId?: ModelStringInput | null,
  and?: Array< ModelVJFPUserFilterInput | null > | null,
  or?: Array< ModelVJFPUserFilterInput | null > | null,
  not?: ModelVJFPUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelVJFPUserConnection = {
  __typename: "ModelVJFPUserConnection",
  items:  Array<VJFPUser | null >,
  nextToken?: string | null,
};

export type ModelReviewUserFilterInput = {
  id?: ModelIDInput | null,
  jobFairId?: ModelIDInput | null,
  boothID?: ModelIDInput | null,
  boothName?: ModelStringInput | null,
  vjfpUserId?: ModelIntInput | null,
  rating?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  ratedBy?: ModelIntInput | null,
  interviewSessionId?: ModelIDInput | null,
  and?: Array< ModelReviewUserFilterInput | null > | null,
  or?: Array< ModelReviewUserFilterInput | null > | null,
  not?: ModelReviewUserFilterInput | null,
};

export type ModelReviewUserConnection = {
  __typename: "ModelReviewUserConnection",
  items:  Array<ReviewUser | null >,
  nextToken?: string | null,
};

export type ModelResumeFilterInput = {
  id?: ModelIDInput | null,
  jobFairId?: ModelStringInput | null,
  boothId?: ModelIDInput | null,
  jobSeekerId?: ModelIntInput | null,
  fileName?: ModelStringInput | null,
  resumeS3Key?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  and?: Array< ModelResumeFilterInput | null > | null,
  or?: Array< ModelResumeFilterInput | null > | null,
  not?: ModelResumeFilterInput | null,
};

export type ModelResumeConnection = {
  __typename: "ModelResumeConnection",
  items:  Array<Resume | null >,
  nextToken?: string | null,
};

export type ModelBoothFilterInput = {
  id?: ModelIDInput | null,
  boothsInfoId?: ModelIntInput | null,
  jobFairId?: ModelStringInput | null,
  cgoCompanyId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isFeatured?: ModelBooleanInput | null,
  chatDuration?: ModelIntInput | null,
  companyImageUrl?: ModelStringInput | null,
  minimumScore?: ModelIntInput | null,
  description?: ModelStringInput | null,
  hotJobSummary?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  recruiters?: ModelIntInput | null,
  sortOrder?: ModelIntInput | null,
  and?: Array< ModelBoothFilterInput | null > | null,
  or?: Array< ModelBoothFilterInput | null > | null,
  not?: ModelBoothFilterInput | null,
};

export type ModelBoothConnection = {
  __typename: "ModelBoothConnection",
  items:  Array<Booth | null >,
  nextToken?: string | null,
};

export type ModelQueueUserPrimaryCompositeKeyConditionInput = {
  eq?: ModelQueueUserPrimaryCompositeKeyInput | null,
  le?: ModelQueueUserPrimaryCompositeKeyInput | null,
  lt?: ModelQueueUserPrimaryCompositeKeyInput | null,
  ge?: ModelQueueUserPrimaryCompositeKeyInput | null,
  gt?: ModelQueueUserPrimaryCompositeKeyInput | null,
  between?: Array< ModelQueueUserPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelQueueUserPrimaryCompositeKeyInput | null,
};

export type ModelQueueUserPrimaryCompositeKeyInput = {
  priority?: number | null,
  createdAt?: string | null,
};

export type ModelQueueUserFilterInput = {
  id?: ModelIDInput | null,
  vjfpUserId?: ModelIntInput | null,
  jobFairId?: ModelStringInput | null,
  boothId?: ModelIDInput | null,
  priority?: ModelIntInput | null,
  score?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  nextInQueueSetBy?: ModelIntInput | null,
  interviewer?: ModelIntInput | null,
  version?: ModelIntInput | null,
  status?: ModelQueueUserStatusInput | null,
  maxScore?: ModelIntInput | null,
  isInvited?: ModelBooleanInput | null,
  and?: Array< ModelQueueUserFilterInput | null > | null,
  or?: Array< ModelQueueUserFilterInput | null > | null,
  not?: ModelQueueUserFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelQuestionsFilterInput = {
  jobFairId?: ModelStringInput | null,
  boothId?: ModelIDInput | null,
  and?: Array< ModelQuestionsFilterInput | null > | null,
  or?: Array< ModelQuestionsFilterInput | null > | null,
  not?: ModelQuestionsFilterInput | null,
};

export type ModelQuestionsConnection = {
  __typename: "ModelQuestionsConnection",
  items:  Array<Questions | null >,
  nextToken?: string | null,
};

export type ModelQuestionAnswersPrimaryCompositeKeyConditionInput = {
  eq?: ModelQuestionAnswersPrimaryCompositeKeyInput | null,
  le?: ModelQuestionAnswersPrimaryCompositeKeyInput | null,
  lt?: ModelQuestionAnswersPrimaryCompositeKeyInput | null,
  ge?: ModelQuestionAnswersPrimaryCompositeKeyInput | null,
  gt?: ModelQuestionAnswersPrimaryCompositeKeyInput | null,
  between?: Array< ModelQuestionAnswersPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelQuestionAnswersPrimaryCompositeKeyInput | null,
};

export type ModelQuestionAnswersPrimaryCompositeKeyInput = {
  boothId?: string | null,
  jobSeekerId?: number | null,
};

export type ModelQuestionAnswersFilterInput = {
  jobFairId?: ModelStringInput | null,
  boothId?: ModelIDInput | null,
  jobSeekerId?: ModelIntInput | null,
  and?: Array< ModelQuestionAnswersFilterInput | null > | null,
  or?: Array< ModelQuestionAnswersFilterInput | null > | null,
  not?: ModelQuestionAnswersFilterInput | null,
};

export type ModelQuestionAnswersConnection = {
  __typename: "ModelQuestionAnswersConnection",
  items:  Array<QuestionAnswers | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  topicKey?: ModelStringInput | null,
  message?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelMessageReceiptsFilterInput = {
  id?: ModelIDInput | null,
  jobFairId?: ModelStringInput | null,
  messageId?: ModelIDInput | null,
  recipientId?: ModelIntInput | null,
  read?: ModelBooleanInput | null,
  and?: Array< ModelMessageReceiptsFilterInput | null > | null,
  or?: Array< ModelMessageReceiptsFilterInput | null > | null,
  not?: ModelMessageReceiptsFilterInput | null,
};

export type ModelMessageReceiptsConnection = {
  __typename: "ModelMessageReceiptsConnection",
  items:  Array<MessageReceipts | null >,
  nextToken?: string | null,
};

export type ModelInterviewSessionFilterInput = {
  id?: ModelIDInput | null,
  boothId?: ModelIDInput | null,
  jobFairId?: ModelStringInput | null,
  status?: ModelVideoSessionStatusInput | null,
  recruiterId?: ModelIntInput | null,
  candidateId?: ModelIntInput | null,
  queueUserId?: ModelIDInput | null,
  durationAdded?: ModelIntInput | null,
  chatDuration?: ModelIntInput | null,
  endedBy?: ModelIntInput | null,
  chatTranscript?: ModelStringInput | null,
  recruiterZoomId?: ModelIntInput | null,
  jobSeekerZoomId?: ModelIntInput | null,
  candidateScore?: ModelIntInput | null,
  candidateMaxScore?: ModelIntInput | null,
  endReason?: ModelEndReasonInput | null,
  interviewStartedAt?: ModelIntInput | null,
  and?: Array< ModelInterviewSessionFilterInput | null > | null,
  or?: Array< ModelInterviewSessionFilterInput | null > | null,
  not?: ModelInterviewSessionFilterInput | null,
};

export type ModelInterviewSessionConnection = {
  __typename: "ModelInterviewSessionConnection",
  items:  Array<InterviewSession | null >,
  nextToken?: string | null,
};

export type ModelBoothVisitorFilterInput = {
  id?: ModelIDInput | null,
  boothId?: ModelIDInput | null,
  jobFairId?: ModelStringInput | null,
  vjfpUserId?: ModelIntInput | null,
  and?: Array< ModelBoothVisitorFilterInput | null > | null,
  or?: Array< ModelBoothVisitorFilterInput | null > | null,
  not?: ModelBoothVisitorFilterInput | null,
};

export type ModelBoothVisitorConnection = {
  __typename: "ModelBoothVisitorConnection",
  items:  Array<BoothVisitor | null >,
  nextToken?: string | null,
};

export type ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyConditionInput = {
  eq?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null,
  le?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null,
  lt?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null,
  ge?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null,
  gt?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null,
  between?: Array< ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null > | null,
  beginsWith?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput | null,
};

export type ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyInput = {
  interviewSessionId?: string | null,
  ratedBy?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type DeleteAddressesMutationVariables = {
  id: number,
};

export type DeleteAddressesMutation = {
  deleteAddresses?:  {
    __typename: "addresses",
    id: number,
    label: string,
    addressable_id?: number | null,
    addressable_type?: string | null,
    street_address1?: string | null,
    street_address2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    mobile?: string | null,
    fax?: string | null,
    website?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    extension?: string | null,
  } | null,
};

export type CreateAddressesMutationVariables = {
  createAddressesInput: CreateAddressesInput,
};

export type CreateAddressesMutation = {
  createAddresses?:  {
    __typename: "addresses",
    id: number,
    label: string,
    addressable_id?: number | null,
    addressable_type?: string | null,
    street_address1?: string | null,
    street_address2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    mobile?: string | null,
    fax?: string | null,
    website?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    extension?: string | null,
  } | null,
};

export type UpdateAddressesMutationVariables = {
  updateAddressesInput: UpdateAddressesInput,
};

export type UpdateAddressesMutation = {
  updateAddresses?:  {
    __typename: "addresses",
    id: number,
    label: string,
    addressable_id?: number | null,
    addressable_type?: string | null,
    street_address1?: string | null,
    street_address2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    mobile?: string | null,
    fax?: string | null,
    website?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    extension?: string | null,
  } | null,
};

export type DeleteApplicants_jobsMutationVariables = {
  id: number,
};

export type DeleteApplicants_jobsMutation = {
  deleteApplicants_jobs?:  {
    __typename: "applicants_jobs",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateApplicants_jobsMutationVariables = {
  createApplicants_jobsInput: CreateApplicants_jobsInput,
};

export type CreateApplicants_jobsMutation = {
  createApplicants_jobs?:  {
    __typename: "applicants_jobs",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateApplicants_jobsMutationVariables = {
  updateApplicants_jobsInput: UpdateApplicants_jobsInput,
};

export type UpdateApplicants_jobsMutation = {
  updateApplicants_jobs?:  {
    __typename: "applicants_jobs",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteAuthsub_tokensMutationVariables = {
  id: number,
};

export type DeleteAuthsub_tokensMutation = {
  deleteAuthsub_tokens?:  {
    __typename: "authsub_tokens",
    id: number,
    token: string,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateAuthsub_tokensMutationVariables = {
  createAuthsub_tokensInput: CreateAuthsub_tokensInput,
};

export type CreateAuthsub_tokensMutation = {
  createAuthsub_tokens?:  {
    __typename: "authsub_tokens",
    id: number,
    token: string,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateAuthsub_tokensMutationVariables = {
  updateAuthsub_tokensInput: UpdateAuthsub_tokensInput,
};

export type UpdateAuthsub_tokensMutation = {
  updateAuthsub_tokens?:  {
    __typename: "authsub_tokens",
    id: number,
    token: string,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteBdrb_job_queuesMutationVariables = {
  id: number,
};

export type DeleteBdrb_job_queuesMutation = {
  deleteBdrb_job_queues?:  {
    __typename: "bdrb_job_queues",
    id: number,
    args?: string | null,
    worker_name?: string | null,
    worker_method?: string | null,
    job_key?: string | null,
    taken?: number | null,
    finished?: number | null,
    timeout?: number | null,
    priority?: number | null,
    submitted_at?: string | null,
    started_at?: string | null,
    finished_at?: string | null,
    archived_at?: string | null,
    scheduled_at?: string | null,
    tag?: string | null,
    submitter_info?: string | null,
    runner_info?: string | null,
    worker_key?: string | null,
  } | null,
};

export type CreateBdrb_job_queuesMutationVariables = {
  createBdrb_job_queuesInput: CreateBdrb_job_queuesInput,
};

export type CreateBdrb_job_queuesMutation = {
  createBdrb_job_queues?:  {
    __typename: "bdrb_job_queues",
    id: number,
    args?: string | null,
    worker_name?: string | null,
    worker_method?: string | null,
    job_key?: string | null,
    taken?: number | null,
    finished?: number | null,
    timeout?: number | null,
    priority?: number | null,
    submitted_at?: string | null,
    started_at?: string | null,
    finished_at?: string | null,
    archived_at?: string | null,
    scheduled_at?: string | null,
    tag?: string | null,
    submitter_info?: string | null,
    runner_info?: string | null,
    worker_key?: string | null,
  } | null,
};

export type UpdateBdrb_job_queuesMutationVariables = {
  updateBdrb_job_queuesInput: UpdateBdrb_job_queuesInput,
};

export type UpdateBdrb_job_queuesMutation = {
  updateBdrb_job_queues?:  {
    __typename: "bdrb_job_queues",
    id: number,
    args?: string | null,
    worker_name?: string | null,
    worker_method?: string | null,
    job_key?: string | null,
    taken?: number | null,
    finished?: number | null,
    timeout?: number | null,
    priority?: number | null,
    submitted_at?: string | null,
    started_at?: string | null,
    finished_at?: string | null,
    archived_at?: string | null,
    scheduled_at?: string | null,
    tag?: string | null,
    submitter_info?: string | null,
    runner_info?: string | null,
    worker_key?: string | null,
  } | null,
};

export type DeleteBooth_recruitersMutationVariables = {
  id: number,
};

export type DeleteBooth_recruitersMutation = {
  deleteBooth_recruiters?:  {
    __typename: "booth_recruiters",
    id: number,
    booth_info_id?: number | null,
    recruiter_id?: number | null,
  } | null,
};

export type CreateBooth_recruitersMutationVariables = {
  createBooth_recruitersInput: CreateBooth_recruitersInput,
};

export type CreateBooth_recruitersMutation = {
  createBooth_recruiters?:  {
    __typename: "booth_recruiters",
    id: number,
    booth_info_id?: number | null,
    recruiter_id?: number | null,
  } | null,
};

export type UpdateBooth_recruitersMutationVariables = {
  updateBooth_recruitersInput: UpdateBooth_recruitersInput,
};

export type UpdateBooth_recruitersMutation = {
  updateBooth_recruiters?:  {
    __typename: "booth_recruiters",
    id: number,
    booth_info_id?: number | null,
    recruiter_id?: number | null,
  } | null,
};

export type DeleteBooths_infoMutationVariables = {
  id: number,
};

export type DeleteBooths_infoMutation = {
  deleteBooths_info?:  {
    __typename: "booths_info",
    id: number,
    employer_id?: number | null,
    jobfair_id?: number | null,
    isFeatured?: boolean | null,
    interview_duration_min?: number | null,
    minimum_score?: number | null,
    banner_company_name?: string | null,
    company_profile?: string | null,
    available_positions?: string | null,
  } | null,
};

export type CreateBooths_infoMutationVariables = {
  createBooths_infoInput: CreateBooths_infoInput,
};

export type CreateBooths_infoMutation = {
  createBooths_info?:  {
    __typename: "booths_info",
    id: number,
    employer_id?: number | null,
    jobfair_id?: number | null,
    isFeatured?: boolean | null,
    interview_duration_min?: number | null,
    minimum_score?: number | null,
    banner_company_name?: string | null,
    company_profile?: string | null,
    available_positions?: string | null,
  } | null,
};

export type UpdateBooths_infoMutationVariables = {
  updateBooths_infoInput: UpdateBooths_infoInput,
};

export type UpdateBooths_infoMutation = {
  updateBooths_info?:  {
    __typename: "booths_info",
    id: number,
    employer_id?: number | null,
    jobfair_id?: number | null,
    isFeatured?: boolean | null,
    interview_duration_min?: number | null,
    minimum_score?: number | null,
    banner_company_name?: string | null,
    company_profile?: string | null,
    available_positions?: string | null,
  } | null,
};

export type DeleteConnectionsMutationVariables = {
  id: number,
};

export type DeleteConnectionsMutation = {
  deleteConnections?:  {
    __typename: "connections",
    id: number,
    provider?: string | null,
    unique_identifier?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    company?: string | null,
    headline?: string | null,
    industry?: string | null,
    location?: string | null,
    profile_url?: string | null,
    picture_url?: string | null,
    photo_file_name?: string | null,
    photo_content_type?: string | null,
    photo_file_size?: number | null,
    photo_updated_at?: string | null,
    user_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateConnectionsMutationVariables = {
  createConnectionsInput: CreateConnectionsInput,
};

export type CreateConnectionsMutation = {
  createConnections?:  {
    __typename: "connections",
    id: number,
    provider?: string | null,
    unique_identifier?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    company?: string | null,
    headline?: string | null,
    industry?: string | null,
    location?: string | null,
    profile_url?: string | null,
    picture_url?: string | null,
    photo_file_name?: string | null,
    photo_content_type?: string | null,
    photo_file_size?: number | null,
    photo_updated_at?: string | null,
    user_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateConnectionsMutationVariables = {
  updateConnectionsInput: UpdateConnectionsInput,
};

export type UpdateConnectionsMutation = {
  updateConnections?:  {
    __typename: "connections",
    id: number,
    provider?: string | null,
    unique_identifier?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    company?: string | null,
    headline?: string | null,
    industry?: string | null,
    location?: string | null,
    profile_url?: string | null,
    picture_url?: string | null,
    photo_file_name?: string | null,
    photo_content_type?: string | null,
    photo_file_size?: number | null,
    photo_updated_at?: string | null,
    user_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteContactsMutationVariables = {
  id: number,
};

export type DeleteContactsMutation = {
  deleteContacts?:  {
    __typename: "contacts",
    id: number,
    employer_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    contacted?: number | null,
    last_contact_date?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateContactsMutationVariables = {
  createContactsInput: CreateContactsInput,
};

export type CreateContactsMutation = {
  createContacts?:  {
    __typename: "contacts",
    id: number,
    employer_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    contacted?: number | null,
    last_contact_date?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateContactsMutationVariables = {
  updateContactsInput: UpdateContactsInput,
};

export type UpdateContactsMutation = {
  updateContacts?:  {
    __typename: "contacts",
    id: number,
    employer_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    contacted?: number | null,
    last_contact_date?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteDelayed_jobsMutationVariables = {
  id: number,
};

export type DeleteDelayed_jobsMutation = {
  deleteDelayed_jobs?:  {
    __typename: "delayed_jobs",
    id: number,
    priority?: number | null,
    attempts?: number | null,
    handler?: string | null,
    last_error?: string | null,
    run_at?: string | null,
    locked_at?: string | null,
    locked_by?: string | null,
    failed_at?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateDelayed_jobsMutationVariables = {
  createDelayed_jobsInput: CreateDelayed_jobsInput,
};

export type CreateDelayed_jobsMutation = {
  createDelayed_jobs?:  {
    __typename: "delayed_jobs",
    id: number,
    priority?: number | null,
    attempts?: number | null,
    handler?: string | null,
    last_error?: string | null,
    run_at?: string | null,
    locked_at?: string | null,
    locked_by?: string | null,
    failed_at?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateDelayed_jobsMutationVariables = {
  updateDelayed_jobsInput: UpdateDelayed_jobsInput,
};

export type UpdateDelayed_jobsMutation = {
  updateDelayed_jobs?:  {
    __typename: "delayed_jobs",
    id: number,
    priority?: number | null,
    attempts?: number | null,
    handler?: string | null,
    last_error?: string | null,
    run_at?: string | null,
    locked_at?: string | null,
    locked_by?: string | null,
    failed_at?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteEducationsMutationVariables = {
  id: number,
};

export type DeleteEducationsMutation = {
  deleteEducations?:  {
    __typename: "educations",
    id: number,
    school_name?: string | null,
    field_of_study?: string | null,
    degree?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    notes?: string | null,
    applicant_id?: number | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateEducationsMutationVariables = {
  createEducationsInput: CreateEducationsInput,
};

export type CreateEducationsMutation = {
  createEducations?:  {
    __typename: "educations",
    id: number,
    school_name?: string | null,
    field_of_study?: string | null,
    degree?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    notes?: string | null,
    applicant_id?: number | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateEducationsMutationVariables = {
  updateEducationsInput: UpdateEducationsInput,
};

export type UpdateEducationsMutation = {
  updateEducations?:  {
    __typename: "educations",
    id: number,
    school_name?: string | null,
    field_of_study?: string | null,
    degree?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    notes?: string | null,
    applicant_id?: number | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteEmployer_statsMutationVariables = {
  id: number,
};

export type DeleteEmployer_statsMutation = {
  deleteEmployer_stats?:  {
    __typename: "employer_stats",
    id: number,
    employer_id?: number | null,
    profile_views?: number | null,
    job_views?: number | null,
    banner_clicks?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    job_applicants?: number | null,
  } | null,
};

export type CreateEmployer_statsMutationVariables = {
  createEmployer_statsInput: CreateEmployer_statsInput,
};

export type CreateEmployer_statsMutation = {
  createEmployer_stats?:  {
    __typename: "employer_stats",
    id: number,
    employer_id?: number | null,
    profile_views?: number | null,
    job_views?: number | null,
    banner_clicks?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    job_applicants?: number | null,
  } | null,
};

export type UpdateEmployer_statsMutationVariables = {
  updateEmployer_statsInput: UpdateEmployer_statsInput,
};

export type UpdateEmployer_statsMutation = {
  updateEmployer_stats?:  {
    __typename: "employer_stats",
    id: number,
    employer_id?: number | null,
    profile_views?: number | null,
    job_views?: number | null,
    banner_clicks?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    job_applicants?: number | null,
  } | null,
};

export type DeleteEmployersMutationVariables = {
  id: number,
};

export type DeleteEmployersMutation = {
  deleteEmployers?:  {
    __typename: "employers",
    id: number,
    name?: string | null,
    profile?: string | null,
    comments?: string | null,
    website?: string | null,
    is_federal_employer?: number | null,
    referal_source?: string | null,
    max_recruiters: number,
    number_job_postings_remaining?: number | null,
    job_postings_expire_at?: string | null,
    number_resume_searches_remaining?: number | null,
    resume_search_expire_at?: string | null,
    number_trial_resume_searches_remaining?: number | null,
    trial_resume_search_expire_at?: string | null,
    is_replace_all_on_import?: number | null,
    is_notify_job_postings?: number | null,
    banner_option?: string | null,
    banner_option_start_at?: string | null,
    service_option?: string | null,
    service_option_start_at?: string | null,
    sales_person_id?: number | null,
    track_image_url?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker_symbol?: string | null,
    twitter_handler?: string | null,
    video_url?: string | null,
    default_jobfair_profile?: string | null,
    default_hot_jobs?: string | null,
    default_job_keywords?: string | null,
    linkedin_url?: string | null,
  } | null,
};

export type CreateEmployersMutationVariables = {
  createEmployersInput: CreateEmployersInput,
};

export type CreateEmployersMutation = {
  createEmployers?:  {
    __typename: "employers",
    id: number,
    name?: string | null,
    profile?: string | null,
    comments?: string | null,
    website?: string | null,
    is_federal_employer?: number | null,
    referal_source?: string | null,
    max_recruiters: number,
    number_job_postings_remaining?: number | null,
    job_postings_expire_at?: string | null,
    number_resume_searches_remaining?: number | null,
    resume_search_expire_at?: string | null,
    number_trial_resume_searches_remaining?: number | null,
    trial_resume_search_expire_at?: string | null,
    is_replace_all_on_import?: number | null,
    is_notify_job_postings?: number | null,
    banner_option?: string | null,
    banner_option_start_at?: string | null,
    service_option?: string | null,
    service_option_start_at?: string | null,
    sales_person_id?: number | null,
    track_image_url?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker_symbol?: string | null,
    twitter_handler?: string | null,
    video_url?: string | null,
    default_jobfair_profile?: string | null,
    default_hot_jobs?: string | null,
    default_job_keywords?: string | null,
    linkedin_url?: string | null,
  } | null,
};

export type UpdateEmployersMutationVariables = {
  updateEmployersInput: UpdateEmployersInput,
};

export type UpdateEmployersMutation = {
  updateEmployers?:  {
    __typename: "employers",
    id: number,
    name?: string | null,
    profile?: string | null,
    comments?: string | null,
    website?: string | null,
    is_federal_employer?: number | null,
    referal_source?: string | null,
    max_recruiters: number,
    number_job_postings_remaining?: number | null,
    job_postings_expire_at?: string | null,
    number_resume_searches_remaining?: number | null,
    resume_search_expire_at?: string | null,
    number_trial_resume_searches_remaining?: number | null,
    trial_resume_search_expire_at?: string | null,
    is_replace_all_on_import?: number | null,
    is_notify_job_postings?: number | null,
    banner_option?: string | null,
    banner_option_start_at?: string | null,
    service_option?: string | null,
    service_option_start_at?: string | null,
    sales_person_id?: number | null,
    track_image_url?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker_symbol?: string | null,
    twitter_handler?: string | null,
    video_url?: string | null,
    default_jobfair_profile?: string | null,
    default_hot_jobs?: string | null,
    default_job_keywords?: string | null,
    linkedin_url?: string | null,
  } | null,
};

export type DeleteForumsMutationVariables = {
  id: number,
};

export type DeleteForumsMutation = {
  deleteForums?:  {
    __typename: "forums",
    id: number,
    name?: string | null,
    description?: string | null,
    last_post_date?: string | null,
    post_count?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateForumsMutationVariables = {
  createForumsInput: CreateForumsInput,
};

export type CreateForumsMutation = {
  createForums?:  {
    __typename: "forums",
    id: number,
    name?: string | null,
    description?: string | null,
    last_post_date?: string | null,
    post_count?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateForumsMutationVariables = {
  updateForumsInput: UpdateForumsInput,
};

export type UpdateForumsMutation = {
  updateForums?:  {
    __typename: "forums",
    id: number,
    name?: string | null,
    description?: string | null,
    last_post_date?: string | null,
    post_count?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteInbox_entriesMutationVariables = {
  id: number,
};

export type DeleteInbox_entriesMutation = {
  deleteInbox_entries?:  {
    __typename: "inbox_entries",
    id: number,
    user_id?: number | null,
    resource_id?: number | null,
    resource_type?: string | null,
    added_by?: string | null,
    status?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateInbox_entriesMutationVariables = {
  createInbox_entriesInput: CreateInbox_entriesInput,
};

export type CreateInbox_entriesMutation = {
  createInbox_entries?:  {
    __typename: "inbox_entries",
    id: number,
    user_id?: number | null,
    resource_id?: number | null,
    resource_type?: string | null,
    added_by?: string | null,
    status?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateInbox_entriesMutationVariables = {
  updateInbox_entriesInput: UpdateInbox_entriesInput,
};

export type UpdateInbox_entriesMutation = {
  updateInbox_entries?:  {
    __typename: "inbox_entries",
    id: number,
    user_id?: number | null,
    resource_id?: number | null,
    resource_type?: string | null,
    added_by?: string | null,
    status?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteInvitesMutationVariables = {
  id: number,
};

export type DeleteInvitesMutation = {
  deleteInvites?:  {
    __typename: "invites",
    id: number,
    jobfair_id?: number | null,
    booth_info_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    status?: string | null,
    company_name?: string | null,
  } | null,
};

export type CreateInvitesMutationVariables = {
  createInvitesInput: CreateInvitesInput,
};

export type CreateInvitesMutation = {
  createInvites?:  {
    __typename: "invites",
    id: number,
    jobfair_id?: number | null,
    booth_info_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    status?: string | null,
    company_name?: string | null,
  } | null,
};

export type UpdateInvitesMutationVariables = {
  updateInvitesInput: UpdateInvitesInput,
};

export type UpdateInvitesMutation = {
  updateInvites?:  {
    __typename: "invites",
    id: number,
    jobfair_id?: number | null,
    booth_info_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    status?: string | null,
    company_name?: string | null,
  } | null,
};

export type DeleteJob_applicationsMutationVariables = {
  id: number,
};

export type DeleteJob_applicationsMutation = {
  deleteJob_applications?:  {
    __typename: "job_applications",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateJob_applicationsMutationVariables = {
  createJob_applicationsInput: CreateJob_applicationsInput,
};

export type CreateJob_applicationsMutation = {
  createJob_applications?:  {
    __typename: "job_applications",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateJob_applicationsMutationVariables = {
  updateJob_applicationsInput: UpdateJob_applicationsInput,
};

export type UpdateJob_applicationsMutation = {
  updateJob_applications?:  {
    __typename: "job_applications",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteJobfairsMutationVariables = {
  id: number,
};

export type DeleteJobfairsMutation = {
  deleteJobfairs?:  {
    __typename: "jobfairs",
    id: number,
    category?: string | null,
    date?: string | null,
    start_time?: string | null,
    end_time?: string | null,
    applicant_virtual_jf_help_url?: string | null,
    recruiter_virtual_jf_help_url?: string | null,
    daylight_savings?: string | null,
  } | null,
};

export type CreateJobfairsMutationVariables = {
  createJobfairsInput: CreateJobfairsInput,
};

export type CreateJobfairsMutation = {
  createJobfairs?:  {
    __typename: "jobfairs",
    id: number,
    category?: string | null,
    date?: string | null,
    start_time?: string | null,
    end_time?: string | null,
    applicant_virtual_jf_help_url?: string | null,
    recruiter_virtual_jf_help_url?: string | null,
    daylight_savings?: string | null,
  } | null,
};

export type UpdateJobfairsMutationVariables = {
  updateJobfairsInput: UpdateJobfairsInput,
};

export type UpdateJobfairsMutation = {
  updateJobfairs?:  {
    __typename: "jobfairs",
    id: number,
    category?: string | null,
    date?: string | null,
    start_time?: string | null,
    end_time?: string | null,
    applicant_virtual_jf_help_url?: string | null,
    recruiter_virtual_jf_help_url?: string | null,
    daylight_savings?: string | null,
  } | null,
};

export type DeleteJobsMutationVariables = {
  id: number,
};

export type DeleteJobsMutation = {
  deleteJobs?:  {
    __typename: "jobs",
    id: number,
    status?: string | null,
    code?: string | null,
    title: string,
    description: string,
    input_method?: string | null,
    requirements?: string | null,
    expires_at?: string | null,
    company_name?: string | null,
    recruiter_id?: number | null,
    education_level?: string | null,
    experience_required?: number | null,
    payrate?: string | null,
    hr_website_url?: string | null,
    online_application_url?: string | null,
    security_clearance?: string | null,
    travel_requirements?: string | null,
    relocation_cost_paid?: string | null,
    show_company_profile: number,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateJobsMutationVariables = {
  createJobsInput: CreateJobsInput,
};

export type CreateJobsMutation = {
  createJobs?:  {
    __typename: "jobs",
    id: number,
    status?: string | null,
    code?: string | null,
    title: string,
    description: string,
    input_method?: string | null,
    requirements?: string | null,
    expires_at?: string | null,
    company_name?: string | null,
    recruiter_id?: number | null,
    education_level?: string | null,
    experience_required?: number | null,
    payrate?: string | null,
    hr_website_url?: string | null,
    online_application_url?: string | null,
    security_clearance?: string | null,
    travel_requirements?: string | null,
    relocation_cost_paid?: string | null,
    show_company_profile: number,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateJobsMutationVariables = {
  updateJobsInput: UpdateJobsInput,
};

export type UpdateJobsMutation = {
  updateJobs?:  {
    __typename: "jobs",
    id: number,
    status?: string | null,
    code?: string | null,
    title: string,
    description: string,
    input_method?: string | null,
    requirements?: string | null,
    expires_at?: string | null,
    company_name?: string | null,
    recruiter_id?: number | null,
    education_level?: string | null,
    experience_required?: number | null,
    payrate?: string | null,
    hr_website_url?: string | null,
    online_application_url?: string | null,
    security_clearance?: string | null,
    travel_requirements?: string | null,
    relocation_cost_paid?: string | null,
    show_company_profile: number,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteMessagesMutationVariables = {
  id: number,
};

export type DeleteMessagesMutation = {
  deleteMessages?:  {
    __typename: "messages",
    id: number,
    category?: string | null,
    body?: string | null,
    action?: string | null,
    action_url?: string | null,
    recipient_id?: number | null,
    recipient_type?: string | null,
    sender_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateMessagesMutationVariables = {
  createMessagesInput: CreateMessagesInput,
};

export type CreateMessagesMutation = {
  createMessages?:  {
    __typename: "messages",
    id: number,
    category?: string | null,
    body?: string | null,
    action?: string | null,
    action_url?: string | null,
    recipient_id?: number | null,
    recipient_type?: string | null,
    sender_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateMessagesMutationVariables = {
  updateMessagesInput: UpdateMessagesInput,
};

export type UpdateMessagesMutation = {
  updateMessages?:  {
    __typename: "messages",
    id: number,
    category?: string | null,
    body?: string | null,
    action?: string | null,
    action_url?: string | null,
    recipient_id?: number | null,
    recipient_type?: string | null,
    sender_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteOfccpMutationVariables = {
  id: number,
};

export type DeleteOfccpMutation = {
  deleteOfccp?:  {
    __typename: "ofccp",
    id: number,
    query_string?: string | null,
    job_code?: string | null,
    job_title?: string | null,
    job_description?: string | null,
    applicant_name?: string | null,
    applicant_modification_date?: string | null,
    applicant_ethnicity?: string | null,
    applicant_gender?: string | null,
    resume_post_date?: string | null,
    resume?: string | null,
    employer_name?: string | null,
    recruiter_name?: string | null,
    recruiter_email?: string | null,
    employer_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateOfccpMutationVariables = {
  createOfccpInput: CreateOfccpInput,
};

export type CreateOfccpMutation = {
  createOfccp?:  {
    __typename: "ofccp",
    id: number,
    query_string?: string | null,
    job_code?: string | null,
    job_title?: string | null,
    job_description?: string | null,
    applicant_name?: string | null,
    applicant_modification_date?: string | null,
    applicant_ethnicity?: string | null,
    applicant_gender?: string | null,
    resume_post_date?: string | null,
    resume?: string | null,
    employer_name?: string | null,
    recruiter_name?: string | null,
    recruiter_email?: string | null,
    employer_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateOfccpMutationVariables = {
  updateOfccpInput: UpdateOfccpInput,
};

export type UpdateOfccpMutation = {
  updateOfccp?:  {
    __typename: "ofccp",
    id: number,
    query_string?: string | null,
    job_code?: string | null,
    job_title?: string | null,
    job_description?: string | null,
    applicant_name?: string | null,
    applicant_modification_date?: string | null,
    applicant_ethnicity?: string | null,
    applicant_gender?: string | null,
    resume_post_date?: string | null,
    resume?: string | null,
    employer_name?: string | null,
    recruiter_name?: string | null,
    recruiter_email?: string | null,
    employer_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeletePositionsMutationVariables = {
  id: number,
};

export type DeletePositionsMutation = {
  deletePositions?:  {
    __typename: "positions",
    id: number,
    employmenable_id?: number | null,
    employmenable_type?: string | null,
    company?: string | null,
    industry?: string | null,
    title?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    summary?: string | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker?: string | null,
  } | null,
};

export type CreatePositionsMutationVariables = {
  createPositionsInput: CreatePositionsInput,
};

export type CreatePositionsMutation = {
  createPositions?:  {
    __typename: "positions",
    id: number,
    employmenable_id?: number | null,
    employmenable_type?: string | null,
    company?: string | null,
    industry?: string | null,
    title?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    summary?: string | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker?: string | null,
  } | null,
};

export type UpdatePositionsMutationVariables = {
  updatePositionsInput: UpdatePositionsInput,
};

export type UpdatePositionsMutation = {
  updatePositions?:  {
    __typename: "positions",
    id: number,
    employmenable_id?: number | null,
    employmenable_type?: string | null,
    company?: string | null,
    industry?: string | null,
    title?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    summary?: string | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker?: string | null,
  } | null,
};

export type DeletePostsMutationVariables = {
  id: number,
};

export type DeletePostsMutation = {
  deletePosts?:  {
    __typename: "posts",
    id: number,
    title?: string | null,
    body?: string | null,
    forum_id?: number | null,
    user_id?: number | null,
    parent_id?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreatePostsMutationVariables = {
  createPostsInput: CreatePostsInput,
};

export type CreatePostsMutation = {
  createPosts?:  {
    __typename: "posts",
    id: number,
    title?: string | null,
    body?: string | null,
    forum_id?: number | null,
    user_id?: number | null,
    parent_id?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdatePostsMutationVariables = {
  updatePostsInput: UpdatePostsInput,
};

export type UpdatePostsMutation = {
  updatePosts?:  {
    __typename: "posts",
    id: number,
    title?: string | null,
    body?: string | null,
    forum_id?: number | null,
    user_id?: number | null,
    parent_id?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteRecruiter_statsMutationVariables = {
  id: number,
};

export type DeleteRecruiter_statsMutation = {
  deleteRecruiter_stats?:  {
    __typename: "recruiter_stats",
    id: number,
    recruiter_id?: number | null,
    resume_views?: number | null,
    resume_searches?: number | null,
    login_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateRecruiter_statsMutationVariables = {
  createRecruiter_statsInput: CreateRecruiter_statsInput,
};

export type CreateRecruiter_statsMutation = {
  createRecruiter_stats?:  {
    __typename: "recruiter_stats",
    id: number,
    recruiter_id?: number | null,
    resume_views?: number | null,
    resume_searches?: number | null,
    login_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateRecruiter_statsMutationVariables = {
  updateRecruiter_statsInput: UpdateRecruiter_statsInput,
};

export type UpdateRecruiter_statsMutation = {
  updateRecruiter_stats?:  {
    __typename: "recruiter_stats",
    id: number,
    recruiter_id?: number | null,
    resume_views?: number | null,
    resume_searches?: number | null,
    login_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteRegistrationsMutationVariables = {
  id: number,
};

export type DeleteRegistrationsMutation = {
  deleteRegistrations?:  {
    __typename: "registrations",
    id: number,
    attendant_type?: string | null,
    attendant_id?: number | null,
    jobfair_id?: number | null,
    lunches_required?: number | null,
    available_positions?: string | null,
    include_in_employer_directory?: number | null,
    outlet_required?: number | null,
    resume_search_access?: number | null,
    paid?: number | null,
    banner_company_name?: string | null,
    fax?: string | null,
    url?: string | null,
    security_clearance?: string | null,
    attending?: number | null,
    enable_search?: number | null,
    ad_type?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    company_profile?: string | null,
    wp_option_1?: number | null,
    wp_option_2?: number | null,
    job_keywords?: string | null,
    referral?: string | null,
  } | null,
};

export type CreateRegistrationsMutationVariables = {
  createRegistrationsInput: CreateRegistrationsInput,
};

export type CreateRegistrationsMutation = {
  createRegistrations?:  {
    __typename: "registrations",
    id: number,
    attendant_type?: string | null,
    attendant_id?: number | null,
    jobfair_id?: number | null,
    lunches_required?: number | null,
    available_positions?: string | null,
    include_in_employer_directory?: number | null,
    outlet_required?: number | null,
    resume_search_access?: number | null,
    paid?: number | null,
    banner_company_name?: string | null,
    fax?: string | null,
    url?: string | null,
    security_clearance?: string | null,
    attending?: number | null,
    enable_search?: number | null,
    ad_type?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    company_profile?: string | null,
    wp_option_1?: number | null,
    wp_option_2?: number | null,
    job_keywords?: string | null,
    referral?: string | null,
  } | null,
};

export type UpdateRegistrationsMutationVariables = {
  updateRegistrationsInput: UpdateRegistrationsInput,
};

export type UpdateRegistrationsMutation = {
  updateRegistrations?:  {
    __typename: "registrations",
    id: number,
    attendant_type?: string | null,
    attendant_id?: number | null,
    jobfair_id?: number | null,
    lunches_required?: number | null,
    available_positions?: string | null,
    include_in_employer_directory?: number | null,
    outlet_required?: number | null,
    resume_search_access?: number | null,
    paid?: number | null,
    banner_company_name?: string | null,
    fax?: string | null,
    url?: string | null,
    security_clearance?: string | null,
    attending?: number | null,
    enable_search?: number | null,
    ad_type?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    company_profile?: string | null,
    wp_option_1?: number | null,
    wp_option_2?: number | null,
    job_keywords?: string | null,
    referral?: string | null,
  } | null,
};

export type DeleteResumesMutationVariables = {
  id: number,
};

export type DeleteResumesMutation = {
  deleteResumes?:  {
    __typename: "resumes",
    id: number,
    applicant_id?: number | null,
    attached_resume_file_name?: string | null,
    attached_resume_content_type?: string | null,
  } | null,
};

export type CreateResumesMutationVariables = {
  createResumesInput: CreateResumesInput,
};

export type CreateResumesMutation = {
  createResumes?:  {
    __typename: "resumes",
    id: number,
    applicant_id?: number | null,
    attached_resume_file_name?: string | null,
    attached_resume_content_type?: string | null,
  } | null,
};

export type UpdateResumesMutationVariables = {
  updateResumesInput: UpdateResumesInput,
};

export type UpdateResumesMutation = {
  updateResumes?:  {
    __typename: "resumes",
    id: number,
    applicant_id?: number | null,
    attached_resume_file_name?: string | null,
    attached_resume_content_type?: string | null,
  } | null,
};

export type DeleteSchema_migrationsMutationVariables = {
  version: string,
};

export type DeleteSchema_migrationsMutation = {
  deleteSchema_migrations?:  {
    __typename: "schema_migrations",
    version: string,
  } | null,
};

export type CreateSchema_migrationsMutationVariables = {
  createSchema_migrationsInput: CreateSchema_migrationsInput,
};

export type CreateSchema_migrationsMutation = {
  createSchema_migrations?:  {
    __typename: "schema_migrations",
    version: string,
  } | null,
};

export type UpdateSchema_migrationsMutationVariables = {
  updateSchema_migrationsInput: UpdateSchema_migrationsInput,
};

export type UpdateSchema_migrationsMutation = {
  updateSchema_migrations?:  {
    __typename: "schema_migrations",
    version: string,
  } | null,
};

export type DeleteSeminarsMutationVariables = {
  id: number,
};

export type DeleteSeminarsMutation = {
  deleteSeminars?:  {
    __typename: "seminars",
    id: number,
    description?: string | null,
    duration?: string | null,
    time?: string | null,
    jobfair_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type CreateSeminarsMutationVariables = {
  createSeminarsInput: CreateSeminarsInput,
};

export type CreateSeminarsMutation = {
  createSeminars?:  {
    __typename: "seminars",
    id: number,
    description?: string | null,
    duration?: string | null,
    time?: string | null,
    jobfair_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type UpdateSeminarsMutationVariables = {
  updateSeminarsInput: UpdateSeminarsInput,
};

export type UpdateSeminarsMutation = {
  updateSeminars?:  {
    __typename: "seminars",
    id: number,
    description?: string | null,
    duration?: string | null,
    time?: string | null,
    jobfair_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type DeleteSessionsMutationVariables = {
  id: number,
};

export type DeleteSessionsMutation = {
  deleteSessions?:  {
    __typename: "sessions",
    id: number,
    session_id: string,
    data?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateSessionsMutationVariables = {
  createSessionsInput: CreateSessionsInput,
};

export type CreateSessionsMutation = {
  createSessions?:  {
    __typename: "sessions",
    id: number,
    session_id: string,
    data?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateSessionsMutationVariables = {
  updateSessionsInput: UpdateSessionsInput,
};

export type UpdateSessionsMutation = {
  updateSessions?:  {
    __typename: "sessions",
    id: number,
    session_id: string,
    data?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteUsage_logsMutationVariables = {
  id: number,
};

export type DeleteUsage_logsMutation = {
  deleteUsage_logs?:  {
    __typename: "usage_logs",
    id: number,
    user_id?: number | null,
    log_type?: string | null,
    body?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type CreateUsage_logsMutationVariables = {
  createUsage_logsInput: CreateUsage_logsInput,
};

export type CreateUsage_logsMutation = {
  createUsage_logs?:  {
    __typename: "usage_logs",
    id: number,
    user_id?: number | null,
    log_type?: string | null,
    body?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type UpdateUsage_logsMutationVariables = {
  updateUsage_logsInput: UpdateUsage_logsInput,
};

export type UpdateUsage_logsMutation = {
  updateUsage_logs?:  {
    __typename: "usage_logs",
    id: number,
    user_id?: number | null,
    log_type?: string | null,
    body?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type DeleteUsersMutationVariables = {
  id: number,
};

export type DeleteUsersMutation = {
  deleteUsers?:  {
    __typename: "users",
    id: number,
    type?: string | null,
    roles?: string | null,
    title?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    email?: string | null,
    job_title?: string | null,
    greeting?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
  } | null,
};

export type CreateUsersMutationVariables = {
  createUsersInput: CreateUsersInput,
};

export type CreateUsersMutation = {
  createUsers?:  {
    __typename: "users",
    id: number,
    type?: string | null,
    roles?: string | null,
    title?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    email?: string | null,
    job_title?: string | null,
    greeting?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
  } | null,
};

export type UpdateUsersMutationVariables = {
  updateUsersInput: UpdateUsersInput,
};

export type UpdateUsersMutation = {
  updateUsers?:  {
    __typename: "users",
    id: number,
    type?: string | null,
    roles?: string | null,
    title?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    email?: string | null,
    job_title?: string | null,
    greeting?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
  } | null,
};

export type DeleteVideo_interviewsMutationVariables = {
  id: number,
};

export type DeleteVideo_interviewsMutation = {
  deleteVideo_interviews?:  {
    __typename: "video_interviews",
    id: number,
    recruiter_id?: number | null,
    interview_date?: string | null,
    start_time?: string | null,
    slot_duration?: number | null,
    slot_interval?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    email_count?: number | null,
    signal_session?: string | null,
  } | null,
};

export type CreateVideo_interviewsMutationVariables = {
  createVideo_interviewsInput: CreateVideo_interviewsInput,
};

export type CreateVideo_interviewsMutation = {
  createVideo_interviews?:  {
    __typename: "video_interviews",
    id: number,
    recruiter_id?: number | null,
    interview_date?: string | null,
    start_time?: string | null,
    slot_duration?: number | null,
    slot_interval?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    email_count?: number | null,
    signal_session?: string | null,
  } | null,
};

export type UpdateVideo_interviewsMutationVariables = {
  updateVideo_interviewsInput: UpdateVideo_interviewsInput,
};

export type UpdateVideo_interviewsMutation = {
  updateVideo_interviews?:  {
    __typename: "video_interviews",
    id: number,
    recruiter_id?: number | null,
    interview_date?: string | null,
    start_time?: string | null,
    slot_duration?: number | null,
    slot_interval?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    email_count?: number | null,
    signal_session?: string | null,
  } | null,
};

export type DeleteVideo_sessionsMutationVariables = {
  id: number,
};

export type DeleteVideo_sessionsMutation = {
  deleteVideo_sessions?:  {
    __typename: "video_sessions",
    id: number,
    video_interview_id?: number | null,
    applicant_id?: number | null,
    session_id?: string | null,
    start_time?: string | null,
    attendant_token?: string | null,
    recruiter_token?: string | null,
    attendant_email_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    session_order?: number | null,
    candidate_rating?: number | null,
  } | null,
};

export type CreateVideo_sessionsMutationVariables = {
  createVideo_sessionsInput: CreateVideo_sessionsInput,
};

export type CreateVideo_sessionsMutation = {
  createVideo_sessions?:  {
    __typename: "video_sessions",
    id: number,
    video_interview_id?: number | null,
    applicant_id?: number | null,
    session_id?: string | null,
    start_time?: string | null,
    attendant_token?: string | null,
    recruiter_token?: string | null,
    attendant_email_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    session_order?: number | null,
    candidate_rating?: number | null,
  } | null,
};

export type UpdateVideo_sessionsMutationVariables = {
  updateVideo_sessionsInput: UpdateVideo_sessionsInput,
};

export type UpdateVideo_sessionsMutation = {
  updateVideo_sessions?:  {
    __typename: "video_sessions",
    id: number,
    video_interview_id?: number | null,
    applicant_id?: number | null,
    session_id?: string | null,
    start_time?: string | null,
    attendant_token?: string | null,
    recruiter_token?: string | null,
    attendant_email_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    session_order?: number | null,
    candidate_rating?: number | null,
  } | null,
};

export type CreateTopicMutationVariables = {
  input: CreateTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type CreateTopicMutation = {
  createTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTopicMutationVariables = {
  input: UpdateTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type UpdateTopicMutation = {
  updateTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTopicMutationVariables = {
  input: DeleteTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type DeleteTopicMutation = {
  deleteTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVJFPUserMutationVariables = {
  input: CreateVJFPUserInput,
  condition?: ModelVJFPUserConditionInput | null,
};

export type CreateVJFPUserMutation = {
  createVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVJFPUserMutationVariables = {
  input: UpdateVJFPUserInput,
  condition?: ModelVJFPUserConditionInput | null,
};

export type UpdateVJFPUserMutation = {
  updateVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVJFPUserMutationVariables = {
  input: DeleteVJFPUserInput,
  condition?: ModelVJFPUserConditionInput | null,
};

export type DeleteVJFPUserMutation = {
  deleteVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReviewUserMutationVariables = {
  input: CreateReviewUserInput,
  condition?: ModelReviewUserConditionInput | null,
};

export type CreateReviewUserMutation = {
  createReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReviewUserMutationVariables = {
  input: UpdateReviewUserInput,
  condition?: ModelReviewUserConditionInput | null,
};

export type UpdateReviewUserMutation = {
  updateReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReviewUserMutationVariables = {
  input: DeleteReviewUserInput,
  condition?: ModelReviewUserConditionInput | null,
};

export type DeleteReviewUserMutation = {
  deleteReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateResumeMutationVariables = {
  input: CreateResumeInput,
  condition?: ModelResumeConditionInput | null,
};

export type CreateResumeMutation = {
  createResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateResumeMutationVariables = {
  input: UpdateResumeInput,
  condition?: ModelResumeConditionInput | null,
};

export type UpdateResumeMutation = {
  updateResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteResumeMutationVariables = {
  input: DeleteResumeInput,
  condition?: ModelResumeConditionInput | null,
};

export type DeleteResumeMutation = {
  deleteResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBoothMutationVariables = {
  input: CreateBoothInput,
  condition?: ModelBoothConditionInput | null,
};

export type CreateBoothMutation = {
  createBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBoothMutationVariables = {
  input: UpdateBoothInput,
  condition?: ModelBoothConditionInput | null,
};

export type UpdateBoothMutation = {
  updateBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBoothMutationVariables = {
  input: DeleteBoothInput,
  condition?: ModelBoothConditionInput | null,
};

export type DeleteBoothMutation = {
  deleteBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQueueUserMutationVariables = {
  input: CreateQueueUserInput,
  condition?: ModelQueueUserConditionInput | null,
};

export type CreateQueueUserMutation = {
  createQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type UpdateQueueUserMutationVariables = {
  input: UpdateQueueUserInput,
  condition?: ModelQueueUserConditionInput | null,
};

export type UpdateQueueUserMutation = {
  updateQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type DeleteQueueUserMutationVariables = {
  input: DeleteQueueUserInput,
  condition?: ModelQueueUserConditionInput | null,
};

export type DeleteQueueUserMutation = {
  deleteQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type CreateQuestionsMutationVariables = {
  input: CreateQuestionsInput,
  condition?: ModelQuestionsConditionInput | null,
};

export type CreateQuestionsMutation = {
  createQuestions?:  {
    __typename: "Questions",
    jobFairId: string,
    boothId: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      name: string,
      rank: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionsMutationVariables = {
  input: UpdateQuestionsInput,
  condition?: ModelQuestionsConditionInput | null,
};

export type UpdateQuestionsMutation = {
  updateQuestions?:  {
    __typename: "Questions",
    jobFairId: string,
    boothId: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      name: string,
      rank: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionsMutationVariables = {
  input: DeleteQuestionsInput,
  condition?: ModelQuestionsConditionInput | null,
};

export type DeleteQuestionsMutation = {
  deleteQuestions?:  {
    __typename: "Questions",
    jobFairId: string,
    boothId: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      name: string,
      rank: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionAnswersMutationVariables = {
  input: CreateQuestionAnswersInput,
  condition?: ModelQuestionAnswersConditionInput | null,
};

export type CreateQuestionAnswersMutation = {
  createQuestionAnswers?:  {
    __typename: "QuestionAnswers",
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    answers?:  Array< {
      __typename: "QuestionAnswer",
      id: string,
      QuestionId: string,
      name: string,
      rank: number,
      answer: boolean,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionAnswersMutationVariables = {
  input: UpdateQuestionAnswersInput,
  condition?: ModelQuestionAnswersConditionInput | null,
};

export type UpdateQuestionAnswersMutation = {
  updateQuestionAnswers?:  {
    __typename: "QuestionAnswers",
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    answers?:  Array< {
      __typename: "QuestionAnswer",
      id: string,
      QuestionId: string,
      name: string,
      rank: number,
      answer: boolean,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionAnswersMutationVariables = {
  input: DeleteQuestionAnswersInput,
  condition?: ModelQuestionAnswersConditionInput | null,
};

export type DeleteQuestionAnswersMutation = {
  deleteQuestionAnswers?:  {
    __typename: "QuestionAnswers",
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    answers?:  Array< {
      __typename: "QuestionAnswer",
      id: string,
      QuestionId: string,
      name: string,
      rank: number,
      answer: boolean,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageReceiptsMutationVariables = {
  input: CreateMessageReceiptsInput,
  condition?: ModelMessageReceiptsConditionInput | null,
};

export type CreateMessageReceiptsMutation = {
  createMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageReceiptsMutationVariables = {
  input: UpdateMessageReceiptsInput,
  condition?: ModelMessageReceiptsConditionInput | null,
};

export type UpdateMessageReceiptsMutation = {
  updateMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageReceiptsMutationVariables = {
  input: DeleteMessageReceiptsInput,
  condition?: ModelMessageReceiptsConditionInput | null,
};

export type DeleteMessageReceiptsMutation = {
  deleteMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInterviewSessionMutationVariables = {
  input: CreateInterviewSessionInput,
  condition?: ModelInterviewSessionConditionInput | null,
};

export type CreateInterviewSessionMutation = {
  createInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInterviewSessionMutationVariables = {
  input: UpdateInterviewSessionInput,
  condition?: ModelInterviewSessionConditionInput | null,
};

export type UpdateInterviewSessionMutation = {
  updateInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInterviewSessionMutationVariables = {
  input: DeleteInterviewSessionInput,
  condition?: ModelInterviewSessionConditionInput | null,
};

export type DeleteInterviewSessionMutation = {
  deleteInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBoothVisitorMutationVariables = {
  input: CreateBoothVisitorInput,
  condition?: ModelBoothVisitorConditionInput | null,
};

export type CreateBoothVisitorMutation = {
  createBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBoothVisitorMutationVariables = {
  input: UpdateBoothVisitorInput,
  condition?: ModelBoothVisitorConditionInput | null,
};

export type UpdateBoothVisitorMutation = {
  updateBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBoothVisitorMutationVariables = {
  input: DeleteBoothVisitorInput,
  condition?: ModelBoothVisitorConditionInput | null,
};

export type DeleteBoothVisitorMutation = {
  deleteBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAddressesQueryVariables = {
  id: number,
};

export type GetAddressesQuery = {
  getAddresses?:  {
    __typename: "addresses",
    id: number,
    label: string,
    addressable_id?: number | null,
    addressable_type?: string | null,
    street_address1?: string | null,
    street_address2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    mobile?: string | null,
    fax?: string | null,
    website?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    extension?: string | null,
  } | null,
};

export type ListAddressessQuery = {
  listAddressess?:  Array< {
    __typename: "addresses",
    id: number,
    label: string,
    addressable_id?: number | null,
    addressable_type?: string | null,
    street_address1?: string | null,
    street_address2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    mobile?: string | null,
    fax?: string | null,
    website?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    extension?: string | null,
  } | null > | null,
};

export type GetApplicants_jobsQueryVariables = {
  id: number,
};

export type GetApplicants_jobsQuery = {
  getApplicants_jobs?:  {
    __typename: "applicants_jobs",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListApplicants_jobssQuery = {
  listApplicants_jobss?:  Array< {
    __typename: "applicants_jobs",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetAuthsub_tokensQueryVariables = {
  id: number,
};

export type GetAuthsub_tokensQuery = {
  getAuthsub_tokens?:  {
    __typename: "authsub_tokens",
    id: number,
    token: string,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListAuthsub_tokenssQuery = {
  listAuthsub_tokenss?:  Array< {
    __typename: "authsub_tokens",
    id: number,
    token: string,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetBdrb_job_queuesQueryVariables = {
  id: number,
};

export type GetBdrb_job_queuesQuery = {
  getBdrb_job_queues?:  {
    __typename: "bdrb_job_queues",
    id: number,
    args?: string | null,
    worker_name?: string | null,
    worker_method?: string | null,
    job_key?: string | null,
    taken?: number | null,
    finished?: number | null,
    timeout?: number | null,
    priority?: number | null,
    submitted_at?: string | null,
    started_at?: string | null,
    finished_at?: string | null,
    archived_at?: string | null,
    scheduled_at?: string | null,
    tag?: string | null,
    submitter_info?: string | null,
    runner_info?: string | null,
    worker_key?: string | null,
  } | null,
};

export type ListBdrb_job_queuessQuery = {
  listBdrb_job_queuess?:  Array< {
    __typename: "bdrb_job_queues",
    id: number,
    args?: string | null,
    worker_name?: string | null,
    worker_method?: string | null,
    job_key?: string | null,
    taken?: number | null,
    finished?: number | null,
    timeout?: number | null,
    priority?: number | null,
    submitted_at?: string | null,
    started_at?: string | null,
    finished_at?: string | null,
    archived_at?: string | null,
    scheduled_at?: string | null,
    tag?: string | null,
    submitter_info?: string | null,
    runner_info?: string | null,
    worker_key?: string | null,
  } | null > | null,
};

export type GetBooth_recruitersQueryVariables = {
  id: number,
};

export type GetBooth_recruitersQuery = {
  getBooth_recruiters?:  {
    __typename: "booth_recruiters",
    id: number,
    booth_info_id?: number | null,
    recruiter_id?: number | null,
  } | null,
};

export type ListBooth_recruiterssQueryVariables = {
  boothInfoId: number,
};

export type ListBooth_recruiterssQuery = {
  listBooth_recruiterss?:  Array< {
    __typename: "booth_recruiters",
    id: number,
    booth_info_id?: number | null,
    recruiter_id?: number | null,
  } | null > | null,
};

export type GetBooths_infoQueryVariables = {
  id: number,
};

export type GetBooths_infoQuery = {
  getBooths_info?:  {
    __typename: "booths_info",
    id: number,
    employer_id?: number | null,
    jobfair_id?: number | null,
    isFeatured?: boolean | null,
    interview_duration_min?: number | null,
    minimum_score?: number | null,
    banner_company_name?: string | null,
    company_profile?: string | null,
    available_positions?: string | null,
  } | null,
};

export type ListBooths_infosQueryVariables = {
  jobFairId: number,
};

export type ListBooths_infosQuery = {
  listBooths_infos?:  Array< {
    __typename: "booths_info",
    id: number,
    employer_id?: number | null,
    jobfair_id?: number | null,
    isFeatured?: boolean | null,
    interview_duration_min?: number | null,
    minimum_score?: number | null,
    banner_company_name?: string | null,
    company_profile?: string | null,
    available_positions?: string | null,
  } | null > | null,
};

export type GetConnectionsQueryVariables = {
  id: number,
};

export type GetConnectionsQuery = {
  getConnections?:  {
    __typename: "connections",
    id: number,
    provider?: string | null,
    unique_identifier?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    company?: string | null,
    headline?: string | null,
    industry?: string | null,
    location?: string | null,
    profile_url?: string | null,
    picture_url?: string | null,
    photo_file_name?: string | null,
    photo_content_type?: string | null,
    photo_file_size?: number | null,
    photo_updated_at?: string | null,
    user_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListConnectionssQuery = {
  listConnectionss?:  Array< {
    __typename: "connections",
    id: number,
    provider?: string | null,
    unique_identifier?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    company?: string | null,
    headline?: string | null,
    industry?: string | null,
    location?: string | null,
    profile_url?: string | null,
    picture_url?: string | null,
    photo_file_name?: string | null,
    photo_content_type?: string | null,
    photo_file_size?: number | null,
    photo_updated_at?: string | null,
    user_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetContactsQueryVariables = {
  id: number,
};

export type GetContactsQuery = {
  getContacts?:  {
    __typename: "contacts",
    id: number,
    employer_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    contacted?: number | null,
    last_contact_date?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListContactssQuery = {
  listContactss?:  Array< {
    __typename: "contacts",
    id: number,
    employer_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    contacted?: number | null,
    last_contact_date?: string | null,
    created_at: string,
    updated_at: string,
  } | null > | null,
};

export type GetDelayed_jobsQueryVariables = {
  id: number,
};

export type GetDelayed_jobsQuery = {
  getDelayed_jobs?:  {
    __typename: "delayed_jobs",
    id: number,
    priority?: number | null,
    attempts?: number | null,
    handler?: string | null,
    last_error?: string | null,
    run_at?: string | null,
    locked_at?: string | null,
    locked_by?: string | null,
    failed_at?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListDelayed_jobssQuery = {
  listDelayed_jobss?:  Array< {
    __typename: "delayed_jobs",
    id: number,
    priority?: number | null,
    attempts?: number | null,
    handler?: string | null,
    last_error?: string | null,
    run_at?: string | null,
    locked_at?: string | null,
    locked_by?: string | null,
    failed_at?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetEducationsQueryVariables = {
  id: number,
};

export type GetEducationsQuery = {
  getEducations?:  {
    __typename: "educations",
    id: number,
    school_name?: string | null,
    field_of_study?: string | null,
    degree?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    notes?: string | null,
    applicant_id?: number | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListEducationssQuery = {
  listEducationss?:  Array< {
    __typename: "educations",
    id: number,
    school_name?: string | null,
    field_of_study?: string | null,
    degree?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    notes?: string | null,
    applicant_id?: number | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetEmployer_statsQueryVariables = {
  id: number,
};

export type GetEmployer_statsQuery = {
  getEmployer_stats?:  {
    __typename: "employer_stats",
    id: number,
    employer_id?: number | null,
    profile_views?: number | null,
    job_views?: number | null,
    banner_clicks?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    job_applicants?: number | null,
  } | null,
};

export type ListEmployer_statssQuery = {
  listEmployer_statss?:  Array< {
    __typename: "employer_stats",
    id: number,
    employer_id?: number | null,
    profile_views?: number | null,
    job_views?: number | null,
    banner_clicks?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    job_applicants?: number | null,
  } | null > | null,
};

export type GetEmployersQueryVariables = {
  id: number,
};

export type GetEmployersQuery = {
  getEmployers?:  {
    __typename: "employers",
    id: number,
    name?: string | null,
    profile?: string | null,
    comments?: string | null,
    website?: string | null,
    is_federal_employer?: number | null,
    referal_source?: string | null,
    max_recruiters: number,
    number_job_postings_remaining?: number | null,
    job_postings_expire_at?: string | null,
    number_resume_searches_remaining?: number | null,
    resume_search_expire_at?: string | null,
    number_trial_resume_searches_remaining?: number | null,
    trial_resume_search_expire_at?: string | null,
    is_replace_all_on_import?: number | null,
    is_notify_job_postings?: number | null,
    banner_option?: string | null,
    banner_option_start_at?: string | null,
    service_option?: string | null,
    service_option_start_at?: string | null,
    sales_person_id?: number | null,
    track_image_url?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker_symbol?: string | null,
    twitter_handler?: string | null,
    video_url?: string | null,
    default_jobfair_profile?: string | null,
    default_hot_jobs?: string | null,
    default_job_keywords?: string | null,
    linkedin_url?: string | null,
  } | null,
};

export type ListEmployerssQuery = {
  listEmployerss?:  Array< {
    __typename: "employers",
    id: number,
    name?: string | null,
    profile?: string | null,
    comments?: string | null,
    website?: string | null,
    is_federal_employer?: number | null,
    referal_source?: string | null,
    max_recruiters: number,
    number_job_postings_remaining?: number | null,
    job_postings_expire_at?: string | null,
    number_resume_searches_remaining?: number | null,
    resume_search_expire_at?: string | null,
    number_trial_resume_searches_remaining?: number | null,
    trial_resume_search_expire_at?: string | null,
    is_replace_all_on_import?: number | null,
    is_notify_job_postings?: number | null,
    banner_option?: string | null,
    banner_option_start_at?: string | null,
    service_option?: string | null,
    service_option_start_at?: string | null,
    sales_person_id?: number | null,
    track_image_url?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker_symbol?: string | null,
    twitter_handler?: string | null,
    video_url?: string | null,
    default_jobfair_profile?: string | null,
    default_hot_jobs?: string | null,
    default_job_keywords?: string | null,
    linkedin_url?: string | null,
  } | null > | null,
};

export type GetForumsQueryVariables = {
  id: number,
};

export type GetForumsQuery = {
  getForums?:  {
    __typename: "forums",
    id: number,
    name?: string | null,
    description?: string | null,
    last_post_date?: string | null,
    post_count?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListForumssQuery = {
  listForumss?:  Array< {
    __typename: "forums",
    id: number,
    name?: string | null,
    description?: string | null,
    last_post_date?: string | null,
    post_count?: number | null,
    created_at: string,
    updated_at: string,
  } | null > | null,
};

export type GetInbox_entriesQueryVariables = {
  id: number,
};

export type GetInbox_entriesQuery = {
  getInbox_entries?:  {
    __typename: "inbox_entries",
    id: number,
    user_id?: number | null,
    resource_id?: number | null,
    resource_type?: string | null,
    added_by?: string | null,
    status?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListInbox_entriessQuery = {
  listInbox_entriess?:  Array< {
    __typename: "inbox_entries",
    id: number,
    user_id?: number | null,
    resource_id?: number | null,
    resource_type?: string | null,
    added_by?: string | null,
    status?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetInvitesQueryVariables = {
  id: number,
};

export type GetInvitesQuery = {
  getInvites?:  {
    __typename: "invites",
    id: number,
    jobfair_id?: number | null,
    booth_info_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    status?: string | null,
    company_name?: string | null,
  } | null,
};

export type ListInvitessQueryVariables = {
  jobFairId: number,
  querySuffix: string,
};

export type ListInvitessQuery = {
  listInvitess?:  Array< {
    __typename: "invites",
    id: number,
    jobfair_id?: number | null,
    booth_info_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    status?: string | null,
    company_name?: string | null,
  } | null > | null,
};

export type GetJob_applicationsQueryVariables = {
  id: number,
};

export type GetJob_applicationsQuery = {
  getJob_applications?:  {
    __typename: "job_applications",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListJob_applicationssQuery = {
  listJob_applicationss?:  Array< {
    __typename: "job_applications",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetJobfairsQueryVariables = {
  id: number,
};

export type GetJobfairsQuery = {
  getJobfairs?:  {
    __typename: "jobfairs",
    id: number,
    category?: string | null,
    date?: string | null,
    start_time?: string | null,
    end_time?: string | null,
    applicant_virtual_jf_help_url?: string | null,
    recruiter_virtual_jf_help_url?: string | null,
    daylight_savings?: string | null,
  } | null,
};

export type ListJobfairssQueryVariables = {
  currentDate: string,
};

export type ListJobfairssQuery = {
  listJobfairss?:  Array< {
    __typename: "jobfairs",
    id: number,
    category?: string | null,
    date?: string | null,
    start_time?: string | null,
    end_time?: string | null,
    applicant_virtual_jf_help_url?: string | null,
    recruiter_virtual_jf_help_url?: string | null,
    daylight_savings?: string | null,
  } | null > | null,
};

export type GetJobsQueryVariables = {
  id: number,
};

export type GetJobsQuery = {
  getJobs?:  {
    __typename: "jobs",
    id: number,
    status?: string | null,
    code?: string | null,
    title: string,
    description: string,
    input_method?: string | null,
    requirements?: string | null,
    expires_at?: string | null,
    company_name?: string | null,
    recruiter_id?: number | null,
    education_level?: string | null,
    experience_required?: number | null,
    payrate?: string | null,
    hr_website_url?: string | null,
    online_application_url?: string | null,
    security_clearance?: string | null,
    travel_requirements?: string | null,
    relocation_cost_paid?: string | null,
    show_company_profile: number,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListJobssQuery = {
  listJobss?:  Array< {
    __typename: "jobs",
    id: number,
    status?: string | null,
    code?: string | null,
    title: string,
    description: string,
    input_method?: string | null,
    requirements?: string | null,
    expires_at?: string | null,
    company_name?: string | null,
    recruiter_id?: number | null,
    education_level?: string | null,
    experience_required?: number | null,
    payrate?: string | null,
    hr_website_url?: string | null,
    online_application_url?: string | null,
    security_clearance?: string | null,
    travel_requirements?: string | null,
    relocation_cost_paid?: string | null,
    show_company_profile: number,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetMessagesQueryVariables = {
  id: number,
};

export type GetMessagesQuery = {
  getMessages?:  {
    __typename: "messages",
    id: number,
    category?: string | null,
    body?: string | null,
    action?: string | null,
    action_url?: string | null,
    recipient_id?: number | null,
    recipient_type?: string | null,
    sender_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListMessagessQuery = {
  listMessagess?:  Array< {
    __typename: "messages",
    id: number,
    category?: string | null,
    body?: string | null,
    action?: string | null,
    action_url?: string | null,
    recipient_id?: number | null,
    recipient_type?: string | null,
    sender_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetOfccpQueryVariables = {
  id: number,
};

export type GetOfccpQuery = {
  getOfccp?:  {
    __typename: "ofccp",
    id: number,
    query_string?: string | null,
    job_code?: string | null,
    job_title?: string | null,
    job_description?: string | null,
    applicant_name?: string | null,
    applicant_modification_date?: string | null,
    applicant_ethnicity?: string | null,
    applicant_gender?: string | null,
    resume_post_date?: string | null,
    resume?: string | null,
    employer_name?: string | null,
    recruiter_name?: string | null,
    recruiter_email?: string | null,
    employer_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListOfccpsQuery = {
  listOfccps?:  Array< {
    __typename: "ofccp",
    id: number,
    query_string?: string | null,
    job_code?: string | null,
    job_title?: string | null,
    job_description?: string | null,
    applicant_name?: string | null,
    applicant_modification_date?: string | null,
    applicant_ethnicity?: string | null,
    applicant_gender?: string | null,
    resume_post_date?: string | null,
    resume?: string | null,
    employer_name?: string | null,
    recruiter_name?: string | null,
    recruiter_email?: string | null,
    employer_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetPositionsQueryVariables = {
  id: number,
};

export type GetPositionsQuery = {
  getPositions?:  {
    __typename: "positions",
    id: number,
    employmenable_id?: number | null,
    employmenable_type?: string | null,
    company?: string | null,
    industry?: string | null,
    title?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    summary?: string | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker?: string | null,
  } | null,
};

export type ListPositionssQuery = {
  listPositionss?:  Array< {
    __typename: "positions",
    id: number,
    employmenable_id?: number | null,
    employmenable_type?: string | null,
    company?: string | null,
    industry?: string | null,
    title?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    summary?: string | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker?: string | null,
  } | null > | null,
};

export type GetPostsQueryVariables = {
  id: number,
};

export type GetPostsQuery = {
  getPosts?:  {
    __typename: "posts",
    id: number,
    title?: string | null,
    body?: string | null,
    forum_id?: number | null,
    user_id?: number | null,
    parent_id?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListPostssQuery = {
  listPostss?:  Array< {
    __typename: "posts",
    id: number,
    title?: string | null,
    body?: string | null,
    forum_id?: number | null,
    user_id?: number | null,
    parent_id?: number | null,
    created_at: string,
    updated_at: string,
  } | null > | null,
};

export type GetRecruiter_statsQueryVariables = {
  id: number,
};

export type GetRecruiter_statsQuery = {
  getRecruiter_stats?:  {
    __typename: "recruiter_stats",
    id: number,
    recruiter_id?: number | null,
    resume_views?: number | null,
    resume_searches?: number | null,
    login_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListRecruiter_statssQuery = {
  listRecruiter_statss?:  Array< {
    __typename: "recruiter_stats",
    id: number,
    recruiter_id?: number | null,
    resume_views?: number | null,
    resume_searches?: number | null,
    login_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetRegistrationsQueryVariables = {
  jobFairId: number,
  attendantId: number,
};

export type GetRegistrationsQuery = {
  getRegistrations?:  {
    __typename: "registrations",
    id: number,
    attendant_type?: string | null,
    attendant_id?: number | null,
    jobfair_id?: number | null,
    lunches_required?: number | null,
    available_positions?: string | null,
    include_in_employer_directory?: number | null,
    outlet_required?: number | null,
    resume_search_access?: number | null,
    paid?: number | null,
    banner_company_name?: string | null,
    fax?: string | null,
    url?: string | null,
    security_clearance?: string | null,
    attending?: number | null,
    enable_search?: number | null,
    ad_type?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    company_profile?: string | null,
    wp_option_1?: number | null,
    wp_option_2?: number | null,
    job_keywords?: string | null,
    referral?: string | null,
  } | null,
};

export type ListRegistrationssQueryVariables = {
  jobFairId: number,
  attendantId: number,
};

export type ListRegistrationssQuery = {
  listRegistrationss?:  Array< {
    __typename: "registrations",
    id: number,
    attendant_type?: string | null,
    attendant_id?: number | null,
    jobfair_id?: number | null,
    lunches_required?: number | null,
    available_positions?: string | null,
    include_in_employer_directory?: number | null,
    outlet_required?: number | null,
    resume_search_access?: number | null,
    paid?: number | null,
    banner_company_name?: string | null,
    fax?: string | null,
    url?: string | null,
    security_clearance?: string | null,
    attending?: number | null,
    enable_search?: number | null,
    ad_type?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    company_profile?: string | null,
    wp_option_1?: number | null,
    wp_option_2?: number | null,
    job_keywords?: string | null,
    referral?: string | null,
  } | null > | null,
};

export type GetResumesQueryVariables = {
  applicantId: number,
};

export type GetResumesQuery = {
  getResumes?:  {
    __typename: "resumes",
    id: number,
    applicant_id?: number | null,
    attached_resume_file_name?: string | null,
    attached_resume_content_type?: string | null,
  } | null,
};

export type ListResumessQuery = {
  listResumess?:  Array< {
    __typename: "resumes",
    id: number,
    applicant_id?: number | null,
    attached_resume_file_name?: string | null,
    attached_resume_content_type?: string | null,
  } | null > | null,
};

export type GetSchema_migrationsQueryVariables = {
  version: string,
};

export type GetSchema_migrationsQuery = {
  getSchema_migrations?:  {
    __typename: "schema_migrations",
    version: string,
  } | null,
};

export type ListSchema_migrationssQuery = {
  listSchema_migrationss?:  Array< {
    __typename: "schema_migrations",
    version: string,
  } | null > | null,
};

export type GetSeminarsQueryVariables = {
  id: number,
};

export type GetSeminarsQuery = {
  getSeminars?:  {
    __typename: "seminars",
    id: number,
    description?: string | null,
    duration?: string | null,
    time?: string | null,
    jobfair_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type ListSeminarssQuery = {
  listSeminarss?:  Array< {
    __typename: "seminars",
    id: number,
    description?: string | null,
    duration?: string | null,
    time?: string | null,
    jobfair_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null > | null,
};

export type GetSessionsQueryVariables = {
  id: number,
};

export type GetSessionsQuery = {
  getSessions?:  {
    __typename: "sessions",
    id: number,
    session_id: string,
    data?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListSessionssQuery = {
  listSessionss?:  Array< {
    __typename: "sessions",
    id: number,
    session_id: string,
    data?: string | null,
    created_at: string,
    updated_at: string,
  } | null > | null,
};

export type GetUsage_logsQueryVariables = {
  id: number,
};

export type GetUsage_logsQuery = {
  getUsage_logs?:  {
    __typename: "usage_logs",
    id: number,
    user_id?: number | null,
    log_type?: string | null,
    body?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type ListUsage_logssQuery = {
  listUsage_logss?:  Array< {
    __typename: "usage_logs",
    id: number,
    user_id?: number | null,
    log_type?: string | null,
    body?: string | null,
    created_at: string,
    updated_at: string,
  } | null > | null,
};

export type GetUsersQueryVariables = {
  id: number,
};

export type GetUsersQuery = {
  getUsers?:  {
    __typename: "users",
    id: number,
    type?: string | null,
    roles?: string | null,
    title?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    email?: string | null,
    job_title?: string | null,
    greeting?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
  } | null,
};

export type ListUserssQueryVariables = {
  jobFairId: number,
  offset: number,
  limit: number,
};

export type ListUserssQuery = {
  listUserss?:  Array< {
    __typename: "users",
    id: number,
    type?: string | null,
    roles?: string | null,
    title?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    email?: string | null,
    job_title?: string | null,
    greeting?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
  } | null > | null,
};

export type GetVideo_interviewsQueryVariables = {
  id: number,
};

export type GetVideo_interviewsQuery = {
  getVideo_interviews?:  {
    __typename: "video_interviews",
    id: number,
    recruiter_id?: number | null,
    interview_date?: string | null,
    start_time?: string | null,
    slot_duration?: number | null,
    slot_interval?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    email_count?: number | null,
    signal_session?: string | null,
  } | null,
};

export type ListVideo_interviewssQuery = {
  listVideo_interviewss?:  Array< {
    __typename: "video_interviews",
    id: number,
    recruiter_id?: number | null,
    interview_date?: string | null,
    start_time?: string | null,
    slot_duration?: number | null,
    slot_interval?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    email_count?: number | null,
    signal_session?: string | null,
  } | null > | null,
};

export type GetVideo_sessionsQueryVariables = {
  id: number,
};

export type GetVideo_sessionsQuery = {
  getVideo_sessions?:  {
    __typename: "video_sessions",
    id: number,
    video_interview_id?: number | null,
    applicant_id?: number | null,
    session_id?: string | null,
    start_time?: string | null,
    attendant_token?: string | null,
    recruiter_token?: string | null,
    attendant_email_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    session_order?: number | null,
    candidate_rating?: number | null,
  } | null,
};

export type ListVideo_sessionssQuery = {
  listVideo_sessionss?:  Array< {
    __typename: "video_sessions",
    id: number,
    video_interview_id?: number | null,
    applicant_id?: number | null,
    session_id?: string | null,
    start_time?: string | null,
    attendant_token?: string | null,
    recruiter_token?: string | null,
    attendant_email_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    session_order?: number | null,
    candidate_rating?: number | null,
  } | null > | null,
};

export type GetTopicQueryVariables = {
  id: string,
};

export type GetTopicQuery = {
  getTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTopicsQueryVariables = {
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTopicsQuery = {
  listTopics?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      key: string,
      model?: string | null,
      subscribers?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVJFPUserQueryVariables = {
  id: number,
};

export type GetVJFPUserQuery = {
  getVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVJFPUsersQueryVariables = {
  id?: number | null,
  filter?: ModelVJFPUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVJFPUsersQuery = {
  listVJFPUsers?:  {
    __typename: "ModelVJFPUserConnection",
    items:  Array< {
      __typename: "VJFPUser",
      id: number,
      firstName: string,
      lastName?: string | null,
      emailId: string,
      role: string,
      isAvailableForChat?: boolean | null,
      title?: string | null,
      job_title?: string | null,
      address?: string | null,
      designation?: string | null,
      greeting?: string | null,
      avatar?: string | null,
      ethnicity?: string | null,
      gender?: string | null,
      availability_date?: string | null,
      branch_of_service?: string | null,
      education_level?: string | null,
      geographic_preference?: string | null,
      security_clearance?: string | null,
      type_of_applicant?: string | null,
      military_status?: string | null,
      us_citizen?: boolean | null,
      willing_to_relocate?: boolean | null,
      employer_id?: number | null,
      picture_url?: string | null,
      summary?: string | null,
      presenceStatus?: UserStatus | null,
      lastConnectedTimeStamp?: number | null,
      lastDisconnectedTimeStamp?: number | null,
      lastPresenceJobFairId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewUserQueryVariables = {
  id: string,
};

export type GetReviewUserQuery = {
  getReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReviewUsersQueryVariables = {
  filter?: ModelReviewUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewUsersQuery = {
  listReviewUsers?:  {
    __typename: "ModelReviewUserConnection",
    items:  Array< {
      __typename: "ReviewUser",
      id: string,
      jobFairId: string,
      boothID: string,
      boothName: string,
      vjfpUserId: number,
      rating: number,
      notes?: string | null,
      ratedBy?: number | null,
      interviewSessionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetResumeQueryVariables = {
  id: string,
};

export type GetResumeQuery = {
  getResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListResumesQueryVariables = {
  filter?: ModelResumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResumesQuery = {
  listResumes?:  {
    __typename: "ModelResumeConnection",
    items:  Array< {
      __typename: "Resume",
      id: string,
      jobFairId: string,
      boothId: string,
      jobSeekerId: number,
      fileName: string,
      resumeS3Key: string,
      notes?: string | null,
      jobTitle?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBoothQueryVariables = {
  id: string,
};

export type GetBoothQuery = {
  getBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBoothsQueryVariables = {
  filter?: ModelBoothFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBoothsQuery = {
  listBooths?:  {
    __typename: "ModelBoothConnection",
    items:  Array< {
      __typename: "Booth",
      id: string,
      boothsInfoId: number,
      jobFairId: string,
      cgoCompanyId?: string | null,
      name: string,
      isFeatured: boolean,
      chatDuration: number,
      companyImageUrl: string,
      minimumScore: number,
      description?: string | null,
      hotJobSummary?: string | null,
      createdBy: string,
      recruiters?: Array< number | null > | null,
      sortOrder?: number | null,
      job_seekers?:  {
        __typename: "ModelQueueUserConnection",
        nextToken?: string | null,
        scannedCount?: number | null,
        count?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQueueUserQueryVariables = {
  boothId: string,
  priority: number,
  createdAt: string,
};

export type GetQueueUserQuery = {
  getQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type ListQueueUsersQueryVariables = {
  boothId?: string | null,
  priorityCreatedAt?: ModelQueueUserPrimaryCompositeKeyConditionInput | null,
  filter?: ModelQueueUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQueueUsersQuery = {
  listQueueUsers?:  {
    __typename: "ModelQueueUserConnection",
    items?:  Array< {
      __typename: "QueueUser",
      id: string,
      vjfpUserId: number,
      jobFairId: string,
      boothId: string,
      priority: number,
      score?: number | null,
      createdAt: string,
      nextInQueueSetBy?: number | null,
      interviewer?: number | null,
      version: number,
      status?: QueueUserStatus | null,
      maxScore: number,
      isInvited?: boolean | null,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type GetQuestionsQueryVariables = {
  jobFairId: string,
  boothId: string,
};

export type GetQuestionsQuery = {
  getQuestions?:  {
    __typename: "Questions",
    jobFairId: string,
    boothId: string,
    questions?:  Array< {
      __typename: "Question",
      id: string,
      name: string,
      rank: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuestionssQueryVariables = {
  jobFairId?: string | null,
  boothId?: ModelIDKeyConditionInput | null,
  filter?: ModelQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionssQuery = {
  listQuestionss?:  {
    __typename: "ModelQuestionsConnection",
    items:  Array< {
      __typename: "Questions",
      jobFairId: string,
      boothId: string,
      questions?:  Array< {
        __typename: "Question",
        id: string,
        name: string,
        rank: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionAnswersQueryVariables = {
  jobFairId: string,
  boothId: string,
  jobSeekerId: number,
};

export type GetQuestionAnswersQuery = {
  getQuestionAnswers?:  {
    __typename: "QuestionAnswers",
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    answers?:  Array< {
      __typename: "QuestionAnswer",
      id: string,
      QuestionId: string,
      name: string,
      rank: number,
      answer: boolean,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuestionAnswerssQueryVariables = {
  jobFairId?: string | null,
  boothIdJobSeekerId?: ModelQuestionAnswersPrimaryCompositeKeyConditionInput | null,
  filter?: ModelQuestionAnswersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionAnswerssQuery = {
  listQuestionAnswerss?:  {
    __typename: "ModelQuestionAnswersConnection",
    items:  Array< {
      __typename: "QuestionAnswers",
      jobFairId: string,
      boothId: string,
      jobSeekerId: number,
      answers?:  Array< {
        __typename: "QuestionAnswer",
        id: string,
        QuestionId: string,
        name: string,
        rank: number,
        answer: boolean,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageReceiptsQueryVariables = {
  id: string,
};

export type GetMessageReceiptsQuery = {
  getMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessageReceiptssQueryVariables = {
  id?: string | null,
  filter?: ModelMessageReceiptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMessageReceiptssQuery = {
  listMessageReceiptss?:  {
    __typename: "ModelMessageReceiptsConnection",
    items:  Array< {
      __typename: "MessageReceipts",
      id: string,
      jobFairId?: string | null,
      messageId: string,
      message:  {
        __typename: "Message",
        id: string,
        topicKey?: string | null,
        message: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      recipientId: number,
      read: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInterviewSessionQueryVariables = {
  id: string,
};

export type GetInterviewSessionQuery = {
  getInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInterviewSessionsQueryVariables = {
  id?: string | null,
  filter?: ModelInterviewSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInterviewSessionsQuery = {
  listInterviewSessions?:  {
    __typename: "ModelInterviewSessionConnection",
    items:  Array< {
      __typename: "InterviewSession",
      id: string,
      boothId: string,
      jobFairId: string,
      status: VideoSessionStatus,
      recruiterId: number,
      candidateId?: number | null,
      queueUserId: string,
      durationAdded: number,
      chatDuration?: number | null,
      endedBy?: number | null,
      chatTranscript?: string | null,
      recruiterZoomId?: number | null,
      jobSeekerZoomId?: number | null,
      candidateScore?: number | null,
      candidateMaxScore?: number | null,
      endReason?: EndReason | null,
      interviewStartedAt?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBoothVisitorQueryVariables = {
  id: string,
};

export type GetBoothVisitorQuery = {
  getBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBoothVisitorsQueryVariables = {
  filter?: ModelBoothVisitorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBoothVisitorsQuery = {
  listBoothVisitors?:  {
    __typename: "ModelBoothVisitorConnection",
    items:  Array< {
      __typename: "BoothVisitor",
      id: string,
      boothId: string,
      jobFairId: string,
      vjfpUserId: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewUserByJobFairIdAndInterviewSessionIdAndRatedByQueryVariables = {
  jobFairId?: string | null,
  interviewSessionIdRatedBy?: ModelReviewUserByJobFairIdAndJobSeekerIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewUserByJobFairIdAndInterviewSessionIdAndRatedByQuery = {
  listReviewUserByJobFairIdAndInterviewSessionIdAndRatedBy?:  {
    __typename: "ModelReviewUserConnection",
    items:  Array< {
      __typename: "ReviewUser",
      id: string,
      jobFairId: string,
      boothID: string,
      boothName: string,
      vjfpUserId: number,
      rating: number,
      notes?: string | null,
      ratedBy?: number | null,
      interviewSessionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListResumesByJobFairIdAndBoothIdQueryVariables = {
  jobFairId?: string | null,
  boothId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResumesByJobFairIdAndBoothIdQuery = {
  listResumesByJobFairIdAndBoothId?:  {
    __typename: "ModelResumeConnection",
    items:  Array< {
      __typename: "Resume",
      id: string,
      jobFairId: string,
      boothId: string,
      jobSeekerId: number,
      fileName: string,
      resumeS3Key: string,
      notes?: string | null,
      jobTitle?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListResumesByJobFairIdAndJobSeekerIdQueryVariables = {
  jobFairId?: string | null,
  jobSeekerId?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResumesByJobFairIdAndJobSeekerIdQuery = {
  listResumesByJobFairIdAndJobSeekerId?:  {
    __typename: "ModelResumeConnection",
    items:  Array< {
      __typename: "Resume",
      id: string,
      jobFairId: string,
      boothId: string,
      jobSeekerId: number,
      fileName: string,
      resumeS3Key: string,
      notes?: string | null,
      jobTitle?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBoothByJobFairIdQueryVariables = {
  jobFairId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBoothFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBoothByJobFairIdQuery = {
  listBoothByJobFairId?:  {
    __typename: "ModelBoothConnection",
    items:  Array< {
      __typename: "Booth",
      id: string,
      boothsInfoId: number,
      jobFairId: string,
      cgoCompanyId?: string | null,
      name: string,
      isFeatured: boolean,
      chatDuration: number,
      companyImageUrl: string,
      minimumScore: number,
      description?: string | null,
      hotJobSummary?: string | null,
      createdBy: string,
      recruiters?: Array< number | null > | null,
      sortOrder?: number | null,
      job_seekers?:  {
        __typename: "ModelQueueUserConnection",
        nextToken?: string | null,
        scannedCount?: number | null,
        count?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMessageReceiptsByJobFairIdAndRecipientIDQueryVariables = {
  jobFairId?: string | null,
  recipientId?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageReceiptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessageReceiptsByJobFairIdAndRecipientIDQuery = {
  listMessageReceiptsByJobFairIdAndRecipientID?:  {
    __typename: "ModelMessageReceiptsConnection",
    items:  Array< {
      __typename: "MessageReceipts",
      id: string,
      jobFairId?: string | null,
      messageId: string,
      message:  {
        __typename: "Message",
        id: string,
        topicKey?: string | null,
        message: string,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
      },
      recipientId: number,
      read: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInterviewSessionByJobFairIdAndRecruiterIdQueryVariables = {
  jobFairId?: string | null,
  recruiterId?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInterviewSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterviewSessionByJobFairIdAndRecruiterIdQuery = {
  listInterviewSessionByJobFairIdAndRecruiterId?:  {
    __typename: "ModelInterviewSessionConnection",
    items:  Array< {
      __typename: "InterviewSession",
      id: string,
      boothId: string,
      jobFairId: string,
      status: VideoSessionStatus,
      recruiterId: number,
      candidateId?: number | null,
      queueUserId: string,
      durationAdded: number,
      chatDuration?: number | null,
      endedBy?: number | null,
      chatTranscript?: string | null,
      recruiterZoomId?: number | null,
      jobSeekerZoomId?: number | null,
      candidateScore?: number | null,
      candidateMaxScore?: number | null,
      endReason?: EndReason | null,
      interviewStartedAt?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInterviewSessionByJobFairIdAndCandidateIdQueryVariables = {
  jobFairId?: string | null,
  candidateId?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInterviewSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterviewSessionByJobFairIdAndCandidateIdQuery = {
  listInterviewSessionByJobFairIdAndCandidateId?:  {
    __typename: "ModelInterviewSessionConnection",
    items:  Array< {
      __typename: "InterviewSession",
      id: string,
      boothId: string,
      jobFairId: string,
      status: VideoSessionStatus,
      recruiterId: number,
      candidateId?: number | null,
      queueUserId: string,
      durationAdded: number,
      chatDuration?: number | null,
      endedBy?: number | null,
      chatTranscript?: string | null,
      recruiterZoomId?: number | null,
      jobSeekerZoomId?: number | null,
      candidateScore?: number | null,
      candidateMaxScore?: number | null,
      endReason?: EndReason | null,
      interviewStartedAt?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInterviewSessionByJobFairIdAndQueueUserIdQueryVariables = {
  jobFairId?: string | null,
  queueUserId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInterviewSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterviewSessionByJobFairIdAndQueueUserIdQuery = {
  listInterviewSessionByJobFairIdAndQueueUserId?:  {
    __typename: "ModelInterviewSessionConnection",
    items:  Array< {
      __typename: "InterviewSession",
      id: string,
      boothId: string,
      jobFairId: string,
      status: VideoSessionStatus,
      recruiterId: number,
      candidateId?: number | null,
      queueUserId: string,
      durationAdded: number,
      chatDuration?: number | null,
      endedBy?: number | null,
      chatTranscript?: string | null,
      recruiterZoomId?: number | null,
      jobSeekerZoomId?: number | null,
      candidateScore?: number | null,
      candidateMaxScore?: number | null,
      endReason?: EndReason | null,
      interviewStartedAt?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateMessageByTopicSubscriptionVariables = {
  topicKey: string,
};

export type OnCreateMessageByTopicSubscription = {
  onCreateMessageByTopic?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQueueUserByBoothIdSubscriptionVariables = {
  boothId: string,
};

export type OnCreateQueueUserByBoothIdSubscription = {
  onCreateQueueUserByBoothId?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateQueueUserByBoothIdSubscriptionVariables = {
  boothId: string,
};

export type OnUpdateQueueUserByBoothIdSubscription = {
  onUpdateQueueUserByBoothId?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteQueueUserByBoothIdSubscriptionVariables = {
  boothId: string,
};

export type OnDeleteQueueUserByBoothIdSubscription = {
  onDeleteQueueUserByBoothId?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnCreateQueueUserByVJFPUserIdSubscriptionVariables = {
  vjfpUserId: number,
};

export type OnCreateQueueUserByVJFPUserIdSubscription = {
  onCreateQueueUserByVJFPUserId?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateQueueUserByVJFPUserIdSubscriptionVariables = {
  vjfpUserId: number,
};

export type OnUpdateQueueUserByVJFPUserIdSubscription = {
  onUpdateQueueUserByVJFPUserId?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteQueueUserByVJFPUserIdSubscriptionVariables = {
  vjfpUserId: number,
};

export type OnDeleteQueueUserByVJFPUserIdSubscription = {
  onDeleteQueueUserByVJFPUserId?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateVJFPUserByIdSubscriptionVariables = {
  id: number,
};

export type OnUpdateVJFPUserByIdSubscription = {
  onUpdateVJFPUserById?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnInterviewSessionByCandidateIdSubscriptionVariables = {
  candidateId: number,
};

export type OnInterviewSessionByCandidateIdSubscription = {
  onInterviewSessionByCandidateId?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInterviewSessionByCandidateIdSubscriptionVariables = {
  candidateId: number,
};

export type OnUpdateInterviewSessionByCandidateIdSubscription = {
  onUpdateInterviewSessionByCandidateId?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAddressesSubscription = {
  onCreateAddresses?:  {
    __typename: "addresses",
    id: number,
    label: string,
    addressable_id?: number | null,
    addressable_type?: string | null,
    street_address1?: string | null,
    street_address2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    email?: string | null,
    phone?: string | null,
    mobile?: string | null,
    fax?: string | null,
    website?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    extension?: string | null,
  } | null,
};

export type OnCreateApplicants_jobsSubscription = {
  onCreateApplicants_jobs?:  {
    __typename: "applicants_jobs",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateAuthsub_tokensSubscription = {
  onCreateAuthsub_tokens?:  {
    __typename: "authsub_tokens",
    id: number,
    token: string,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateBdrb_job_queuesSubscription = {
  onCreateBdrb_job_queues?:  {
    __typename: "bdrb_job_queues",
    id: number,
    args?: string | null,
    worker_name?: string | null,
    worker_method?: string | null,
    job_key?: string | null,
    taken?: number | null,
    finished?: number | null,
    timeout?: number | null,
    priority?: number | null,
    submitted_at?: string | null,
    started_at?: string | null,
    finished_at?: string | null,
    archived_at?: string | null,
    scheduled_at?: string | null,
    tag?: string | null,
    submitter_info?: string | null,
    runner_info?: string | null,
    worker_key?: string | null,
  } | null,
};

export type OnCreateBooth_recruitersSubscription = {
  onCreateBooth_recruiters?:  {
    __typename: "booth_recruiters",
    id: number,
    booth_info_id?: number | null,
    recruiter_id?: number | null,
  } | null,
};

export type OnCreateBooths_infoSubscription = {
  onCreateBooths_info?:  {
    __typename: "booths_info",
    id: number,
    employer_id?: number | null,
    jobfair_id?: number | null,
    isFeatured?: boolean | null,
    interview_duration_min?: number | null,
    minimum_score?: number | null,
    banner_company_name?: string | null,
    company_profile?: string | null,
    available_positions?: string | null,
  } | null,
};

export type OnCreateConnectionsSubscription = {
  onCreateConnections?:  {
    __typename: "connections",
    id: number,
    provider?: string | null,
    unique_identifier?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    company?: string | null,
    headline?: string | null,
    industry?: string | null,
    location?: string | null,
    profile_url?: string | null,
    picture_url?: string | null,
    photo_file_name?: string | null,
    photo_content_type?: string | null,
    photo_file_size?: number | null,
    photo_updated_at?: string | null,
    user_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateContactsSubscription = {
  onCreateContacts?:  {
    __typename: "contacts",
    id: number,
    employer_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    contacted?: number | null,
    last_contact_date?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateDelayed_jobsSubscription = {
  onCreateDelayed_jobs?:  {
    __typename: "delayed_jobs",
    id: number,
    priority?: number | null,
    attempts?: number | null,
    handler?: string | null,
    last_error?: string | null,
    run_at?: string | null,
    locked_at?: string | null,
    locked_by?: string | null,
    failed_at?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateEducationsSubscription = {
  onCreateEducations?:  {
    __typename: "educations",
    id: number,
    school_name?: string | null,
    field_of_study?: string | null,
    degree?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    notes?: string | null,
    applicant_id?: number | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateEmployer_statsSubscription = {
  onCreateEmployer_stats?:  {
    __typename: "employer_stats",
    id: number,
    employer_id?: number | null,
    profile_views?: number | null,
    job_views?: number | null,
    banner_clicks?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    job_applicants?: number | null,
  } | null,
};

export type OnCreateEmployersSubscription = {
  onCreateEmployers?:  {
    __typename: "employers",
    id: number,
    name?: string | null,
    profile?: string | null,
    comments?: string | null,
    website?: string | null,
    is_federal_employer?: number | null,
    referal_source?: string | null,
    max_recruiters: number,
    number_job_postings_remaining?: number | null,
    job_postings_expire_at?: string | null,
    number_resume_searches_remaining?: number | null,
    resume_search_expire_at?: string | null,
    number_trial_resume_searches_remaining?: number | null,
    trial_resume_search_expire_at?: string | null,
    is_replace_all_on_import?: number | null,
    is_notify_job_postings?: number | null,
    banner_option?: string | null,
    banner_option_start_at?: string | null,
    service_option?: string | null,
    service_option_start_at?: string | null,
    sales_person_id?: number | null,
    track_image_url?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker_symbol?: string | null,
    twitter_handler?: string | null,
    video_url?: string | null,
    default_jobfair_profile?: string | null,
    default_hot_jobs?: string | null,
    default_job_keywords?: string | null,
    linkedin_url?: string | null,
  } | null,
};

export type OnCreateForumsSubscription = {
  onCreateForums?:  {
    __typename: "forums",
    id: number,
    name?: string | null,
    description?: string | null,
    last_post_date?: string | null,
    post_count?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateInbox_entriesSubscription = {
  onCreateInbox_entries?:  {
    __typename: "inbox_entries",
    id: number,
    user_id?: number | null,
    resource_id?: number | null,
    resource_type?: string | null,
    added_by?: string | null,
    status?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateInvitesSubscription = {
  onCreateInvites?:  {
    __typename: "invites",
    id: number,
    jobfair_id?: number | null,
    booth_info_id?: number | null,
    applicant_id?: number | null,
    recruiter_id?: number | null,
    status?: string | null,
    company_name?: string | null,
  } | null,
};

export type OnCreateJob_applicationsSubscription = {
  onCreateJob_applications?:  {
    __typename: "job_applications",
    id: number,
    applicant_id?: number | null,
    job_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateJobfairsSubscription = {
  onCreateJobfairs?:  {
    __typename: "jobfairs",
    id: number,
    category?: string | null,
    date?: string | null,
    start_time?: string | null,
    end_time?: string | null,
    applicant_virtual_jf_help_url?: string | null,
    recruiter_virtual_jf_help_url?: string | null,
    daylight_savings?: string | null,
  } | null,
};

export type OnCreateJobsSubscription = {
  onCreateJobs?:  {
    __typename: "jobs",
    id: number,
    status?: string | null,
    code?: string | null,
    title: string,
    description: string,
    input_method?: string | null,
    requirements?: string | null,
    expires_at?: string | null,
    company_name?: string | null,
    recruiter_id?: number | null,
    education_level?: string | null,
    experience_required?: number | null,
    payrate?: string | null,
    hr_website_url?: string | null,
    online_application_url?: string | null,
    security_clearance?: string | null,
    travel_requirements?: string | null,
    relocation_cost_paid?: string | null,
    show_company_profile: number,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateMessagesSubscription = {
  onCreateMessages?:  {
    __typename: "messages",
    id: number,
    category?: string | null,
    body?: string | null,
    action?: string | null,
    action_url?: string | null,
    recipient_id?: number | null,
    recipient_type?: string | null,
    sender_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateOfccpSubscription = {
  onCreateOfccp?:  {
    __typename: "ofccp",
    id: number,
    query_string?: string | null,
    job_code?: string | null,
    job_title?: string | null,
    job_description?: string | null,
    applicant_name?: string | null,
    applicant_modification_date?: string | null,
    applicant_ethnicity?: string | null,
    applicant_gender?: string | null,
    resume_post_date?: string | null,
    resume?: string | null,
    employer_name?: string | null,
    recruiter_name?: string | null,
    recruiter_email?: string | null,
    employer_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreatePositionsSubscription = {
  onCreatePositions?:  {
    __typename: "positions",
    id: number,
    employmenable_id?: number | null,
    employmenable_type?: string | null,
    company?: string | null,
    industry?: string | null,
    title?: string | null,
    start_date?: string | null,
    end_date?: string | null,
    summary?: string | null,
    unique_identifier?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    ticker?: string | null,
  } | null,
};

export type OnCreatePostsSubscription = {
  onCreatePosts?:  {
    __typename: "posts",
    id: number,
    title?: string | null,
    body?: string | null,
    forum_id?: number | null,
    user_id?: number | null,
    parent_id?: number | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateRecruiter_statsSubscription = {
  onCreateRecruiter_stats?:  {
    __typename: "recruiter_stats",
    id: number,
    recruiter_id?: number | null,
    resume_views?: number | null,
    resume_searches?: number | null,
    login_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateRegistrationsSubscription = {
  onCreateRegistrations?:  {
    __typename: "registrations",
    id: number,
    attendant_type?: string | null,
    attendant_id?: number | null,
    jobfair_id?: number | null,
    lunches_required?: number | null,
    available_positions?: string | null,
    include_in_employer_directory?: number | null,
    outlet_required?: number | null,
    resume_search_access?: number | null,
    paid?: number | null,
    banner_company_name?: string | null,
    fax?: string | null,
    url?: string | null,
    security_clearance?: string | null,
    attending?: number | null,
    enable_search?: number | null,
    ad_type?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    company_profile?: string | null,
    wp_option_1?: number | null,
    wp_option_2?: number | null,
    job_keywords?: string | null,
    referral?: string | null,
  } | null,
};

export type OnCreateResumesSubscription = {
  onCreateResumes?:  {
    __typename: "resumes",
    id: number,
    applicant_id?: number | null,
    attached_resume_file_name?: string | null,
    attached_resume_content_type?: string | null,
  } | null,
};

export type OnCreateSchema_migrationsSubscription = {
  onCreateSchema_migrations?:  {
    __typename: "schema_migrations",
    version: string,
  } | null,
};

export type OnCreateSeminarsSubscription = {
  onCreateSeminars?:  {
    __typename: "seminars",
    id: number,
    description?: string | null,
    duration?: string | null,
    time?: string | null,
    jobfair_id?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
  } | null,
};

export type OnCreateSessionsSubscription = {
  onCreateSessions?:  {
    __typename: "sessions",
    id: number,
    session_id: string,
    data?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateUsage_logsSubscription = {
  onCreateUsage_logs?:  {
    __typename: "usage_logs",
    id: number,
    user_id?: number | null,
    log_type?: string | null,
    body?: string | null,
    created_at: string,
    updated_at: string,
  } | null,
};

export type OnCreateUsersSubscription = {
  onCreateUsers?:  {
    __typename: "users",
    id: number,
    type?: string | null,
    roles?: string | null,
    title?: string | null,
    first_name?: string | null,
    last_name?: string | null,
    email?: string | null,
    job_title?: string | null,
    greeting?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
  } | null,
};

export type OnCreateVideo_interviewsSubscription = {
  onCreateVideo_interviews?:  {
    __typename: "video_interviews",
    id: number,
    recruiter_id?: number | null,
    interview_date?: string | null,
    start_time?: string | null,
    slot_duration?: number | null,
    slot_interval?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    email_count?: number | null,
    signal_session?: string | null,
  } | null,
};

export type OnCreateVideo_sessionsSubscription = {
  onCreateVideo_sessions?:  {
    __typename: "video_sessions",
    id: number,
    video_interview_id?: number | null,
    applicant_id?: number | null,
    session_id?: string | null,
    start_time?: string | null,
    attendant_token?: string | null,
    recruiter_token?: string | null,
    attendant_email_count?: number | null,
    created_at?: string | null,
    updated_at?: string | null,
    session_order?: number | null,
    candidate_rating?: number | null,
  } | null,
};

export type OnCreateTopicSubscription = {
  onCreateTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTopicSubscription = {
  onUpdateTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTopicSubscription = {
  onDeleteTopic?:  {
    __typename: "Topic",
    id: string,
    key: string,
    model?: string | null,
    subscribers?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVJFPUserSubscription = {
  onCreateVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVJFPUserSubscription = {
  onUpdateVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVJFPUserSubscription = {
  onDeleteVJFPUser?:  {
    __typename: "VJFPUser",
    id: number,
    firstName: string,
    lastName?: string | null,
    emailId: string,
    role: string,
    isAvailableForChat?: boolean | null,
    title?: string | null,
    job_title?: string | null,
    address?: string | null,
    designation?: string | null,
    greeting?: string | null,
    avatar?: string | null,
    ethnicity?: string | null,
    gender?: string | null,
    availability_date?: string | null,
    branch_of_service?: string | null,
    education_level?: string | null,
    geographic_preference?: string | null,
    security_clearance?: string | null,
    type_of_applicant?: string | null,
    military_status?: string | null,
    us_citizen?: boolean | null,
    willing_to_relocate?: boolean | null,
    employer_id?: number | null,
    picture_url?: string | null,
    summary?: string | null,
    presenceStatus?: UserStatus | null,
    lastConnectedTimeStamp?: number | null,
    lastDisconnectedTimeStamp?: number | null,
    lastPresenceJobFairId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReviewUserSubscription = {
  onCreateReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReviewUserSubscription = {
  onUpdateReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReviewUserSubscription = {
  onDeleteReviewUser?:  {
    __typename: "ReviewUser",
    id: string,
    jobFairId: string,
    boothID: string,
    boothName: string,
    vjfpUserId: number,
    rating: number,
    notes?: string | null,
    ratedBy?: number | null,
    interviewSessionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateResumeSubscription = {
  onCreateResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateResumeSubscription = {
  onUpdateResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteResumeSubscription = {
  onDeleteResume?:  {
    __typename: "Resume",
    id: string,
    jobFairId: string,
    boothId: string,
    jobSeekerId: number,
    fileName: string,
    resumeS3Key: string,
    notes?: string | null,
    jobTitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBoothSubscription = {
  onCreateBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBoothSubscription = {
  onUpdateBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBoothSubscription = {
  onDeleteBooth?:  {
    __typename: "Booth",
    id: string,
    boothsInfoId: number,
    jobFairId: string,
    cgoCompanyId?: string | null,
    name: string,
    isFeatured: boolean,
    chatDuration: number,
    companyImageUrl: string,
    minimumScore: number,
    description?: string | null,
    hotJobSummary?: string | null,
    createdBy: string,
    recruiters?: Array< number | null > | null,
    sortOrder?: number | null,
    job_seekers?:  {
      __typename: "ModelQueueUserConnection",
      items?:  Array< {
        __typename: "QueueUser",
        id: string,
        vjfpUserId: number,
        jobFairId: string,
        boothId: string,
        priority: number,
        score?: number | null,
        createdAt: string,
        nextInQueueSetBy?: number | null,
        interviewer?: number | null,
        version: number,
        status?: QueueUserStatus | null,
        maxScore: number,
        isInvited?: boolean | null,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
      scannedCount?: number | null,
      count?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQueueUserSubscription = {
  onCreateQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateQueueUserSubscription = {
  onUpdateQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteQueueUserSubscription = {
  onDeleteQueueUser?:  {
    __typename: "QueueUser",
    id: string,
    vjfpUserId: number,
    jobFairId: string,
    boothId: string,
    priority: number,
    score?: number | null,
    createdAt: string,
    nextInQueueSetBy?: number | null,
    interviewer?: number | null,
    version: number,
    status?: QueueUserStatus | null,
    maxScore: number,
    isInvited?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    topicKey?: string | null,
    message: string,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMessageReceiptsSubscription = {
  onCreateMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageReceiptsSubscription = {
  onUpdateMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageReceiptsSubscription = {
  onDeleteMessageReceipts?:  {
    __typename: "MessageReceipts",
    id: string,
    jobFairId?: string | null,
    messageId: string,
    message:  {
      __typename: "Message",
      id: string,
      topicKey?: string | null,
      message: string,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
    },
    recipientId: number,
    read: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInterviewSessionSubscription = {
  onCreateInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInterviewSessionSubscription = {
  onUpdateInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInterviewSessionSubscription = {
  onDeleteInterviewSession?:  {
    __typename: "InterviewSession",
    id: string,
    boothId: string,
    jobFairId: string,
    status: VideoSessionStatus,
    recruiterId: number,
    candidateId?: number | null,
    queueUserId: string,
    durationAdded: number,
    chatDuration?: number | null,
    endedBy?: number | null,
    chatTranscript?: string | null,
    recruiterZoomId?: number | null,
    jobSeekerZoomId?: number | null,
    candidateScore?: number | null,
    candidateMaxScore?: number | null,
    endReason?: EndReason | null,
    interviewStartedAt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBoothVisitorSubscription = {
  onCreateBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBoothVisitorSubscription = {
  onUpdateBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBoothVisitorSubscription = {
  onDeleteBoothVisitor?:  {
    __typename: "BoothVisitor",
    id: string,
    boothId: string,
    jobFairId: string,
    vjfpUserId: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};
