import { toAbsoluteUrl } from '_metronic/helpers';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PaymentSuccess: React.FC = () => {
    const history = useHistory();


  return (
    <div className='card'>
      <div className='card-body p-lg-17'>
        <div className='mb-18'>
          <div className='text-center'>
            <h1 className='fs-2hx text-dark mb-5'>Payment Successful!</h1>
            <div className='fs-5 text-muted fw-bold'>
              Your job fair payment has been processed successfully.
            </div>
          </div>
        </div>
        <div className='mb-16'>
          <div className='d-flex flex-center'>
            <img
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
              className='mw-100 mh-300px'
              alt=''
            />
          </div>
        </div>
        <div className='mb-15 text-center'>
          <div className='fs-5 text-muted fw-bold'>
            Please wait for admin activation of your job fair.
            <br />
            You will be notified via email once the job fair is activated. Mean while you can setup Booth / Groups and Onboard Recruiters
          </div>
        </div>
        <div className='d-flex flex-center flex-column-fluid'>
          <button
            type='button'
            onClick={() => history.push('/employer-admin/manage-jobfairs')}
            className='btn btn-lg btn-primary fw-bolder'
          >
            Return to Job Fairs
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
