/* eslint-disable no-case-declarations */
import { LayoutSplashScreen } from '_metronic/layout/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { VJFPCache } from 'app/services/CacheUtils';
import { QueueUserService } from 'app/services/QueueUserService';
import { VJFPUserService } from 'app/services/VJFPUserService';
import { Auth, Hub } from 'aws-amplify';
import { HubCallback } from 'global';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { clearAllAmplifySubscriptions, setupQueueSubscriptionsByCandidateId } from '../amplify-subscriptions';
import { UserStatus, VJFPUser } from '../API';
import { CognitoUserResponse, IUser, Roles } from '../types';
import { useAppContext } from './app-context';

Auth.configure({
  cookieStorage: {
    domain: window.location.hostname === 'localhost' ? 'localhost' : 'videojobfairs.com',
    secure: false,
    expires: 1
  }
});

/* IAuthContext interface is used as return type of useAuth() consumer */

interface IAuthContext {
  user: IUser | null | undefined;
  login(username: string, password: string): Promise<CognitoUserResponse>;
  logout(jobFairId: string | undefined, userId: number): Promise<any>;
  completeNewPassword(user: any, password: string): Promise<any>;
}

// Type safety for react Props
interface Props {
  children?: ReactNode
}

// delegate login to Amplify's signin method
const login = (username: string, password: string): Promise<CognitoUserResponse> => Auth.signIn(username, password);

// delegate completeNewPassword to Amplify's completeNewPassword method ; used in forgot password functionality as it 2 step process after code verification
const completeNewPassword = (user: any, password: string) => Auth.completeNewPassword(user, password);

// delegate logout to Amplify's signOut method ; Also it updates the status as logged out from presence table.

const logout = async (jobFairId: string | undefined, userId: number): Promise<any> => {
  const signOutRef = await Auth.signOut();
  if (jobFairId) {
    await new VJFPUserService().updateStatus(jobFairId, userId, UserStatus.LOGGED_OUT);
  }
  return signOutRef;
};

// Get session from local storage
const getSession = (): Promise<CognitoUserSession | null> => Auth.currentSession();

// seggregated functionality from provider
const useCognito = () => {
  // user State
  const [user, setUser] = useState<IUser | null | undefined>(undefined);
  const { jobFair } = useAppContext();
  // Get Presence Status Helper , If there is no presence, we make sure to return logged-in status from here

  const getPresenceStatusFromVJFPUser = (vjfpUser: VJFPUser): UserStatus => {
    switch (vjfpUser?.presenceStatus) {
      case UserStatus.AWAY:
      case UserStatus.LOGGED_IN:
        return vjfpUser?.presenceStatus;
      case UserStatus.DISCONNECTED:
      case UserStatus.LOGGED_OUT:
      default:
        return UserStatus.LOGGED_IN;
    }
  };

  const getPresenceStatus = async (userId: number): Promise<UserStatus> => {
    const vjfpUser = await new VJFPUserService().getById(userId);
    return vjfpUser ? getPresenceStatusFromVJFPUser(vjfpUser) : UserStatus.LOGGED_OUT;
  };

  // Amplify relies on HubCallback events, so we are leveraging same here

  const authListener: HubCallback = async ({ payload: { event, data } }) => {
    /* There could multiple events like 'signIn', 'signOut', 'signIn_failiure' , etc...
    We will using these events to proceed to set userState for othe components */

    switch (event) {
      case 'signIn':
        const user = await new VJFPUserService().getByEmail(data.attributes.email);
        if (!user) { return; }

        const _roles = data.attributes['custom:role']?.split(',') || [];

        const userData: IUser = {
          id: user.id,
          username: data.username,
          firstName: data.attributes.given_name,
          lastName: data.attributes.family_name,
          email: data.attributes.email,
          roles: _roles,
          token: data.signInUserSession.accessToken,
          status: await getPresenceStatus(user.id),
          isAvailableForChat: !_roles.includes(Roles.Recruiter)
        };

        if (jobFair && userData?.id) {
          await new VJFPUserService().updateStatus(jobFair.id, userData.id, UserStatus.LOGGED_IN);
        } // create or update entry VJFPUserTable

        setUser(userData); // sets user
        VJFPCache.clear();
        break;

      case 'signOut':

        // set user state is null , as user is no longer available
        setUser(null);

        // unsubscribe from all active subscriptions
        clearAllAmplifySubscriptions();
        break;
    }
  };

  // Hub Events subscription setup
  useEffect(() => {
    Hub.listen('auth', authListener);
    return () => Hub.remove('auth', authListener);
  }, []);

  // In case user is already logged in , we can get currentUserInfo() from localstorage session and update user state. No need to re-login
  useEffect(() => {
    getSession().then((session) => {
      VJFPCache.clear();
      if (session && session.isValid()) {
        console.log('session valid');

        Auth.currentUserInfo().then(async (user: any) => {
          if (user.attributes) {
            let attributes: any;
            for (const key of document.cookie.split(';')) {
              const k = key.split('=');
              if (k[0].startsWith(' CognitoIdentityServiceProvider') && k[0].endsWith('userData')) { attributes = JSON.parse(decodeURIComponent(k[1])).UserAttributes; }
            };

            const userAttributes: Record<string, string> = {};
            for (let i = 0; i < attributes.length; i++) {
              userAttributes[attributes[i].Name] = attributes[i].Value;
            }

            const user = await new VJFPUserService().getByEmail(userAttributes.email);
            if (!user) { return; }

            window.sessionStorage.setItem('VJFPUserId', user.id.toString());
            const vjfpUserService = new VJFPUserService();

            if (!user?.id) {
              alert('user?.id is null');
              return;
            }

            const newStatus = getPresenceStatusFromVJFPUser(user);

            try {
              setUser({
                id: user.id,
                username: userAttributes.sub,
                firstName: userAttributes.given_name,
                lastName: userAttributes.family_name,
                email: userAttributes.email,
                roles: user.roles,
                token: session.getAccessToken(),
                status: newStatus,
                isAvailableForChat: !user.roles.includes(Roles.Recruiter)
              });
              if (user.roles.includes(Roles.Applicant)) {
                setupQueueSubscriptionsByCandidateId(user.id);
              }
            } catch (err) {
              console.log(err);
            }

            if (jobFair?.id && user.id && (user.lastPresenceJobFairId !== jobFair?.id || user.presenceStatus !== newStatus)) {
              await vjfpUserService.updateStatus(jobFair.id, user.id, newStatus);

              // If Role is Recruiter we need to Process Queue for the very first time (Note this will call process request and not immediately process queue)
              if (user.roles.includes(Roles.Recruiter)) {
                try {
                  new QueueUserService().processQueue(jobFair.id);
                } catch (err) {
                  console.log(err);
                }
              }
            }
          } else {
            setUser(null);
          }
        });
      } else {
        console.log('session not valid or do not exists');
        setUser(null);
      }
    }).catch(err => {
      console.log('Session Error', err);
      setUser(null);
    });
    return () => clearAllAmplifySubscriptions();
  }, []);

  // return IAuthContext
  return { user, login, logout, completeNewPassword };
};

// Context based on IAuthContext
const AuthContext = createContext<IAuthContext>({
  user: undefined,
  login,
  logout,
  completeNewPassword
});

// Context Consumer
export const useAuth = () => {
  return useContext(AuthContext);
};

// Context Provider
export const AuthProvider = ({ children }: Props) => {
  const auth = useCognito();
  return (<AuthContext.Provider value={auth}>
    {auth.user === undefined ? <LayoutSplashScreen /> : children}
  </AuthContext.Provider>);
};
