import { CreateReviewUserInput, ReviewUser, UpdateReviewUserInput } from 'app/API';
import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse } from 'app/types';
import axios from 'axios';

import { config } from '../../config';

const EndPoints = {
  ReviewUserByJobFairIdAndInterviewSessionAndRatedBy: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/reviewUsers/:interviewSession/:ratedBy`
};

export class ReviewUserService {
  async update (updateReviewUserInput: UpdateReviewUserInput) {
    try {
      const { jobFairId, interviewSessionId, ratedBy } = updateReviewUserInput;
      const result = await axios.put<NgAPIResponse<ReviewUser>>(UrlBuilder(EndPoints.ReviewUserByJobFairIdAndInterviewSessionAndRatedBy, { jobFairId, interviewSession: interviewSessionId, ratedBy }), updateReviewUserInput, await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async create (createReviewUserInput: CreateReviewUserInput) {
    try {
      const { jobFairId, interviewSessionId, ratedBy } = createReviewUserInput;
      const result = await axios.post<NgAPIResponse<ReviewUser>>(UrlBuilder(EndPoints.ReviewUserByJobFairIdAndInterviewSessionAndRatedBy, { jobFairId, interviewSession: interviewSessionId, ratedBy }), createReviewUserInput, await getCommonAxiosCommon());
      return result.data?.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getUserReviewBySessionIdAndRatedBy (jobFairId: string, interviewSession: string, ratedBy: number): Promise<ReviewUser | null> {
    const result = await axios.get<NgAPIResponse<ReviewUser[]>>(UrlBuilder(EndPoints.ReviewUserByJobFairIdAndInterviewSessionAndRatedBy, { jobFairId, interviewSession, ratedBy }), await getCommonAxiosCommon());
    return result.data?.data?.length ? result.data?.data[0] : null;
  }

  async updateCandidateRating (jobFairId : string, interviewSession: string, ratedBy: number, rating: number) {
    const result = await axios.put<NgAPIResponse<ReviewUser>>(UrlBuilder(EndPoints.ReviewUserByJobFairIdAndInterviewSessionAndRatedBy, { jobFairId, interviewSession, ratedBy }), { rating }, await getCommonAxiosCommon());
    return result.data?.data;
  }

  async updateCandidateNotes (jobFairId : string, interviewSession: string, ratedBy: number, notes: string) {
    const result = await axios.put<NgAPIResponse<ReviewUser>>(UrlBuilder(EndPoints.ReviewUserByJobFairIdAndInterviewSessionAndRatedBy, { jobFairId, interviewSession, ratedBy }), { notes }, await getCommonAxiosCommon());
    return result.data?.data;
  }
}
