import { MenuItem } from './MenuItem';
import { AdminRoutes } from 'app/modules/admin/AdminPage';

export function AdminMenuItems() {
  
  return (
    <>
      <MenuItem title="Manage Lobby" to={AdminRoutes.Lobby} />
    </>);
}
