import { HMSMessage } from "@100mslive/react-sdk";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import { useAuth } from "app/context/auth-context";
import { getInitials, getInitialsByFullName, getStyleByIndex } from "app/helpers/MiscUtils";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import ReactTimeAgo from 'react-time-ago'

function PicWrapper({ avatar, initials }: { avatar?: string, initials?: string }) {
    return (<div className="symbol symbol-35px symbol-circle">
        {avatar ?
            <img alt="Pic" src={toAbsoluteUrl('/media/interview/userPic.png')} /> :
            <div className={"symbol-label fs-2 fw-bold bg-" + getStyleByIndex(0) + " text-inverse-" + getStyleByIndex(0)}>{initials}</div>
        }
    </div>);
}
export const ChatMessage = ({ message }: { message: HMSMessage }) => {
    const { user } = useAuth();
    const isCurrentUser = message.senderUserId === user?.id.toString();
    return (<div key={'div-' + message.id.toLowerCase()} className={classNames("d-flex mb-10", { 'justify-content-start': !isCurrentUser }, { 'justify-content-end': isCurrentUser })}>
        <div className={classNames("d-flex flex-column", { 'align-items-start': !isCurrentUser }, { 'align-items-end': isCurrentUser })} style={{ width: "100%" }}>
            <div className="d-flex align-items-center mb-2">
                {isCurrentUser ?
                    <>
                        <div className="me-3">
                            <div><ReactTimeAgo date={message.time} locale="en-US" /></div>
                            <a
                                className="ms-1 fs-5 fw-bolder text-gray-900 text-hover-primary me-1">
                                You
                            </a>
                        </div>
                        <PicWrapper initials={getInitials(user?.firstName, user?.lastName)} />
                    </> :
                    <>

                        <PicWrapper initials={getInitialsByFullName(message.senderName)} />
                        <div className="ms-3">
                            <a
                                className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1 me-1">
                                {message.senderName || 'Participant'}
                            </a>
                            <div><ReactTimeAgo date={message.time} locale="en-US" /></div>
                        </div>
                    </>}
            </div>

            <Row style={{ alignItems: "center" }}>
                <div className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start mb-1" style={{minWidth:200, maxWidth: 400}}
                    data-kt-element="message-text">
                    {message.message}
                </div>
            </Row>
        </div>
    </div>
    );
}