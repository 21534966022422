import './profile.scss';

import { Badge, Image } from 'react-bootstrap';

import { UserStatus, QueueUserStatus } from 'app/API';
import { getStyleByIndex } from 'app/helpers/MiscUtils';
import { ReactNode } from 'react';

type JobSeekerProfileInfoPops = {
  index: number;
  profilePicUrl?: string;
  name?: string;
  initials?: string;
  score?: number;
  maxScore?: number;
  rating?: number;
  designation?: string;
  address?: string;
  status?: UserStatus | QueueUserStatus;
  children?: ReactNode;
  interviewer?: string;
  isInvited?: boolean;
  summary?: string;
  onInterviewClick?: ()=>void;
}

export const JobSeekerListItem = (props: JobSeekerProfileInfoPops) => {
  const bgMap: Map<UserStatus | QueueUserStatus, string> = new Map<UserStatus, string>();
  bgMap.set(UserStatus.AWAY, 'warning');
  bgMap.set(UserStatus.DISCONNECTED, 'danger');
  bgMap.set(UserStatus.LOGGED_OUT, 'info');
  bgMap.set(UserStatus.LOGGED_IN, 'info');
  bgMap.set(QueueUserStatus.IN_QUEUE, 'primary');
  bgMap.set(QueueUserStatus.IN_INTERVIEW, 'primary');
  bgMap.set(QueueUserStatus.NEXT_IN_QUEUE, 'success');
  bgMap.set(QueueUserStatus.BUSY, 'danger');

  const userStatus: UserStatus | QueueUserStatus = props.status === UserStatus.DISCONNECTED ? UserStatus.LOGGED_OUT : props.status || UserStatus.LOGGED_OUT;

  return (<div className="jobSeekerListItem">
    <div className="imageWrap">
      {props.profilePicUrl
        ? <Image src={props.profilePicUrl} />
        : <div className="symbol symbol-50px">
          <div className={'symbol-label fs-2 fw-bold bg-' + getStyleByIndex(props.index) + ' text-inverse-' + getStyleByIndex(props.index)}>{props.initials}</div>
        </div>
      }
    </div>
    <div className="d-flex align-items-center">
      <div className={'rightItem'}>
        <h4>{props.name}</h4>
        {props.interviewer && <em>Interviewer : {props.interviewer} {props.onInterviewClick && <a onClick={props.onInterviewClick}>Go to interview session</a>}</em> }
        {props.summary && <h5>{props.summary}</h5>}
        {props.address && <em>{props.address}</em>}
        {props.rating && <p> Rating {props.rating} / 5 </p>}
        {!props.children && <p className="score">
          {props.isInvited ? 
          <span className="svg-icon svg-icon-primary svg-icon-2x">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"/>
              </g>
            </svg>
          </span> 
          : <>{props.maxScore ? <> Score: {props.score} / {props.maxScore} </> : <>N/A </>}</>}
        </p>}
        { props.status && <Badge className={`me-auto mx-2 badge-${bgMap.get(userStatus)}`} ><small>{userStatus.replaceAll('_', ' ')}</small></Badge>}
      </div>
      {props.children &&
        <div className="rightItem" >
          {props.children}

        </div>}
    </div>

  </div>);
};
