import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';

const EndPoints = {
  GetMatrixByJobFairId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/matrix`,
  GetMatrixByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/matrix/:boothId`
};

export type MatrixResponse = {
  recruiterLogins: number
  jobSeekerLogins: number
  totalOnlineUsers: number
  totalOngoingInterviews: number
  totalInterviews: number
  totalVisitorsBooth: number
}

export type BoothWiseMatrixResponse = {
  boothVisitors: number,
  jobSeekerLogins: number,
  jobSeekersEnteredBooth: number,
  boothInterviews: number,
  resumesLeftAtBooth: number
}

export class MatrixService {
  async getMatrixByJobFairId (jobFairId: string) {
    const result = await axios.get<NgAPIResponse<MatrixResponse>>(
      UrlBuilder(EndPoints.GetMatrixByJobFairId, { jobFairId })
      , await getCommonAxiosCommon()
    );
    return result.data;
  }

  async getMatrixByJobFairIdAndBoothId (jobFairId: string, boothId: string) {
    const result = await axios.get<NgAPIResponse<BoothWiseMatrixResponse>>(
      UrlBuilder(EndPoints.GetMatrixByJobFairIdAndBoothId, { jobFairId, boothId })
      , await getCommonAxiosCommon()
    );
    return result.data;
  }
}
