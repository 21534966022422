/* This file will act as common entry point to register all Pubsub instances */

import { Message } from './API';
import { IInterviewSession, ILocalAppEvent, IPresence, IQueueUser } from './app-models';
import { PubSub } from './services/PubSub';
import { CGOInviteAppModel, ChangeEvent, JobFair } from './types';

export const UpdatePresencePubsub = new PubSub<IPresence, any>();

export const ChangeQueueUserPubsub = new PubSub<ChangeEvent<IQueueUser>, any>();

export const ChangeInterviewSessionPubsub = new PubSub<ChangeEvent<IInterviewSession>, any>();

export const CreateMessagePubsub = new PubSub<Message, any>();

export const LocalAppEventPubSub = new PubSub<ILocalAppEvent, any>();

export const ChangeInvitePubsub = new PubSub<CGOInviteAppModel, any>();

export const ChangeJobFairPubsub = new PubSub<ChangeEvent<JobFair>, any>();
