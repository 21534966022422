import { AuthPage } from 'app/modules/auth';
import ComingSoon from 'app/modules/ComingSoon';
import { Redirect, Route, Switch } from 'react-router-dom';

export function PublicRoutes () {
  return (
    <Switch>
      <Route path='/coming-soon' component={ComingSoon} />
      <Route path='/auth' component={AuthPage} />
      <Redirect to='/auth' />
    </Switch>
  );
}
