import React, { useState, useEffect } from 'react'
import OrganizationForm from './manage-organisation'
import ManageJobFairs from './manage-jobfairs'
import { KTSVG } from '_metronic/helpers'
import { useAuth } from 'app/context/auth-context';
import { OrgService, Organization } from 'app/services/OrgService';
import { AlertModal } from 'app/modules/shared/modals/AlertModal';

const EmployerAdminDashboard: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const { user } = useAuth();
  const [existingOrg, setExistingOrg] = useState<Organization | null>(null);
  const [showDetailsSavedModal, setShowDetailsSavedModal] = useState(false);
  const [steps, setSteps] = useState<{ title: string, description: string, enabled: boolean }[]>([
    { title: 'Organization', description: 'Setup Your Organization Details', enabled: true },
    { title: 'Jobfairs', description: 'Manage Your Job Fairs', enabled: false },
  ]);

  const fetchOrgData = async () => {
    if (user?.id) {
      try {
        const orgService = new OrgService();
        const orgData = await orgService.getOrgById(user.id);
        if (orgData) {
          setExistingOrg(orgData);
          setSteps(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[1].enabled = true;
            return updatedSteps;
          });
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    }
  };

  useEffect(() => {
    fetchOrgData();
  }, [user?.id]);


  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }

  const nextStep = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1)
    }
  }

  return (
    <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid between m-10' id='kt_create_account_stepper'>
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='stepper-nav'>
            {steps.map((step, index) => (
              <div key={index} className='stepper-item'>
                <div className={`stepper-wrapper d-flex ${currentStep === index + 1 ? 'current' : ''}`}>
                  <div className='stepper-icon w-40px h-40px'>
                    <span className='stepper-number'>{index + 1}</span>
                  </div>
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{step.title}</h3>
                    <div className='stepper-desc fw-semibold'>{step.description}</div>
                  </div>
                  {index < steps.length - 1 && <div className='stepper-line h-40px'></div>}
                </div>
              </div>

            ))}
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-grow-1 w-100'>
        {currentStep === 1 && <OrganizationForm existingOrg={existingOrg} onSave={()=>{
          setShowDetailsSavedModal(true);
          fetchOrgData();
          }} />}
        {currentStep === 2 && <ManageJobFairs />}

        <div className='d-flex flex-stack flex-wrap pt-10'>
          <button
            onClick={prevStep}
            type='button'
            className='btn btn-light-primary'
          >
          
            <KTSVG path='/media/icons/duotone/Navigation/Arrow-left.svg' className='svg-icon-4' />
            Back
          </button>
          <button disabled={currentStep !== steps.length && !steps[currentStep].enabled}
            onClick={nextStep}
            type='button'
            className='btn btn-primary'
          >
            Next
            <KTSVG path='/media/icons/duotone/Navigation/Arrow-right.svg' className='svg-icon-3' />
          </button>
        </div>
      </div>
      <AlertModal
        show={showDetailsSavedModal}
        onOK={() => {setShowDetailsSavedModal(false); nextStep();}}
        title="Success"
      >
        Details Saved Successfully </AlertModal>
    </div>
  )
}

export default EmployerAdminDashboard
