import { Col, Row } from "react-bootstrap";
import { invites, VJFPUser } from "app/API";
import { useEffect, useState } from "react";

import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BasePageProps, InviteStatus } from "app/types";
import { InviteService } from "app/services/InviteService";
import { JobSeekerListItem } from "app/modules/shared/profile/JobSeekerListItem";
import { Mailbox2 } from "react-bootstrap-icons";
import { VJFPUserService } from "app/services/VJFPUserService";
import { getInitials } from "app/helpers/MiscUtils";
import { useAppContext } from "app/context/app-context";
import { useAuth } from "app/context/auth-context";
import BlockUi from "react-block-ui";

interface InviteItem extends invites {
    candidate?: VJFPUser;
}

const RecruiterInvites = (props: BasePageProps) => {
    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const vjfpUserService = new VJFPUserService();
    const inviteService = new InviteService();
    const [invites, setInvites] = useState<(InviteItem | undefined)[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getInvites = () => {
        if (!user || !jobFair)
            return;
        setLoading(true);
        const invitations = inviteService.getByInvitedById(jobFair.id, user.id) as Promise<(InviteItem | undefined)[]>;
        invitations.then(async (_invites) => {
            if (_invites) {

                const promises = _invites.map(async (inv) => {
                    if (inv?.applicant_id)
                        inv.candidate = await vjfpUserService.getById(inv.applicant_id);
                });

                await Promise.all(promises);
                setInvites(_invites);
                setLoading(false);
            }
        });
    }

    useEffect(() => {

        getInvites();
    }, [user, jobFair]);

    const sortMap = new Map<string | undefined | null, number>();
    sortMap.set("PENDING", 1);
    sortMap.set("ACCEPTED", 2);
    sortMap.set("REJECTED", 3);
    sortMap.set(undefined, 0);
    sortMap.set(null, 0);

    return (
        <div className="chatPage">
            <AppTopNav title={props.title} badge={invites?.length ? invites?.length.toString() : "0"} breadcrumbs={props.breadcrumbs}>
            </AppTopNav>

            <div className="container-fluid">
                <Row className="mt-10">
                    <Col md={3} />
                    <Col md={6}>
                        <BlockUi tag="div" className="jobSeekerListing" blocking={loading}>
                            <div className="mb-6">
                                <h6>Your invitations to interview are shown below.</h6>
                            </div>

                            {invites && [...invites].sort((a, b) => ((sortMap.get(a?.status) || 0) > (sortMap.get(b?.status) || 0) ? 1 : -1)).map((invitation, index) => invitation &&
                                <JobSeekerListItem index={index} key={index}
                                    initials={getInitials(invitation?.candidate?.firstName, invitation?.candidate?.lastName)}
                                    name={invitation?.candidate?.firstName + ' ' + invitation?.candidate?.lastName}
                                    designation={invitation?.candidate?.designation || ''}
                                    address={invitation?.candidate?.address || ''}
                                >
                                    <div className="d-flex justify-content-end">
                                        {invitation.status === InviteStatus.PENDING ? <span className="text-info"> Pending</span> : invitation.status === InviteStatus.ACCEPTED ? <span className="text-success">Accepted</span> : <span className="text-danger">Declined</span>}
                                    </div>
                                </JobSeekerListItem>
                            )}

                            {!loading && invites && invites.length === 0 && <div className="d-flex border m-10 p-10 justify-content-center text-align-center">
                                <Mailbox2 color="grey" size={30} />
                                <p className="m-3"> No Invites sent by you</p>
                            </div>}
                        </BlockUi>
                    </Col>
                    <Col md={3} />
                </Row>
            </div>
        </div>
    );
}

export default RecruiterInvites;