import { BasePageProps } from "app/types";
import { Card, Col, Row } from "react-bootstrap";

import { useEffect, useState } from "react";
import { ArrowClockwise } from "react-bootstrap-icons";
import { BoothWiseMatrixResponse, MatrixService } from "app/services/MatrixService";
import { useAppContext } from "app/context/app-context";
import { KTSVG } from "_metronic/helpers";

interface RecruiterBoothMatrixProps extends BasePageProps {
    boothId: string;
}

const RecruiterBoothMatrix = (props: RecruiterBoothMatrixProps) => {
    const [matrix, setMatrix] = useState<BoothWiseMatrixResponse | null>(null);
    const [refreshCount, setRefreshCount] = useState<number>(0);
    const { jobFair } = useAppContext();

    useEffect(() => {
        if (!jobFair?.id) {
            return;
        }
        const matrixService = new MatrixService();
        matrixService.getMatrixByJobFairIdAndBoothId(jobFair.id, props.boothId).then((response) => {
            setMatrix(response.data);
        }
        );
       
    }, [jobFair, refreshCount]);

    return (

        <Card className="ms-4 me-10">
            <Card.Body>
                <Row>
                    <Col>
                        <span title="Refresh Matrix" onClick={() => setRefreshCount(value => value + 1)} style={{ cursor: "pointer" }} className="float-end m-2"><ArrowClockwise size={20} /></span>
                        <div className="d-flex flex-wrap flex-stack">
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                                <div className="d-flex flex-wrap">
                                    <InfoCard value={0} title='Total Candidates Registered' svgPath="/media/icons/duotone/General/user.svg"></InfoCard>
                                    <InfoCard value={matrix?.jobSeekerLogins || 0} title='Applicant Logins' svgPath="/media/icons/duotone/General/user.svg"></InfoCard>
                                    <InfoCard value={matrix?.boothVisitors || 0} title='Unique booth visitors' svgPath="/media/icons/duotone/General/user.svg"></InfoCard>
                                    <InfoCard value={matrix?.jobSeekersEnteredBooth || 0} title='Entered booth queue' svgPath="/media/icons/duotone/General/user.svg"></InfoCard>
                                    <InfoCard value={matrix?.boothInterviews || 0} title='Booth Interviews' svgPath="/media/icons/duotone/Communication/chat.svg"></InfoCard>
                                    <InfoCard value={matrix?.resumesLeftAtBooth || 0} title='Resumes Left' svgPath="/media/icons/duotone/Files/user-folder.svg"></InfoCard>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

    );
}

function InfoCard(props: { svgPath: string, value: number, title: string }) {

    return (<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
        <div className="d-flex align-items-center">
            <KTSVG path={props.svgPath} className="svg-icon svg-icon-3 svg-icon-success me-2" />
            <div className="fs-2 fw-bolder">{props.value}</div>
        </div>
        <div className="fw-bold fs-6 text-gray-400">{props.title}
        </div>
    </div>);
}


export default RecruiterBoothMatrix;