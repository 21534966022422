/* eslint-disable react-hooks/exhaustive-deps */

import {Header} from './Header'
import {MenuComponent} from '../../../assets/ts/components'
import {useEffect} from 'react'
import {useLayout} from '../../core'
import {useLocation} from 'react-router-dom'

export function HeaderWrapper() {
  const {pathname} = useLocation()
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <>
        <Header />
        {/* <Topbar /> */}
    </>
    )
}
