import './chat-message-item.scss';

import { ChatRecord } from "../chat-types";
import classNames from 'classnames';
import { getStyleByIndex } from 'app/helpers/MiscUtils';
import { toAbsoluteUrl } from '_metronic/helpers';
import { Col, Row } from "react-bootstrap-v5";

interface ChatMessageItemProps {
  record: ChatRecord;
  currentUserId: number;
  participantName?: string;
  participantInitials?: string;
  sendBy?: string;
}


function PicWrapper({ avatar, initials }: { avatar?: string, initials?: string }) {
  return (<div className="symbol symbol-35px symbol-circle">
    {avatar ?
      <img alt="Pic" src={toAbsoluteUrl('/media/interview/userPic.png')} /> :
      <div className={"symbol-label fs-2 fw-bold bg-" + getStyleByIndex(0) + " text-inverse-" + getStyleByIndex(0)}>{initials}</div>
    }
    </div>);
}


const ChatMessageItem = (props: ChatMessageItemProps) => {

  const {record, sendBy} = props;
    const {message, sender, timestamp} = record;
    const isCurrentUser = sender.name === sendBy;
    const timeCheck = new Date(timestamp).toLocaleTimeString();
    const chatMessage = Array.isArray(message) ? message : [message];
    return (
    <div className={classNames("d-flex mb-10", { 'justify-content-start': !isCurrentUser }, { 'justify-content-end': isCurrentUser })}>
      <div className={classNames("d-flex flex-column", { 'align-items-start': !isCurrentUser }, { 'align-items-end': isCurrentUser })} style={{width: "100%"}}>
        <div className="d-flex align-items-center mb-2">
          {isCurrentUser ?
            <>
              <div className="me-3">
                <a
                  className="ms-1 fs-5 fw-bolder text-gray-900 text-hover-primary me-1">
                  You
                </a>
              </div>
              <PicWrapper initials="you" />
            </> :
            <>

              <PicWrapper initials={props.participantInitials} />
              <div className="ms-3">
                <a
                  className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1 me-1">
                  {props.participantName || 'Participant'}
                </a>
              </div>
            </>}
            
        </div>
        {chatMessage.map((text, index) => (
          <Row key={"chatmessage_row_item_" + index} style={{width: "100%", alignItems: "center"}}>
            <Col md={5} style={{textAlign: "right"}}> 
              {timeCheck}
            </Col> 
            <Col md={7}>
              <div key={"chatmessage_col_item_" + index} className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start mb-1"
                data-kt-element="message-text">
                {text}
              </div>
            </Col> 
        </Row>
        ))}
      </div>
    </div>
    );
};

export default ChatMessageItem;