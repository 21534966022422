import { AppModal } from "app/modules/shared/modals/AppModal";
import { BoothService } from "app/services/BoothService";
import { CustomMenuItem } from "./CustomMenuItem";
import { MenuItem } from "./MenuItem";
import { RecruiterRoutes } from "app/modules/recruiter/RecruiterPage";
import { useAuth } from "app/context/auth-context";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "app/context/app-context";
import { LocalAppEventPubSub } from "app/app-events";
import { LocalAppEventType } from "app/app-models";
import { UserStatus } from "app/API";
import { navigate } from "app/helpers/MiscUtils";
import { Preview } from "app/modules/shared";
import { config } from "config";
import { VJFPUserService } from "app/services/VJFPUserService";
import { Button, Modal } from 'react-bootstrap'
import { Check } from 'react-bootstrap-icons'
import { HMSRoomProvider } from "@100mslive/react-sdk";

export function RecruiterMenuItems() {
  const { user } = useAuth();
  const { jobFair } = useAppContext();
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [showTestCameraModal, setShowTestCameraModal] = useState<boolean>(false);
  const [showTestVideoInstruction, setShowTestVideoInstruction] = useState<boolean>(false);
  const closeTestVideoInstruction = () => setShowTestVideoInstruction(false);
  const closeTestCameraModal = () => setShowTestCameraModal(false);
  const closeAlertModal = () => { setShowAlertModal(false) }
  
  useEffect(() => {
    const subscription = LocalAppEventPubSub.subscribe((eventType, event) => {
      if (event.type === LocalAppEventType.LeaveEvent) {
        setShowAlertModal(true);
      }
    });

    return () => {
      subscription.dispose();
    }
  }, [])


  return (
    <>
      <MenuItem title='Booth' to={RecruiterRoutes.Booth} />
      <MenuItem title='Invites' to={RecruiterRoutes.Invites} />
      <MenuItem title='History' to={RecruiterRoutes.History} />
      <CustomMenuItem title="Leave Event" newTab={false} onClick={() => { setShowAlertModal(true) }} />
      <div className="menu-item me-lg-1">
        <ul>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Test Video</a>
            <ul className="dropdown-menu" style={{ width: "180%" }}>
              <li>
                <CustomMenuItem title='Test your video' newTab={false} onClick={() => { setShowTestCameraModal(true) }} />
              </li>
              <li>
                <CustomMenuItem title='Release your camera' newTab={false} onClick={() => { setShowTestVideoInstruction(true) }} />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      
      {showTestCameraModal && <AppModal show={showTestCameraModal} title="Test Video" onSuccess={closeTestCameraModal} onReject={closeTestCameraModal} onHide={closeTestCameraModal}  >
        <HMSRoomProvider>
          <Preview roomId="641fea9567ad60b5a6af9570" isPreviewActive={showTestCameraModal} showDeviceSetting = {true} />
        </HMSRoomProvider>
      </AppModal>}
      <Modal
        className='modal-custom'
        tabIndex={-1}
        show={showTestVideoInstruction}
        onHide={closeTestVideoInstruction}
        centered
      >
        <Modal.Header closeButton={!!showTestVideoInstruction}>
          <Modal.Title>
            <h5>Release your camera</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p style={{ lineHeight: '1.2' }}>
              If you receive a notification that your camera is being used by another application,
              you must turn off that other app.
            </p>
            <br />
            <p>
              <strong>For a Windows computer</strong>, follow these instructions:
            </p>
            <br />
            <p>
              <strong>Find app using the camera and turn it off:</strong>
            </p>
            <ol start={1} type='1' style={{ listStyleType: 'decimal', marginLeft: '5%' }}>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                In the Windows search box of your computer, type{' '}
                <strong>Camera privacy settings</strong> and click to open it.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                Make sure everything is set to &ldquo;On&rdquo; as you scroll down the list of apps.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                If you see that one of the apps shows &ldquo;Currently in use&rdquo;, then you need
                to turn off that app to free your camera.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                To do this, go to Task Manager by right-clicking on the task bar at bottom of your
                computer and select &ldquo;Task Manager&rdquo; in the drop-down list. Click on the
                application in the list and click the &ldquo;End task&rdquo; button.
              </li>
            </ol>
            <br />
            <p>If that does not free your camera, try this:&nbsp;</p>
            <br />
            <p>
              <strong>Re-enable your camera:</strong>
            </p>
            <ol start={1} type='1' style={{ listStyleType: 'decimal', marginLeft: '5%' }}>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                In the Windows search box of your computer, type <strong>Device Manager</strong> and
                click to open it.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                Go to <strong>Cameras</strong> in list, and expand it to view all camera options.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                Right click on the camera device, and click &ldquo;Disable device.&rdquo;
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                Right click on it again and click &ldquo;Enable device.&rdquo;
              </li>
            </ol>
            <br />
            <br />
            <p>
              &nbsp;<strong>For a Mac</strong>, see which apps have access to your camera and
              microphone:
            </p>
            <ol start={1} type='1' style={{ listStyleType: 'decimal', marginLeft: '5%' }}>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                Go to System Preferences &gt; Security &amp; Privacy and click the Privacy tab at
                the top.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                In the left panel, Click on Camera.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                You will see a list of which apps have asked to use your camera.
              </li>
              <li style={{ marginTop: '5px', lineHeight: '1.2' }}>
                Uncheck the boxes next to the apps that might be using the camera, and then close
                those apps.
              </li>
            </ol>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={closeTestVideoInstruction}>
            {' '}
            <span className='svg-icon svg-icon-1'>
              <Check />
            </span>
            <span className='ml-1'>OK</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
