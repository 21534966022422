import { Cache } from 'aws-amplify';

// Define an interface for custom storage
interface CustomStorage {
  data: Record<string, any>;
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
  length: number;
  clear(): void;
  key(index: number): string | null;
}

// Implement the custom in-memory storage class
class InMemoryStorage implements CustomStorage {
  data: Record<string, any> = {};

  setItem (key: string, value: string): void {
    this.data[key] = value;
  }

  getItem (key: string): string | null {
    return this.data[key] || null;
  }

  removeItem (key: string): void {
    delete this.data[key];
  }

  get length (): number {
    return Object.keys(this.data).length;
  }

  clear (): void {
    this.data = {};
  }

  key (index: number): string | null {
    const keys = Object.keys(this.data);
    return keys[index] || null;
  }
}

export const VJFPCache = Cache.createInstance({
  keyPrefix: 'x',
  defaultTTL: 30 * 60 * 1000,
  storage: new InMemoryStorage()
});
