import React, { useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap-v5";
import $ from 'jquery';
import _ from 'lodash';
import { useAppContext } from "app/context/app-context";
import { ReviewUserService } from "app/services/ReviewUserService";
import { useAuth } from "app/context/auth-context";
import { usePersistFn } from "../../hooks";

type NotesProps = {
    title: string,
    initialValue: string;
    textAreaClass?: string;
    interviewSessionId?: string;
    vjfpUserId?: number;
    boothID?: string;
    boothName?: string;
}

export const Notes = (props: NotesProps) => {
    const txtInputRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [showExpand, setShowExpand] = useState(false);
    const { jobFair } = useAppContext();
    const { user } = useAuth();


    const saveNotes = usePersistFn(
        async (notes: string) => {
            if (!jobFair || !props.interviewSessionId || !props.boothID || !props.vjfpUserId || !user?.id)
                return

            const reviewUserService = new ReviewUserService();
            const reviewUser = await reviewUserService.getUserReviewBySessionIdAndRatedBy(jobFair.id, props.interviewSessionId, user.id);
            if (reviewUser?.id && reviewUser.ratedBy) {
                await reviewUserService.updateCandidateNotes(jobFair.id, props.interviewSessionId, reviewUser.ratedBy, notes);
            }
            else
                await reviewUserService.create({
                    vjfpUserId: props.vjfpUserId,
                    jobFairId: jobFair.id,
                    boothID: props.boothID,
                    boothName: props.boothName || '',
                    interviewSessionId: props.interviewSessionId,
                    ratedBy: user.id,
                    rating: 0,
                    notes: txtInputRef?.current?.value || ''
                });
        }
    );


    const saveNotesData = () => {
        if (txtInputRef.current?.value)
            saveNotes(txtInputRef.current.value);
    }

    const debouncedChangeHandler = useCallback(
        _.debounce(saveNotesData, 1000)
        , [txtInputRef.current]);

    const expandClick = () => {
        $('body').toggleClass('showNotes');
        const classEnabled = $('body').hasClass('showNotes');
        if (classEnabled) {
            setShowExpand(true);
        } else {
            setShowExpand(false);
        }
    }

    useEffect(() => {
        if (txtInputRef.current?.value) {
            txtInputRef.current.value = props.initialValue;
        }

    }, [props.initialValue])

    return (
        <>
            <a onClick={() => expandClick()} data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-placement="top" title={!showExpand ? "Expand Full View" : "Close Full view"}>
                <Row >
                    <Col md={10}>
                        {props.title}
                    </Col>
                    <Col md={2} >
                        {
                            !showExpand ? <svg xmlns="http://www.w3.org/2000/svg" width="13.997" height="13.997" viewBox="0 0 13.997 13.997">
                                <path id="Icon_awesome-expand-arrows-alt" data-name="Icon awesome-expand-arrows-alt" d="M14,12v3.5a.748.748,0,0,1-.75.75h-3.5a.751.751,0,0,1-.531-1.281l1.131-1.131L7,10.486l-3.35,3.353L4.78,14.966a.751.751,0,0,1-.531,1.281H.75A.748.748,0,0,1,0,15.5V12a.751.751,0,0,1,1.281-.531L2.412,12.6,5.763,9.249,2.411,5.9,1.281,7.03A.751.751,0,0,1,0,6.5V3a.748.748,0,0,1,.75-.75h3.5A.751.751,0,0,1,4.78,3.531L3.649,4.662,7,8.011l3.35-3.353L9.217,3.531A.751.751,0,0,1,9.748,2.25h3.5A.748.748,0,0,1,14,3V6.5a.751.751,0,0,1-1.281.531L11.586,5.9,8.234,9.249,11.586,12.6l1.13-1.131A.749.749,0,0,1,14,12Z" transform="translate(0 -2.25)" fill="#009ef7" />
                            </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                        fill="#000000">
                                        <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1"></rect>
                                    </g>
                                </svg>
                        }
                    </Col>
                </Row>
            </a>
            <div>
                <textarea className={props.textAreaClass || ''} onKeyUp={debouncedChangeHandler} ref={txtInputRef} placeholder="Enter your notes here..." rows={3} >
                </textarea>
            </div>
        </>
    );
};