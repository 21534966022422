import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse, QuestionsResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';

import { Question, QuestionAnswer, QuestionAnswerInput, QuestionAnswers, QuestionInput } from '../API';

const EndPoints = {
  QuestionsByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/boothQuestions/:boothId`,
  QuestionAnswersByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/boothQuestionAnswers/:boothId/:jobSeekerId`
};

export class QuestionAnswerService {
  async getAllQueueQuestionsByBoothId (jobFairId: string, boothId: string): Promise<Question[]> {
    const result = await axios.get<NgAPIResponse<QuestionsResponse>>(UrlBuilder(EndPoints.QuestionsByJobFairIdAndBoothId, { jobFairId, boothId }), await getCommonAxiosCommon());
    return result.data.data.questions;
  }

  async createOrUpdateAllQuestions (jobFairId: string, boothId: string, questions: QuestionInput[]) {
    try {
      const result = await axios.post<NgAPIResponse<Question[]>>(UrlBuilder(EndPoints.QuestionsByJobFairIdAndBoothId, { jobFairId, boothId }), { questions }, await getCommonAxiosCommon());
      return result.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  async deleteAllQuestions (jobFairId: string, boothId: string) {
    const result = await axios.delete<NgAPIResponse<Question[]>>(UrlBuilder(EndPoints.QuestionsByJobFairIdAndBoothId, { jobFairId, boothId }), await getCommonAxiosCommon());
    return result.data.data;
  }

  async createOrUpdateAllQuestionAnswers (jobFairId: string, boothId: string, jobSeekerId: number, answers: QuestionAnswerInput[]) {
    try {
      const result = await axios.post<NgAPIResponse<QuestionAnswer[]>>(UrlBuilder(EndPoints.QuestionAnswersByJobFairIdAndBoothId, { jobFairId, boothId, jobSeekerId }), { answers }, await getCommonAxiosCommon());
      return result.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getAllQuestionsAnswersByBoothAndUserId (jobFairId: string, boothId: string, jobSeekerId: number): Promise <QuestionAnswers> {
    const result = await axios.get<NgAPIResponse<QuestionAnswers>>(UrlBuilder(EndPoints.QuestionAnswersByJobFairIdAndBoothId, { jobFairId, boothId, jobSeekerId }), await getCommonAxiosCommon());
    return result.data.data;
  }
}
