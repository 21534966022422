import React from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { JobFair } from 'app/types';

interface Props {
    show: boolean;
    onHide: () => void;
    onSubmit: (values: any) => void;
    jobFair?: JobFair;
}

const CreateEditJobFairModal: React.FC<Props> = ({ show, onHide, onSubmit, jobFair }) => {
    const isEditMode = !!jobFair;

    const formik = useFormik({
        initialValues: {
            name: jobFair?.name || '',
            startDate: jobFair?.startDate || '',
            startTime: jobFair?.startTime || '',
            timezone: jobFair?.timezone || '',
            durationHours: jobFair?.durationHours || 3,
            durationMinutes: jobFair?.durationMinutes || 0,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required').max(100, 'Must be 100 characters or less'),
            startDate: Yup.date().required('Required'),
            startTime: Yup.string().required('Required'),
            timezone: Yup.string().required('Required'),
            durationHours: Yup.number().min(0).required('Required').max(10, 'Must be 10 or less'),
            durationMinutes: Yup.number().min(0).max(59).required('Required').max(59, 'Must be 59 or less'),
        }),
        onSubmit: (values) => {
            onSubmit(values);
            onHide();
        },
    });

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit Job Fair' : 'Create New Job Fair'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-name">Name</label>
                        <input
                            type="text"
                            id="name"
                            {...formik.getFieldProps('name')}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">Start Date</label>
                        <input
                            type="date"
                            id="startDate"
                            {...formik.getFieldProps('startDate')}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="startTime" className="form-label">Start Time</label>
                        <input
                            type="time"
                            id="startTime"
                            {...formik.getFieldProps('startTime')}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="timezone" className="form-label">Timezone</label>
                        <select
                            id="timezone"
                            {...formik.getFieldProps('timezone')}
                            className="form-control"
                        >
                            <option value="">Select Timezone</option>
                            <option value="EST">EST</option>
                            <option value="EDT">EDT</option>
                        </select>
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label>Duration</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                id="durationHours"
                                {...formik.getFieldProps('durationHours')}
                                isInvalid={formik.touched.durationHours && !!formik.errors.durationHours}
                            />
                            <InputGroup.Text>Hrs</InputGroup.Text>
                            <Form.Control
                                type="number"
                                id="durationMinutes"
                                {...formik.getFieldProps('durationMinutes')}
                                isInvalid={formik.touched.durationMinutes && !!formik.errors.durationMinutes}
                            />
                            <InputGroup.Text>Mins</InputGroup.Text>
                        </InputGroup>
                        {formik.touched.durationHours && formik.errors.durationHours && (
                            <Form.Control.Feedback type="invalid">{formik.errors.durationHours}</Form.Control.Feedback>
                        )}
                        {formik.touched.durationMinutes && formik.errors.durationMinutes && (
                            <Form.Control.Feedback type="invalid">{formik.errors.durationMinutes}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" className="btn btn-primary" onClick={() => formik.handleSubmit()}>{isEditMode ? 'Update' : 'Create'}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateEditJobFairModal;
