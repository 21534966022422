import { UserStatus, VJFPUser } from 'app/API';
import { Auth } from 'aws-amplify';

let lastIDToken: string|undefined;
export async function getCommonAxiosCommon () {
  if (!lastIDToken) { lastIDToken = (await Auth.currentSession()).getIdToken().getJwtToken(); }

  return {
    headers: {
      Authorization: lastIDToken
    }
  };
}

export function getInitials (firstName: string | undefined, lastName: string | null | undefined): string | undefined {
  if (firstName?.length && lastName?.length) {
    return firstName[0] + lastName[0];
  } else if (firstName?.length) {
    return firstName[0];
  } else if (lastName?.length) {
    return lastName[0];
  }

  return '--';
}

export function getInitialsByFullName (name: string|undefined): string | undefined {
  if (!name) return '--';

  const nameParts = name.split(' ');
  if (nameParts.length >= 2) {
    return nameParts[0][0] + nameParts[1][0];
  } else if (nameParts.length === 1) {
    return nameParts[0][0];
  }

  return '--';
}

export const getJobFairIdFromUrlParam = () => {
  const res = window.location.pathname.match(/^\/jobfairs\/(\d+)/);
  if (res?.length === 2) {
    return res[1];
  }
  return '0';
};

export function UrlParamReplace (url: string, param?: string, value?: string) {
  const replacedWithJobFairId = url.replaceAll(':jobFairId', getJobFairIdFromUrlParam());
  return (param && value) ? replacedWithJobFairId.replaceAll(param, value) : replacedWithJobFairId;
}

export function UrlBuilder (url: string, obj: any, extractJobFairIdFromQueryString:boolean = false) {
  let _url = extractJobFairIdFromQueryString ? url.replaceAll(':jobFairId', getJobFairIdFromUrlParam()) : url;
  Object.keys(obj).forEach(k => {
    if (obj[k] != null && (typeof obj[k] === 'string' || typeof obj[k] === 'number')) {
      _url = _url.replaceAll(`:${k}`, obj[k].toString());
    } else {
      console.log('Error in URL' + _url + ' key = ' + k + 'value = ' + obj[k]);
      throw new Error('Error in URL' + _url + ' key = ' + k + 'value = ' + obj[k]);
    }
  });

  return _url;
}

export function getUserStatus (vjfpUser?: VJFPUser) {
  // if (vjfpUser?.isAvailableForChat === false) {
  //   return UserStatus.AWAY;
  // }
  return vjfpUser?.presenceStatus || UserStatus.LOGGED_OUT;
}

export function isUserStatusMatched (vjfpUser1: VJFPUser, vjfpUser2: VJFPUser) {
  return vjfpUser1?.isAvailableForChat === vjfpUser2?.isAvailableForChat && vjfpUser1?.presenceStatus === vjfpUser2?.presenceStatus;
}

const bgMapInitials: Map<number, string> = new Map<number, string>();
bgMapInitials.set(0, 'info');
bgMapInitials.set(1, 'danger');
bgMapInitials.set(2, 'success');
bgMapInitials.set(3, 'warning');
bgMapInitials.set(4, 'primary');
bgMapInitials.set(5, 'secondary');

export function getStyleByIndex (index: number) {
  return bgMapInitials.get(index % 6);
};

const statusRank: Map<UserStatus | undefined | null, number> = new Map<UserStatus | undefined, number>();
statusRank.set(UserStatus.LOGGED_IN, 0);
statusRank.set(UserStatus.NEXT_IN_QUEUE, 1);
statusRank.set(UserStatus.IN_INTERVIEW, 2);
statusRank.set(UserStatus.AWAY, 3);
statusRank.set(UserStatus.DISCONNECTED, 4);
statusRank.set(UserStatus.LOGGED_OUT, 5);
statusRank.set(undefined, 6);
statusRank.set(null, 7);

export function presenseSorter (a?: UserStatus|null, b?: UserStatus|null) {
  return (statusRank.get(a) || 0) - (statusRank.get(b) || 0);
}

export function navigate (link: string, newTab: boolean = false) {
  const a = document.createElement('a');
  a.href = link;
  if (newTab) {
    a.setAttribute('target', '_blank');
  }
  a.click();
}

export const MappingBranch: Map<string, string> = new Map<string, string>();
MappingBranch.set('0', 'Unspecified');
MappingBranch.set('1', 'Army');
MappingBranch.set('2', 'Navy');
MappingBranch.set('3', 'Air Force');
MappingBranch.set('4', 'Marine Corps');
MappingBranch.set('5', 'Coast Guard');
MappingBranch.set('6', 'Army Reserve');
MappingBranch.set('7', 'Navy Reserve');
MappingBranch.set('8', 'Air Force Reserve');
MappingBranch.set('9', 'Marine Corps Reserve');
MappingBranch.set('10', 'Army National Guard');
MappingBranch.set('12', 'Coast Guard Reserve');
MappingBranch.set('13', 'Air National Guard');
MappingBranch.set('14', 'Other');

export const MappingType: Map<string, string> = new Map<string, string>();
MappingType.set('3', 'E-1 ');
MappingType.set('22', 'E-2');
MappingType.set('23', 'E-3');
MappingType.set('24', 'E-4');
MappingType.set('25', 'E-5');
MappingType.set('26', 'E-6');
MappingType.set('27', 'E-7');
MappingType.set('28', 'E-8');
MappingType.set('29', 'E-9');
MappingType.set('16', 'W-1');
MappingType.set('31', 'W-2');
MappingType.set('32', 'W-3');
MappingType.set('33', 'W-4');
MappingType.set('34', 'W-5');
MappingType.set('13', 'O-1');
MappingType.set('5', 'O-2');
MappingType.set('6', 'O-3');
MappingType.set('7', 'O-4');
MappingType.set('8', 'O-5');
MappingType.set('9', 'O-6');
MappingType.set('10', 'O-7');
MappingType.set('12', 'O-8');
MappingType.set('11', 'O-9');
MappingType.set('35', 'O-10');
MappingType.set('30', 'Military Spouse');
MappingType.set('37', 'Civilian');
MappingType.set('0', 'Unspecified');

export const MappingEducation: Map<string, string> = new Map<string, string>();
MappingEducation.set('0', 'Unspecified');
MappingEducation.set('1', 'High School or GED');
MappingEducation.set('3', "Associate's Degree");
MappingEducation.set('4', "Bachelor's Degree or higher");

export const MappingClearance: Map<string, string> = new Map<string, string>();
MappingClearance.set('0', 'Unspecified');
MappingClearance.set('1', 'Secret');
MappingClearance.set('2', 'Top Secret');
MappingClearance.set('3', 'None');

export const MappingGeography: Map<string, string> = new Map<string, string>();
MappingGeography.set('0', 'Unspecified');
MappingGeography.set('AW', 'Anywhere');
MappingGeography.set('NE', 'Northeast');
MappingGeography.set('SE', 'Southeast');
MappingGeography.set('WE', 'West');
MappingGeography.set('NW', 'Northwest');
MappingGeography.set('SW', 'Southwest');
MappingGeography.set('MW', 'Midwest');
MappingGeography.set('MA', 'MidAtlantic');
MappingGeography.set('MO', 'Mountain');
