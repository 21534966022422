/* eslint-disable camelcase */
import { getCommonAxiosCommon, UrlBuilder } from 'app/helpers/MiscUtils';
import { NgAPIResponse } from 'app/types';
import axios from 'axios';
import { config } from 'config';
import { BehaviorSubject, Observable } from 'rxjs';

import { Booth, GroupType, UpdateBoothInput } from '../API';
import { VJFPCache } from './CacheUtils';

const EndPoints = {
  BoothByJobFairIdAndBoothId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/booths/:boothId`,
  BoothsListByJobFairId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/booths`,
  BoothsListByJobFairIdAndByRecruiterId: `${config.vjfpNGRestAPI}/jobfairs/:jobFairId/booths/byRecruiterId/:recruiterId`
};

export class BoothService {
  async updateSortOrder (jobFairId: string, boothId: string, newSortOrder: number) {
    try {
      await axios.put(UrlBuilder(EndPoints.BoothByJobFairIdAndBoothId, { jobFairId, boothId }), {
        sortOrder: newSortOrder
      }, await getCommonAxiosCommon());
    } catch (err) {
      console.log(err);
    }
  }

  async updateBoothInfo (jobFairId: string, boothId: string, description: string, hotJobSummary: string, defaultInterviewDuration: number) {
    try {
      const result = await axios.put<NgAPIResponse<any>>(UrlBuilder(EndPoints.BoothByJobFairIdAndBoothId, { jobFairId, boothId }), {
        description,
        hotJobSummary,
        chatDuration: defaultInterviewDuration * 60000
      }, await getCommonAxiosCommon());

      if (!result.data?.isSuccess) { console.error(result.data.errors); }
    } catch (err) {
      console.log(err);
    }
  }

  async updateBooth (jobFairId: string, input: UpdateBoothInput) {
    try {
      const result = await axios.put<NgAPIResponse<any>>(UrlBuilder(EndPoints.BoothByJobFairIdAndBoothId, { jobFairId, boothId: input.id }), input, await getCommonAxiosCommon());
      if (!result.data?.isSuccess) { console.error(result.data.errors); }
      return input;
    } catch (err) {
      console.log(err);
    }
  }

  async getBoothRecruitersIds (boothInfoId: number) {
    return Error('New implementation required');
  }

  async getAllBooths (jobFairId: string): Promise<Booth[]> {
    try {
      const cachedBooths = VJFPCache.getItem(`BOOTHS_${jobFairId}`);
      if (cachedBooths) {
        return cachedBooths;
      }

      const result = await axios.get<NgAPIResponse<Booth[]>>(UrlBuilder(EndPoints.BoothsListByJobFairId, { jobFairId }), await getCommonAxiosCommon());
      if (!result.data?.isSuccess) {
        console.error(result.data.errors);
        return [];
      };

      const vjfpBooths = result?.data.data;

      VJFPCache.setItem(`BOOTHS_${jobFairId}`, vjfpBooths);
      return vjfpBooths;
    } catch (err) {
      console.log(err);
    }
    return [];
  }

  async getBoothByRecruiter (jobFairId: string, recruiterId: number) {
    try {
      const result = await axios.get<NgAPIResponse<Booth>>(UrlBuilder(EndPoints.BoothsListByJobFairIdAndByRecruiterId, { jobFairId, recruiterId }), await getCommonAxiosCommon());
      if (!result.data?.isSuccess) { console.error(result.data.errors); }
      return this.convertToBoothOrSubBooth(result.data.data, recruiterId);
    } catch (err) {
      console.log(err);
    }
  }

  async getBoothById (jobFairId: string, boothId: string, logBoothVisit: boolean = false) : Promise<Booth> {
    const cachedBooths = VJFPCache.getItem(`BOOTHS_${jobFairId}`);
    if (cachedBooths) {
      return cachedBooths.find((x: { id: string; }) => x.id === boothId);
    }
    let url = UrlBuilder(EndPoints.BoothByJobFairIdAndBoothId, { jobFairId, boothId });
    if (logBoothVisit) {
      url += '?logBoothVisit=true';
    }

    const result = await axios.get<NgAPIResponse<Booth>>(url, await getCommonAxiosCommon());
    const booth = result.data.data;
    return booth;
  }

  async getBoothOrSubBoothById (jobFairId: string, boothId: string, recruiterId: number, logBoothVisit: boolean = false) {
    const cachedBooths = VJFPCache.getItem(`BOOTHS_${jobFairId}`);
    if (cachedBooths) {
      return cachedBooths.find((x: { id: string; }) => x.id === boothId);
    }
    let url = UrlBuilder(EndPoints.BoothByJobFairIdAndBoothId, { jobFairId, boothId });
    if (logBoothVisit) {
      url += '?logBoothVisit=true';
    }

    const result = await axios.get<NgAPIResponse<Booth>>(url, await getCommonAxiosCommon());
    return this.convertToBoothOrSubBooth(result.data.data, recruiterId);
  }

  async convertToBoothOrSubBooth (_booth: Booth, recruiterId: number) {
    if (!_booth.subBooths || _booth.subBooths.length === 0) {
      return _booth;
    } else {
      const convert = (booth: Booth, subBooth: GroupType): Booth => {
        return {
          ...booth,
          id: booth.id + '-' + subBooth.id.toString(),
          name: booth.name + ' > ' + subBooth.name,
          chatDuration: subBooth.chatDuration == null ? booth.chatDuration : subBooth.chatDuration,
          minimumScore: subBooth.minimumScore == null ? booth.minimumScore : subBooth.minimumScore,
          description: subBooth.description,
          hotJobSummary: subBooth.hotJobSummary,
          recruiters: subBooth.recruiters,
          sortOrder: subBooth.sortOrder,
          parentBoothId: booth.id
        };
      };

      for (const subBooth of _booth.subBooths!) {
        if (subBooth.recruiters?.some(x => x === recruiterId)) {
          return convert(_booth!, subBooth);
        }
      }
      return _booth;
    }
  }
}

const boothViewCounterSubject = new BehaviorSubject<number>(0);

let _currentBoothViewCounter: number = 0;

export const ActiveBoothViewCounterState = {
  getCurrentBoothViewCounter: (): number => _currentBoothViewCounter,
  getBoothViewCounterObservable: (): Observable<number> => boothViewCounterSubject.asObservable(),
  setBoothViewCounter: (counter: number) => {
    if (counter !== _currentBoothViewCounter) { boothViewCounterSubject.next(counter); }
    _currentBoothViewCounter = counter;
  },
  clearBoothViewCounter: () => {
    boothViewCounterSubject.next(0);
    _currentBoothViewCounter = 0;
  }
};
