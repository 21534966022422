import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'app/context/auth-context'
import { useAppContext } from 'app/context/app-context';
import { Roles } from 'app/types';
import { QueueUserService } from 'app/services/QueueUserService';
import { VJFPUserService } from 'app/services/VJFPUserService';

export function Logout() {
  const { user, logout } = useAuth();
  const { jobFair } = useAppContext();
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true; // track if the component is still mounted

    if (user) {
      if(jobFair?.id && user.roles?.includes(Roles.Applicant)){
        new QueueUserService().leaveQueueFromAllBooths(jobFair.id, user.id);
      }
      logout(jobFair?.id, user.id).then(() => {
        if (isMounted) {
          setIsLoggedOut(true);
        }
      });
    }

    return () => {
      isMounted = false; // cleanup when the component is unmounted
    };
  }, [user, logout, jobFair]);

  if (isLoggedOut) {
    return <Redirect to='/auth/login' />;
  }

  return null;
}
