import { Button, Col, Container, Row } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";

import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BasePageProps, BoothState } from "app/types";
import BoothCard from "app/modules/shared/booth-card/BoothCard";
import { BoothService } from "app/services/BoothService";
import { QueueUserService } from "app/services/QueueUserService";
import { useAppContext } from "app/context/app-context";
import { useAuth } from "app/context/auth-context";
import { useHistory, useLocation } from "react-router-dom";
import { Booth, VideoSessionStatus } from 'app/API';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { getFileUrlFromS3 } from "app/helpers/awsUtils";
import { UrlParamReplace } from "app/helpers/MiscUtils";
import { JSRoutes } from "../JSPage";
import useIsMounted from "app/hooks/useIsMounted";
import { AlertModal } from "app/modules/shared/modals/AlertModal";
import { RxSubscription } from "app/RxSubscriptions";

const JSLobby = (props: BasePageProps) => {
    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const [booths, setBooths] = useState<Booth[]>([]);
    const [boothState, setBoothState] = useState<BoothState[]>([]);
    const boothService = new BoothService();
    const queueUserService = new QueueUserService();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const isMounted = useIsMounted('JSLobby');
    const [isEndedByRecruiter, setIsEndedByRecruiter] = useState<boolean>(false);

    useEffect(() => {
        if (!jobFair?.id) {
            return;
        }
        setLoading(true);
        (async () => {
            const _booths = await boothService.getAllBooths(jobFair.id);
            
            if (isMounted()) {
                setBooths(_booths.sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0)) as Booth[]);
                setLoading(false);
                if(_booths?.length === 1){
                    onBoothDetail(_booths[0].id); 
                }
            }
        })();
    }, [jobFair?.id, isMounted]);



    // Set required Booth State for company logo
    useEffect(() => {
        if (!booths?.length)
        return;

        const _boothStates = [...boothState];
        (async () => {
            await Promise.all(booths.map(async b => {
                try {
                    const bs = _boothStates.find(s => s.boothId == b.id);
                    const _companyUrl = b?.cgoCompanyId ? (await getFileUrlFromS3('employer_logos/' + b.cgoCompanyId + '.jpg', 'employer_logos/')).fileUrl.toString() : '';
                    if (bs)
                        b.companyImageUrl = _companyUrl
                    else
                        _boothStates.push({ boothId: b.id, isSubscribed: false, companyImageUrl: _companyUrl })
                }
                catch (err) {
                    console.log('logo missing for employer id' + b?.cgoCompanyId);
                }
            }));
            if (isMounted())
                setBoothState(_boothStates);
            return true;
        })();
    }, [booths, isMounted])

    const removeInQueueStatus = useCallback(
        (boothId: string) => {
            setBoothState(prev => {
                const bt = prev.find(x => x.boothId === boothId);
                if (bt) {
                    bt.isSubscribed = false;
                }
                return [...prev];
            })
        },
        [boothState],
    )


    const setInQueueStatus = useCallback(
        async () => {
            if (!jobFair?.id || !user?.id || !booths?.length || !boothState?.length)
                return;

            const subscribedBoothIds = await queueUserService.getAllSubscribedBoothsByJobSeeker(jobFair.id, user.id);
            if (!isMounted()) return;
            const _boothStates = [...boothState];
            _boothStates.filter(x => !subscribedBoothIds.includes(x.boothId)).forEach(bs => {
                bs.isSubscribed = false;
            });

            subscribedBoothIds.forEach(x => {
                const bs = (_boothStates.find((y) => y?.boothId === x)) as BoothState;
                if (bs) {
                    bs.isSubscribed = true;
                }
                else
                    _boothStates.push({ boothId: x, isSubscribed: true, companyImageUrl: '' });
            });
            setBoothState(_boothStates);
        },
        [booths, boothState, user, isMounted]
    )


    // Set required Booth State If user is Entered or Subscribed to a Booth
    useEffect(() => {
        if (!jobFair?.id || !user?.id || !boothState?.length)
            return;
        RxSubscription.onChangeInterviewSessionByCandidateIdObservable().subscribe(async (event) => {
            if (event && event.mode === 'update' && event.value.status === VideoSessionStatus.ENDED && isMounted()) {
                if (event.value.endedBy !== user.id)
                    setIsEndedByRecruiter(true);
                setTimeout(() => {
                    removeInQueueStatus(event.value.boothId);
                    //  window.location.reload();
                }, 2000);

                RxSubscription.clearInterviewSessionByCandidateId();
            }
        });
        (async () => await setInQueueStatus())();
    }, [jobFair?.id, user?.id, boothState?.length, isMounted]);

    const getBoothState = useCallback(
        (boothId: string) => {
            return boothState.find(bs => bs.boothId === boothId);
        },
        [boothState]
    );

    const onBoothDetail = (boothId: string) => {
        history.push({
            pathname: UrlParamReplace(JSRoutes.BoothDetail, ':boothId', boothId),
            state: {
                boothState: getBoothState(boothId),
                booth: booths.find(b => b.id === boothId)
            }
        });
    }

    return (
        <>
            <div className="chatPage">
                <AppTopNav title={props.title} breadcrumbs={props.breadcrumbs}>
                </AppTopNav>
                <BlockUi id="boothLoadingContainer" tag="div" className="container-fluid" blocking={loading}>
                    <Container className="mt-4 lobbyCard">
                        <Row>
                            {booths && booths.map((b, index) => {
                                const _bs = getBoothState(b.id);
                                return (<Col className="col-md-4" key={"card-" + index} >
                                    <BoothCard isFeatured={b.isFeatured} logo={_bs?.companyImageUrl}>
                                        <BoothCard.Title isInQueue={_bs?.isSubscribed}>{b.name}</BoothCard.Title>
                                        <BoothCard.Description>{(b.description?.length || 0) > 150 ? b.description?.substr(0, 150) + '...' : (b.description) || ''}</BoothCard.Description>
                                        <div className="cardBodyWrap">
                                            <BoothCard.Body jobsCount={0} recruitersCount={b.recruiters?.length || 0} >
                                                <Button id={"boothBtn" + b.name.replaceAll(' ', '')} variant="success" className="w-100 mt-3 py-3" onClick={() => { onBoothDetail(b.id) }}>Enter Booth</Button>
                                            </BoothCard.Body>
                                        </div>
                                    </BoothCard>
                                </Col>);
                            })
                            }

                        </Row>
                    </Container>
                </BlockUi>
            </div>
            <AlertModal title="Interview has ended"
                show={isEndedByRecruiter}
                onOK={async () => {
                    setIsEndedByRecruiter(false);
                }}>
            </AlertModal>
        </>
    );
}

export default JSLobby;