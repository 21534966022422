
import { useAuth } from "app/context/auth-context";
import { Roles } from "app/types";

type PropState = {
    showEndChatModal: () => void;
}

export const EndChatButton = (props: PropState) => {
    const { user } = useAuth();
    return (
        <>
            <div className="end">
                <button  onClick={() => props.showEndChatModal()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36.637" height="36.637"
                        viewBox="0 0 36.637 36.637">
                        <g id="phone-call" transform="translate(37.018 17.956) rotate(135)">
                            <g id="Group_18" data-name="Group 18" transform="translate(0.539 0)">
                                <path id="Path_42" data-name="Path 42"
                                    d="M25.74,19.033,22.12,15.413a2.407,2.407,0,0,0-4.007.9,2.463,2.463,0,0,1-2.844,1.551c-2.585-.646-6.075-4.007-6.721-6.721A2.343,2.343,0,0,1,10.1,8.3,2.407,2.407,0,0,0,11,4.3L7.386.679a2.581,2.581,0,0,0-3.49,0L1.44,3.134c-2.456,2.585.259,9.435,6.333,15.51s12.925,8.919,15.51,6.333l2.456-2.456A2.581,2.581,0,0,0,25.74,19.033Z"
                                    transform="translate(-0.539 0)" fill="#fff" />
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </>);
}