import { useDropzone, DropzoneOptions } from 'react-dropzone';

interface DropzoneComponentOptions extends DropzoneOptions {
  disabledMsg?: string
}

function DropzoneComponent(props: DropzoneComponentOptions) {

  const {
    getRootProps,
    getInputProps,
  } = useDropzone(props);

  return (
    <div {...getRootProps()}>
      {!props.disabled && <input {...getInputProps()} />}
      <div className="currentBox">
        <i className="fas fa-cloud-upload-alt"></i>
        <p>Drag and Drop File to Upload</p>
        <small>or</small>
        <button
          disabled={props.disabled}
          className={"btn btn-dark" + (!props.disabled ? "" : " disabled")}>
          <i className="fas fa-plus"></i>
          Select File to Upload
        </button>

        {props.disabled && props.disabledMsg && <h1>
          {props.disabledMsg}
        </h1>}
      </div>

    </div>
  )
}

export default DropzoneComponent;