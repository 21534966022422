import { useEffect, useState } from "react";
import { useAuth } from 'app/context/auth-context';
import { ToastContainer, Toast } from 'react-bootstrap';
import { ExclamationOctagonFill } from 'react-bootstrap-icons';
import { useSound } from 'use-sound';
import { toAbsoluteUrl } from '_metronic/helpers';
import { useAppContext } from 'app/context/app-context';
import { BoothService } from 'app/services/BoothService';
import useIsMounted from 'app/hooks/useIsMounted';
import { AppNotification, Roles } from 'app/types';
import { ChangeQueueUserPubsub } from 'app/app-events';
import { PubSubEventType } from 'app/services/PubSub';

const boothService = new BoothService();

const Notifications = () => {
    const [notifications, setNotifications] = useState<AppNotification[]>([]);
    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const [playSound] = useSound(
        toAbsoluteUrl('/media/sounds/ding.mp3'),
        { volume: 0.5 }
    );
    const [isRing, setIsRing] = useState<boolean>(false);

    const isMounted = useIsMounted('Notifications');

    useEffect(() => {
        if (!user?.id || !jobFair?.id) return;

        const createQueueUserSubscription = ChangeQueueUserPubsub.subscribe(async (type, event) => {
            if (type === PubSubEventType.Amplify && user.roles?.includes(Roles.Applicant) && event.mode === 'upsert') {
                if (event.value.priority === 0) {
                    const booth = await boothService.getBoothById(jobFair.id, event.value.boothId);
                    if (!isMounted()) return;
                    if (booth?.name) {
                        const n = { headerText: 'Notification', bodyText: 'Please stay online -- You are next to interview with ' + booth.name };
                        setNotifications(prevNotifications => [...prevNotifications, n]); // Using functional update
                        setIsRing(true);
                    }
                } else {
                    setNotifications([]);
                }
            }
        });

        return () => {
            createQueueUserSubscription.dispose();
        };
    }, [user?.id, jobFair?.id, isMounted]);

    useEffect(() => {
        if (isRing) {
            playSound();
            setIsRing(false);
        }
    }, [isRing, playSound]);

    const removeToast = (n: AppNotification) => {
        setNotifications(notifications.filter(x => x !== n));
    };

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            className="position-relative"
            style={{ width: '100%', zIndex: 1000 }}
        >
            <ToastContainer position="top-center" className="p-3">
                {notifications.map((x: AppNotification, index: number) =>
                    (<Toast onClose={() => { removeToast(x) }} key={index}>
                        <Toast.Header className="bg-light-info">
                            <span className="me-1 text-info"> <ExclamationOctagonFill /> </span>
                            <strong className="me-auto">{x.headerText}</strong>
                        </Toast.Header>
                        <Toast.Body>{x.bodyText}</Toast.Body>
                    </Toast>)
                )}
            </ToastContainer>
        </div>
    );
}

export default Notifications;
