import { Col, Row } from "react-bootstrap";
import { Check2, Mailbox2, X } from "react-bootstrap-icons";
import { Booth, invites, VJFPUser } from "app/API";
import { getInitials } from "app/helpers/MiscUtils";
import { useCallback, useEffect, useState } from "react";

import { AppModal } from "app/modules/shared/modals/AppModal";
import { AppTopNav } from "app/modules/shared/AppTopNav";
import { BasePageProps, InviteStatus, JobFairStatus } from "app/types";
import { InviteService } from "app/services/InviteService";
import { QueueUserService } from "app/services/QueueUserService";
import { RecruiterListItem } from "app/modules/shared/profile/RecruiterListItem";
import { VJFPUserService } from "app/services/VJFPUserService";
import classNames from "classnames";
import { useAppContext } from "app/context/app-context";
import { useAuth } from "app/context/auth-context";
import { BoothService } from "app/services/BoothService";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import { QuestionAnswerService } from "app/services/QuestionAnswerService";

interface InviteItem extends invites {
    recruiter?: VJFPUser;
}

const JSInvitations = (props: BasePageProps) => {
    const { user } = useAuth();
    const { jobFair } = useAppContext();
    const queueUserService = new QueueUserService();
    const vjfpUserService = new VJFPUserService();
    const boothService = new BoothService();
    const questionAnswerService = new QuestionAnswerService();
    const inviteService = new InviteService();
    const [invites, setInvites] = useState<(InviteItem | undefined)[]>([]);
    const [rejectBoothInfoId, setRejectBoothInfoId] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const acceptInviteAndEnterQueue = useCallback(
        async (boothsInfoId: number, groupId?: string ) => {
            if (!user?.id || !boothsInfoId || !jobFair?.id) return;
            setLoading(true);
            //const booth = await boothService.getBoothById(jobFair.id, boothsInfoId.toString());
            // Priority 1 means Invited candidates and pass null for score
            const queueQuestions = await questionAnswerService.getAllQueueQuestionsByBoothId(jobFair.id,groupId || boothsInfoId.toString());
            const _adjustedBoothId = groupId ? boothsInfoId.toString() + '-' + groupId : boothsInfoId.toString();
            const isSuccess = await queueUserService.EnterQueue(jobFair.id, _adjustedBoothId, user.id, 1, null, (queueQuestions?.length || 0), true);
            if (isSuccess) {
                await inviteService.updateStatus(jobFair.id, user.id, boothsInfoId, "ACCEPTED");
                await getInvites();
            }
            setLoading(false);
        },
        [jobFair?.id, user?.id],
    );

    const rejectInvitation = async () => {
        setLoading(true);
        if (rejectBoothInfoId && user && user.id && jobFair?.id) {
            await inviteService.updateStatus(jobFair.id, user.id, rejectBoothInfoId, InviteStatus.REJECTED);
        }

        setRejectBoothInfoId(undefined);
        await getInvites();
    }

    const closeRejectModel = () => {
        setRejectBoothInfoId(undefined);
    };

    const displayRejectModal = async (boothInfoId: number) => {
        setRejectBoothInfoId(boothInfoId);
    }

    const getInvites = () => {
        if (!user || !jobFair)
            return;
        setLoading(true);
        const invitations = inviteService.getByUserId(jobFair.id, user.id) as Promise<(InviteItem | undefined)[]>;
        invitations.then(async (_invites) => {
            if (_invites) {

                const promises = _invites.map(async (inv) => {
                    if (inv?.recruiter_id)
                        inv.recruiter = await vjfpUserService.getById(inv.recruiter_id);
                });

                await Promise.all(promises);
                setInvites(_invites);
                setLoading(false)
            }
        });

        return invitations;
    }

    useEffect(() => {
        getInvites();
    }, [user, jobFair]);

    const sortMap = new Map<string | undefined | null, number>();
    sortMap.set("PENDING", 1);
    sortMap.set("ACCEPTED", 2);
    sortMap.set("REJECTED", 3);
    sortMap.set(undefined, 0);
    sortMap.set(null, 0);

    return (
        <div className="chatPage">
            <AppTopNav title={props.title} badge={invites?.length ? invites?.length.toString() : "0"} breadcrumbs={props.breadcrumbs}>
            </AppTopNav>

            <div className="container-fluid">
                <Row className="mt-10">
                    <Col md={3} />
                    <Col md={6}>
                        <BlockUi tag="div" blocking={loading} className="jobSeekerListing">
                            <div>
                                <h6>Your invitations to interview are shown below.</h6>
                                <p className="mt-5 mb-5">During the event, click to accept or deny each invitation. If you accept, you will be automatically placed in that company's booth queue and matched with a recruiter as soon as they are available.</p>
                            </div>

                            {invites && [...invites].sort((a, b) => ((sortMap.get(a?.status) || 0) > (sortMap.get(b?.status) || 0) ? 1 : -1)).map((invitation, index) => invitation &&
                                <RecruiterListItem index={index} key={index}
                                    initials={getInitials(invitation?.recruiter?.firstName, invitation?.recruiter?.lastName)}
                                    name={invitation?.recruiter?.firstName + ' ' + invitation?.recruiter?.lastName}
                                    designation={invitation?.company_name || ''}
                                    address={''}
                                >
                                    {invitation.status === InviteStatus.PENDING ?
                                        <div className="d-flex justify-content-end">
                                            <button disabled={jobFair?.status !== JobFairStatus.Started} onClick={async () => {
                                                if (jobFair?.id && invitation.booth_info_id) {
                                                    const _booth = await boothService.getBoothById(jobFair?.id, invitation.booth_info_id.toString());
                                                    const _subBooth = _booth.subBooths?.find(b=> b.recruiters?.some(r=> r == invitation.recruiter_id));
                                                    acceptInviteAndEnterQueue(invitation.booth_info_id, _subBooth?.id );
                                                }
                                            }} className={classNames("btn btn-sm btn-dark m-1", { "btn-disable": jobFair?.status !== JobFairStatus.Started })} >
                                                <span className="svg-icon svg-icon-1">
                                                    <Check2 />
                                                </span>
                                                Accept
                                            </button>
                                            <button disabled={jobFair?.status !== JobFairStatus.Started} onClick={() => { invitation.booth_info_id && displayRejectModal(invitation.booth_info_id) }} className={classNames("btn btn-sm btn-outline btn-outline-danger m-1", { "btn-disable": jobFair?.status !== JobFairStatus.Started })}>
                                                <span className="svg-icon svg-icon-1">
                                                    <X />
                                                </span>
                                                Decline
                                            </button>
                                        </div> :
                                        <div className="d-flex justify-content-end">
                                            {invitation.status === InviteStatus.ACCEPTED ? <span className="text-success">Accepted</span> : <span className="text-danger">Declined</span>}
                                        </div>
                                    }
                                </RecruiterListItem>
                            )}

                            {!loading && invites && invites.length === 0 && <div className="d-flex border m-10 p-10 justify-content-center text-align-center">
                                <Mailbox2 color="grey" size={30} />
                                <p className="m-3"> No Invites</p>
                            </div>}
                        </BlockUi>
                    </Col>
                    <Col md={3} />
                </Row>
            </div>
            <AppModal title="Confirm" variant="YesNo" show={!!rejectBoothInfoId} onHide={closeRejectModel} onSuccess={rejectInvitation} >
                Are you sure you want to reject Invite?
            </AppModal>
        </div>
    );
}

export default JSInvitations;