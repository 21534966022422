/* eslint-disable camelcase */
/* eslint-disable typescript-enum/no-enum */
/* eslint-disable no-unused-vars */
import { CognitoUser } from '@aws-amplify/auth';
import {
  ChatClient as SDKChatClient,
  Participant,
  Stream,
  VideoClient
} from '@zoom/videosdk';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { config } from 'config';
import { PollingConfig } from 'react-detect-offline';

import { Booth, Question, QuestionAnswer, QueueUser, QueueUserStatus, UserStatus, VJFPUser } from './API';

export type ZoomClient = typeof VideoClient;
export type MediaStream = typeof Stream;
export type ZoomParticipant = Participant;
export type ChatClient = typeof SDKChatClient;

export interface AppModel {
  topicKey?: string | null | undefined;
}

export interface CGOJobfair {
  id: number,
  category?: string | null,
  date?: string | null,
  start_time?: string | null,
  end_time?: string | null,
  applicant_virtual_jf_help_url?: string | null,
  recruiter_virtual_jf_help_url?: string | null,
  daylight_savings?: string | null,
}

export interface IUser {
  id: number;
  username: string;
  firstName?: string;
  lastName?: string;
  roles?: [string];
  status?: UserStatus;
  token: CognitoAccessToken;
  email?: string;
  avatar?: string;
  isAvailableForChat: boolean;
}

export interface CognitoUserResponse extends CognitoUser {
  username: string;
  Session: string;
  signInUserSession?: any;
  authenticationFlowType: string;
  keyPrefix: string;
  userDataKey: string;
}

export interface AppNotification {
  headerText: string,
  bodyText: string,
}

export const Roles = {
  Admin: 'Admin',
  Recruiter: 'Recruiter',
  Applicant: 'Applicant',
  EmployerAdmin: 'EmployerAdmin'
};

export interface BasePageProps {
  title?: string;
  breadcrumbs?: {
    text: string,
    url?: string
  }[];
}

export interface QueueUserV1 extends QueueUser {
  vjfpUser?: VJFPUser
}

export type Candidate = {
  vjfpUser?: VJFPUser,
  vjfpUserId: number,
  boothId: string,
  priority: number,
  createdAt: string,
  isBusy: boolean,
  score: number | null | undefined,
  nextInQueueSetBy?: number | null,
  interviewer?: number | null,
  interviewerName?: string,
  status: QueueUserStatus,
  presenceSubscription?: {
    dispose: () => void;
  }
}

const hostname = window.location.origin === 'localhost' ? 'localhost' : config.vjfpBaseUrl;

export const polling: PollingConfig = {
  url: `${hostname}/ping-back.gif`,
  enabled: true,
  interval: 5000,
  timeout: 5000
};

// eslint-disable-next-line typescript-enum/no-enum
export enum InviteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export const jobFairUrl = '/jobfairs/:jobFairId';
export const jobSeekerUrl = `${jobFairUrl}/job-seeker`;
export const adminUrl = `${jobFairUrl}/admin`;
export const recruiterUrl = `${jobFairUrl}/recruiter`;
export const employerAdminUrl = '/employer-admin';

export enum JobFairStatus {
  Upcoming = 'Upcoming',
  Started = 'Started',
  Ended = 'Ended',
  ClosingSession = 'ClosingSession'
};

export interface JobFairOld {
  id: string;
  name: string;
  start: string;
  end: string;
  status: JobFairStatus;
  applicant_virtual_jf_help_url: string;
  recruiter_virtual_jf_help_url: string;
  timeRemainingInMs: number;

  raw: CGOJobfair;
}

export interface JobFair {
  id: string;
  name: string;
  companyId: string;
  startDate: string;
  startTime: string;
  timezone: string;
  durationHours: number;
  durationMinutes: number;
  paymentStatus: 'Paid' | 'UnPaid';
  createdAt: string;
  status: JobFairStatus;
}

export interface BoothState {
  boothId: string;
  isSubscribed: boolean;
  companyImageUrl: string;
};

export interface BoothWithState {
  booth?: Booth,
  boothState?: BoothState
}

export interface IBoothTab {
  title: string
  onClick: Function
}

export interface IQueueQuestionAnswerForm {
  queueQuestion: Array<QuestionAnswer>
  boothId: string
}

export interface IBoothResumeUploaded {
  id: string
  fileName: string
  fileKey: string
  fileUrl?: string
  jobTitle: string | null | undefined
  notes: string | null | undefined
}

export interface IBoothResumeSelected {
  selectedFileName: string
  selectedFileKey: string
  notes: string
  jobTitle: string
  uploadProgress: number
  isFileUploading: boolean
}

export interface dropzoneError {
  errorCode: string,
  getErrorMsg: Function
}

export interface CGOBoothInfo {
  id: number,
  employer_id?: number | null,
  jobfair_id?: number | null,
  isFeatured?: boolean | null,
  interview_duration_min?: number | null,
  minimum_score?: number | null,
  banner_company_name?: string | null,
  company_profile?: string | null,
  available_positions?: string | null,
}

export interface CGOInvite {
  id: number,
  jobfair_id?: number | null,
  booth_info_id?: number | null,
  applicant_id?: number | null,
  recruiter_id?: number | null,
  status?: string | null,
  company_name?: string | null,
}

export interface CGOInviteAppModel extends CGOInvite, AppModel{
  count: number;
}

export interface CGOResume {
  id: number,
  applicant_id?: number | null,
  attached_resume_file_name?: string | null,
  attached_resume_content_type?: string | null,
}

export interface CGOUser {
  id: number,
  type?: string | null,
  roles?: string | null,
  title?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  job_title?: string | null,
  greeting?: string | null,
  ethnicity?: string | null,
  gender?: string | null,
  availability_date?: string | null,
  branch_of_service?: string | null,
  education_level?: string | null,
  geographic_preference?: string | null,
  security_clearance?: string | null,
  type_of_applicant?: string | null,
  military_status?: string | null,
  us_citizen?: boolean | null,
  willing_to_relocate?: boolean | null,
  employer_id?: number | null,
  picture_url?: string | null,
  summary?: string | null,
}

export type NGError = {
  code: string;
  message: string;
}

export interface NgAPIResponse<T> {
  isSuccess: boolean;
  data: T;
  errors: NGError[];
}

export interface QuestionsResponse {
  boothId: string;
  jobFairId: string;

  questions: Question[];
}

export interface ChangeEvent<T> extends AppModel {

  value : T,

  mode : 'create'| 'update'| 'upsert' | 'delete';

 }
