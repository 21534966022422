import React from 'react';
import './ComingSoon.css';
import { toAbsoluteUrl } from '_metronic/helpers';

const ComingSoon: React.FC = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Coming Soon</h1>
      <p className="coming-soon-text">We are working hard to bring you something amazing. Stay tuned!</p>
      <div className="coming-soon-timer">
      <img width="300px" alt='Logo' src={toAbsoluteUrl('cover_logo.jpg')} />
      </div>
      <div className="coming-soon-footer">
        <p>Follow us on social media:</p>
        <div className="social-icons">
          {/* Replace '#' with your social media links */}
          <a href="#" className="icon">Facebook</a>
          <a href="#" className="icon">Twitter</a>
          <a href="#" className="icon">Instagram</a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
