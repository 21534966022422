
import { Amplify } from 'aws-amplify';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
// eslint-disable-next-line camelcase
import { AppProvider } from './context/app-context';
import { AuthProvider } from './context/auth-context';
import { Routes } from './routing/Routes';

Amplify.configure({
  Auth: {
    region: 'us-east-1', // Your Cognito User Pool region
    identityPoolId: 'us-east-1:e8b2bddf-5ff7-4a43-a1b8-05220c3bb005',
    userPoolId: 'us-east-1_Pmy9OEKcU', // Your existing Cognito User Pool ID
    userPoolWebClientId: '49j080veqch3ss1d8e3h41equ2', // Your existing User Pool App Client ID
    mandatorySignIn: false,
    Storage: {
      AWSS3: {
        region: 'us-east-1', // e.g., 'us-east-1'
        bucket: 'videojobfairs-docs'
      }
    }
  }
});

// intercept
axiosRetry(axios, {
  retries: 3,
  retryCondition: (error: AxiosError) => {
    return axiosRetry.isNetworkError(error) || axiosRetry.isRetryableError(error) || error.response?.status === 429;
  },
  retryDelay: axiosRetry.exponentialDelay
});

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  useEffect(() => {
    if (window.location.hostname === 'www.videojobfairs.com' && window.location.pathname === '/') {
      // Redirect to /coming-soon if at the root path
      window.location.replace('/coming-soon');
    }
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AppProvider>
              <AuthProvider>
                <Routes />
              </AuthProvider>
            </AppProvider>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
