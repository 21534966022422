import * as Yup from 'yup'

import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'

import { Auth } from 'aws-amplify'
import clsx from 'clsx'
import { useFormik } from 'formik'

type Params = {
    email: string;
}


const initialValues = {
    email: '',
    code: '',
    password: '',
    changepassword: ''
}

const forgotPasswordCodeVerificationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    code: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Code is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        })
})

export function ForgotPasswordCodeVerification() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const params = useParams<Params>();

    initialValues.email = params.email;
    
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordCodeVerificationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined);

            Auth.forgotPasswordSubmit(values.email, values.code, values.password)
                .then(() => {
                    setHasErrors(false);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.code === 'InvalidPasswordException') {
                        formik.setFieldError('password', 'Password does not conform to policy: Password must have symbol characters')
                      } else {
                        setHasErrors(true)
                        setStatus('The verification code entered is incorrect')
                      }
                    setLoading(false);
                    setSubmitting(false);
                    setStatus('The verification code entered is incorrect');
                })

        }
    });

    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark mb-3'>Reset Password</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='mb-lg-15 alert alert-info'>
                        <div className='alert-text font-weight-bold'>
                            Sent password reset code. Please check your email. </div>
                    </div>
                    {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            Sorry, looks like there are some errors detected, please try again.
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                        <div className='text-info'>Password reset is successful</div>
                    </div>
                )}
                {/* end::Title */}

                {/* begin::Form group Email */}
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                    <input
                        placeholder='Email'
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            { 'is-invalid': formik.touched.email && formik.errors.email },
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group Code */}
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>Verification Code</label>
                    <input
                        placeholder='Code'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('code')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            { 'is-invalid': formik.touched.code && formik.errors.code },
                            {
                                'is-valid': formik.touched.code && !formik.errors.code,
                            }
                        )}
                    />
                    {formik.touched.code && formik.errors.code && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.code}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group Password */}
                <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                        <div className='position-relative mb-3'>
                            <input
                                type='password'
                                placeholder='Password'
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                    <input
                        type='password'
                        placeholder='Password confirmation'
                        autoComplete='off'
                        {...formik.getFieldProps('changepassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                            },
                            {
                                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                            }
                        )}
                    />
                    {formik.touched.changepassword && formik.errors.changepassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.changepassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                    >
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
                {/* end::Form group */}
            </form>
        </>
    )
}
